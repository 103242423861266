import React, { useEffect, useState } from "react";

function Section_10({
  handleInputChange,
  formik,
  handleInputSave,
  removeArticlesAttachedRow,
  addArticlesAttachedRow,
  lang,
  fileUploadFunction,
  isPageComplete,
}) {
  useEffect(() => {
    isPageComplete(7, 1, 1);
  }, []);
  return (
    <div>
      <div>
        <h3>
          {lang == "english"
            ? "Description of articles attached to this application"
            : lang == "hindi"
            ? "आवेदन से संलग्न अनुबन्ध"
            : "आवेदनपत्रे संलग्नानि अनुलग्नकानि"}
        </h3>
        <p className="text-primary">
          {lang == "english"
            ? "All fields are mandatory"
            : lang == "hindi"
            ? "सभी प्रश्‍न अनिवार्य हैं।"
            : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
        </p>

        <div>
          <blockquote className="mb-30">
            {lang == "english"
              ? "Details of the copiesof certificates attached with the application ( Name of certificate like- shastri, acharya, samhita, pad, kram, ghan, award, honour etc.).Give a attached serial number givingto each document (Mention this should be in the order in which the copies are attached ) - ( Add additional pages if necessary )"
              : lang == "hindi"
              ? "आवेदनपत्र के साथ संलग्न प्रमाणपत्र की प्रतिलिपियों का विवरण (प्रमाणपत्र का नाम यथा- शास्त्री, आचार्य, संहिता, पद, क्रम, घन, पुरस्कार, सम्मान इत्यादि) देते हुए संलग्न क्रमसंख्या (जिस क्रम पर प्रतिलिपि संलग्न की गई है) का उल्लेख करें-(आवश्यकता होने पर अतिरिक्त पृष्ठ जोड़े)"
              : "आवेदनपत्रेण सह संलग्नप्रमाणपत्राणां प्रतिलिपीनां विवरणं (प्रमाणपत्रस्य नाम यथा शास्त्री, आचार्यः, संहिता, पदः, क्रमः, घनः, पुरस्कारः, सम्मानः इत्यादिकं) दत्त्वा संलग्नस्य क्रमाङ्कस्य (यस्मिन् क्रमे प्रतिलिपिः अस्ति) उल्लेखं करोतु-(आवश्यकता चेत् अतिरिक्तपृष्ठानि योजयतु)"}
          </blockquote>
        </div>
        <div className="table-responsive mb-30">
          <table className="table">
            <thead>
              <tr>
                <th>
                  {lang == "english"
                    ? "Details of documents"
                    : lang == "hindi"
                    ? "प्रलेखों का विवरण "
                    : "प्रलेखानां विवरणम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Upload certificates  "
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु "}
                </th>
                <th width="120" className="text-right">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {lang == "english"
                    ? "Your passport size photo"
                    : lang == "hindi"
                    ? "अपना पासपोर्ट साइज फोटो यहाँ"
                    : "स्वस्य पासपोर्ट-साइज-फोटो इति अत्र"}
                </td>
                <td>
                  {formik.values?.p_upload_passport_photo_string && (
                    <button
                      className="uploadLink"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.p_upload_passport_photo_string}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                      onBlur={(e) => handleInputSave()}
                    >
                      {`${formik?.values?.p_upload_passport_photo_string.replace(
                        "upload-file/",
                        ""
                      )}`}
                    </button>
                  )}
                </td>
                <td></td>
              </tr>

              <tr>
                <td>
                  {lang == "english"
                    ? "Affidavit"
                    : lang == "hindi"
                    ? "शपथपत्र"
                    : "शपथपत्रम्"}
                </td>
                <td>
                  {formik.values?.ad_affidavit_file_string && (
                    <button
                      className="uploadLink"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.ad_affidavit_file_string}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                      onBlur={(e) => handleInputSave()}
                    >
                      {`${formik?.values?.ad_affidavit_file_string.replace(
                        "upload-file/",
                        ""
                      )}`}
                    </button>
                  )}
                </td>
                <td></td>
              </tr>

              {formik.values.vi_list_of_exam_passed?.map((row, index) => (
                <>
                  {formik.values?.vi_list_of_exam_passed[index]
                    .vi_exam_certificate &&
                  formik.values?.vi_list_of_exam_passed[index]
                    .vi_exam_certificate !== "N/A" ? (
                    <tr>
                     
                      <td>
                        {
                          formik.values?.vi_list_of_exam_passed[index]
                            .vi_name_of_exam
                        }
                      </td>
                      <td>
                        {formik.values?.vi_list_of_exam_passed[index]
                          .vi_exam_certificate && (
                          <button
                            className="uploadLink"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vi_list_of_exam_passed[index].vi_exam_certificate}`,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          >
                            {`${formik.values?.vi_list_of_exam_passed[
                              index
                            ].vi_exam_certificate.replace("upload-file/", "")}`}
                          </button>
                        )}
                      </td>
                      <td></td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </>
              ))}

              {formik.values.vi_list_studied_veda_details?.map((row, index) => (
                <>
                  {formik.values?.vi_list_studied_veda_details[index]
                    .vi_exam_certificate &&
                  formik.values?.vi_list_studied_veda_details[index]
                    .vi_exam_certificate !== "N/A" ? (
                    <tr>
                     
                      <td>
                        {
                          formik.values?.vi_list_studied_veda_details[index]
                            .vi_name_of_granth
                        }
                      </td>
                      <td>
                        {formik.values?.vi_list_studied_veda_details[index]
                          .vi_exam_certificate && (
                          <button
                            className="uploadLink"
                            onClick={(e) => {
                              e.preventDefault();
                              window.open(
                                `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vi_list_studied_veda_details[index].vi_exam_certificate}`,
                                "_blank",
                                "noopener,noreferrer"
                              );
                            }}
                          >
                            {`${formik.values?.vi_list_studied_veda_details[
                              index
                            ].vi_exam_certificate.replace("upload-file/", "")}`}
                          </button>
                        )}
                      </td>
                      <td></td>
                    </tr>
                  ) : (
                    <></>
                  )}
                </>
              ))}

              {formik.values?.vt_attendance_in_veda_sabhas_table?.map(
                (row, index) => (
                  <>
                    {formik.values?.vt_attendance_in_veda_sabhas_table[index]
                      .certificate_string &&
                    formik.values?.vt_attendance_in_veda_sabhas_table[index]
                      .certificate_string !== "N/A" ? (
                      <tr>
                        
                        <td>
                          {
                            formik.values?.vt_attendance_in_veda_sabhas_table[
                              index
                            ].student_name
                          }
                        </td>
                        <td>
                          {formik.values?.vt_attendance_in_veda_sabhas_table[
                            index
                          ].certificate_string && (
                            <button
                              className="uploadLink"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vt_attendance_in_veda_sabhas_table[index].certificate_string}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                            >
                              {`${formik.values?.vt_attendance_in_veda_sabhas_table[
                                index
                              ].certificate_string.replace(
                                "upload-file/",
                                ""
                              )}`}
                            </button>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                )
              )}

              {formik.values?.vt_veda_students_currently_being_taught_by_you_table?.map(
                (son, index) => (
                  <>
                    {formik.values
                      ?.vt_veda_students_currently_being_taught_by_you_table[
                      index
                    ].certificate_string &&
                    formik.values
                      ?.vt_veda_students_currently_being_taught_by_you_table[
                      index
                    ].certificate_string !== "N/A" ? (
                      <tr>
                       
                        <td>
                          {
                            formik.values
                              ?.vt_veda_students_currently_being_taught_by_you_table[
                              index
                            ].student_name
                          }
                        </td>
                        <td>
                          {formik.values
                            ?.vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].certificate_string && (
                            <button
                              className="uploadLink"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vt_veda_students_currently_being_taught_by_you_table[index].certificate_string}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                            >
                              {`${formik.values?.vt_veda_students_currently_being_taught_by_you_table[
                                index
                              ].certificate_string.replace(
                                "upload-file/",
                                ""
                              )}`}
                            </button>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                )
              )}

              {formik.values?.vl_list_of_attendance_in_veda_sabha_table?.map(
                (son, index) => (
                  <>
                    {formik.values?.vl_list_of_attendance_in_veda_sabha_table[
                      index
                    ].certificate_string &&
                    formik.values?.vl_list_of_attendance_in_veda_sabha_table[
                      index
                    ].certificate_string != "N/A" ? (
                      <tr>
                       
                        <td>
                          {
                            formik.values
                              ?.vl_list_of_attendance_in_veda_sabha_table[index]
                              .meeting_or_conference
                          }
                        </td>
                        <td>
                          {formik.values
                            ?.vl_list_of_attendance_in_veda_sabha_table[index]
                            .certificate_string && (
                            <button
                              className="uploadLink"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vl_list_of_attendance_in_veda_sabha_table[index].certificate_string}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                            >
                              {`${formik.values?.vl_list_of_attendance_in_veda_sabha_table[
                                index
                              ].certificate_string.replace(
                                "upload-file/",
                                ""
                              )}`}
                            </button>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                )
              )}

              {formik.values?.vl_have_you_participated_in_vedic_shrauta_yajna_table?.map(
                (son, index) => (
                  <>
                    {formik.values
                      ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                      index
                    ].certificate_string &&
                    formik.values
                      ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                      index
                    ].certificate_string != "N/A" ? (
                      <tr>
                       
                        <td>
                          {
                            formik.values
                              ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                              index
                            ].yajna
                          }
                        </td>
                        <td>
                          {formik.values
                            ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                            index
                          ].certificate_string && (
                            <button
                              className="uploadLink"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vl_have_you_participated_in_vedic_shrauta_yajna_table[index].certificate_string}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                            >
                              {`${formik.values?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                                index
                              ].certificate_string.replace(
                                "upload-file/",
                                ""
                              )}`}
                            </button>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                )
              )}

              {formik.values?.vl_information_on_testimonials_and_awards_received_table?.map(
                (son, index) => (
                  <>
                    {formik.values
                      ?.vl_information_on_testimonials_and_awards_received_table[
                      index
                    ].certificate_string &&
                    formik.values
                      ?.vl_information_on_testimonials_and_awards_received_table[
                      index
                    ].certificate_string != "N/A" ? (
                      <tr>
                        {/* <td></td> */}
                        <td>
                          {
                            formik.values
                              ?.vl_information_on_testimonials_and_awards_received_table[
                              index
                            ].award_name
                          }
                        </td>
                        <td>
                          {formik.values
                            ?.vl_information_on_testimonials_and_awards_received_table[
                            index
                          ].certificate_string && (
                            <button
                              className="uploadLink"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vl_information_on_testimonials_and_awards_received_table[index].certificate_string}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                            >
                              {`${formik.values?.vl_information_on_testimonials_and_awards_received_table[
                                index
                              ].certificate_string.replace(
                                "upload-file/",
                                ""
                              )}`}
                            </button>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                )
              )}

              {formik.values?.vl_information_about_books_table?.map(
                (son, index) => (
                  <>
                    {formik.values?.vl_information_about_books_table[index]
                      .certificate_string &&
                    formik.values?.vl_information_about_books_table[index]
                      .certificate_string !== "N/A" ? (
                      <tr>
                       
                        <td>
                          {
                            formik.values?.vl_information_about_books_table[
                              index
                            ].topic
                          }
                        </td>
                        <td>
                          {formik.values?.vl_information_about_books_table[
                            index
                          ].certificate_string && (
                            <button
                              className="uploadLink"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vl_information_about_books_table[index].certificate_string}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                            >
                              {`${formik.values?.vl_information_about_books_table[
                                index
                              ].certificate_string.replace(
                                "upload-file/",
                                ""
                              )}`}
                            </button>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    ) : (
                      <></>
                    )}
                  </>
                )
              )}

              {formik.values.al_description_of_articles?.map(
                (article, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        name={`al_description_of_articles[${index}].details_of_document`}
                        className="form-control"
                        id={`al_description_of_articles[${index}].details_of_document`}
                        value={
                          formik.values.al_description_of_articles[index]
                            .details_of_document || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `al_description_of_articles[${index}.details_of_document]`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors?.al_description_of_articles &&
                      formik.errors?.al_description_of_articles[index]
                        .details_of_document ? (
                        <div className="text-danger">
                          {formik.errors?.al_description_of_articles[index]
                            .details_of_document &&
                            formik.errors?.al_description_of_articles[index]
                              .details_of_document}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        name={`al_description_of_articles[${index}].attach_file`}
                        className="form-control"
                        id={`al_description_of_articles[${index}].attach_file`}
                        onChange={async (e) => {
                          if (e.target.files.length > 0) {
                            await fileUploadFunction(
                              `al_description_of_articles[${index}].attach_file_string`,
                              e.target.files[0]
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.values?.al_description_of_articles[index]
                        .attach_file_string && (
                        <button
                          className="uploadLink"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.al_description_of_articles[index].attach_file_string}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {`${formik.values?.al_description_of_articles[
                            index
                          ].attach_file_string.replace("upload-file/", "")}`}
                        </button>
                      )}
                      {formik.touched &&
                      formik.errors?.al_description_of_articles &&
                      formik.errors?.al_description_of_articles[index]
                        .attach_file_string ? (
                        <div className="text-danger">
                          {formik.errors?.al_description_of_articles[index]
                            .attach_file_string &&
                            formik.errors?.al_description_of_articles[index]
                              .attach_file_string}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values.al_description_of_articles.length >= 2 ? (
                        <button
                          type="button"
                          className="btn btn-outline-danger"
                          onClick={(e) => removeArticlesAttachedRow(index, e)}
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className="border-bottom-0">
                <td className="text-right" colspan="3">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={(e) => addArticlesAttachedRow(e)}
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Section_10;
