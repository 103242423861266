import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import axiosPrivate from "../../Hooks/axiosPrivate";
// import LoaderContext from "../../context/Loader/LoaderContext";
import Loader from "../../context/Loader/Loader.js";

const Main = (props) => {
  const navigate = useNavigate();

  const login = localStorage.getItem("userData");
  const [loaderCheck, setLoaderCheck] = useState(false);

  useEffect(() => {
    async function checkLoginOrNot() {
      setLoaderCheck(true);
      const refreshToken = localStorage.getItem("refreshToken");
      const responseAnswers = await axiosPrivate.get(`user-answer-data`);
      if (
        responseAnswers.status == 200 &&
        responseAnswers?.data?.data &&
        responseAnswers?.data?.data.length > 0 &&
        responseAnswers.data.data[0].status == "In Progress"
      ) {
        let url = "";
        if (responseAnswers?.data?.data[0]?.type == "Vedavidyarthi") {
          url = "/student_form";
        } else if (responseAnswers?.data?.data[0]?.type == "Vedaadhyapaka") {
          url = "/teacher_form";
        } else if (responseAnswers?.data?.data[0]?.type == "Vedavidyalaya") {
          url = "/school_form";
        }
        setLoaderCheck(false);
        navigate(url, {
          state: {
            lang: responseAnswers.data.data[0].language,
            user_type: responseAnswers.data.data[0].type,
            apply_type: responseAnswers.data.data[0].user_option,
            oldData: responseAnswers.data.data[0],
          },
        });
      } else {
        setLoaderCheck(false);
        navigate("/home");
      }
    }
    if (login != null) {
      checkLoginOrNot();
      setLoaderCheck(false);
    } else {
      setLoaderCheck(false);
      navigate("/");
    }
  }, []);

  return (
    <Fragment>
      <Navbar />
      <div className="MainContainer">
        <Outlet />
      </div>
    </Fragment>
  );
};

export default Main;
