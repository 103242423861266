import React, { useEffect, useState } from "react";
import "./style.css";

function page2_information_of_vedic_educaiton({
  formData,
  formik,
  handleInputChange,
  handleInputSave,
  addVedadhyapakaVedvidyalayaDetails,
  removeVedadhyapakaVedvidyalayaDetails,
  addRelativeDetails,
  removeRelativeDetails,
  addGranthaDetails,
  removeGranthaDetails,
  addAwardDetails,
  removeAwardDetails,
  lang,
  fileUploadFunction,
  addvi_ved_and_passed_of_ved_examinations,
  removevi_ved_and_passed_of_ved_examinations,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(17);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.vi_studied_veda, count);
    count = handleInputValue(formik?.values?.vi_studied_vedasakha, count);
    count = handleInputValue(formik?.values?.vi_more_than_one_veda, count);
    count = handleInputValue(
      formik?.values?.vi_ved_and_passed_of_ved_examinations[0]
        .vi_month_and_year,
      count
    );
    count = handleInputValue(
      formik?.values?.vi_ved_and_passed_of_ved_examinations[1]
        .vi_month_and_year,
      count
    );
    count = handleInputValue(
      formik?.values?.vedadhyapaka_vedvidyalaya_details[0]?.from,
      count
    );
    count = handleInputValue(
      formik?.values?.vedadhyapaka_vedvidyalaya_details[0]?.till,
      count
    );
    count = handleInputValue(
      formik?.values?.vedadhyapaka_vedvidyalaya_details[0]?.vedadhyapak_name,
      count
    );
    count = handleInputValue(
      formik?.values?.vedadhyapaka_vedvidyalaya_details[0]?.vedadhyapak_mobile,
      count
    );
    count = handleInputValue(
      formik?.values?.vedadhyapaka_vedvidyalaya_details[0]?.vedvidyalaya_name,
      count
    );
    count = handleInputValue(formik?.values?.is_relative, count);
    count = handleInputValue(
      formik?.values?.grantha_details[0].grantha_name,
      count
    );
    count = handleInputValue(
      formik?.values?.grantha_details[0].start_study,
      count
    );
    count = handleInputValue(
      formik?.values?.grantha_details[0].end_study,
      count
    );
    count = handleInputValue(
      formik?.values?.grantha_details[0].exam_month_year,
      count
    );
    count = handleInputValue(
      formik?.values?.grantha_details[0].certificate_string,
      count
    );
    count = handleInputValue(formik?.values?.have_received_ward, count);
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(2, totalCount, actualCount);
  }, [actualCount, totalCount]);

  let RigvedEnglish = [
    "Shakalashakha",
    "Ashvalayanashakha",
    "Bashkalashakha",
    "Shankhayanashakha",
  ];

  let RigvedHindi = [
    "शाकलशाखा ",
    "आश्वलायनशाखा ",
    "बाष्कलशाखा ",
    "शांखायनशाखा ",
  ];

  let RigvedSanskrit = [
    "शाकलशाखा ",
    "आश्वलायनशाखा ",
    "बाष्कलशाखा ",
    "शांखायनशाखा ",
  ];

  RigvedEnglish =
    lang == "english"
      ? RigvedEnglish
      : lang == "hindi"
      ? RigvedHindi
      : RigvedSanskrit;

  let ShuklayajurvedEnglish = ["Kanvashakha", "Madhyandinashakha"];
  let ShuklayajurvedHindi = ["काण्वशाखा", "माध्यन्दिनशाखा"];
  let ShuklayajurvedSanskrit = ["काण्वशाखा ", "माध्यन्दिनशाखा "];

  ShuklayajurvedEnglish =
    lang == "english"
      ? ShuklayajurvedEnglish
      : lang == "hindi"
      ? ShuklayajurvedHindi
      : ShuklayajurvedSanskrit;

  let KrishnayajurvedEnglish = [
    "Taittiriyashakha",
    "Maitrayanishakha",
    "Kathashakha",
    "Kapishthal",
  ];
  let KrishnayajurvedHindi = [
    "तैत्तिरीयशाखा",
    "मैत्रायणीशाखा",
    "कठशाखा",
    "कपिष्ठलशाखा",
  ];
  let KrishnayajurvedSanskrit = [
    "तैत्तिरीयशाखा ",
    "मैत्रायणीशाखा ",
    "कठशाखा ",
    "कपिष्ठलशाखा ",
  ];
  KrishnayajurvedEnglish =
    lang == "english"
      ? KrishnayajurvedEnglish
      : lang == "hindi"
      ? KrishnayajurvedHindi
      : KrishnayajurvedSanskrit;

  let SamvedEnglish = [
    "Kauthumashakha",
    "Ranayaniyashakha",
    "Maitrayanishakha",
  ];
  let SamvedHindi = ["कौथुमशाखा", "राणायनीयशाखा", "मैत्रायणीशाखा"];
  let SamvedSanskrit = ["कौथुमशाखा ", "राणायनीयशाखा ", "मैत्रायणीशाखा "];

  SamvedEnglish =
    lang == "english"
      ? SamvedEnglish
      : lang == "hindi"
      ? SamvedHindi
      : SamvedSanskrit;

  let AtharvavedaEnglish = ["Shaunakashakha", "Paippaladashakha"];
  let AtharvavedaHindi = ["शौनकशाखा ", "पैप्पलादशाखा "];
  let AtharvavedaSanskrit = ["शौनकशाखा ", "पैप्पलादशाखा "];

  AtharvavedaEnglish =
    lang == "english"
      ? AtharvavedaEnglish
      : lang == "hindi"
      ? AtharvavedaHindi
      : AtharvavedaSanskrit;

  console.log(formik.values.vi_ved_and_passed_of_ved_examinations);

  return (
    <div>
      <div className="">
        <h3>
          {lang == "english"
            ? "Information of vedic education"
            : lang == "hindi"
            ? "वैदिक शिक्षा की जानकारी"
            : "वैदिकशिक्षायाः सूचना"}
        </h3>
        <p className="text-primary mb-30">
          {lang == "english"
            ? "All fields are mandatory"
            : lang == "hindi"
            ? "सभी प्रश्न अनिवार्य हैं।"
            : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
        </p>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label>
              {lang == "english"
                ? "Which ved have you studied"
                : lang == "hindi"
                ? "अधीत वेद"
                : "अधीतः वेदः"}
            </label>
            <select
              name="vi_studied_veda"
              id="vi_studied_veda"
              value={formik?.values?.vi_studied_veda || ""}
              onChange={(e) => handleInputChange("vi_studied_veda", e)}
              onBlur={(e) => handleInputSave()}
              className="form-control"
            >
              <option selected>
                ---{" "}
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने "
                  : " चिनोतु"}{" "}
                ---
              </option>
              <option value="Rigveda">
                {lang == "english"
                  ? "Rigveda"
                  : lang == "hindi"
                  ? "ऋग्वेद "
                  : "ऋग्वेदः"}
              </option>
              <option value="Shuklayajurveda">
                {lang == "english"
                  ? "Shuklayajurveda"
                  : lang == "hindi"
                  ? "शुक्लयजुर्वेद"
                  : "शुक्लयजुर्वेदः "}
              </option>
              <option value="Krishnayajurveda">
                {lang == "english"
                  ? "Krishnayajurveda"
                  : lang == "hindi"
                  ? "कृष्णयजुर्वेद"
                  : "कृष्णयजुर्वेदः "}
              </option>
              <option value="Samveda">
                {lang == "english"
                  ? "Samveda"
                  : lang == "hindi"
                  ? "सामवेद "
                  : "सामवेदः "}
              </option>
              <option value="Atharvaveda">
                {lang == "english"
                  ? "Atharvaveda"
                  : lang == "hindi"
                  ? "अथर्ववेद "
                  : "अथर्ववेदः "}
              </option>
            </select>
            {formik.touched && formik.errors.vi_studied_veda ? (
              <div className="text-danger">{formik.errors.vi_studied_veda}</div>
            ) : null}
          </div>

          <div className="col-md-6 col-lg-4 form-group">
            <label>
              {lang == "english"
                ? "Studied vedashakha"
                : lang == "hindi"
                ? "अधीत वेदशाखा"
                : "अधीता वेदशाखा"}
            </label>
            <select
              name="vi_studied_vedasakha"
              id="vi_studied_vedasakha"
              value={formik?.values?.vi_studied_vedasakha || ""}
              onChange={(e) => {
                handleInputChange("vi_studied_vedasakha", e);
                if (e.target.value == "Other") {
                  formik.setFieldValue("vi_studied_vedasakha_other", "");
                }
                if (e.target.value != "Other") {
                  formik.setFieldValue("vi_studied_vedasakha_other", "N/A");
                }
              }}
              onBlur={(e) => handleInputSave()}
              className="form-control"
            >
              <option selected>
                ---{" "}
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने "
                  : " चिनोतु"}{" "}
                ---
              </option>
              {formik?.values?.vi_studied_veda === "Rigveda" &&
                RigvedEnglish.map((shakha, index) => (
                  <option key={index} value={shakha}>
                    {shakha}
                  </option>
                ))}
              {formik?.values?.vi_studied_veda === "Shuklayajurveda" &&
                ShuklayajurvedEnglish.map((shakha, index) => (
                  <option key={index} value={shakha}>
                    {shakha}
                  </option>
                ))}
              {formik?.values?.vi_studied_veda === "Krishnayajurveda" &&
                KrishnayajurvedEnglish.map((shakha, index) => (
                  <option key={index} value={shakha}>
                    {shakha}
                  </option>
                ))}
              {formik?.values?.vi_studied_veda === "Samveda" &&
                SamvedEnglish.map((shakha, index) => (
                  <option key={index} value={shakha}>
                    {shakha}
                  </option>
                ))}
              {formik?.values?.vi_studied_veda === "Atharvaveda" &&
                AtharvavedaEnglish.map((shakha, index) => (
                  <option key={index} value={shakha}>
                    {shakha}
                  </option>
                ))}
            </select>
            {formik.touched && formik.errors.vi_studied_vedasakha ? (
              <div className="text-danger">
                {formik.errors.vi_studied_vedasakha}
              </div>
            ) : null}
          </div>
          {formik?.values?.vi_studied_vedasakha == "Other" ? (
            <>
              <div className={`col-md-6 col-lg-4 form-group`}>
                <label>
                  {" "}
                  {lang == "english"
                    ? "Other vedashakha"
                    : lang == "hindi"
                    ? "अन्य वेदशाखा "
                    : "अन्यः वेदशाखा"}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="vi_studied_vedasakha_other"
                  id="vi_studied_vedasakha_other"
                  value={formik?.values?.vi_studied_vedasakha_other || ""}
                  onChange={(e) =>
                    handleInputChange("vi_studied_vedasakha_other", e)
                  }
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched && formik.errors.vi_studied_vedasakha_other ? (
                  <div className="text-danger">
                    {formik.errors.vi_studied_vedasakha_other}
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-8 form-group">
            <label>
              {lang == "english"
                ? "If you have studied more than one vedashakha?"
                : lang == "hindi"
                ? "यदि एक से अधिक वेदशाखा का अध्ययन किया है?"
                : "यदि भवान् एकाधिकं वेदशाखां वा अधीतवान् तर्हि?"}
            </label>
            <select
              name="vi_more_than_one_veda_option"
              id="vi_more_than_one_veda_option"
              value={formik?.values?.vi_more_than_one_veda_option || ""}
              onChange={(e) => {
                handleInputChange("vi_more_than_one_veda_option", e);
                if (e.target.value == "yes") {
                  formik.setFieldValue("vi_more_than_one_veda", "");
                }
                if (e.target.value == "no") {
                  formik.setFieldValue("vi_more_than_one_veda", "N/A");
                  formik.setFieldValue("vi_more_than_one_veda_other", "N/A");
                }
              }}
              className="form-control"
              onBlur={(e) => handleInputSave()}
            >
              <option selected>
                ---{" "}
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने "
                  : " चिनोतु"}{" "}
                ---
              </option>
              <option value="yes">
                {lang == "english" ? "Yes" : lang == "hindi" ? "हाँ" : "आम्"}
              </option>
              <option value="no">
                {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
              </option>
            </select>
            {formik.touched && formik.errors.vi_more_than_one_veda_option ? (
              <div className="text-danger">
                {formik.errors.vi_more_than_one_veda_option}
              </div>
            ) : null}
          </div>
          {formik?.values?.vi_more_than_one_veda_option == "yes" ? (
            <>
              <div className="col-md-12 col-lg-8 form-group">
                <label>
                  {lang == "english"
                    ? "vedashakha"
                    : lang == "hindi"
                    ? "वेदशाखा"
                    : "वेदशाखां"}
                </label>
                <select
                  name="vi_more_than_one_veda"
                  id="vi_more_than_one_veda"
                  value={formik?.values?.vi_more_than_one_veda || ""}
                  onChange={(e) => {
                    handleInputChange("vi_more_than_one_veda", e);
                    if (e.target.value == "Other") {
                      formik.setFieldValue("vi_more_than_one_veda_other", "");
                    }
                    if (e.target.value != "Other") {
                      formik.setFieldValue(
                        "vi_more_than_one_veda_other",
                        "N/A"
                      );
                    }
                  }}
                  className="form-control"
                  onBlur={(e) => handleInputSave()}
                >
                  <option selected>
                    ---{" "}
                    {lang == "english"
                      ? "Select"
                      : lang == "hindi"
                      ? "चुने "
                      : " चिनोतु"}{" "}
                    ---
                  </option>
                  <option value="shakalshakha">
                    {" "}
                    {lang == "english"
                      ? "Shakalashakha"
                      : lang == "hindi"
                      ? "शाकलशाखा"
                      : " शाकलशाखा "}{" "}
                  </option>
                  <option value="Ashvlayanshakha">
                    {lang == "english"
                      ? "Ashvalayanahshakha"
                      : lang == "hindi"
                      ? "आश्वलायनशाखा"
                      : "आश्वलायनशाखा"}
                  </option>
                  <option value="Bashkalshakha">
                    {lang == "english"
                      ? "Bashkalshakha"
                      : lang == "hindi"
                      ? "बाष्कलशाखा"
                      : "बाष्कलशाखा"}
                  </option>
                  <option value="Shankhayanshakha">
                    {lang == "english"
                      ? "Shankhayanshakha"
                      : lang == "hindi"
                      ? "शांखायनशाखा"
                      : "शांखायनशाखा"}
                  </option>
                  <option value="Kanvshakha">
                    {lang == "english"
                      ? "Kanvshakha"
                      : lang == "hindi"
                      ? "काण्वशाखा"
                      : "काण्वशाखा"}
                  </option>
                  <option value="Madhyandinshakha">
                    {lang == "english"
                      ? "Madhyandinshakha"
                      : lang == "hindi"
                      ? "माध्यन्दिनशाखा"
                      : "माध्यन्दिनशाखा"}
                  </option>
                  <option value="Taittiriyashakha">
                    {lang == "english"
                      ? "Taittiriyashakha"
                      : lang == "hindi"
                      ? "तैत्तिरीयशाखा"
                      : "तैत्तिरीयशाखा"}
                  </option>
                  <option value="Maitrayanishakha">
                    {lang == "english"
                      ? "Maitrayanishakha"
                      : lang == "hindi"
                      ? "मैत्रायणीशाखा"
                      : "मैत्रायणीशाखा"}
                  </option>
                  <option value="Kathashakha">
                    {lang == "english"
                      ? "Kathashakha"
                      : lang == "hindi"
                      ? "कठशाखा"
                      : "कठशाखा"}
                  </option>
                  <option value="Kapishthalshakha">
                    {lang == "english"
                      ? "Kapishthalshakha"
                      : lang == "hindi"
                      ? "कपिष्ठलशाखा"
                      : "कपिष्ठलशाखा"}
                  </option>
                  <option value="Kouthumshakha">
                    {lang == "english"
                      ? "Kouthumshakha"
                      : lang == "hindi"
                      ? "कौथुमशाखा"
                      : "कौथुमशाखा"}
                  </option>
                  <option value="Ranayaniyashakha">
                    {lang == "english"
                      ? "Ranayaniyashakha"
                      : lang == "hindi"
                      ? "राणायनीयशाखा"
                      : "राणायनीयशाखा"}
                  </option>
                  <option value="Maitrayanishakha">
                    {lang == "english"
                      ? "Maitrayanishakha"
                      : lang == "hindi"
                      ? "मैत्रायणीशाखा"
                      : "मैत्रायणीशाखा"}
                  </option>
                  <option value="Shounskshakha">
                    {lang == "english"
                      ? "Shounskshakha"
                      : lang == "hindi"
                      ? "शौनकशाखा"
                      : "शौनकशाखा"}
                  </option>
                  <option value="Paippaladshakha">
                    {lang == "english"
                      ? "Paippaladshakha"
                      : lang == "hindi"
                      ? "पैप्पलादशाखा"
                      : "पैप्पलादशाखा"}
                  </option>
                  <option value="Other">
                    {lang == "english"
                      ? "Other"
                      : lang == "hindi"
                      ? "अन्य"
                      : "अन्यः "}
                  </option>
                </select>
                {formik.touched && formik.errors.vi_more_than_one_veda ? (
                  <div className="text-danger">
                    {formik.errors.vi_more_than_one_veda}
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {formik?.values?.vi_more_than_one_veda == "Other" ? (
            <>
              <div className={`col-md-6 col-lg-4 form-group`}>
                <label>
                  {" "}
                  {lang == "english"
                    ? "Other vedashakha"
                    : lang == "hindi"
                    ? "अन्य वेदशाखा "
                    : "अन्यः वेदशाखा"}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="vi_more_than_one_veda_other"
                  id="vi_more_than_one_veda_other"
                  value={formik?.values?.vi_more_than_one_veda_other || ""}
                  onChange={(e) =>
                    handleInputChange("vi_more_than_one_veda_other", e)
                  }
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched && formik.errors.vi_more_than_one_veda_other ? (
                  <div className="text-danger">
                    {formik.errors.vi_more_than_one_veda_other}
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>

        <label>
          {lang == "english"
            ? "List the order in which you studied the ved and passed of ved examinations. If, at any one level of study, you have taken examinations more than once and / or in different institutions, then give the details of all the examinations in chronological order. If you have studied more than one ved or vedashakha, then after giving the details of swavedashakha study, give the details of the study of other veds and vedshakhas."
            : lang == "hindi"
            ? "वेदाध्ययन का क्रम व उत्तीर्ण वेदपरीक्षाएँ- यदि किसी एक स्तर पर एक से अधिक बार, अलग-अलग संस्थाओं में परीक्षाएँ दी हैं, तो काल क्रम में सभी परीक्षाओं का विवरण दीजिये। यदि एक से अधिक वेद / शाखा का अध्ययन किया है, तो स्ववेदशाखा अध्ययन का विवरण देने के बाद अन्य वेदाध्ययन का विवरण दीजिये।"
            : "वेदाध्ययनस्य क्रमः उत्तीर्णाः वेदपरीक्षाः च- यदि कस्मिंश्चित् एकस्मिन् स्तरे नैकवारं  विभिन्नासु संस्थासु परीक्षाः अददात्, तर्हि कालक्रमानुसारेण दीयमानानां सर्वासां परीक्षाणां विवरणं ददातु, यदि भवान् एकाधिकं वेदम् एकाधिकां वेदशाखां वा अधीतवान् तर्हि स्ववेदशाखायाः विवरणं दत्वा अन्यवेदाध्ययनस्य विवरणं ददातु"}
        </label>
        <div className="table-responsive mb-30">
          <table className="table">
            <thead>
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रम संख्या"
                    : "क्रमसङ्ख्"}
                </th>
                <th>
                  {lang == "english"
                    ? "Month and year"
                    : lang == "hindi"
                    ? "माह और वर्ष "
                    : "मासः वर्षं च "}
                </th>
                <th>
                  {lang == "english"
                    ? " Passed exam"
                    : lang == "hindi"
                    ? " उत्तीर्ण परीक्षा"
                    : "उत्तीर्ण-परीक्षा "}
                </th>
                <th>
                  {lang == "english"
                    ? "Examination board"
                    : lang == "hindi"
                    ? "परीक्षा बोर्ड"
                    : "परीक्षा बोर्ड इति"}
                </th>
                <th>
                  {lang == "english"
                    ? "Division"
                    : lang == "hindi"
                    ? "श्रेणी"
                    : "श्रेणी"}
                </th>
                <th>
                  {lang == "english"
                    ? " Upload certificates"
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु"}
                </th>
                <th width="120">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
            </thead>
            <tbody>
              {formik?.values?.vi_ved_and_passed_of_ved_examinations?.map(
                (row, index) => (
                  <tr key={1 + index}>
                    <td>{1 + index}</td>
                    <td>
                      <input
                        type="month"
                        className="form-control"
                        id={`vi_ved_and_passed_of_ved_examinations[${index}].vi_month_and_year`}
                        name={`vi_ved_and_passed_of_ved_examinations[${index}].vi_month_and_year`}
                        value={
                          formik?.values?.vi_ved_and_passed_of_ved_examinations[
                            index
                          ].vi_month_and_year || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vi_ved_and_passed_of_ved_examinations[${index}].vi_month_and_year`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.vi_ved_and_passed_of_ved_examinations &&
                      formik.errors.vi_ved_and_passed_of_ved_examinations[index]
                        ?.vi_month_and_year ? (
                        <div className="text-danger">
                          {
                            formik.errors.vi_ved_and_passed_of_ved_examinations[
                              index
                            ].vi_month_and_year
                          }
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {index == 0 &&
                        (lang == "english"
                          ? "Upnanayan sanskar"
                          : lang == "hindi"
                          ? "उपनयन संस्कार"
                          : "उपनयनसंस्का रः")}
                      {index == 1 &&
                        (lang == "english"
                          ? "Started Samhita study"
                          : lang == "hindi"
                          ? "संहिता अध्ययन प्रारम्भ"
                          : "संहिता- अध्ययन- प्रारम्भः")}
                      {index == 2 &&
                        (lang == "english"
                          ? "Moolanta exam"
                          : lang == "hindi"
                          ? "मूलान्त परीक्षा"
                          : "मूलान्तपरीक्षा")}
                      {index >= 3 ? (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            id={`vi_ved_and_passed_of_ved_examinations[${index}].vi_passed_exam`}
                            name={`vi_ved_and_passed_of_ved_examinations[${index}].vi_passed_exam`}
                            value={
                              formik?.values
                                ?.vi_ved_and_passed_of_ved_examinations[index]
                                ?.vi_passed_exam || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                `vi_ved_and_passed_of_ved_examinations[${index}].vi_passed_exam`,
                                e
                              )
                            }
                            onBlur={(e) => handleInputSave()}
                            disabled={index <= 1 ? "disabled" : ""}
                          />
                          {formik.touched &&
                          formik.errors.vi_ved_and_passed_of_ved_examinations &&
                          formik.errors.vi_ved_and_passed_of_ved_examinations[
                            index
                          ]?.vi_passed_exam ? (
                            <div className="text-danger">
                              {
                                formik.errors
                                  .vi_ved_and_passed_of_ved_examinations[index]
                                  .vi_passed_exam
                              }
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </td>
                    <td>
                      {index <= 1 ? (
                        <input type="text" disabled placeholder="----" />
                      ) : (
                        <>
                          <input
                            type="text"
                            id={`vi_ved_and_passed_of_ved_examinations[${index}].vi_exam_board`}
                            name={`vi_ved_and_passed_of_ved_examinations[${index}].vi_exam_board`}
                            value={
                              formik?.values
                                ?.vi_ved_and_passed_of_ved_examinations[index]
                                ?.vi_exam_board || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                `vi_ved_and_passed_of_ved_examinations[${index}].vi_exam_board`,
                                e
                              )
                            }
                            onBlur={(e) => handleInputSave()}
                            disabled={index <= 1 ? "disabled" : ""}
                          />
                          {formik.touched &&
                          formik.errors.vi_ved_and_passed_of_ved_examinations &&
                          formik.errors.vi_ved_and_passed_of_ved_examinations[
                            index
                          ]?.vi_exam_board ? (
                            <div className="text-danger">
                              {
                                formik.errors
                                  .vi_ved_and_passed_of_ved_examinations[index]
                                  .vi_exam_board
                              }
                            </div>
                          ) : null}
                        </>
                      )}
                    </td>
                    <td>
                      {index <= 1 ? (
                        <input type="text" disabled placeholder="----" />
                      ) : (
                        <>
                          <input
                            type="text"
                            id={`vi_ved_and_passed_of_ved_examinations[${index}].vi_exam_division`}
                            name={`vi_ved_and_passed_of_ved_examinations[${index}].vi_exam_division`}
                            value={
                              formik?.values
                                ?.vi_ved_and_passed_of_ved_examinations[index]
                                ?.vi_exam_division || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                `vi_ved_and_passed_of_ved_examinations[${index}].vi_exam_division`,
                                e
                              )
                            }
                            onBlur={(e) => handleInputSave()}
                            disabled={index <= 1 ? "disabled" : ""}
                          />
                          {formik.touched &&
                          formik.errors.vi_ved_and_passed_of_ved_examinations &&
                          formik.errors.vi_ved_and_passed_of_ved_examinations[
                            index
                          ]?.vi_exam_division ? (
                            <div className="text-danger">
                              {
                                formik.errors
                                  .vi_ved_and_passed_of_ved_examinations[index]
                                  .vi_exam_division
                              }
                            </div>
                          ) : null}
                        </>
                      )}
                    </td>
                    <td>
                      {index <= 1 ? (
                        <input type="file" className="form-control" disabled />
                      ) : (
                        <>
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            className="form-control"
                            id={`vi_ved_and_passed_of_ved_examinations[${index}].vi_exam_certificate`}
                            name={`vi_ved_and_passed_of_ved_examinations[${index}].vi_exam_certificate`}
                            onChange={async (e) => {
                              e.preventDefault();
                              if (e.target.files.length > 0) {
                                await fileUploadFunction(
                                  `vi_ved_and_passed_of_ved_examinations[${index}].vi_exam_certificate_string`,
                                  e.target.files[0]
                                );
                              }
                            }}
                            onBlur={(e) => {
                              handleInputSave(e);
                            }}
                          />
                          {formik.values?.vi_ved_and_passed_of_ved_examinations[
                            index
                          ]?.vi_exam_certificate_string && (
                            <button
                              className="uploadLink"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.vi_ved_and_passed_of_ved_examinations[index]?.vi_exam_certificate_string}`,
                                  "_blank",
                                  "noopener,noreferrer"
                                );
                              }}
                            >
                              {`${formik?.values?.vi_ved_and_passed_of_ved_examinations[
                                index
                              ].vi_exam_certificate_string.replace(
                                "upload-file/",
                                ""
                              )}`}
                            </button>
                          )}
                          {formik.touched &&
                          formik.errors.vi_ved_and_passed_of_ved_examinations &&
                          formik.errors.vi_ved_and_passed_of_ved_examinations[
                            index
                          ]?.vi_exam_certificate_string ? (
                            <div className="text-danger">
                              {
                                formik.errors
                                  .vi_ved_and_passed_of_ved_examinations[index]
                                  .vi_exam_certificate_string
                              }
                            </div>
                          ) : null}
                        </>
                      )}
                    </td>
                    <td className="text-right">
                      {index >= 3 ? (
                        <button
                          onClick={(e) =>
                            removevi_ved_and_passed_of_ved_examinations(
                              index,
                              e
                            )
                          }
                          type="button"
                          className="btn btn-outline-danger"
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                )
              )}
              <tr className="border-bottom-0">
                <td className="text-right" colspan="7">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={(e) => addvi_ved_and_passed_of_ved_examinations(e)}
                  >
                    {" "}
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <label>
          {lang == "english"
            ? "Name of your vedaadhyapaka and the vedavidyalaya where you studied"
            : lang == "hindi"
            ? "अपने वेदाध्यापक व वेदविद्यालय के नाम जहाँ आपने अध्ययन किया"
            : "भवतः वेदाध्यापकानां वेदविद्यालयानाञ्च नामानि यत्र भवता अध्ययनं कृतम्"}
        </label>
        <div className="table-responsive mb-30">
          <table className="table">
            <thead>
              <tr>
                <th>
                  {lang == "english"
                    ? "Duration of study (month / year) from"
                    : lang == "hindi"
                    ? "अवधि (माह / वर्ष) से"
                    : "अवधिः (मासः / वर्षम्) तः "}
                </th>
                <th>
                  {lang == "english"
                    ? "Duration of study (month / year) till"
                    : lang == "hindi"
                    ? "अवधि (माह / वर्ष) तक"
                    : "अवधिः (मासः / वर्षम्) पर्यन्तम्"}
                </th>
                <th>
                  {lang == "english"
                    ? "Vedaadhyapaka name "
                    : lang == "hindi"
                    ? "वेदाध्यापक का नाम"
                    : "वेदाध्यापकस्य नाम"}
                </th>
                <th>
                  {lang == "english"
                    ? "Vedaadhyapaka mobile number"
                    : lang == "hindi"
                    ? "वेदाध्यापक का मोबाईल नंबर"
                    : "वेदाध्यापकस्य दूरवाणीसङ्ख्या"}
                </th>
                <th>
                  {lang == "english"
                    ? "Name of vedavidyalaya "
                    : lang == "hindi"
                    ? "वेदविद्यालय का नाम"
                    : "वेदविद्यालयस्य नाम"}
                </th>
                <th width="120">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
            </thead>
            <tbody>
              {formik?.values?.vedadhyapaka_vedvidyalaya_details.map(
                (row, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="month"
                        className="form-control"
                        id={`vedadhyapaka_vedvidyalaya_details[${index}].from`}
                        name={`vedadhyapaka_vedvidyalaya_details[${index}].from`}
                        value={
                          formik?.values?.vedadhyapaka_vedvidyalaya_details[
                            index
                          ].from || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vedadhyapaka_vedvidyalaya_details[${index}].from`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details[index]
                        ?.from ? (
                        <div className="text-danger">
                          {
                            formik.errors.vedadhyapaka_vedvidyalaya_details[
                              index
                            ]?.from
                          }
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="month"
                        className="form-control"
                        id={`vedadhyapaka_vedvidyalaya_details[${index}].till`}
                        name={`vedadhyapaka_vedvidyalaya_details[${index}].till`}
                        value={
                          formik?.values?.vedadhyapaka_vedvidyalaya_details[
                            index
                          ]?.till || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vedadhyapaka_vedvidyalaya_details[${index}].till`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details[index]
                        ?.till ? (
                        <div className="text-danger">
                          {
                            formik.errors.vedadhyapaka_vedvidyalaya_details[
                              index
                            ]?.till
                          }
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`vedadhyapaka_vedvidyalaya_details[${index}].vedadhyapak_name`}
                        name={`vedadhyapaka_vedvidyalaya_details[${index}].vedadhyapak_name`}
                        value={
                          formik?.values?.vedadhyapaka_vedvidyalaya_details[
                            index
                          ]?.vedadhyapak_name || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vedadhyapaka_vedvidyalaya_details[${index}].vedadhyapak_name`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details[index]
                        ?.vedadhyapak_name ? (
                        <div className="text-danger">
                          {
                            formik.errors.vedadhyapaka_vedvidyalaya_details[
                              index
                            ]?.vedadhyapak_name
                          }
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`vedadhyapaka_vedvidyalaya_details[${index}].vedadhyapak_mobile`}
                        name={`vedadhyapaka_vedvidyalaya_details[${index}].vedadhyapak_mobile`}
                        value={
                          formik?.values?.vedadhyapaka_vedvidyalaya_details[
                            index
                          ]?.vedadhyapak_mobile || ""
                        }
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            handleInputChange(
                              `vedadhyapaka_vedvidyalaya_details[${index}].vedadhyapak_mobile`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details[index]
                        ?.vedadhyapak_mobile ? (
                        <div className="text-danger">
                          {
                            formik.errors.vedadhyapaka_vedvidyalaya_details[
                              index
                            ]?.vedadhyapak_mobile
                          }
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`vedadhyapaka_vedvidyalaya_details[${index}].vedvidyalaya_name`}
                        name={`vedadhyapaka_vedvidyalaya_details[${index}].vedvidyalaya_name`}
                        value={
                          formik?.values?.vedadhyapaka_vedvidyalaya_details[
                            index
                          ]?.vedvidyalaya_name || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vedadhyapaka_vedvidyalaya_details[${index}].vedvidyalaya_name`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details &&
                      formik.errors.vedadhyapaka_vedvidyalaya_details[index]
                        ?.vedvidyalaya_name ? (
                        <div className="text-danger">
                          {
                            formik.errors.vedadhyapaka_vedvidyalaya_details[
                              index
                            ]?.vedvidyalaya_name
                          }
                        </div>
                      ) : null}
                    </td>
                    <td className="d-flex">
                      {formik?.values?.vedadhyapaka_vedvidyalaya_details
                        .length >= 2 ? (
                        <button
                          onClick={(e) =>
                            removeVedadhyapakaVedvidyalayaDetails(index, e)
                          }
                          type="button"
                          className="btn btn-outline-danger"
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className="border-bottom-0">
                <td className="text-right" colspan="7">
                  <button
                    onClick={(e) => addVedadhyapakaVedvidyalayaDetails(e)}
                    type="button"
                    className="btn btn-danger"
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h6>
          {lang == "english"
            ? "Of any of the above mentioned ved teachers are your close relatives?"
            : lang == "hindi"
            ? "क्या उपर्युक्त वेदाध्यापक में से कोई आपके निकट परिजन हैं?"
            : "उपर्युक्त-वेदाध्यापकेषु कोऽपि भवतः निकटबान्धवः अस्ति?"}
        </h6>
        <div>
          <select
            className="form-control"
            name="is_relative"
            id="is_relative"
            value={formik?.values?.is_relative || ""}
            onChange={(e) => {
              handleInputChange("is_relative", e);
              if (e.target.value == "yes") {
                formik.setFieldValue("relative_details", [
                  {
                    serial_no: "",
                    vedadhyapakName: "",
                    relation: "",
                    other_relation: "",
                  },
                ]);
              }
              if (e.target.value == "no") {
                formik.setFieldValue("relative_details", [
                  {
                    serial_no: "N/A",
                    vedadhyapakName: "N/A",
                    relation: "N/A",
                    other_relation: "N/A",
                  },
                ]);
              }
            }}
            onBlur={(e) => handleInputSave()}
          >
            <option selected>
              {" "}
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes" : lang == "hindi" ? "हाँ" : "आम्"}
            </option>
            <option value="no">
              {lang == "english" ? "No" : lang == "hindi" ? "नहीं " : " न"}
            </option>
          </select>
          {formik.touched && formik.errors.is_relative ? (
            <div className="text-danger">{formik.errors.is_relative}</div>
          ) : null}
        </div>
        <div className="mb-30 mt-30">
          <div
            className={`table-responsive ${
              formik?.values?.is_relative == "yes" ? "d-block" : "d-none"
            }`}
          >
            <h6>
              {lang == "english"
                ? "Describe the family relationship with serial number."
                : lang == "hindi"
                ? "क्रम संख्या लिखकर पारिवारिक सम्बन्ध का वर्णन करें।"
                : "तन्नाम्नः क्रमाङ्कं लिखित्वा पारिवारिकसम्बन्धस्य विवरणं ददातु।"}
            </h6>
            <table className="table">
              <thead>
                <tr>
                  <th width="100">
                    {lang == "english"
                      ? "Serial number"
                      : lang == "hindi"
                      ? " क्रमांक "
                      : "क्रमाङ्कः"}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Name of vedadhyapak"
                      : lang == "hindi"
                      ? "वेदाध्यपक का नाम"
                      : "वेदाध्यपक नाम"}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Relation"
                      : lang == "hindi"
                      ? " सम्बन्ध  "
                      : "सम्बन्धः"}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Other"
                      : lang == "hindi"
                      ? "अन्य"
                      : "अन्यः "}
                  </th>
                  <th width="120">
                    {lang == "english"
                      ? "Action"
                      : lang == "hindi"
                      ? "क्रिया"
                      : "क्रिया"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {formik?.values?.relative_details.map((row, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`relative_details[${index}].serial_no`}
                        name={`relative_details[${index}].serial_no`}
                        value={
                          formik?.values?.relative_details[index]?.serial_no ||
                          ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `relative_details[${index}].serial_no`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.relative_details &&
                      formik.errors.relative_details[index]?.serial_no ? (
                        <div className="text-danger">
                          {formik.errors.relative_details[index]?.serial_no}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`relative_details[${index}].vedadhyapakName`}
                        name={`relative_details[${index}].vedadhyapakName`}
                        value={
                          formik?.values?.relative_details[index]
                            .vedadhyapakName || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `relative_details[${index}].  vedadhyapakName`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.relative_details &&
                      formik.errors.relative_details[index]?.vedadhyapakName ? (
                        <div className="text-danger">
                          {
                            formik.errors.relative_details[index]
                              .vedadhyapakName
                          }
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <select
                        name={`relative_details[${index}].relation`}
                        id={`relative_details[${index}].relation`}
                        className="form-control"
                        value={
                          formik?.values?.relative_details[index]?.relation ||
                          ""
                        }
                        onChange={(e) => {
                          handleInputChange(
                            `relative_details[${index}].relation`,
                            e
                          );
                          if (e.target.value == "Other") {
                            formik.setFieldValue(
                              `relative_details[${index}].other_relation`,
                              ""
                            );
                          }
                          if (e.target.value != "Other") {
                            formik.setFieldValue(
                              `relative_details[${index}].other_relation`,
                              "N/A"
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      >
                        <option>
                          ---
                          {lang == "english"
                            ? "Select"
                            : lang == "hindi"
                            ? "चुने"
                            : "चिनोतु"}
                          ---
                        </option>
                        <option value="Father">
                          {lang == "english"
                            ? "Father "
                            : lang == "hindi"
                            ? "पिता "
                            : "पिता "}
                        </option>
                        <option value="Brother">
                          {lang == "english"
                            ? "Brother "
                            : lang == "hindi"
                            ? "भाई "
                            : "भ्राता "}
                        </option>
                        <option value="Other">
                          {lang == "english"
                            ? "Other"
                            : lang == "hindi"
                            ? "अन्य"
                            : "अन्यः "}
                        </option>
                      </select>
                      {formik.touched &&
                      formik.errors.relative_details &&
                      formik.errors.relative_details[index]?.relation ? (
                        <div className="text-danger">
                          {formik.errors.relative_details[index]?.relation}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik?.values?.relative_details[index]?.relation ==
                      "Other" ? (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            id={`relative_details[${index}].other_relation`}
                            name={`relative_details[${index}].other_relation`}
                            value={
                              formik?.values?.relative_details[index]
                                ?.other_relation || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                `relative_details[${index}].  other_relation`,
                                e
                              )
                            }
                            onBlur={(e) => handleInputSave()}
                          />
                          {formik.touched &&
                          formik.errors.relative_details &&
                          formik.errors.relative_details[index]
                            ?.other_relation ? (
                            <div className="text-danger">
                              {
                                formik.errors.relative_details[index]
                                  .other_relation
                              }
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </td>
                    <td className="d-flex">
                      {formik?.values?.relative_details.length >= 2 ? (
                        <button
                          onClick={(e) => removeRelativeDetails(index, e)}
                          type="button"
                          className="btn btn-outline-danger"
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                ))}
                <tr className="border-bottom-0">
                  <td className="text-right" colspan="7">
                    <button
                      onClick={(e) => addRelativeDetails(e)}
                      type="button"
                      className="btn btn-danger"
                    >
                      {lang == "english"
                        ? "Add"
                        : lang == "hindi"
                        ? "जोड़ें"
                        : "संयोजयतु"}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <label>
          {lang == "english"
            ? "Description of adhit ved granthas - Describe all those adhit vedashakha granth, that you have studied or are studying and in which you have passed the examination. If you are studying any grantha, but have not sat the exam, then mention that also. If you have studied any other vedashakha or grantha other than adhit vedashakha, then give details of all such granthas at the end. "
            : lang == "hindi"
            ? "  अधीत वेद ग्रन्थों का विवरण- अधीत वेदशाखा के उन सभी ग्रन्थों का विवरण दीजिये जिनका अध्ययन कर आप परीक्षा उत्तीर्ण कर चुकॆ हैं। यदि किसी ग्रन्थ का अध्ययन कर रहे हैं, परन्तु परीक्षा शेष है, तो उसका भी उल्लेख कीजिये। यदि अधीत वेदशाखा के अलावा किसी अन्य वेदशाखा या ग्रन्थ का अध्ययन किया है, तो अन्त में ऐसे सभी ग्रन्थों का विवरण दीजिये।​"
            : "अधीतानां वेदग्रन्थानां विवरणम् अधीतायाः वेदशाखायाः तान् सर्वान् ग्रन्थान् विवृणोतु, यान् अधीत्य भवान् परीक्षाम् उत्तीर्णवान्। यदि भवान् कस्यचित् ग्रन्थस्य अध्ययनं करोति, किन्तु परीक्षा अवशिष्टा अस्ति, तर्हि तदपि उल्लेख्यताम्। यदि अधीतवेदशाखां विहाय अन्यां कामपि वेदशाखां कमपि ग्रन्थं वा अधीतवान्, तर्हि अन्ते तादृशानां सर्वेषां ग्रन्थानां विवरणं ददातु।"}
        </label>
        <div className="table-responsive mb-30">
          <table className="table">
            <thead>
              <tr>
                <th className="wrapTh">
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? " क्रम संख्या "
                    : "क्रमसङ्ख्या"}
                </th>
                <th className="wrapTh">
                  {lang == "english"
                    ? "Name of the grantha"
                    : lang == "hindi"
                    ? " ग्रन्थ का नाम  "
                    : " ग्रन्थस्य नाम"}
                </th>
                <th className="wrapTh">
                  {lang == "english"
                    ? "Month and year of start of study "
                    : lang == "hindi"
                    ? "अध्ययनारम्भ का माह और वर्ष "
                    : "अध्ययनारम्भस्य मासः वर्षं च"}
                </th>
                <th className="wrapTh">
                  {lang == "english"
                    ? "Month and year of the end of your study"
                    : lang == "hindi"
                    ? "अध्ययन समाप्ति का माह और वर्ष"
                    : "अध्ययनसमा प्तेः मासः वर्षं च"}
                </th>
                <th className="wrapTh">
                  {lang == "english"
                    ? "Month and year of examination"
                    : lang == "hindi"
                    ? " परीक्षा का माह और वर्ष "
                    : "परीक्षायाः मासः वर्षं च"}
                </th>
                <th className="wrapTh">
                  {lang == "english"
                    ? "Upload certificates"
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु"}
                </th>
                <th width="120" className="wrapTh">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
            </thead>
            <tbody>
              {formik?.values?.grantha_details.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`grantha_details[${index}].grantha_name`}
                      name={`grantha_details[${index}].grantha_name`}
                      value={
                        formik?.values?.grantha_details[index]?.grantha_name ||
                        ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `grantha_details[${index}].grantha_name`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors.grantha_details &&
                    formik.errors.grantha_details[index]?.grantha_name ? (
                      <div className="text-danger">
                        {formik.errors.grantha_details[index]?.grantha_name}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="month"
                      className="form-control"
                      id={`grantha_details[${index}].start_study`}
                      name={`grantha_details[${index}].start_study`}
                      value={
                        formik?.values?.grantha_details[index]?.start_study ||
                        ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `grantha_details[${index}].start_study`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors.grantha_details &&
                    formik.errors.grantha_details[index]?.start_study ? (
                      <div className="text-danger">
                        {formik.errors.grantha_details[index]?.start_study}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="month"
                      className="form-control"
                      id={`grantha_details[${index}].end_study`}
                      name={`grantha_details[${index}].end_study`}
                      value={
                        formik?.values?.grantha_details[index]?.end_study || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `grantha_details[${index}].end_study`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors.grantha_details &&
                    formik.errors.grantha_details[index]?.end_study ? (
                      <div className="text-danger">
                        {formik.errors.grantha_details[index]?.end_study}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="month"
                      className="form-control"
                      id={`grantha_details[${index}].exam_month_year`}
                      name={`grantha_details[${index}].exam_month_year`}
                      value={
                        formik?.values?.grantha_details[index]
                          ?.exam_month_year || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `grantha_details[${index}].exam_month_year`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors.grantha_details &&
                    formik.errors.grantha_details[index]?.exam_month_year ? (
                      <div className="text-danger">
                        {formik.errors.grantha_details[index]?.exam_month_year}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      className="form-control"
                      id={`grantha_details[${index}].certificate`}
                      name={`grantha_details[${index}].certificate`}
                      onChange={async (e) => {
                        e.preventDefault();
                        if (e.target.files.length > 0) {
                          await fileUploadFunction(
                            `grantha_details[${index}].certificate_string`,
                            e.target.files[0]
                          );
                        }
                      }}
                      onBlur={(e) => {
                        handleInputSave(e);
                      }}
                    />
                    {formik.values?.grantha_details[index]
                      ?.certificate_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.grantha_details[index]?.certificate_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik?.values?.grantha_details[
                          index
                        ].certificate_string.replace("upload-file/", "")}`}
                      </button>
                    )}
                    {formik.touched &&
                    formik.errors.grantha_details &&
                    formik.errors.grantha_details[index]?.certificate_string ? (
                      <div className="text-danger">
                        {
                          formik.errors.grantha_details[index]
                            .certificate_string
                        }
                      </div>
                    ) : null}
                  </td>
                  <td className="d-flex">
                    {formik?.values?.grantha_details.length >= 2 ? (
                      <button
                        onClick={(e) => removeGranthaDetails(index, e)}
                        type="button"
                        className="btn btn-outline-danger"
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}

              <tr className="border-bottom-0">
                {" "}
                <td className="text-right" colspan="7">
                  <button
                    onClick={(e) => addGranthaDetails(e)}
                    type="button"
                    className="btn btn-danger"
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row mb-30">
          <div className="col-md-6">
            <label>
              {lang == "english"
                ? "Have you received any award?"
                : lang == "hindi"
                ? "क्या आपको कोई पुरस्कार मिला है?"
                : "किं भवता किमपि पुरस्कारं प्राप्तम् ?"}
            </label>
            <select
              name="have_received_ward"
              id="have_received_ward"
              className="form-control"
              value={formik?.values?.have_received_ward || ""}
              onChange={(e) => {
                handleInputChange("have_received_ward", e);
                if (e.target.value == "yes") {
                  formik.setFieldValue("award_details", [
                    {
                      award_month_year: "",
                      award_name: "",
                      awarding_organisation: "",
                      certificate: "",
                      certificate_string: "",
                    },
                  ]);
                }
                if (e.target.value == "no") {
                  formik.setFieldValue("award_details", [
                    {
                      award_month_year: "N/A",
                      award_name: "N/A",
                      awarding_organisation: "N/A",
                      certificate: "N/A",
                      certificate_string: "N/A",
                    },
                  ]);
                }
              }}
              onBlur={(e) => handleInputSave()}
            >
              <option>
                ---
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
                ---
              </option>
              <option value="yes">
                {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
              </option>
              <option value="no">
                {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
              </option>
            </select>
            {formik.touched && formik.errors.have_received_ward ? (
              <div className="text-danger">
                {formik.errors.have_received_ward}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`table-responsive mb-30 ${
            formik?.values?.have_received_ward == "yes" ? "" : "d-none"
          }`}
        >
          <label>
            {lang == "english"
              ? "Details of awards / honours earned by you: (list in chronological order)."
              : lang == "hindi"
              ? "आपके द्वारा अर्जित पुरस्कार / सम्मानों का विवरण: (कालक्रम में लिखें)।"
              : "भवता अर्जितानां पुरस्काराणां/सम्मानानां विवरणम् : (कालक्रमेण लिखतु)।"}
          </label>
          <table className="table">
            <thead>
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? " क्रम संख्या "
                    : "क्रमसङ्ख्या"}
                </th>
                <th>
                  {lang == "english"
                    ? "Month and year "
                    : lang == "hindi"
                    ? "माह और वर्ष "
                    : "मासः वर्षम्"}
                </th>
                <th>
                  {lang == "english"
                    ? "Name of award "
                    : lang == "hindi"
                    ? "पुरस्कार का नाम"
                    : "पुरस्कारस्य नाम "}
                </th>
                <th>
                  {lang == "english"
                    ? "Name of awarding organisation "
                    : lang == "hindi"
                    ? "प्रदाता का नाम"
                    : " प्रदातुः नाम"}
                </th>
                <th>
                  {lang == "english"
                    ? "Upload certificates"
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु"}
                </th>
                <th width="120">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
            </thead>
            <tbody>
              {formik?.values?.award_details.map((row, index) => (
                <tr key={index}>
                  <td>{1 + index}</td>
                  <td>
                    <input
                      type="month"
                      className="form-control"
                      id={`award_details[${index}].award_month_year`}
                      name={`award_details[${index}].award_month_year`}
                      value={
                        formik?.values?.award_details[index]
                          ?.award_month_year || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `award_details[${index}].award_month_year`,
                          e
                        )
                      }
                      onBlur={(e) => {
                        handleInputSave();
                      }}
                    />
                    {formik.touched &&
                    formik.errors.award_details &&
                    formik.errors.award_details[index]?.award_month_year ? (
                      <div className="text-danger">
                        {formik.errors.award_details[index]?.award_month_year}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`award_details[${index}].award_name`}
                      name={`award_details[${index}].award_name`}
                      value={
                        formik?.values?.award_details[index]?.award_name || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `award_details[${index}].award_name`,
                          e
                        )
                      }
                      onBlur={(e) => {
                        handleInputSave();
                      }}
                    />
                    {formik.touched &&
                    formik.errors.award_details &&
                    formik.errors.award_details[index]?.award_name ? (
                      <div className="text-danger">
                        {formik.errors.award_details[index]?.award_name}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`award_details[${index}].awarding_organisation`}
                      name={`award_details[${index}].awarding_organisation`}
                      value={
                        formik?.values?.award_details[index]
                          .awarding_organisation || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `award_details[${index}].awarding_organisation`,
                          e
                        )
                      }
                      onBlur={(e) => {
                        handleInputSave();
                      }}
                    />
                    {formik.touched &&
                    formik.errors.award_details &&
                    formik.errors.award_details[index]
                      ?.awarding_organisation ? (
                      <div className="text-danger">
                        {
                          formik.errors.award_details[index]
                            .awarding_organisation
                        }
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="file"
                      className="form-control"
                      id={`award_details[${index}].certificate`}
                      name={`award_details[${index}].certificate`}
                      onChange={async (e) => {
                        e.preventDefault();
                        if (e.target.files.length > 0) {
                          await fileUploadFunction(
                            `award_details[${index}].certificate_string`,
                            e.target.files[0]
                          );
                        }
                      }}
                      onBlur={(e) => {
                        handleInputSave(e);
                      }}
                    />
                    {formik.values?.award_details[index]
                      ?.certificate_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.award_details[index]?.certificate_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik?.values?.award_details[
                          index
                        ].certificate_string.replace("upload-file/", "")}`}
                      </button>
                    )}
                    {formik.touched &&
                    formik.errors.award_details &&
                    formik.errors.award_details[index]?.certificate_string ? (
                      <div className="text-danger">
                        {formik.errors.award_details[index]?.certificate_string}
                      </div>
                    ) : null}
                  </td>
                  <td className="d-flex">
                    {formik?.values?.award_details.length >= 2 ? (
                      <button
                        onClick={(e) => removeAwardDetails(index, e)}
                        type="button"
                        className="btn btn-outline-danger"
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}

              <tr className="border-bottom-0">
                <td className="text-right" colspan="7">
                  {" "}
                  <button
                    onClick={(e) => addAwardDetails(e)}
                    type="button"
                    className="btn btn-danger"
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default page2_information_of_vedic_educaiton;
