import React, { useState, useEffect } from "react";
import "./style.css";

function Page1_General_information({
  formData,
  handleInputChange,
  formik,
  addBrotherSisterRow,
  removeBrotherSisterRow,
  handleInputSave,
  lang,
  fileUploadFunction,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(25);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };
  const handleInputValueChangeBrotherSister = (index, e) => {
    console.log(index, e.target.value);
    handleInputChange(`pi_brothers_sisters[${index}]?.studying_the_vedas`, e);
    if (e.target.value === "No") {
      formik.setFieldValue(`pi_brothers_sisters[${index}].study_level`, "N/A");
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.pi_name, count);
    count = handleInputValue(formik?.values?.pi_adhar_card_no, count);
    count = handleInputValue(formik?.values?.pi_date_of_birth, count);
    count = handleInputValue(formik?.values?.pi_place_of_birth, count);
    count = handleInputValue(formik?.values?.pi_fathers_name, count);
    count = handleInputValue(formik?.values?.pi_mothers_name, count);
    count = handleInputValue(formik?.values?.pi_rishi_gotra, count);
    count = handleInputValue(formik?.values?.pi_rishi_gotra_other, count);
    count = handleInputValue(formik?.values?.pi_Swavedshakha, count);
    count = handleInputValue(formik?.values?.pi_anyashakha, count);
    count = handleInputValue(
      formik?.values?.pi_full_corresponding_address_line_1,
      count
    );
    count = handleInputValue(
      formik?.values?.pi_full_correspondence_address_district,
      count
    );
    count = handleInputValue(
      formik?.values?.pi_full_correspondence_address_state,
      count
    );
    count = handleInputValue(
      formik?.values?.pi_full_correspondence_address_pincode,
      count
    );
    count = handleInputValue(
      formik?.values?.pi_permanent_address_pincode,
      count
    );
    count = handleInputValue(
      formik?.values?.pi_permanent_address_district,
      count
    );
    count = handleInputValue(formik?.values?.pi_permanent_address_state, count);
    count = handleInputValue(
      formik?.values?.pi_permanent_home_address_line_1,
      count
    );
    count = handleInputValue(formik?.values?.pi_mobile_no, count);
    count = handleInputValue(formik?.values?.pi_email_address, count);
    count = handleInputValue(formik?.values?.pi_language, count);
    count = handleInputValue(formik?.values?.pi_have_brothers_sisters, count);
    count = handleInputValue(formik?.values?.pi_present_guruji_name, count);
    count = handleInputValue(formik?.values?.pi_guruji_mobile, count);
    count = handleInputValue(formik?.values?.pi_passport_photo_string, count);
    count = handleInputValue(
      formik?.values?.pi_details_of_children_show,
      count
    );
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(1, totalCount, actualCount);
  }, [actualCount, totalCount]);

  return (
    <div>
      <form>
        <div>
          <h3>
            {lang == "english"
              ? "Personal information"
              : lang == "hindi"
              ? "व्यक्तिगत जानकारी"
              : "व्यक्तिगतसूचना"}
          </h3>
          <p className="text-primary mb-30">
            {lang == "english"
              ? "All fields are mandatory"
              : lang == "hindi"
              ? "सभी प्रश्न अनिवार्य हैं।"
              : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
          </p>
          <div className="row">
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="pi_name">
                {lang == "english"
                  ? "Full Name"
                  : lang == "hindi"
                  ? "पूरा नाम"
                  : "सम्पूर्ण नाम"}
              </label>
              <input
                id="pi_name"
                name="pi_name"
                type="text"
                className="form-control"
                value={formik?.values?.pi_name || ""}
                onChange={(e) => {
                  handleInputChange("pi_name", e);
                  formik.setFieldValue("sa_applicant_Name", e.target.value);
                  formik.setFieldValue("ga_applicant_name", e.target.value);
                }}
                onBlur={(e) => handleInputSave()}
              />
              {formik.touched && formik.errors.pi_name ? (
                <div className="text-danger">{formik.errors.pi_name}</div>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="pi_adhar_card_no">
                {lang == "english"
                  ? "Aadhar card number"
                  : lang == "hindi"
                  ? "आधारपत्र संख्या "
                  : "आधारपत्रसङ्ख्या"}
              </label>
              <input
                id="pi_adhar_card_no"
                name="pi_adhar_card_no"
                type="number"
                max="12"
                className="form-control"
                value={formik?.values?.pi_adhar_card_no || ""}
                onChange={(e) => {
                  if (e.target.value.length <= 12) {
                    handleInputChange("pi_adhar_card_no", e);
                  }
                }}
                onBlur={(e) => handleInputSave()}
              />
              {formik.touched && formik.errors.pi_adhar_card_no ? (
                <div className="text-danger">
                  {formik.errors.pi_adhar_card_no}
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="pi_date_of_birth">
                {lang == "english"
                  ? "Date of birth"
                  : lang == "hindi"
                  ? "जन्म दिनांक"
                  : "जन्मदिनाङ्कः"}
              </label>
              <input
                id="pi_date_of_birth"
                name="pi_date_of_birth"
                type="date"
                maxLength={10}
                max={new Date()?.toISOString()?.slice(0, 10)}
                className="form-control"
                value={formik?.values?.pi_date_of_birth || ""}
                onChange={(e) => handleInputChange("pi_date_of_birth", e)}
                onBlur={(e) => handleInputSave()}
              />
              {formik.touched && formik.errors.pi_date_of_birth ? (
                <div className="text-danger">
                  {formik.errors.pi_date_of_birth}
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="pi_place_of_birth">
                {lang == "english"
                  ? "Place of birth"
                  : lang == "hindi"
                  ? "जन्म स्थान"
                  : "जन्मस्थानम्"}
              </label>
              <input
                id="pi_place_of_birth"
                name="pi_place_of_birth"
                type="text"
                className="form-control"
                value={formik?.values?.pi_place_of_birth || ""}
                onChange={(e) => handleInputChange("pi_place_of_birth", e)}
                onBlur={(e) => handleInputSave()}
              />
              {formik.touched && formik.errors.pi_place_of_birth ? (
                <div className="text-danger">
                  {formik.errors.pi_place_of_birth}
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="pi_fathers_name">
                {lang == "english"
                  ? "Father's name"
                  : lang == "hindi"
                  ? "पिता का नाम "
                  : "पितुः नाम "}
              </label>
              <input
                id="pi_fathers_name"
                name="pi_fathers_name"
                type="text"
                className="form-control"
                value={formik?.values?.pi_fathers_name || ""}
                onChange={(e) => handleInputChange("pi_fathers_name", e)}
                onBlur={(e) => handleInputSave()}
              />
              {formik.touched && formik.errors.pi_fathers_name ? (
                <div className="text-danger">
                  {formik.errors.pi_fathers_name}
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="pi_mothers_name">
                {lang == "english"
                  ? "Mother's name"
                  : lang == "hindi"
                  ? "माता का नाम"
                  : "मातुः नाम "}
              </label>
              <input
                id="pi_mothers_name"
                name="pi_mothers_name"
                type="text"
                className="  form-control"
                value={formik?.values?.pi_mothers_name || ""}
                onChange={(e) => handleInputChange("pi_mothers_name", e)}
                onBlur={(e) => handleInputSave()}
              />
              {formik.touched && formik.errors.pi_mothers_name ? (
                <div className="text-danger">
                  {formik.errors.pi_mothers_name}
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="pi_rishi_gotra">
                {lang == "english"
                  ? "Rishi gotra"
                  : lang == "hindi"
                  ? "ऋषि गोत्र"
                  : " ऋषिगोत्रम्"}
              </label>
              <select
                id="pi_rishi_gotra"
                name="pi_rishi_gotra"
                className="form-control"
                onChange={(e) => {
                  handleInputChange("pi_rishi_gotra", e);
                  if (e.target.value == "Anyagotra") {
                    formik.setFieldValue("pi_rishi_gotra_other", "");
                  } else {
                    formik.setFieldValue("pi_rishi_gotra_other", "N/A");
                  }
                }}
                onBlur={(e) => handleInputSave()}
                value={formik?.values?.pi_rishi_gotra}
              >
                <option>
                  ---
                  {lang == "english"
                    ? "Select"
                    : lang == "hindi"
                    ? "चुने"
                    : "चिनोतु"}
                  ---
                </option>
                <option value="Bharadvajagotra">
                  {lang == "english"
                    ? "Bharadvajagotra"
                    : lang == "hindi"
                    ? "भारद्वाजगोत्र"
                    : "भारद्वाजगोत्रः"}
                </option>

                <option value="Vatsagotra">
                  {lang == "english"
                    ? "Vatsagotra"
                    : lang == "hindi"
                    ? "वत्सगोत्र"
                    : "वत्सगोत्रः"}
                </option>

                <option value="Kashyapagotra">
                  {lang == "english"
                    ? "Kashyapagotra"
                    : lang == "hindi"
                    ? "काश्यपगोत्र"
                    : "काश्यपगोत्रः"}
                </option>

                <option value="Gautamagotra">
                  {lang == "english"
                    ? "Gautamagotra"
                    : lang == "hindi"
                    ? "गौतमगोत्र"
                    : "गौतमगोत्रः"}
                </option>

                <option value="Vishvamitragotra">
                  {lang == "english"
                    ? "Vishvamitragotra"
                    : lang == "hindi"
                    ? "विश्वामित्रगोत्र"
                    : "विश्वामित्रगोत्रः"}
                </option>

                <option value="Jamadagnigotra">
                  {lang == "english"
                    ? "Jamadagnigotra"
                    : lang == "hindi"
                    ? "जामदग्निगोत्र"
                    : "जामदग्निगोत्रः"}
                </option>

                <option value="Atreyagotra">
                  {lang == "english"
                    ? "Atreyagotra"
                    : lang == "hindi"
                    ? "आत्रेयगोत्र"
                    : "आत्रेयगोत्रः"}
                </option>

                <option value="Vashishtgotra">
                  {lang == "english"
                    ? "Vashishtgotra"
                    : lang == "hindi"
                    ? "वासिष्ठगोत्र"
                    : "वासिष्ठगोत्रः"}
                </option>

                <option value="Atrigotra">
                  {lang == "english"
                    ? "Atrigotra"
                    : lang == "hindi"
                    ? "अत्रिगोत्र"
                    : "अत्रिगोत्रः"}
                </option>

                <option value="Anyagotra">
                  {lang == "english"
                    ? "Anyagotra"
                    : lang == "hindi"
                    ? "अन्यगोत्र"
                    : "अन्यगोत्रः"}
                </option>
              </select>
              {formik.touched && formik.errors.pi_rishi_gotra ? (
                <div className="text-danger">
                  {formik.errors.pi_rishi_gotra}
                </div>
              ) : null}
            </div>
            {formik?.values?.pi_rishi_gotra == "Anyagotra" && (
              <>
                <div className="col-md-6 col-lg-4 form-group">
                  <label htmlfor="pi_rishi_gotra_other">
                    {lang == "english"
                      ? "Anyagotra"
                      : lang == "hindi"
                      ? "अन्यगोत्र"
                      : "अन्यगोत्रः"}
                  </label>
                  <input
                    type="text"
                    id="pi_rishi_gotra_other"
                    name="pi_rishi_gotra_other"
                    className="form-control"
                    onChange={(e) =>
                      handleInputChange("pi_rishi_gotra_other", e)
                    }
                    onBlur={(e) => handleInputSave()}
                    value={formik?.values?.pi_rishi_gotra_other}
                  />
                </div>
                {formik.touched && formik.errors.pi_rishi_gotra_other ? (
                  <div className="text-danger">
                    {formik.errors.pi_rishi_gotra_other}
                  </div>
                ) : null}
              </>
            )}

            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="pi_Swavedshakha">
                {lang == "english"
                  ? "Swavedshakha"
                  : lang == "hindi"
                  ? "स्ववेदशाखा"
                  : "स्ववेदशाखा"}
              </label>
              <select
                id="pi_Swavedshakha"
                name="pi_Swavedshakha"
                className="form-control"
                value={formik?.values?.pi_Swavedshakha || ""}
                onChange={(e) => {
                  handleInputChange("pi_Swavedshakha", e);
                  if (e.target.value !== "Anyashakha") {
                    formik.setFieldValue("pi_anyashakha", "N/A");
                  }
                  if (e.target.value == "Anyashakha") {
                    formik.setFieldValue("pi_anyashakha", "");
                  }
                }}
                onBlur={(e) => handleInputSave()}
              >
                <option>
                  ---
                  {lang == "english"
                    ? "Select"
                    : lang == "hindi"
                    ? "चुने"
                    : "चिनोतु"}
                  ---
                </option>
                <option value="Shakalshakha">
                  {lang == "english"
                    ? "Shakalshakha"
                    : lang == "hindi"
                    ? "शाकलशाखा "
                    : "शाकलशाखा "}
                </option>
                <option value="Ashvalayanshakha">
                  {lang == "english"
                    ? "Ashvalayanashakha"
                    : lang == "hindi"
                    ? "आश्वलायनशाखा "
                    : "आश्वलायनशाखा "}
                </option>
                <option value="Bashkalshakha">
                  {lang == "english"
                    ? "Bashkalashakha"
                    : lang == "hindi"
                    ? "बाष्कलशाखा "
                    : "बाष्कलशाखा "}
                </option>
                <option value="Shankhayanshakha">
                  {lang == "english"
                    ? "Shankhayanashakha"
                    : lang == "hindi"
                    ? "शांखायनशाखा "
                    : "शाङ्खायनशाखा "}
                </option>
                <option value="Kanvashakha ">
                  {lang == "english"
                    ? "Kanvashakha"
                    : lang == "hindi"
                    ? "काण्वशाखा "
                    : "काण्वशाखा "}
                </option>
                <option value="Madhyandinshakha">
                  {lang == "english"
                    ? "Madhyandinshakha"
                    : lang == "hindi"
                    ? "माध्यन्दिनशाखा "
                    : "माध्यन्दिनशाखा "}
                </option>
                <option value="Taittiriyashakha ">
                  {lang == "english"
                    ? "Taittiriyashakha"
                    : lang == "hindi"
                    ? "तैत्तिरीयशाखा "
                    : "तैत्तिरीयशाखा "}
                </option>
                <option value="Maitrayanishakha ">
                  {lang == "english"
                    ? "Maitrayanishakha"
                    : lang == "hindi"
                    ? "मैत्रायणीशाखा "
                    : "मैत्रायणीशाखा "}
                </option>
                <option value="Kathashakha ">
                  {lang == "english"
                    ? "Kathashakha"
                    : lang == "hindi"
                    ? "कठशाखा "
                    : "कठशाखा "}
                </option>
                <option value="Kapishthalshakha ">
                  {lang == "english"
                    ? "Kapishthalashakha"
                    : lang == "hindi"
                    ? "कपिष्ठलशाखा "
                    : "कपिष्ठलशाखा "}
                </option>
                <option value="Kouthumshakha ">
                  {lang == "english"
                    ? "Kouthumshakha"
                    : lang == "hindi"
                    ? "कौथुमशाखा "
                    : "कौथुमशाखा "}
                </option>
                <option value="Ranayaniyashakha ">
                  {lang == "english"
                    ? "Ranayaniyashakha"
                    : lang == "hindi"
                    ? "राणायनीयशाखा "
                    : "राणायनीयशाखा "}
                </option>
                <option value="Jaiminiyashakha ">
                  {lang == "english"
                    ? "Jaiminiyashakha"
                    : lang == "hindi"
                    ? "जैमिनीयशाखा "
                    : "जैमिनीयशाखा "}
                </option>
                <option value="Shounkashakha ">
                  {lang == "english"
                    ? "Shounkashakha"
                    : lang == "hindi"
                    ? "शौनकशाखा "
                    : "शौनकशाखा "}
                </option>
                <option value="Paippaladshakha ">
                  {lang == "english"
                    ? "Paippaladshakha"
                    : lang == "hindi"
                    ? "पैप्पलादशाखा "
                    : "पैप्पलादशाखा"}
                </option>
                <option value={"Anyashakha"}>
                  {lang == "english"
                    ? "Anyashakha "
                    : lang == "hindi"
                    ? "अन्यशाखा"
                    : "अन्यशाखा "}
                </option>
              </select>
              {formik.touched && formik.errors.pi_Swavedshakha ? (
                <div className="text-danger">
                  {formik.errors.pi_Swavedshakha}
                </div>
              ) : null}
            </div>
            {formik?.values?.pi_Swavedshakha == "Anyashakha" && (
              <div className="col-md-6 col-lg-4 form-group">
                <label htmlFor="pi_anyashakha">
                  {lang == "english"
                    ? "Anyashakha "
                    : lang == "hindi"
                    ? "अन्यशाखा"
                    : "अन्यशाखा "}
                </label>
                <input
                  type="text"
                  name="pi_anyashakha"
                  id="pi_anyashakha"
                  onChange={(e) => handleInputChange("pi_anyashakha", e)}
                  onBlur={(e) => handleInputSave()}
                  value={formik?.values?.pi_anyashakha}
                  className="form-control"
                />
                {formik.touched && formik.errors.pi_anyashakha ? (
                  <div className="text-danger">
                    {formik.errors.pi_anyashakha}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <h5>
          {lang == "english"
            ? "Full correspondence address"
            : lang == "hindi"
            ? "पत्राचार का पूर्ण पता"
            : "पूर्णपत्रसङ्केतः"}
        </h5>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_full_correspondence_address_pincode">
              {lang == "english"
                ? "Pin code"
                : lang == "hindi"
                ? "पिन कोड"
                : "पिनकोड-क्रमाङ्क:"}
            </label>
            <input
              type="number"
              name="pi_full_correspondence_address_pincode"
              id="pi_full_correspondence_address_pincode"
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  handleInputChange(
                    "pi_full_correspondence_address_pincode",
                    e
                  );
                  if (formik?.values?.pi_same_as.length > 0) {
                    formik.setFieldValue(
                      "pi_permanent_address_pincode",
                      e.target.value
                    );
                  }
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_full_correspondence_address_pincode}
              className="form-control"
            />
            {formik.touched &&
            formik.errors.pi_full_correspondence_address_pincode ? (
              <div className="text-danger">
                {formik.errors.pi_full_correspondence_address_pincode}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_full_correspondence_address_district">
              {lang == "english"
                ? "District"
                : lang == "hindi"
                ? "जिला "
                : "जनपदः"}
            </label>
            <input
              type="text"
              name="pi_full_correspondence_address_district"
              id="pi_full_correspondence_address_district"
              onChange={(e) => {
                handleInputChange("pi_full_correspondence_address_district", e);
                if (formik?.values?.pi_same_as.length > 0) {
                  formik.setFieldValue(
                    "pi_permanent_address_district",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_full_correspondence_address_district}
              className="form-control"
            />
            {formik.touched &&
            formik.errors.pi_full_correspondence_address_district ? (
              <div className="text-danger">
                {formik.errors.pi_full_correspondence_address_district}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_full_correspondence_address_state">
              {lang == "english"
                ? "State"
                : lang == "hindi"
                ? "राज्य "
                : "राज्यम् "}
            </label>
            <input
              type="text"
              name="pi_full_correspondence_address_state"
              id="pi_full_correspondence_address_state"
              onChange={(e) => {
                handleInputChange("pi_full_correspondence_address_state", e);
                if (formik?.values?.pi_same_as.length > 0) {
                  formik.setFieldValue(
                    "pi_permanent_address_state",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_full_correspondence_address_state}
              className="form-control"
            />
            {formik.touched &&
            formik.errors.pi_full_correspondence_address_state ? (
              <div className="text-danger">
                {formik.errors.pi_full_correspondence_address_state}
              </div>
            ) : null}
          </div>
          <div className="col-12 form-group">
            <label htmlFor="pi_full_corresponding_address_line_1">
              {lang == "english" ? "Address" : lang == "hindi" ? "पता" : "पता"}
            </label>
            <input
              name="pi_full_corresponding_address_line_1"
              type="text"
              id="pi_full_corresponding_address_line_1"
              className="form-control"
              onChange={(e) => {
                handleInputChange("pi_full_corresponding_address_line_1", e);
                if (formik?.values?.pi_same_as.length > 0) {
                  formik.setFieldValue(
                    "pi_permanent_home_address_line_1",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_full_corresponding_address_line_1}
            />
            {formik.touched &&
            formik.errors.pi_full_corresponding_address_line_1 ? (
              <div className="text-danger">
                {formik.errors.pi_full_corresponding_address_line_1}
              </div>
            ) : null}
          </div>
        </div>

        <h5>
          {lang == "english"
            ? "Permanent / paternal home address"
            : lang == "hindi"
            ? "स्थायी/पितृ गृह का पता"
            : "स्थायि/पितृगृहपत्रसङ्केतः"}
        </h5>
        <div className="mb-5px">
          <input
            type="checkbox"
            id="pi_same_as"
            name="pi_same_as"
            onChange={(e) => {
              handleInputChange("pi_same_as", e);
              if (e.target.value.length > 0) {
                formik.setFieldValue(
                  "pi_permanent_address_pincode",
                  formik?.values?.pi_full_correspondence_address_pincode
                );
                formik.setFieldValue(
                  "pi_permanent_address_district",
                  formik?.values?.pi_full_correspondence_address_district
                );
                formik.setFieldValue(
                  "pi_permanent_address_state",
                  formik?.values?.pi_full_correspondence_address_state
                );
                formik.setFieldValue(
                  "pi_permanent_home_address_line_1",
                  formik?.values?.pi_full_corresponding_address_line_1
                );
              } else {
              }
            }}
            onBlur={(e) => handleInputSave()}
            checked={formik?.values?.pi_same_as.length > 0 ? true : false}
          />
          {formik.touched &&
          formik.errors.pi_same_as &&
          formik.errors.pi_same_as ? (
            <div className="text-danger">
              {formik.errors.pi_same_as && formik.errors.pi_same_as}
            </div>
          ) : null}
          <label for="same_as" className="pl-3 mb-0">
            {lang == "english"
              ? "Same as full correspondence"
              : lang == "hindi"
              ? "पत्राचार के पते के समान"
              : "पूर्णपत्रसङ्केतः इव"}
          </label>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_permanent_address_pincode">
              {lang == "english"
                ? "Pin code"
                : lang == "hindi"
                ? "पिन कोड"
                : "पिनकोड-क्रमाङ्क:"}
            </label>
            <input
              type="number"
              name="pi_permanent_address_pincode"
              id="pi_permanent_address_pincode"
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  handleInputChange("pi_permanent_address_pincode", e);
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_permanent_address_pincode}
              className="form-control"
            />
            {formik.touched && formik.errors.pi_permanent_address_pincode ? (
              <div className="text-danger">
                {formik.errors.pi_permanent_address_pincode}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_permanent_address_district">
              {lang == "english"
                ? "District"
                : lang == "hindi"
                ? "जिला "
                : "जनपदः"}
            </label>
            <input
              type="text"
              name="pi_permanent_address_district"
              id="pi_permanent_address_district"
              onChange={(e) =>
                handleInputChange("pi_permanent_address_district", e)
              }
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_permanent_address_district}
              className="form-control"
            />
            {formik.touched && formik.errors.pi_permanent_address_district ? (
              <div className="text-danger">
                {formik.errors.pi_permanent_address_district}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_permanent_address_state">
              {" "}
              {lang == "english"
                ? "State"
                : lang == "hindi"
                ? "राज्य "
                : "राज्यम् "}{" "}
            </label>
            <input
              type="text"
              name="pi_permanent_address_state"
              id="pi_permanent_address_state"
              onChange={(e) =>
                handleInputChange("pi_permanent_address_state", e)
              }
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_permanent_address_state}
              className="form-control"
            />
            {formik.touched && formik.errors.pi_permanent_address_state ? (
              <div className="text-danger">
                {formik.errors.pi_permanent_address_state}
              </div>
            ) : null}
          </div>
          <div className="col-12 form-group">
            <label htmlFor=" pi_permanent_home_address_line_1">
              {lang == "english" ? "Address" : lang == "hindi" ? "पता" : "पता"}
            </label>
            <input
              name="pi_permanent_home_address_line_1"
              type="text"
              id="pi_permanent_home_address_line_1"
              className="  form-control"
              onChange={(e) =>
                handleInputChange("pi_permanent_home_address_line_1", e)
              }
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_permanent_home_address_line_1}
            />
            {formik.touched &&
            formik.errors.pi_permanent_home_address_line_1 ? (
              <div className="text-danger">
                {formik.errors.pi_permanent_home_address_line_1}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_mobile_no">
              {lang == "english"
                ? "Mobile number"
                : lang == "hindi"
                ? "मोबाइल नंबर"
                : "चलदूरवाणीसङ्ख्या"}
            </label>
            <input
              type="number"
              name="pi_mobile_no"
              id="pi_mobile_no"
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  handleInputChange("pi_mobile_no", e);
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_mobile_no}
              className="form-control"
            />
            {formik.touched && formik.errors.pi_mobile_no ? (
              <div className="text-danger">{formik.errors.pi_mobile_no}</div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_email_address">
              {lang == "english"
                ? "Email address"
                : lang == "hindi"
                ? "ईमेल आईडी॰"
                : "अन्तर्जालीय पत्रसङ्केतः"}
            </label>
            <input
              type="email"
              name="pi_email_address"
              id="pi_email_address"
              onChange={(e) => handleInputChange("pi_email_address", e)}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.pi_email_address}
              className="form-control"
            />
            {formik.touched && formik.errors.pi_email_address ? (
              <div className="text-danger">
                {formik.errors.pi_email_address}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 form-group">
            <label htmlFor="language">
              {lang == "english"
                ? "In which language would you be comfortable having an  application related discussion?"
                : lang == "hindi"
                ? "आवेदन सम्बन्धित चर्चा आपसे किस भाषा में की जाए?"
                : "आवेदनसम्बद्धा वार्ता भवता सह कया भाषया विधातव्या ?"}
            </label>
            <select
              name="pi_language"
              id="pi_language"
              className="form-control"
              value={formik?.values?.pi_language || ""}
              onChange={(e) => handleInputChange("pi_language", e)}
              onBlur={(e) => handleInputSave()}
            >
              <option>
                ---
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
                ---
              </option>
              <option value="hindi">हिन्दी</option>
              <option value="sanskrit">संस्कृतम्</option>
              <option value="english">English</option>
            </select>
            {formik.touched && formik.errors.pi_language ? (
              <div className="text-danger">{formik.errors.pi_language}</div>
            ) : null}
          </div>
          <div className="col-lg-4 form-group">
            <label htmlFor="pi_have_brothers_sisters">
              {lang == "english"
                ? "Do you have any brothers / sisters?"
                : lang == "hindi"
                ? "क्या आपका कोई भाई / बहन है?"
                : "भवतः केचन भ्रातरः / भगिन्यः सन्ति वा?"}
            </label>
            <select
              name="pi_have_brothers_sisters"
              id="pi_have_brothers_sisters"
              className="form-control"
              value={formik?.values?.pi_have_brothers_sisters || ""}
              onChange={(e) => {
                handleInputChange("pi_have_brothers_sisters", e);
                if (e.target.value == "yes") {
                  formik.setFieldValue("pi_brothers_sisters", [
                    {
                      name: "",
                      gender: "",
                      age: 0,
                      other: "",
                      study_level: "",
                      studying_the_vedas: "",
                    },
                  ]);
                }
                if (e.target.value == "no") {
                  formik.setFieldValue("pi_brothers_sisters", [
                    {
                      name: "N/A",
                      gender: "N/A",
                      age: 0,
                      other: "N/A",
                      study_level: "N/A",
                      studying_the_vedas: "N/A",
                    },
                  ]);
                }
              }}
              onBlur={(e) => handleInputSave()}
            >
              <option>
                ---
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
                ---
              </option>
              <option value="yes">
                {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
              </option>
              <option value="no">
                {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
              </option>
            </select>
            {formik.touched && formik.errors.pi_have_brothers_sisters ? (
              <div className="text-danger">
                {formik.errors.pi_have_brothers_sisters}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`table-responsive mb-30 ${
            formik?.values?.pi_have_brothers_sisters === "yes" ? "" : "d-none"
          }`}
        >
          <label>
            {" "}
            {lang == "english"
              ? "Details of your brothers / sisters (in age order)."
              : lang == "hindi"
              ? "आपके भाई / बहनों का विवरण (आयु क्रम से)।"
              : " भवतः भ्रातॄणां / भगिनीनां विवरणं (आयुक्रमेण)।"}
          </label>
          <table className="table">
            <thead>
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number "
                    : lang == "hindi"
                    ? "क्रम संख्या"
                    : "क्रमसङ्ख्"}
                </th>
                <th>
                  {lang == "english"
                    ? "Brother / sister's name"
                    : lang == "hindi"
                    ? "भाई अथवा बहिन का नाम "
                    : "भ्रातुः भगिन्याः वा नाम "}
                </th>
                <th>
                  {lang == "english"
                    ? "Gender"
                    : lang == "hindi"
                    ? "लिंग "
                    : "लिङ्गम्"}
                </th>
                <th>
                  {lang == "english"
                    ? "Age"
                    : lang == "hindi"
                    ? "आयु "
                    : "आयुः"}
                </th>
                <th>
                  {lang == "english"
                    ? "Has your brother / sister studied or is studying the vedas? "
                    : lang == "hindi"
                    ? "क्या भाई / बहन ने वेदाध्ययन किया है अथवा कर रहे हैं?  "
                    : "किं भ्रात्रा/भगिन्या वेदाध्ययनं कृतम्/क्रियते? "}
                </th>
                <th>
                  {lang == "english"
                    ? "Study level"
                    : lang == "hindi"
                    ? "अध्ययन स्तर"
                    : "अध्ययनस्तरः "}
                </th>
                <th>
                  {lang == "english"
                    ? "Other"
                    : lang == "hindi"
                    ? "अन्य"
                    : "अन्य"}
                </th>
                <th width="120">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
            </thead>
            <tbody>
              {formik?.values?.pi_brothers_sisters.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      id={`pi_brothers_sisters[${index}].name`}
                      name={`pi_brothers_sisters[${index}].name`}
                      type="text"
                      className="form-control"
                      value={
                        formik?.values?.pi_brothers_sisters[index]?.name || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `pi_brothers_sisters[${index}].name`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors.pi_brothers_sisters &&
                    formik.errors.pi_brothers_sisters[index]?.name ? (
                      <div className="text-danger">
                        {formik.errors.pi_brothers_sisters[index].name}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      name={`pi_brothers_sisters[${index}].gender`}
                      id={`pi_brothers_sisters[${index}].gender`}
                      className="form-control"
                      value={
                        formik?.values?.pi_brothers_sisters[index].gender || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `pi_brothers_sisters[${index}].gender`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    >
                      <option>
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="male">
                        {lang == "english"
                          ? "Male"
                          : lang == "hindi"
                          ? "पुरुष"
                          : "पुरुषः"}
                      </option>
                      <option value="female">
                        {lang == "english"
                          ? "Female"
                          : lang == "hindi"
                          ? "स्त्री"
                          : "स्त्री"}
                      </option>
                      <option value="other">
                        {lang == "english"
                          ? "Other"
                          : lang == "hindi"
                          ? "अन्य"
                          : "इतरम्"}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors.pi_brothers_sisters &&
                    formik.errors.pi_brothers_sisters[index]?.gender ? (
                      <div className="text-danger">
                        {formik.errors.pi_brothers_sisters[index].gender}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      name={`pi_brothers_sisters[${index}].age`}
                      id={`pi_brothers_sisters[${index}].age`}
                      type="number"
                      className="form-control"
                      value={
                        formik?.values?.pi_brothers_sisters[index].age || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `pi_brothers_sisters[${index}].age`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors.pi_brothers_sisters &&
                    formik.errors.pi_brothers_sisters[index]?.age ? (
                      <div className="text-danger">
                        {formik.errors.pi_brothers_sisters[index].age}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      name={`pi_brothers_sisters[${index}].studying_the_vedas`}
                      id={`pi_brothers_sisters[${index}].studying_the_vedas`}
                      className="form-control"
                      value={
                        formik?.values?.pi_brothers_sisters[index]
                          .studying_the_vedas || ""
                      }
                      onChange={(e) =>
                        handleInputValueChangeBrotherSister(index, e)
                      }
                      onBlur={(e) => handleInputSave()}
                    >
                      <option>
                        {" "}
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="yes">
                        {lang == "english"
                          ? "Yes"
                          : lang == "hindi"
                          ? "हाँ"
                          : "आम्"}
                      </option>
                      <option value="No">
                        {lang == "english"
                          ? "No"
                          : lang == "hindi"
                          ? "नहीं "
                          : " न"}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors.pi_brothers_sisters &&
                    formik.errors.pi_brothers_sisters[index]
                      ?.studying_the_vedas ? (
                      <div className="text-danger">
                        {
                          formik.errors.pi_brothers_sisters[index]
                            .studying_the_vedas
                        }
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      id={`pi_brothers_sisters[${index}].study_level`}
                      name={`pi_brothers_sisters[${index}].study_level`}
                      type="text"
                      className={`form-control ${
                        formik?.values?.pi_brothers_sisters[index]
                          ?.studying_the_vedas === "yes"
                          ? ""
                          : "d-none"
                      }`}
                      value={
                        formik?.values?.pi_brothers_sisters[index]
                          .study_level || ""
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `pi_brothers_sisters[${index}].study_level`,
                          e
                        );
                        if (e.target.value != "Other") {
                          formik.setFieldValue(
                            `pi_brothers_sisters[${index}].study_level`,
                            e.target.value
                          );
                        }
                        if (e.target.value == "Other") {
                          formik.setFieldValue(
                            `pi_brothers_sisters[${index}].other`,
                            ""
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                      required={
                        formik?.values?.pi_brothers_sisters[index]
                          ?.studying_the_vedas === "yes"
                      }
                    >
                      <option>
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="Mulanta">
                        {lang == "english"
                          ? "Mulanta"
                          : lang == "hindi"
                          ? "मूलांत"
                          : "मूलान्तः"}
                      </option>
                      <option value="Kramanta">
                        {lang == "english"
                          ? "Kramanta"
                          : lang == "hindi"
                          ? "क्रमांत "
                          : "क्रमान्त:"}
                      </option>
                      <option value="Ghananta">
                        {lang == "english"
                          ? "Ghananta"
                          : lang == "hindi"
                          ? "घनांत"
                          : "घनान्तः"}
                      </option>
                      <option value="Shadanga">
                        {lang == "english"
                          ? "Shadanga"
                          : lang == "hindi"
                          ? "षडङ्ग"
                          : "षडङ्गानि"}
                      </option>
                      <option value="Lakshana">
                        {lang == "english"
                          ? "Lakshana"
                          : lang == "hindi"
                          ? "लक्षण"
                          : "लक्षणम्"}
                      </option>
                      <option value="Bhashya">
                        {lang == "english"
                          ? "Bhashya"
                          : lang == "hindi"
                          ? "भाष्य"
                          : "भाष्यम्"}
                      </option>
                      <option value="Other">
                        {lang == "english"
                          ? "Other"
                          : lang == "hindi"
                          ? "अन्य  "
                          : "अन्यः  "}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors.pi_brothers_sisters &&
                    formik.errors.pi_brothers_sisters[index]?.study_level ? (
                      <div className="text-danger">
                        {formik.errors.pi_brothers_sisters[index].study_level}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik?.values?.pi_brothers_sisters[index]?.study_level &&
                    formik?.values?.pi_brothers_sisters[index]?.study_level ===
                      "Other" ? (
                      <input
                        type="text"
                        className="form-control"
                        id={`pi_brothers_sisters[${index}].other`}
                        name={`pi_brothers_sisters[${index}].other`}
                        value={
                          formik?.values?.pi_brothers_sisters[index].other || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `pi_brothers_sisters[${index}].other`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                    ) : (
                      ""
                    )}
                    {formik.touched &&
                    formik.errors &&
                    formik.errors.pi_brothers_sisters &&
                    formik.errors.pi_brothers_sisters[index] &&
                    formik.errors.pi_brothers_sisters[index]?.other ? (
                      <div className="text-danger">
                        {formik.errors.pi_brothers_sisters[index].other}
                      </div>
                    ) : null}
                  </td>

                  <td>
                    {formik?.values?.pi_brothers_sisters.length >= 2 ? (
                      <button
                        onClick={(e) => removeBrotherSisterRow(index, e)}
                        type="button"
                        className="btn btn-outline-danger"
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}

              <tr className="border-bottom-0">
                <td className="text-right" colspan="8">
                  <button
                    onClick={(e) => addBrotherSisterRow(e)}
                    type="button"
                    className="btn btn-danger"
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_present_guruji_name">
              {lang == "english"
                ? "Name of your present vedaadhyapaka"
                : lang == "hindi"
                ? "आपके वर्तमान वेदाध्यापक का नाम"
                : " भवतः अद्यतनवेदाध्यापकस्य नाम"}
            </label>
            <input
              id="pi_present_guruji_name"
              name="pi_present_guruji_name"
              type="text"
              className="form-control"
              value={formik?.values?.pi_present_guruji_name || ""}
              onChange={(e) => {
                handleInputChange("pi_present_guruji_name", e);
                formik.setFieldValue("ga_teacher_full_name", e.target.value);
                formik.setFieldValue("g_guruji_name_info", e.target.value);
              }}
            />
            {formik.touched && formik.errors.pi_present_guruji_name ? (
              <div className="text-danger">
                {formik.errors.pi_present_guruji_name}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_guruji_mobile">
              {lang == "english"
                ? "Mobile number of vedaadhyapaka"
                : lang == "hindi"
                ? "वेदाध्यापक के मोबाइल नंबर"
                : "वेदाध्यापकस्य चलदूरवाणीसङ्ख्या"}
            </label>
            <input
              id="pi_guruji_mobile"
              name="pi_guruji_mobile"
              type="number"
              className="form-control"
              value={formik?.values?.pi_guruji_mobile || ""}
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  handleInputChange("pi_guruji_mobile", e);
                }
              }}
              onBlur={(e) => handleInputSave()}
            />
            {formik.touched && formik.errors.pi_guruji_mobile ? (
              <div className="text-danger">
                {formik.errors.pi_guruji_mobile}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_passport_photo">
              {lang == "english"
                ? "Upload a passport sized photo of yourself"
                : lang == "hindi"
                ? "अपनी पासपोर्ट-साईज की अपनी फोटो यहाँ अपलोड करे "
                : "स्वस्य पासपोर्टफोटो इति अत्र अपलोड इति करोतु"}
            </label>
            <input
              id="pi_passport_photo"
              accept=".jpg, .jpeg, .png, .pdf"
              type="file"
              className="form-control"
              name="pi_passport_photo"
              onChange={async (e) => {
                e.preventDefault();

                if (e.target.files.length > 0) {
                  await fileUploadFunction(
                    "pi_passport_photo_string",
                    e.target.files[0]
                  );
                }
              }}
              onBlur={(e) => {
                handleInputSave(e);
              }}
            />
            {formik.values?.pi_passport_photo_string && (
              <button
                className="uploadLink"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.pi_passport_photo_string}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
                onBlur={(e) => handleInputSave()}
              >
                {`${formik?.values?.pi_passport_photo_string.replace(
                  "upload-file/",
                  ""
                )}`}
              </button>
            )}
            {formik.touched && formik.errors.pi_passport_photo_string ? (
              <div className="text-danger">
                {formik.errors.pi_passport_photo_string}
              </div>
            ) : null}
          </div>

          {/* only use for singhal foundation */}
          {/* <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_date_of_receipt_of_application">
              Date of receipt of application
            </label>
            <input
              id="pi_date_of_receipt_of_application"
              name="pi_date_of_receipt_of_application"
              type="date"
              className="form-control"
              value={formik?.values?.pi_date_of_receipt_of_application || ""}
              onChange={(e) =>
                handleInputChange("pi_date_of_receipt_of_application", e)
              }
            />
            {formik.touched &&
            formik.errors.pi_date_of_receipt_of_application ? (
              <div className="text-danger">
                {formik.errors.pi_date_of_receipt_of_application}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_application_verification_date">
              Application verification date
            </label>
            <input
              id="pi_application_verification_date"
              name="pi_application_verification_date"
              type="date"
              className="  form-control"
              value={formik?.values?.pi_application_verification_date || ""}
              onChange={(e) =>
                handleInputChange("pi_application_verification_date", e)
              }
            />
            {formik.touched &&
            formik.errors.pi_application_verification_date ? (
              <div className="text-danger">
                {formik.errors.pi_application_verification_date}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="pi_investigation_officer">
              Investigation officer
            </label>
            <input
              id="pi_investigation_officer"
              name="pi_investigation_officer"
              type="text"
              className="form-control"
              value={formik?.values?.pi_investigation_officer || ""}
              onChange={(e) => handleInputChange("pi_investigation_officer", e)}
            />
            {formik.touched && formik.errors.pi_investigation_officer ? (
              <div className="text-danger">
                {formik.errors.pi_investigation_officer}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="investigation_officer">Code number</label>
            <input
              id="pi_code_number"
              name="pi_code_number"
              type="text"
              className="form-control"
              value={formik?.values?.pi_code_number || ""}
              onChange={(e) => handleInputChange("pi_code_number", e)}
            />
            {formik.touched && formik.errors.pi_code_number ? (
              <div className="text-danger">{formik.errors.pi_code_number}</div>
            ) : null}
          </div> */}
        </div>

        {/* <div className="row">
          <div className="col-12">
            <h3>Other comments</h3>
            <textarea
              id="pi_other_comments"
              name="pi_other_comments"
              className="w-100"
              rows={2}
              value={formik?.values?.pi_other_comments || ""}
              onChange={(e) => handleInputChange("pi_other_comments", e)}
            ></textarea>
            {formik.touched && formik.errors.pi_other_comments ? (
              <div className="text-danger">
                {formik.errors.pi_other_comments}
              </div>
            ) : null}
          </div>
        </div> */}
      </form>
    </div>
  );
}

export default Page1_General_information;
