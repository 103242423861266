import React, { useEffect, useState } from "react";
import Select from "react-select";

function Section_2({
  formik,
  lang = "english",
  handleInputSave,
  handleInputChange,
  addStudentVidyalay,
  removeStudentVidyalay,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(34);
  const [actualCount, setActualCount] = useState(0);
  const [ved, setVed] = useState([]);
  const [vedShakha, setVedShakha] = useState([]);
  const [vedaShakhaOptions, setVedaShakhaOptions] = useState([]);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(
      formik?.values?.si_studied_veda_and_vedshakha,
      count
    );
    count = handleInputValue(formik?.values?.si_ved_select, count);
    count = handleInputValue(
      formik?.values?.si_present_student_count[0]?.si_vedas_and_vedshakhas,
      count
    );
    count = handleInputValue(
      formik?.values?.si_present_student_count[0]?.si_vidhyarthi_study_sanhita,
      count
    );
    count = handleInputValue(
      formik?.values?.si_present_student_count[0]?.si_vidhyarthi_study_krama,
      count
    );
    count = handleInputValue(
      formik?.values?.si_present_student_count[0]?.si_vidhyarthi_study_ghana,
      count
    );
    count = handleInputValue(
      formik?.values?.si_present_student_count[0]
        ?.si_vidhyarthi_study_beyond_ghana,
      count
    );
    count = handleInputValue(
      formik?.values?.si_present_student_count[0]
        ?.si_vidhyarthi_study_total_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_list_students_studying_at_vedvidyalay,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[0]?.session_start_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[0]?.this_session_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[0]?.education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[0]
        ?.before_education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[0]?.session_end_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[1]?.session_start_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[1]?.this_session_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[1]?.education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[1]
        ?.before_education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[1]?.session_end_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[2]?.session_start_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[2]?.this_session_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[2]?.education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[2]
        ?.before_education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[2]?.session_end_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[3]?.session_start_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[3]?.this_session_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[3]?.education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[3]
        ?.before_education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[3]?.session_end_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[4]?.session_start_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[4]?.this_session_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[4]?.education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[4]
        ?.before_education_complete_student_count,
      count
    );
    count = handleInputValue(
      formik?.values?.si_student_status[4]?.session_end_student_count,
      count
    );
    setActualCount(count);
    // setTotalCount(total_count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(2, totalCount, actualCount);
  }, [actualCount, totalCount]);

  let defaultDropDownValue = "";
  let vedOption = [];
  let rigved = [];
  let shuklayajurved = [];
  let krishnayajurved = [];
  let samved = [];
  let atharvaveda = [];
  if (lang === "english") {
    rigved = [
      { value: "Shakalashakha", label: "Shakalashakha" },
      { value: "Ashvalayanashakha", label: "Ashvalayanashakha" },
      { value: "Bashkalashakha", label: "Bashkalashakha" },
      { value: "Shankhayanashakha", label: "Shankhayanashakha" },
    ];
    shuklayajurved = [
      { value: "Kanvashakha", label: "Kanvashakha" },
      { value: "Madhyandinashakha", label: "Madhyandinashakha" },
    ];
    krishnayajurved = [
      { value: "Taittiriyashakha", label: "Taittiriyashakha" },
      { value: "Maitrayanishakha", label: "Maitrayanishakha" },
      { value: "Kathashakha", label: "Kathashakha" },
      { value: "Kapishthal", label: "Kapishthal" },
    ];
    samved = [
      { value: "Kauthumashakha", label: "Kauthumashakha" },
      { value: "Ranayaniyashakha", label: "Ranayaniyashakha" },
      { value: "Maitrayanishakha", label: "Maitrayanishakha" },
    ];
    atharvaveda = [
      { value: "Shaunakashakha", label: "Shaunakashakha" },
      { value: "Paippaladashakha", label: "Paippaladashakha" },
    ];
    defaultDropDownValue = "Select";
    vedOption = [
      { value: "Rigveda", label: "Rigveda" },
      { value: "Shuklayajurveda", label: "Shuklayajurveda" },
      { value: "Krishnayajurveda", label: "Krishnayajurveda" },
      { value: "Samveda", label: "Samveda" },
      { value: "Atharvaveda", label: "Atharvaveda" },
    ];
  } else if (lang === "hindi") {
    rigved = [
      { value: "Shakalashakha", label: "शाकलशाखा" },
      { value: "Ashvalayanashakha", label: "आश्वलायनशाखा" },
      { value: "Bashkalashakha", label: "बाष्कलशाखा" },
      { value: "Shankhayanashakha", label: "शांखायनशाखा" },
    ];
    shuklayajurved = [
      { value: "Kanvashakha", label: "काण्वशाखा" },
      { value: "Madhyandinashakha", label: "माध्यन्दिनशाखा" },
    ];
    krishnayajurved = [
      { value: "Taittiriyashakha", label: "तैत्तिरीयशाखा" },
      { value: "Maitrayanishakha", label: "मैत्रायणीशाखा" },
      { value: "Kathashakha", label: "कठशाखा" },
      { value: "Kapishthal", label: "कपिष्ठलशाखा" },
    ];
    samved = [
      { value: "Kauthumashakha", label: "कौशुमाशाखा" },
      { value: "Ranayaniyashakha", label: "रानायनीशाखा" },
      { value: "Maitrayanishakha", label: "माध्यन्दिनशाखा" },
    ];
    atharvaveda = [
      { value: "Shaunakashakha", label: "शानकषाखा" },
      { value: "Paippaladashakha", label: "पैप्पलादषाखा" },
    ];
    defaultDropDownValue = "चुने";
    vedOption = [
      { value: "Rigveda", label: "ऋग्वेद" },
      { value: "Shuklayajurveda", label: "शुक्लयजुर्वेद" },
      { value: "Krishnayajurveda", label: "कृष्णयजुर्वेद" },
      { value: "Samveda", label: "सामवेद" },
      { value: "Atharvaveda", label: "अथर्ववेद" },
    ];
  } else {
    rigved = [
      { value: "Shakalashakha", label: "शाकलशाखा" },
      { value: "Ashvalayanashakha", label: "आश्वलायनशाखा" },
      { value: "Bashkalashakha", label: "बाष्कलशाखा" },
      { value: "Shankhayanashakha", label: "शांखायनशाखा" },
    ];
    shuklayajurved = [
      { value: "Kanvashakha", label: "काण्वशाखा" },
      { value: "Madhyandinashakha", label: "माध्यन्दिनशाखा" },
    ];
    krishnayajurved = [
      { value: "Taittiriyashakha", label: "तैत्तिरीयशाखा" },
      { value: "Maitrayanishakha", label: "मैत्रायणीशाखा" },
      { value: "Kathashakha", label: "कठशाखा" },
      { value: "Kapishthal", label: "कपिष्ठलशाखा" },
    ];
    samved = [
      { value: "Kauthumashakha", label: "कौशुमाशाखा" },
      { value: "Ranayaniyashakha", label: "रानायनीशाखा" },
      { value: "Maitrayanishakha", label: "माध्यन्दिनशाखा" },
    ];
    atharvaveda = [
      { value: "Shaunakashakha", label: "शानकषाखा" },
      { value: "Paippaladashakha", label: "पैप्पलादषाखा" },
    ];
    defaultDropDownValue = "चिनोतु";
    vedOption = [
      { value: "Rigveda", label: "ऋग्वेदः" },
      { value: "Shuklayajurveda", label: "शुक्लयजुर्वेदः" },
      { value: "Krishnayajurveda", label: "कृष्णयजुर्वेदः" },
      { value: "Samveda", label: "सामवेदः" },
      { value: "Atharvaveda", label: "अथर्ववेदः" },
    ];
  }

  useEffect(() => {
    let selectedValues = formik?.values?.si_studied_veda_and_vedshakha?.map(
      (option) => option.value
    );
    let options = [];
    if (selectedValues?.includes("Rigveda")) {
      rigved.map((option) => {
        options.push(option);
      });
    }
    if (selectedValues?.includes("Shuklayajurveda")) {
      shuklayajurved.map((option) => {
        options.push(option);
      });
    }
    if (selectedValues?.includes("Krishnayajurveda")) {
      krishnayajurved.map((option) => {
        options.push(option);
      });
    }
    if (selectedValues?.includes("Samveda")) {
      samved.map((option) => {
        options.push(option);
      });
    }
    if (selectedValues?.includes("Atharvaveda")) {
      atharvaveda.map((option) => {
        options.push(option);
      });
    }
    setVedaShakhaOptions(options);
  }, [formik?.values?.si_studied_veda_and_vedshakha]);

  const vedShakaChange = (fieldName, e) => {
    let oldName = [];
    for (let k = 0; k < formik.values.si_present_student_count.length; k++) {
      oldName.push(
        formik.values.si_present_student_count[k].si_vedas_and_vedshakhas
      );
    }

    let eventData = [];

    for (let i = 0; i < e.length; i++) {
      eventData.push(e[i].label);
    }

    let newData = [];

    for (let i = 0; i < e.length; i++) {
      if (formik.values.si_present_student_count.length > 0) {
        for (
          let m = 0;
          m < formik.values.si_present_student_count.length;
          m++
        ) {
          if (
            formik.values.si_present_student_count[m].si_vedas_and_vedshakhas ==
            e[i].label
          ) {
            newData.push(formik.values.si_present_student_count[m]);
          }
        }
      } else {
        newData.push({
          si_vedas_and_vedshakhas: e[i].label,
          si_vidhyarthi_study_sanhita: "",
          si_vidhyarthi_study_krama: "",
          si_vidhyarthi_study_ghana: "",
          si_vidhyarthi_study_beyond_ghana: "",
          si_vidhyarthi_study_total_count: "",
        });
      }
    }

    const difference = eventData.filter(
      (element) => !oldName.includes(element)
    );

    if (
      difference.length > 0 &&
      formik.values.si_present_student_count.length > 0
    ) {
      for (let h = 0; h < difference.length; h++) {
        newData.push({
          si_vedas_and_vedshakhas: difference[h],
          si_vidhyarthi_study_sanhita: "",
          si_vidhyarthi_study_krama: "",
          si_vidhyarthi_study_ghana: "",
          si_vidhyarthi_study_beyond_ghana: "",
          si_vidhyarthi_study_total_count: "",
        });
      }
    }

    formik.setFieldValue("si_present_student_count", newData);
    formik.setFieldValue("si_ved_select", e);
  };

  const presentStudy = (field_name, e) => {
    formik.setFieldValue(field_name, e);

    if (e.length == 0) {
      formik.setFieldValue("si_ved_select", []);
      formik.setFieldValue("si_present_student_count", []);
    }

    if (e.length > 0) {
      for (let i = 0; i < e.length; i++) {
        let selectParents = e.map((option) => {
          return option.value;
        });

        let options = [];
        let optionsLable = [];
        if (selectParents?.includes("Rigveda")) {
          rigved.map((option) => {
            options.push(option.value);
            optionsLable.push(option.label);
          });
        }
        if (selectParents?.includes("Shuklayajurveda")) {
          shuklayajurved.map((option) => {
            options.push(option.value);
            optionsLable.push(option.label);
          });
        }
        if (selectParents?.includes("Krishnayajurveda")) {
          krishnayajurved.map((option) => {
            options.push(option.value);
            optionsLable.push(option.label);
          });
        }
        if (selectParents?.includes("Samveda")) {
          samved.map((option) => {
            options.push(option.value);
            optionsLable.push(option.label);
          });
        }
        if (selectParents?.includes("Atharvaveda")) {
          atharvaveda.map((option) => {
            options.push(option.value);
            optionsLable.push(option.label);
          });
        }

        let selectedParents = [];
        if (formik.values.si_ved_select.length > 0) {
          for (let l = 0; l < formik.values.si_ved_select.length; l++) {
            if (options.includes(formik.values.si_ved_select[l].value)) {
              selectedParents.push(formik.values.si_ved_select[l]);
            }
          }
        }

        const newSelected = [];
        for (let w = 0; w < selectedParents.length; w++) {
          newSelected.push(selectedParents[w].label);
        }

        let studentCount = [];
        if (formik?.values?.si_present_student_count.length > 0) {
          for (
            let u = 0;
            u < formik?.values?.si_present_student_count.length;
            u++
          ) {
            if (
              newSelected.includes(
                formik?.values?.si_present_student_count[u]
                  ?.si_vedas_and_vedshakhas
              )
            ) {
              studentCount.push(formik?.values?.si_present_student_count[u]);
            }
          }
        }

        formik.setFieldValue("si_present_student_count", studentCount);
        formik.setFieldValue("si_ved_select", selectedParents);
      }
    }
  };

  return (
    <div>
      <h3>
        {lang == "english"
          ? "Vedavidhyarthi related information"
          : lang == "hindi"
          ? "वेदविद्यार्थी सम्बन्धित जानकारी "
          : "विद्यार्थिसम्बद्धाः सूचनाः"}
      </h3>
      <p className="text-primary  mb-30">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
          ? "सभी प्रश्‍न अनिवार्य हैं।"
          : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>
      <div className="row">
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="si_studied_veda_and_vedshakha">
            {lang == "english"
              ? "In the institution which veds and vedashakhas are being taught at present ?"
              : lang == "hindi"
              ? "संस्था में कौन-कौन से वेद और वेदशाखाओं का अध्यापन वर्तमान में हो रहा है? कृपया ✓ चिह्न करें"
              : "सम्प्रति संस्थायां के वेदाः का: वेदशाखाश्च पाठ्यन्ते ? कृपया ✓ इति चिह्नं करोतु"}
          </label>
          <Select
            value={formik?.values?.si_studied_veda_and_vedshakha}
            options={vedOption}
            placeholder={defaultDropDownValue}
            isMulti
            onChange={(e) => {
              presentStudy(`si_studied_veda_and_vedshakha`, e);
            }}
            onBlur={(e) => handleInputSave(e)}
          />
          {formik?.errors?.si_studied_veda_and_vedshakha && (
            <div className="text-danger">
              {formik?.errors?.si_studied_veda_and_vedshakha}
            </div>
          )}
        </div>
        <div className="col-md-6 col-lg-6 form-group">
          <label htmlFor="si_ved_select">
            {lang == "english"
              ? "Vedashakha "
              : lang == "hindi"
              ? "वेदशाखा "
              : "वेदशाखा "}
          </label>
          <Select
            value={formik?.values?.si_ved_select}
            options={vedaShakhaOptions}
            placeholder={defaultDropDownValue}
            isMulti
            onChange={(e) => {
              vedShakaChange(`si_ved_select`, e);
            }}
            onBlur={(e) => handleInputSave(e)}
          />
          {formik?.errors?.si_ved_select && (
            <div className="text-danger">{formik?.errors?.si_ved_select}</div>
          )}
        </div>
      </div>
      <label>
        {lang == "english"
          ? "Current Vedavidyarthi number"
          : lang == "hindi"
          ? "वर्तमान वेदविद्यार्थियों की संख्या"
          : "सम्प्रति वेदवेदविद्यार्थिनः सङ्ख्या"}
      </label>
      <div className="table-responsive form-group">
        <table className="table">
          <thead>
            <tr>
              <th className="wrapTh">
                {lang == "english"
                  ? "Vedashakha"
                  : lang == "hindi"
                  ? "वेदशाखा "
                  : "वेदशाखा "}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "Number of vedavidyarthis studying sanhita"
                  : lang == "hindi"
                  ? "संहिताध्ययन कर रहे वेदविद्यार्थी"
                  : "संहिताध्ययनरताः वेदविद्यार्थिनः"}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "Number of vedavidyarthis studying krama"
                  : lang == "hindi"
                  ? "क्रम-अध्ययन कर रहे वेदविद्यार्थी "
                  : "क्रमान्ताध्ययनरताः वेदविद्यार्थिनः "}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "Number of vedavidyarthis studying Ghana"
                  : lang == "hindi"
                  ? "घन-अध्ययन कर रहे वेदविद्यार्थी "
                  : "घनान्ताध्ययनरताः वेदविद्यार्थिनः "}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "Number of vedavidyarthis studying beyond ghana"
                  : lang == "hindi"
                  ? "घन से आगे पढ़ रहे वेदविद्यार्थी "
                  : "घनान्तादुत्तरस्तरे अध्ययनरताः वेदविद्यार्थिनः "}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "Total vedavidyarthi"
                  : lang == "hindi"
                  ? "कुलवेदविद्यार्थी "
                  : "वेदविद्यार्थिनः योगः"}
              </th>
            </tr>
          </thead>
          <tbody>
            {formik?.values?.si_ved_select &&
              formik?.values?.si_present_student_count?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <p>
                      {
                        formik.values.si_present_student_count[index]
                          ?.si_vedas_and_vedshakhas
                      }
                    </p>
                    <input
                      type="hidden"
                      id={`si_present_student_count[${index}].si_vedas_and_vedshakhas`}
                      name={`si_present_student_count[${index}].si_vedas_and_vedshakhas`}
                      value={
                        formik?.values?.si_present_student_count[index]
                          ?.si_vedas_and_vedshakhas || 0
                      }
                    />
                    {formik.touched &&
                    formik.errors.si_present_student_count &&
                    formik.errors.si_present_student_count[index]
                      ?.si_vedas_and_vedshakhas ? (
                      <div className="text-danger">
                        {
                          formik.errors.si_present_student_count[index]
                            ?.si_vedas_and_vedshakhas
                        }
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`si_present_student_count[${index}].si_vidhyarthi_study_sanhita`}
                      name={`si_present_student_count[${index}].si_vidhyarthi_study_sanhita`}
                      value={
                        formik?.values?.si_present_student_count[index]
                          ?.si_vidhyarthi_study_sanhita || 0
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `si_present_student_count[${index}].si_vidhyarthi_study_sanhita`,
                          e
                        );
                        if (e.target.value) {
                          formik.setFieldValue(
                            `si_present_student_count[${index}].si_vidhyarthi_study_total_count`,
                            parseInt(
                              parseInt(e.target.value || 0) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_beyond_ghana || 0
                                ) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_beyond_ghana || 0
                                ) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_beyond_ghana || 0
                                )
                            )
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave(e)}
                    />
                    {formik?.errors?.si_present_student_count &&
                      formik?.errors?.si_present_student_count[index]
                        ?.si_vidhyarthi_study_sanhita && (
                        <div className="text-danger">
                          {
                            formik?.errors?.si_present_student_count[index]
                              ?.si_vidhyarthi_study_sanhita
                          }
                        </div>
                      )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`si_present_student_count[${index}].si_vidhyarthi_study_krama`}
                      name={`si_present_student_count[${index}].si_vidhyarthi_study_krama`}
                      value={
                        formik?.values?.si_present_student_count[index]
                          ?.si_vidhyarthi_study_krama || 0
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `si_present_student_count[${index}].si_vidhyarthi_study_krama`,
                          e
                        );
                        if (e.target.value) {
                          formik.setFieldValue(
                            `si_present_student_count[${index}].si_vidhyarthi_study_total_count`,
                            parseInt(
                              parseInt(e.target.value || 0) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_sanhita || 0
                                ) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_ghana || 0
                                ) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_beyond_ghana || 0
                                )
                            )
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave(e)}
                    />
                    {formik?.errors?.si_present_student_count &&
                      formik?.errors?.si_present_student_count[index]
                        ?.si_vidhyarthi_study_krama && (
                        <div className="text-danger">
                          {
                            formik?.errors?.si_present_student_count[index]
                              ?.si_vidhyarthi_study_krama
                          }
                        </div>
                      )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`si_present_student_count[${index}].si_vidhyarthi_study_ghana`}
                      name={`si_present_student_count[${index}].si_vidhyarthi_study_ghana`}
                      value={
                        formik?.values?.si_present_student_count[index]
                          ?.si_vidhyarthi_study_ghana || 0
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `si_present_student_count[${index}].si_vidhyarthi_study_ghana`,
                          e
                        );
                        if (e.target.value) {
                          formik.setFieldValue(
                            `si_present_student_count[${index}].si_vidhyarthi_study_total_count`,
                            parseInt(
                              parseInt(e.target.value || 0) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_sanhita || 0
                                ) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_krama || 0
                                ) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_beyond_ghana || 0
                                )
                            )
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave(e)}
                    />
                    {formik?.errors?.si_present_student_count &&
                      formik?.errors?.si_present_student_count[index]
                        ?.si_vidhyarthi_study_ghana && (
                        <div className="text-danger">
                          {
                            formik?.errors?.si_present_student_count[index]
                              ?.si_vidhyarthi_study_ghana
                          }
                        </div>
                      )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`si_present_student_count[${index}].si_vidhyarthi_study_beyond_ghana`}
                      name={`si_present_student_count[${index}].si_vidhyarthi_study_beyond_ghana`}
                      value={
                        formik?.values?.si_present_student_count[index]
                          ?.si_vidhyarthi_study_beyond_ghana || 0
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `si_present_student_count[${index}].si_vidhyarthi_study_beyond_ghana`,
                          e
                        );
                        if (e.target.value) {
                          formik.setFieldValue(
                            `si_present_student_count[${index}].si_vidhyarthi_study_total_count`,
                            parseInt(
                              parseInt(e.target.value || 0) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_sanhita || 0
                                ) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_krama || 0
                                ) +
                                parseInt(
                                  formik?.values?.si_present_student_count[
                                    index
                                  ]?.si_vidhyarthi_study_ghana || 0
                                )
                            )
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave(e)}
                    />
                    {formik?.errors?.si_present_student_count &&
                      formik?.errors?.si_present_student_count[index]
                        ?.si_vidhyarthi_study_beyond_ghana && (
                        <div className="text-danger">
                          {
                            formik?.errors?.si_present_student_count[index]
                              ?.si_vidhyarthi_study_beyond_ghana
                          }
                        </div>
                      )}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`si_present_student_count[${index}].si_vidhyarthi_study_total_count`}
                      name={`si_present_student_count[${index}].si_vidhyarthi_study_total_count`}
                      value={
                        formik?.values?.si_present_student_count[index]
                          ?.si_vidhyarthi_study_total_count || 0
                      }
                      disabled
                    />
                    {formik.touched &&
                    formik.errors.si_present_student_count &&
                    formik.errors.si_present_student_count[index]
                      ?.si_vidhyarthi_study_total_count ? (
                      <div className="text-danger">
                        {
                          formik.errors.si_present_student_count[index]
                            ?.si_vidhyarthi_study_total_count
                        }
                      </div>
                    ) : null}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="form-group">
        <label>
          {lang == "english"
            ? "List of all vedavidhyarthi studying at the vedavidyalay at present. Attach Annexure-1?"
            : lang == "hindi"
            ? "वर्तमान में अध्ययन कर रहे सभी विद्यार्थियों की सूची. अनुबन्ध-१ संलग्न करें?"
            : "सम्प्रति अध्ययनं कुर्वतां सर्वेषां वेदवेदविद्यार्थिनः सूची. अनुबन्धम्-१ संलग्नं करोतु?"}
        </label>
        <select
          className="form-control "
          id="si_list_students_studying_at_vedvidyalay"
          name="si_list_students_studying_at_vedvidyalay"
          value={formik?.values?.si_list_students_studying_at_vedvidyalay || ""}
          onChange={(e) => {
            handleInputChange("si_list_students_studying_at_vedvidyalay", e);
            if (e.target.value === "yes") {
              formik.setFieldValue(
                "si_present_students_studying_at_vedvidyalay",
                [
                  {
                    student_name: "",
                    age: "",
                    vedashakha: "",
                    year: "",
                    exam_level: "",
                    other: "",
                  },
                ]
              );
            }
            if (e.target.value === "no") {
              formik.setFieldValue(
                "si_present_students_studying_at_vedvidyalay",
                [
                  {
                    student_name: "N/A",
                    age: "00",
                    vedashakha: "N/A",
                    year: "0000",
                    exam_level: "N/A",
                    other: "N/A",
                  },
                ]
              );
            }
          }}
          onBlur={(e) => handleInputSave(e)}
        >
          <option className="mb-10">
            ---
            {lang == "english" ? "Select" : lang == "hindi" ? "चुने" : "चिनोतु"}
            ---
          </option>
          <option value="yes">
            {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
          </option>
          <option value="no">
            {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
          </option>
        </select>
      </div>

      {formik?.errors?.si_list_students_studying_at_vedvidyalay && (
        <div className="text-danger">
          {formik?.errors?.si_list_students_studying_at_vedvidyalay}
        </div>
      )}
      {formik?.values?.si_list_students_studying_at_vedvidyalay == "yes" ? (
        <div className="table-responsive form-group">
          <table className="table">
            <thead>
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रम संख्या"
                    : "क्रमसङ्ख्या"}
                </th>
                <th>
                  {lang == "english"
                    ? "Student name "
                    : lang == "hindi"
                    ? "विद्यार्थी का नाम "
                    : "विद्यार्थिनः नाम"}
                </th>
                <th>
                  {lang === "english"
                    ? "Age"
                    : lang === "hindi"
                    ? "आयु "
                    : "आयुः "}
                </th>
                <th>
                  {lang == "english"
                    ? "Vedashakha "
                    : lang == "hindi"
                    ? "वेदशाखा "
                    : "वेदशाखा "}
                </th>
                <th>
                  {lang == "english"
                    ? "Year"
                    : lang == "hindi"
                    ? "वर्ष "
                    : "वर्षम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Exam level"
                    : lang == "hindi"
                    ? "परीक्षा संस्था का नाम "
                    : "परीक्षासंस्थायाः नाम "}
                </th>
                <th>
                  {lang == "english"
                    ? "Other"
                    : lang == "hindi"
                    ? "अन्य  "
                    : "अन्यः  "}
                </th>
                <th width="120" className="text-right">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
            </thead>
            <tbody>
              {formik?.values?.si_present_students_studying_at_vedvidyalay.map(
                (data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`si_present_students_studying_at_vedvidyalay[${index}].student_name`}
                        name={`si_present_students_studying_at_vedvidyalay[${index}].student_name`}
                        value={
                          formik.values
                            .si_present_students_studying_at_vedvidyalay[index]
                            ?.student_name || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `si_present_students_studying_at_vedvidyalay[${index}].student_name`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave(e)}
                      />
                      {formik?.errors
                        ?.si_present_students_studying_at_vedvidyalay &&
                        formik?.errors
                          ?.si_present_students_studying_at_vedvidyalay[index]
                          ?.student_name && (
                          <div className="text-danger">
                            {
                              formik?.errors
                                ?.si_present_students_studying_at_vedvidyalay[
                                index
                              ]?.student_name
                            }
                          </div>
                        )}
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`si_present_students_studying_at_vedvidyalay[${index}].age`}
                        name={`si_present_students_studying_at_vedvidyalay[${index}].age`}
                        value={
                          formik?.values
                            ?.si_present_students_studying_at_vedvidyalay[index]
                            .age
                        }
                        onChange={(e) => {
                          if (e.target.value <= 120) {
                            handleInputChange(
                              `si_present_students_studying_at_vedvidyalay[${index}].age`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave(e)}
                      />
                      {formik?.errors
                        ?.si_present_students_studying_at_vedvidyalay &&
                        formik?.errors
                          ?.si_present_students_studying_at_vedvidyalay[index]
                          ?.age && (
                          <div className="text-danger">
                            {
                              formik?.errors
                                ?.si_present_students_studying_at_vedvidyalay[
                                index
                              ].age
                            }
                          </div>
                        )}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`si_present_students_studying_at_vedvidyalay[${index}].vedashakha`}
                        name={`si_present_students_studying_at_vedvidyalay[${index}].vedashakha`}
                        value={
                          formik?.values
                            ?.si_present_students_studying_at_vedvidyalay[index]
                            .vedashakha
                        }
                        onChange={(e) => {
                          handleInputChange(
                            `si_present_students_studying_at_vedvidyalay[${index}].vedashakha`,
                            e
                          );
                        }}
                        onBlur={(e) => handleInputSave(e)}
                      />
                      {formik?.errors
                        ?.si_present_students_studying_at_vedvidyalay &&
                        formik?.errors
                          ?.si_present_students_studying_at_vedvidyalay[index]
                          ?.vedashakha && (
                          <div className="text-danger">
                            {
                              formik?.errors
                                ?.si_present_students_studying_at_vedvidyalay[
                                index
                              ].vedashakha
                            }
                          </div>
                        )}
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`si_present_students_studying_at_vedvidyalay[${index}].year`}
                        name={`si_present_students_studying_at_vedvidyalay[${index}].year`}
                        value={
                          formik?.values
                            ?.si_present_students_studying_at_vedvidyalay[index]
                            .year
                        }
                        onChange={(e) => {
                          if (e.target.value <= 2025) {
                            handleInputChange(
                              `si_present_students_studying_at_vedvidyalay[${index}].year`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave(e)}
                      />
                      {formik?.errors
                        ?.si_present_students_studying_at_vedvidyalay &&
                        formik?.errors
                          ?.si_present_students_studying_at_vedvidyalay[index]
                          ?.year && (
                          <div className="text-danger">
                            {
                              formik?.errors
                                ?.si_present_students_studying_at_vedvidyalay[
                                index
                              ].year
                            }
                          </div>
                        )}
                    </td>
                    <td>
                      <select
                        className="form-control"
                        id={`si_present_students_studying_at_vedvidyalay[${index}].exam_level`}
                        name={`si_present_students_studying_at_vedvidyalay[${index}].exam_level`}
                        value={
                          formik?.values
                            ?.si_present_students_studying_at_vedvidyalay[index]
                            .exam_level
                        }
                        onChange={(e) => {
                          handleInputChange(
                            `si_present_students_studying_at_vedvidyalay[${index}].exam_level`,
                            e
                          );
                          if (e.target.value == "Other") {
                            formik.setFieldValue(
                              `si_present_students_studying_at_vedvidyalay[${index}].other`,
                              ""
                            );
                          }
                          if (e.target.value != "Other") {
                            formik.setFieldValue(
                              `si_present_students_studying_at_vedvidyalay[${index}].other`,
                              "N/A"
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave(e)}
                      >
                        <option>
                          ---
                          {lang == "english"
                            ? "Select"
                            : lang == "hindi"
                            ? "चुने"
                            : "चिनोतु"}
                          ---
                        </option>
                        <option value="Mulanta">
                          {lang == "english"
                            ? "Mulanta"
                            : lang == "hindi"
                            ? "मूलांत"
                            : "मूलान्तः"}
                        </option>
                        <option value="Kramanta">
                          {lang == "english"
                            ? "Kramanta"
                            : lang == "hindi"
                            ? "क्रमांत "
                            : "क्रमान्त:"}
                        </option>
                        <option value="Ghananta">
                          {lang == "english"
                            ? "Ghananta"
                            : lang == "hindi"
                            ? "घनांत"
                            : "घनान्तः"}
                        </option>
                        <option value="Shadanga">
                          {lang == "english"
                            ? "Shadanga"
                            : lang == "hindi"
                            ? "षडङ्ग"
                            : "षडङ्गानि"}
                        </option>
                        <option value="Lakshana">
                          {lang == "english"
                            ? "Lakshana"
                            : lang == "hindi"
                            ? "लक्षण"
                            : "लक्षणम्"}
                        </option>
                        <option value="Bhashya">
                          {lang == "english"
                            ? "Bhashya"
                            : lang == "hindi"
                            ? "भाष्य"
                            : "भाष्यम्"}
                        </option>
                        <option value="Other">
                          {lang == "english"
                            ? "Other"
                            : lang == "hindi"
                            ? "अन्य  "
                            : "अन्यः  "}
                        </option>
                      </select>
                      {formik?.errors
                        ?.si_present_students_studying_at_vedvidyalay &&
                        formik?.errors
                          ?.si_present_students_studying_at_vedvidyalay[index]
                          ?.exam_level && (
                          <div className="text-danger">
                            {
                              formik?.errors
                                ?.si_present_students_studying_at_vedvidyalay[
                                index
                              ].exam_level
                            }
                          </div>
                        )}
                    </td>
                    <td>
                      {formik.values
                        .si_present_students_studying_at_vedvidyalay[index]
                        ?.exam_level == "Other" ? (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            disabled={
                              formik.values
                                .si_present_students_studying_at_vedvidyalay[
                                index
                              ].exam_level === "Other"
                                ? ""
                                : "disabled"
                            }
                            id={`si_present_students_studying_at_vedvidyalay[${index}].other`}
                            name={`si_present_students_studying_at_vedvidyalay[${index}].other`}
                            value={
                              formik.values
                                .si_present_students_studying_at_vedvidyalay[
                                index
                              ].other
                            }
                            onChange={(e) =>
                              handleInputChange(
                                `si_present_students_studying_at_vedvidyalay[${index}].other`,
                                e
                              )
                            }
                            onBlur={(e) => handleInputSave(e)}
                          />
                          {formik?.errors
                            ?.si_present_students_studying_at_vedvidyalay &&
                            formik?.errors
                              ?.si_present_students_studying_at_vedvidyalay[
                              index
                            ]?.other && (
                              <div className="text-danger">
                                {
                                  formik?.errors
                                    ?.si_present_students_studying_at_vedvidyalay[
                                    index
                                  ].other
                                }
                              </div>
                            )}
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className="text-right">
                      {formik?.values
                        ?.si_present_students_studying_at_vedvidyalay.length -
                        1 ===
                      index ? (
                        <button
                          onClick={(e) => removeStudentVidyalay(index, e)}
                          type="button"
                          className="btn btn-outline-danger"
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className="border-bottom-0">
                <td className="text-right" colspan="8">
                  <button
                    onClick={(e) => addStudentVidyalay(e)}
                    type="button"
                    className="btn btn-danger"
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}

      <label>
        {lang == "english"
          ? "Status of the vedavidyarthis over the last 5 years give as per academic session of vedavidyalay"
          : lang == "hindi"
          ? "पिछले ५ वर्षों में विद्यार्थियों की स्थिति (विद्यालय के शिक्षा सत्र के अनुसार दीजिए) "
          : "विगतपञ्चवर्षाणां छात्राणां स्थितिः (विद्यालयस्य शैक्षणिकसत्रानुसारं ददातु)"}
      </label>
      <div className="table-responsive form-group">
        <table className="table">
          <thead>
            <tr>
              <th className="wrapTh">
                {lang == "english" ? "Year" : lang == "hindi" ? "" : ""}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "Number of sudents at the beginning of the session"
                  : lang == "hindi"
                  ? "सत्रारम्भ में वेदविद्यार्थी संख्या"
                  : "सत्रस्यारम्भे  वेदविद्यार्थिसङ्ख्या "}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "New vedavidyarthis joining this session"
                  : lang == "hindi"
                  ? "सत्र में नये वेदविद्यार्थी जुड़े "
                  : "सत्रे  सम्मिलिताः नववेदविद्यार्थिनः"}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "Number of vedavidyarthis completing their education"
                  : lang == "hindi"
                  ? "शिक्षा पूर्ण कर जाने वाले विद्यार्थियों की संख्या "
                  : "सम्पूर्णं वेदाध्ययनं कृत्वा समावर्तनं प्राप्तवतां वेदवेदविद्यार्थिनः सङ्ख्या"}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "Number of vedavidyarthis leaving before completing their education"
                  : lang == "hindi"
                  ? "शिक्षा पूर्ण होने से पहले छोड़ने वाले वेदविद्यार्थियों की संख्या"
                  : "सम्पूर्णं वेदाध्ययनं  अकृत्वैव विद्यालयं त्यक्तवतां वेदवेदविद्यार्थिनः सङ्ख्या "}
              </th>
              <th className="wrapTh">
                {lang == "english"
                  ? "Number of vedavidyarthis at the end of the session"
                  : lang == "hindi"
                  ? "सत्रान्त में वेगविद्यार्थियों की संख्या "
                  : "सत्रान्ते वेदविद्यार्थिसङ्ख्या "}
              </th>
            </tr>
          </thead>
          <tbody>
            {formik?.values?.si_student_status.map((key, index) => (
              <tr key={index}>
                <th>{new Date().getFullYear() - index - 1}</th>
                <td>
                  <input
                    id={`si_student_status[${index}].session_start_student_count`}
                    name={`si_student_status[${index}].session_start_student_count`}
                    type="number"
                    className="form-control"
                    value={
                      formik.values?.si_student_status[index]
                        ?.session_start_student_count
                    }
                    onChange={(e) => {
                      handleInputChange(
                        `si_student_status[${index}].session_start_student_count`,
                        e
                      );
                      if (e.target.value) {
                        formik.setFieldValue(
                          `si_student_status[${index}].session_end_student_count`,
                          parseInt(
                            parseInt(e.target.value || 0) +
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .this_session_student_count || 0
                              )
                          ) -
                            parseInt(
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .education_complete_student_count || 0
                              ) +
                                parseInt(
                                  formik?.values?.si_student_status[index]
                                    .before_education_complete_student_count ||
                                    0
                                )
                            )
                        );
                      } else {
                        formik.setFieldValue(
                          `si_student_status[${index}].session_end_student_count`,
                          parseInt(
                            parseInt(0) +
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .this_session_student_count || 0
                              )
                          ) -
                            parseInt(
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .education_complete_student_count || 0
                              ) +
                                parseInt(
                                  formik?.values?.si_student_status[index]
                                    .before_education_complete_student_count ||
                                    0
                                )
                            )
                        );
                      }
                    }}
                    onBlur={(e) => handleInputSave(e)}
                  />
                  {formik?.errors?.si_student_status &&
                    formik?.errors?.si_student_status[index]
                      ?.session_start_student_count && (
                      <div className="text-danger">
                        {
                          formik?.errors?.si_student_status[index]
                            .session_start_student_count
                        }
                      </div>
                    )}
                </td>
                <td>
                  <input
                    id={`si_student_status[${index}].this_session_student_count`}
                    name={`si_student_status[${index}].this_session_student_count`}
                    type="number"
                    className="form-control"
                    value={
                      formik.values?.si_student_status[index]
                        ?.this_session_student_count
                    }
                    onChange={(e) => {
                      handleInputChange(
                        `si_student_status[${index}].this_session_student_count`,
                        e
                      );
                      if (e.target.value) {
                        formik.setFieldValue(
                          `si_student_status[${index}].session_end_student_count`,
                          parseInt(
                            parseInt(e.target.value || 0) +
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .session_start_student_count || 0
                              )
                          ) -
                            parseInt(
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .education_complete_student_count || 0
                              ) +
                                parseInt(
                                  formik?.values?.si_student_status[index]
                                    .before_education_complete_student_count ||
                                    0
                                )
                            )
                        );
                      } else {
                        formik.setFieldValue(
                          `si_student_status[${index}].session_end_student_count`,
                          parseInt(
                            parseInt(0) +
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .session_start_student_count || 0
                              )
                          ) -
                            parseInt(
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .education_complete_student_count || 0
                              ) +
                                parseInt(
                                  formik?.values?.si_student_status[index]
                                    .before_education_complete_student_count ||
                                    0
                                )
                            )
                        );
                      }
                    }}
                    onBlur={(e) => handleInputSave(e)}
                  />
                  {formik?.errors?.si_student_status &&
                    formik?.errors?.si_student_status[index]
                      ?.this_session_student_count && (
                      <div className="text-danger">
                        {
                          formik?.errors?.si_student_status[index]
                            .this_session_student_count
                        }
                      </div>
                    )}
                </td>
                <td>
                  <input
                    id={`si_student_status[${index}].education_complete_student_count`}
                    name={`si_student_status[${index}].education_complete_student_count`}
                    type="number"
                    className="form-control"
                    value={
                      formik.values?.si_student_status[index]
                        ?.education_complete_student_count
                    }
                    onChange={(e) => {
                      handleInputChange(
                        `si_student_status[${index}].education_complete_student_count`,
                        e
                      );
                      if (e.target.value) {
                        formik.setFieldValue(
                          `si_student_status[${index}].session_end_student_count`,
                          parseInt(
                            parseInt(
                              formik?.values?.si_student_status[index]
                                .session_start_student_count || 0
                            ) +
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .this_session_student_count || 0
                              )
                          ) -
                            parseInt(
                              parseInt(e.target.value || 0) +
                                parseInt(
                                  formik?.values?.si_student_status[index]
                                    .before_education_complete_student_count ||
                                    0
                                )
                            )
                        );
                      } else {
                        formik.setFieldValue(
                          `si_student_status[${index}].session_end_student_count`,
                          parseInt(
                            parseInt(
                              formik?.values?.si_student_status[index]
                                .session_start_student_count || 0
                            ) +
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .this_session_student_count || 0
                              )
                          ) -
                            parseInt(
                              parseInt(0) +
                                parseInt(
                                  formik?.values?.si_student_status[index]
                                    .before_education_complete_student_count ||
                                    0
                                )
                            )
                        );
                      }
                    }}
                    onBlur={(e) => handleInputSave(e)}
                  />
                  {formik?.errors?.si_student_status &&
                    formik?.errors?.si_student_status[index]
                      ?.education_complete_student_count && (
                      <div className="text-danger">
                        {
                          formik?.errors?.si_student_status[index]
                            .education_complete_student_count
                        }
                      </div>
                    )}
                </td>
                <td>
                  <input
                    id={`si_student_status[${index}].before_education_complete_student_count`}
                    name={`si_student_status[${index}].before_education_complete_student_count`}
                    type="number"
                    className="form-control"
                    value={
                      formik.values?.si_student_status[index]
                        ?.before_education_complete_student_count
                    }
                    onChange={(e) => {
                      handleInputChange(
                        `si_student_status[${index}].before_education_complete_student_count`,
                        e
                      );
                      if (e.target.value) {
                        formik.setFieldValue(
                          `si_student_status[${index}].session_end_student_count`,
                          parseInt(
                            parseInt(
                              formik?.values?.si_student_status[index]
                                .session_start_student_count || 0
                            ) +
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .this_session_student_count || 0
                              )
                          ) -
                            parseInt(
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .education_complete_student_count || 0
                              ) + parseInt(e.target.value || 0)
                            )
                        );
                      } else {
                        formik.setFieldValue(
                          `si_student_status[${index}].session_end_student_count`,
                          parseInt(
                            parseInt(
                              formik?.values?.si_student_status[index]
                                .session_start_student_count || 0
                            ) +
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .this_session_student_count || 0
                              )
                          ) -
                            parseInt(
                              parseInt(
                                formik?.values?.si_student_status[index]
                                  .education_complete_student_count || 0
                              ) + parseInt(0)
                            )
                        );
                      }
                    }}
                    onBlur={(e) => handleInputSave(e)}
                  />
                  {formik?.errors?.si_student_status &&
                    formik?.errors?.si_student_status[index]
                      ?.before_education_complete_student_count && (
                      <div className="text-danger">
                        {
                          formik?.errors?.si_student_status[index]
                            .before_education_complete_student_count
                        }
                      </div>
                    )}
                </td>
                <td>
                  <input
                    id={`si_student_status[${index}].session_end_student_count`}
                    name={`si_student_status[${index}].session_end_student_count`}
                    type="number"
                    className="form-control"
                    value={
                      formik.values?.si_student_status[index]
                        ?.session_end_student_count
                    }
                    disabled
                  />
                  {formik?.errors?.si_student_status &&
                    formik?.errors?.si_student_status[index]
                      ?.session_end_student_count && (
                      <div className="text-danger">
                        {
                          formik?.errors?.si_student_status[index]
                            .session_end_student_count
                        }
                      </div>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* --------------------------------------------------------------------- */}
    </div>
  );
}

export default Section_2;
