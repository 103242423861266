const Page4FormField = {
  sa_applicant_Name: "",
  sa_applicant_file: "",
  sa_applicant_file_string: "",
  sa_applicant_full_name: "",
  sa_applicant_signature: "",
  sa_applicant_location: "",
  sa_applicant_date: "",
};

export default Page4FormField;
