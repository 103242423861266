import * as Yup from "yup";

const validationSchema = {
  // General info Validations
  name: Yup.string().required("This field is required"),
  full_address: Yup.string().required("This field is required"),
  name_of_the_head: Yup.string().required("This field is required"),
  mobile: Yup.string()
    .matches(/^\d{10}$/, { message: "Mobile number must be 10 digits" })
    .required("This field is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("This field is required"),
  // website: Yup.string().required("This field is required"),
  contact_person_name: Yup.string().required("This field is required"),
  contact_person_email: Yup.string()
    .email("Invalid email format")
    .required("This field is required"),
  contact_person_mobile: Yup.string()
    .matches(/^\d{10}$/, { message: "Mobile number must be 10 digits" })
    .required("This field is required"),
  full_correspondence_pincode: Yup.string()
    .required("This field is required")
    .min(6, "Pincode Must be of 6 digits"),
  full_correspondence_district: Yup.string().required("This field is required"),
  full_correspondence_state: Yup.string().required("This field is required"),
  full_correspondence_address: Yup.string().required("This field is required"),
  communication_language: Yup.string().required("This field is required"),
  founder_institution: Yup.string().required("This field is required"),
  trust_name: Yup.string().required("This field is required"),
  registration_no: Yup.string().required("This field is required"),
  chief_trustee_name: Yup.string().required("This field is required"),
  trustee_mobile: Yup.string()
    .min(10, "Mobile number must be 10 digits")
    .required("This field is required"),
  sponsor_name: Yup.string().required("This field is required"),
  sponsor_address: Yup.string().required("This field is required"),
  head_sponsor_name: Yup.string().required("This field is required"),
  founder_name: Yup.string().required("This field is required"),
  founder_address: Yup.string().required("This field is required"),
  founder_mobile: Yup.string()
    .matches(/^\d{10}$/, {
      message: "Mobile number must be 10 digits",
    })
    .required("This field is required"),
  starting_date: Yup.string().nullable().required("This field is required"),
  was_school_closed: Yup.string().required("This field is required"),
  from_date: Yup.string().nullable().required("This field is required"),
  till_date: Yup.string().nullable().required("This field is required"),

  //   Student info Validations
  si_studied_veda_and_vedshakha: Yup.array().required("This field is required"),
  si_ved_select: Yup.array().required("This field is required"),

  si_present_student_count: Yup.array().of(
    Yup.object().shape({
      si_vedas_and_vedshakhas: Yup.string().required("This field is required"),
      si_vidhyarthi_study_sanhita: Yup.string().required(
        "This field is required"
      ),
      si_vidhyarthi_study_krama: Yup.string().required(
        "This field is required"
      ),
      si_vidhyarthi_study_ghana: Yup.string().required(
        "This field is required"
      ),
      si_vidhyarthi_study_beyond_ghana: Yup.string().required(
        "This field is required"
      ),
      si_vidhyarthi_study_total_count: Yup.string().required(
        "This field is required"
      ),
    })
  ),

  si_list_students_studying_at_vedvidyalay: Yup.string().required(
    "This field is required"
  ),
  si_present_students_studying_at_vedvidyalay: Yup.array().of(
    Yup.object().shape({
      student_name: Yup.string().required("This field is required"),
      age: Yup.number().required("This field is required"),
      vedashakha: Yup.string().required("This field is required"),
      year: Yup.number().required("This field is required"),
      exam_level: Yup.string().required("This field is required"),
      other: Yup.string().required("This field is required"),
    })
  ),
  si_student_status: Yup.array().of(
    Yup.object().shape({
      session_start_student_count: Yup.number().required(
        "This field is required"
      ),
      this_session_student_count: Yup.number().required(
        "This field is required"
      ),
      education_complete_student_count: Yup.number().required(
        "This field is required"
      ),
      before_education_complete_student_count: Yup.number().required(
        "This field is required"
      ),
      session_end_student_count: Yup.number().required(
        "This field is required"
      ),
    })
  ),

  purpose_of_establishing_the_vedavidyalaya: Yup.string().required(
    "This field is required"
  ),
  special_achievements: Yup.string().required("This field is required"),
  desc_of_veda_related_education: Yup.string().required(
    "This field is required"
  ),
  details_of_veda_related_programs: Yup.string().required(
    "This field is required"
  ),
  mention_five_achievements: Yup.string().required("This field is required"),
  list_of_awards_of_honours: Yup.string().required("This field is required"),
  list_of_awards_of_honours_details: Yup.array().of(
    Yup.object().shape({
      name_of_award_honor: Yup.string().required("This field is required"),
      name_of_institution: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
      attached_document_string: Yup.string().required("This field is required"),
    })
  ),

  // document_details: Yup.array().of(
  //   Yup.object().shape({
  //     details_of_document: Yup.string().required("This field is required"),
  //     attached_document_string: Yup.string().required("This field is required"),
  //   })
  // ),

  //   Veda teaching Validations
  vt_information_of_passed_student: Yup.array().of(
    Yup.object().shape({
      level: Yup.string().required("This field is required"),
      rigveda: Yup.string().required("This field is required"),
      shuklayajurve: Yup.string().required("This field is required"),
      krishnayajurve: Yup.string().required("This field is required"),
      samveda: Yup.string().required("This field is required"),
      atharvaveda: Yup.string().required("This field is required"),
    })
  ),
  vt_list_of_student_passed_the_kramanta_ghananta: Yup.string().required(
    "This field is required"
  ),
  vt_list_of_student_passed_the_kramanta_ghananta_detail: Yup.array().of(
    Yup.object().shape({
      vedavidyarthi_name: Yup.string().required("This field is required"),
      level_of_vedadhyaya: Yup.string().required("This field is required"),
      other: Yup.string().required("This field is required"),
      name_of_the_examine_institution: Yup.string().required(
        "This field is required"
      ),
      year: Yup.string().required("This field is required"),
      division: Yup.string().required("This field is required"),
      attached_document_string: Yup.string().required("This field is required"),
    })
  ),

  vt_list_of_awards_recived_by_students: Yup.string().required(
    "This field is required"
  ),
  vt_list_of_awards_recived_by_students_detail: Yup.array().of(
    Yup.object().shape({
      student_name: Yup.string().required("This field is required"),
      name_of_the_award: Yup.string().required("This field is required"),
      name_of_the_institute: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
      attached_document_string: Yup.string().required("This field is required"),
    })
  ),
  vt_daily_routine_of_the_student: Yup.string().required(
    "This field is required"
  ),
  vt_list_of_full_time_and_part_time: Yup.string().required(
    "This field is required"
  ),
  vt_list_of_full_time_and_part_time_details: Yup.array().of(
    Yup.object().shape({
      Adhyapaka_name: Yup.string().required("This field is required"),
      vedshakha: Yup.string().required("This field is required"),
      level_of_vedgyan: Yup.string().required("This field is required"),
      other_vedgyan: Yup.string().required("This field is required"),
      age: Yup.string().required("This field is required"),
      fulltime_parttime: Yup.string().required("This field is required"),
      attached_document_string: Yup.string().required("This field is required"),
    })
  ),

  vt_left_the_vedvidyalaya: Yup.string().required("This field is required"),
  vt_left_the_vedvidyalaya_details: Yup.array().of(
    Yup.object().shape({
      teacher_name: Yup.string().required("This field is required"),
      vedshakha: Yup.string().required("This field is required"),
      when_started: Yup.string().required("This field is required"),
      when_end: Yup.string().required("This field is required"),
      reason_for_leaving: Yup.string().required("This field is required"),
    })
  ),
  vt_former_vedavidyarthi: Yup.string().required("This field is required"),
  vt_former_vedavidyarthi_details: Yup.array().of(
    Yup.object().shape({
      student_name: Yup.string().required("This field is required"),
      vedadgyapan_finish: Yup.string().required("This field is required"),
      vedshakha: Yup.string().required("This field is required"),
      institution_begin_vedadhyapan: Yup.string().required(
        "This field is required"
      ),
    })
  ),
  vt_list_of_awards_receive_by_teacher: Yup.string().required(
    "This field is required"
  ),
  vt_list_of_awards_receive_by_teacher_details: Yup.array().of(
    Yup.object().shape({
      teacher_name: Yup.string().required("This field is required"),
      name_of_award: Yup.string().required("This field is required"),
      name_of_institution: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
      attached_document_string: Yup.string().required("This field is required"),
    })
  ),
  vt_daily_routine_of_the_vedadhyapaks: Yup.string().required(
    "This field is required"
  ),
  vt_efforts_being_made_by_the_teacher: Yup.string().required(
    "This field is required"
  ),

  //   Other significant info. Validations
  // purpose_of_establishing_the_vedavidyalaya: Yup.string().required(
  //   "This field is required"
  // ),
  // special_achievements: Yup.string().required("This field is required"),
  // desc_of_veda_related_education: Yup.string().required(
  //   "This field is required"
  // ),
  // details_of_veda_related_programs: Yup.string().required(
  //   "This field is required"
  // ),
  // mention_five_achievements: Yup.string().required("This field is required"),
  // list_of_awards_of_honours: Yup.string().required("This field is required"),
  // list_of_awards_of_honours_details: Yup.array().of(
  //   Yup.object().shape({
  //     name_of_award_honor: Yup.string().required("This field is required"),
  //     name_of_institution: Yup.string().required("This field is required"),
  //     year: Yup.string().required("This field is required"),
  //   })
  // ),

  //  Affidavit Validations
  af_name: Yup.string().required("This field is required"),
  af_years: Yup.string().required("This field is required"),
  af_affidavit_string: Yup.string().required("This field is required"),
  // af_full_name: Yup.string().required("This field is required"),
  // af_signature: Yup.string().required("This field is required"),
  // af_location: Yup.string().required("This field is required"),
  // af_date: Yup.string().required("This field is required"),

  //   List of documents Validations
  // document_details: Yup.array().of(
  //   Yup.object().shape({
  //     details_of_document: Yup.string().required("This field is required"),
  //     attached_document_string: Yup.string().required("This field is required"),
  //   })
  // ),
};

export default validationSchema;
