import React, { useEffect, useState } from "react";
function Section_6({
  formik,
  lang,
  handleInputSave,
  handleInputChange,
  addDocumentDetails,
  removeDocumentDetails,
  fileUploadFunction,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(0);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(
      formik.values.document_details[0].details_of_document,
      count
    );
    count = handleInputValue(
      formik.values.document_details[0].attached_document_string,
      count
    );
    setActualCount(count);
    // setTotalCount(total_count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(6, 0, 0);
  }, [actualCount, totalCount]);
  return (
    <div>
      <h3>
        {lang == "english"
          ? "List of documents attached with the application form"
          : lang == "hindi"
          ? "आवेदन-पत्र से संलग्न प्रलेखों की सूची"
          : "आवेदनपत्रेण सह संलग्नानां प्रलेखानां सूची"}
      </h3>
      <p className="text-primary mb-30">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
          ? "सभी प्रश्‍न अनिवार्य हैं।"
          : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>

      <div className="form-group">
        <h5>
          {lang == "english"
            ? "Description of articles attached to this application"
            : lang == "hindi"
            ? "आवेदन से संलग्न अनुबन्ध"
            : "आवेदनपत्रे संलग्नानि अनुलग्नकानि"}
        </h5>
        <label>
          {lang == "english"
            ? "Details of the copies of certificates attached with the application (Name of certificate like, shastri, acharya, samhita, pad, kram, ghan, awards and honours received etc.) attach a serial number to each entry in the order in which the copies are attached - (add additional pages if necessary)"
            : lang == "hindi"
            ? "आवेदनपत्र के साथ संलग्न प्रमाणपत्र की प्रतिलिपियों का विवरण (प्रमाणपत्र का नाम यथा- शास्त्री, आचार्य, संहिता, पद, क्रम, घन, पुरस्कार, सम्मान इत्यादि) देते हुए संलग्न क्रमसंख्या (जिस क्रम पर प्रतिलिपि संलग्न की गई है) का उल्लेख करें-(आवश्यकता होने पर अतिरिक्त पृष्ठ जोड़े)"
            : "आवेदनपत्रेण सह संलग्नप्रमाणपत्राणां प्रतिलिपीनां विवरणं (प्रमाणपत्रस्य नाम यथा शास्त्री, आचार्यः, संहिता, पदः, क्रमः, घनः, पुरस्कारः, सम्मानः इत्यादिकं) दत्त्वा संलग्नस्य क्रमाङ्कस्य (यस्मिन् क्रमे प्रतिलिपिः अस्ति) उल्लेखं करोतु-(आवश्यकता चेत् अतिरिक्तपृष्ठानि योजयतु)"}
        </label>
      </div>

      <div className="table-responsive form-group">
        <table className="table">
          <tr>
            <th>
              {lang == "english"
                ? "Details of documents"
                : lang == "hindi"
                ? "प्रलेखों का विवरण"
                : "प्रलेखानां विवरणम्"}
            </th>
            <th>
              {lang == "english"
                ? "Attach certificate"
                : lang == "hindi"
                ? "प्रमाण पत्र अपलोड करे "
                : "प्रमाणपत्रम् अपलोड इति करोतु"}
            </th>
            <th width="120">
              {lang == "english"
                ? "Action"
                : lang == "hindi"
                ? "क्रिया"
                : "क्रिया"}
            </th>
          </tr>
          <tbody>
            <tr>
              <td>Affidavit</td>
              <td>
                {formik.values?.af_affidavit_string && (
                  <button
                    className="uploadLink"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        `${process.env.REACT_APP_MEDIA_URL}/${formik.values.af_affidavit_string}`,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    {`${formik.values.af_affidavit_string.replace(
                      "upload-file/",
                      ""
                    )}`}
                  </button>
                )}
              </td>
              <td></td>
            </tr>
            {formik?.values?.vt_list_of_student_passed_the_kramanta_ghananta_detail?.map(
              (data, index) => (
                <tr key={index}>
                  <td>
                    {formik?.values
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ]?.name_of_the_examine_institution || ""}
                  </td>
                  <td>
                    {formik?.values
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ]?.attached_document_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.vt_list_of_student_passed_the_kramanta_ghananta_detail[index].attached_document_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik?.values?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ]?.attached_document_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                  </td>
                  <td>
                    {formik?.values
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail
                      .length >= 2 ? (
                      <button
                        onClick={(e) => removeDocumentDetails(index, e)}
                        type="button"
                        className="btn btn-outline-danger"
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              )
            )}
            {formik?.values?.document_details?.map((data, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    name={`document_details[${index}].details_of_document`}
                    id={`document_details[${index}].details_of_document`}
                    className="form-control"
                    value={
                      formik?.values?.document_details[index]
                        .details_of_document || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `document_details[${index}].details_of_document`,
                        e
                      )
                    }
                    onBlur={(e) =>
                      handleInputSave(
                        `document_details[${index}].details_of_document`,
                        e
                      )
                    }
                  />
                  {formik.touched &&
                  formik.errors.document_details &&
                  formik.errors.document_details[index]?.details_of_document ? (
                    <div className="text-danger">
                      {
                        formik.errors.document_details[index]
                          ?.details_of_document
                      }
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    name={`document_details[${index}].attached_document`}
                    id={`document_details[${index}].attached_document`}
                    className="form-control"
                    onChange={async (e) => {
                      if (e.target.files.length > 0) {
                        await fileUploadFunction(
                          `document_details[${index}].attached_document_string`,
                          e.target.files[0]
                        );
                      }
                    }}
                    onBlur={(e) => handleInputSave()}
                  />
                  {formik?.values?.document_details[index]
                    ?.attached_document_string && (
                    <button
                      className="uploadLink"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.document_details[index].attached_document_string}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      {`${formik?.values?.document_details[
                        index
                      ]?.attached_document_string.replace("upload-file/", "")}`}
                    </button>
                  )}
                </td>
                <td>
                  {formik?.values?.document_details.length >= 2 ? (
                    <button
                      onClick={(e) => removeDocumentDetails(index, e)}
                      type="button"
                      className="btn btn-outline-danger"
                    >
                      {lang == "english"
                        ? "Remove"
                        : lang == "hindi"
                        ? "हटाएँ"
                        : "अपाकरोतु"}
                    </button>
                  ) : null}
                </td>
              </tr>
            ))}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="7">
                {" "}
                <button
                  onClick={(e) => addDocumentDetails(e)}
                  type="button"
                  className="btn btn-danger"
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                    ? "जोड़ें"
                    : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Section_6;
