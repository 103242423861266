import React, { useEffect } from "react";
import "./style.css";

function Page7_annexure_attached_to_the_application({
  formData,
  formik,
  handleInputChange,
  handleInputSave,
  addAnnexureDetails,
  removeAnnexureDetails,
  fileUploadFunction,
  lang,
  isPageComplete,
}) {
  useEffect(() => {
    isPageComplete(7, 1, 1);
  }, []);

  return (
    <div>
      <h3>
        {lang == "english"
          ? "Annexures attached to the application"
          : lang == "hindi"
          ? "आवेदन से संलग्न अनुबन्ध"
          : "आवेदनपत्रे संलग्नानि अनुलग्नकानि"}
      </h3>
      <p className="text-primary mb-30">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
          ? "सभी प्रश्न अनिवार्य हैं।"
          : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>
      <div className="row">
        <div className="col-12">
          <label>
            {lang == "english"
              ? "Give details of the copies of certificates attached with the application (Name of the certificates like- Shastri, Acharya, Samhita, Pad, Kram, Ghan etc.) in the sequence in which the copy is attached and number them in the same order."
              : lang == "hindi"
              ? "आवेदनपत्र के साथ संलग्न प्रमाणपत्र की प्रतिलिपियों का विवरण (प्रमाणपत्र का नाम यथा- शास्त्री, आचार्य, संहिता, पद, क्रम, घन इत्यादि) देते हुए संलग्न क्रमसंख्या (जिस क्रम पर प्रतिलिपि संलग्न की गई है) का उल्लेख करे-"
              : "आवेदनपत्रेण सह संलग्नप्रमाणपत्राणां प्रतिलिपीनां विवरणं (प्रमाणपत्रस्य नाम यथा शास्त्री, आचार्यः, संहिता, पदः, क्रमः, घनः, पुरस्कारः, सम्मानः इत्यादयः) दत्त्वा संलग्नस्य क्रमाङ्कस्य (यस्मिन् क्रमे प्रतिलिपिः अस्ति) उल्लेखं करोतु-(आवश्यकता चेत् अतिरिक्तपृष्ठानि योजयतु)"}
          </label>
        </div>
        <div className="col-12">
          <div className="table-responsive mb-30">
            <table className="table">
              <thead>
                <tr>
                  {/* <th>
                    {lang == "english"
                      ? "Serial Number"
                      : lang == "hindi"
                      ? "क्रमांक "
                      : "क्रमाङ्कः"}
                  </th> */}
                  <th>
                    {lang == "english"
                      ? "Year "
                      : lang == "hindi"
                      ? "वर्ष "
                      : "वर्षम् "}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Certificate details"
                      : lang == "hindi"
                      ? "प्रमाण-पत्र का विवरण"
                      : "प्रमाणपत्रविवरणम्"}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Upload PDF"
                      : lang == "hindi"
                      ? "अपलोड करें"
                      : "अपलोड इति करोतु"}
                  </th>
                  <th width="120">
                    {lang == "english"
                      ? "Action"
                      : lang == "hindi"
                      ? "क्रिया"
                      : "क्रिया"}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* <td>1</td> */}
                  <td></td>
                  <td>
                    {" "}
                    {lang == "english"
                      ? "Passport sized photo of yourself"
                      : lang == "hindi"
                      ? "अपनी पासपोर्ट-साईज की फोटो "
                      : "स्वस्य पासपोर्टफोटो इति अत्र"}
                  </td>
                  <td>
                    {formik.values?.pi_passport_photo_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.pi_passport_photo_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        onBlur={(e) => handleInputSave()}
                      >
                        {`${formik?.values?.pi_passport_photo_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  {/* <td>2</td> */}
                  <td></td>
                  <td>
                    {" "}
                    {lang == "english"
                      ? "Student affidavit"
                      : lang == "hindi"
                      ? "आवेदक शपथपत्र"
                      : "आवेदकेन शपथपत्रम्"}{" "}
                  </td>
                  <td>
                    {formik.values?.sa_applicant_file_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.sa_applicant_file_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        onBlur={(e) => handleInputSave()}
                      >
                        {`${formik?.values?.sa_applicant_file_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  {/* <td>3</td> */}
                  <td></td>
                  <td>
                    {" "}
                    {lang == "english"
                      ? "Information given by your present vedadhyapak"
                      : lang == "hindi"
                      ? "आपके वर्तमान वेदाध्यापक द्वारा दी गई जानकारी"
                      : "भवतः वर्तमानवेदाध्यापकेन दत्ता सूचना"}
                  </td>
                  <td>
                    {formik.values?.g_guruji_education_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.g_guruji_education_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        onBlur={(e) => handleInputSave()}
                      >
                        {`${formik?.values?.g_guruji_education_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  {/* <td>4</td> */}
                  <td></td>
                  <td>
                    {lang == "english"
                      ? "Affidavit given by Guruji of the applicant"
                      : lang == "hindi"
                      ? "आवेदक के गुरुजी द्वारा दिया गया शपथपत्र"
                      : "आवेदकस्य गुरुणा दत्तं शपथपत्रम्"}
                  </td>
                  <td>
                    {formik.values?.ga_applicant_file_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.ga_applicant_file_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        onBlur={(e) => handleInputSave()}
                      >
                        {`${formik?.values?.ga_applicant_file_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                  </td>
                  <td></td>
                </tr>

                {formik.values.vi_ved_and_passed_of_ved_examinations?.map(
                  (row, index) => (
                    <>
                      {formik.values.vi_ved_and_passed_of_ved_examinations[
                        index
                      ].vi_exam_certificate_string ? (
                        <>
                          <tr>
                            {/* <td></td> */}
                            <td></td>
                            <td>
                              {
                                formik.values
                                  ?.vi_ved_and_passed_of_ved_examinations[index]
                                  ?.vi_passed_exam
                              }
                            </td>
                            <td>
                              {formik.values
                                ?.vi_ved_and_passed_of_ved_examinations[index]
                                ?.vi_exam_certificate_string && (
                                <button
                                  className="uploadLink"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                      `${process.env.REACT_APP_MEDIA_URL}/${formik.values.vi_ved_and_passed_of_ved_examinations[index].vi_exam_certificate_string}`,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                >
                                  {`${formik.values.vi_ved_and_passed_of_ved_examinations[
                                    index
                                  ].vi_exam_certificate_string.replace(
                                    "upload-file/",
                                    ""
                                  )}`}
                                </button>
                              )}
                            </td>
                            <td></td>
                          </tr>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )
                )}

                {formik.values.grantha_details.map((row, index) => (
                  <>
                    {formik.values.grantha_details[index].certificate_string &&
                    formik.values.grantha_details[index].certificate_string !=
                      "N/A" ? (
                      <>
                        <tr>
                          {/* <td></td> */}
                          <td></td>
                          <td>
                            {formik.values.grantha_details[index]?.grantha_name}
                          </td>
                          <td>
                            {formik.values?.grantha_details[index]
                              .certificate_string && (
                              <button
                                className="uploadLink"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(
                                    `${process.env.REACT_APP_MEDIA_URL}/${formik.values.grantha_details[index]?.certificate_string}`,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }}
                              >
                                {`${formik.values.grantha_details[
                                  index
                                ]?.certificate_string.replace(
                                  "upload-file/",
                                  ""
                                )}`}
                              </button>
                            )}
                          </td>
                          <td></td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}

                {formik.values.award_details.map((row, index) => (
                  <>
                    {formik.values.award_details[index]?.certificate_string &&
                    formik.values.award_details[index]?.certificate_string !=
                      "N/A" ? (
                      <>
                        <tr>
                          {/* <td></td> */}
                          <td></td>
                          <td>
                            {formik.values.award_details[index]?.award_name}
                          </td>
                          <td>
                            {formik.values?.award_details[index]
                              .certificate_string && (
                              <button
                                className="uploadLink"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(
                                    `${process.env.REACT_APP_MEDIA_URL}/${formik.values.award_details[index]?.certificate_string}`,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }}
                              >
                                {`${formik.values.award_details[
                                  index
                                ]?.certificate_string.replace(
                                  "upload-file/",
                                  ""
                                )}`}
                              </button>
                            )}
                          </td>
                          <td></td>
                        </tr>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}

                {formik.values.a_annexure_details?.map((row, index) => (
                  <tr key={index}>
                    {/* <td>{index + 5}</td> */}
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        name={`a_annexure_details[${index}].year`}
                        id={`a_annexure_details[${index}].year`}
                        value={
                          formik.values.a_annexure_details[index]?.year || ""
                        }
                        onChange={(e) => {
                          if (
                            e.target.value.length <= 4 &&
                            e.target.value <= 2024
                          ) {
                            handleInputChange(
                              `a_annexure_details[${index}].year`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.a_annexure_details &&
                      formik.errors.a_annexure_details[index]?.year ? (
                        <div className="text-danger">
                          {formik.errors.a_annexure_details[index]?.year}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        name={`a_annexure_details[${index}].certificate_details`}
                        id={`a_annexure_details[${index}].certificate_details`}
                        value={
                          formik.values.a_annexure_details[index]
                            .certificate_details || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `a_annexure_details[${index}].certificate_details`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors.a_annexure_details &&
                      formik.errors.a_annexure_details[index]
                        ?.certificate_details ? (
                        <div className="text-danger">
                          {
                            formik.errors.a_annexure_details[index]
                              .certificate_details
                          }
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        className="form-control"
                        name={`a_annexure_details[${index}].uploaded_doc`}
                        id={`a_annexure_details[${index}].uploaded_doc`}
                        onChange={async (e) => {
                          e.preventDefault();
                          if (e.target.files.length > 0) {
                            await fileUploadFunction(
                              `a_annexure_details[${index}].uploaded_doc_string`,
                              e.target.files[0]
                            );
                          }
                        }}
                        onBlur={(e) => {
                          handleInputSave(e);
                        }}
                      />
                      {formik.values.a_annexure_details[index]
                        ?.uploaded_doc_string && (
                        <button
                          className="uploadLink"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}/${formik.values.a_annexure_details[index]?.uploaded_doc_string}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {`${formik.values.a_annexure_details[
                            index
                          ].uploaded_doc_string.replace("upload-file/", "")}`}
                        </button>
                      )}
                      {formik.touched &&
                      formik.errors.a_annexure_details &&
                      formik.errors.a_annexure_details[index]
                        ?.uploaded_doc_string ? (
                        <div className="text-danger">
                          {
                            formik.errors.a_annexure_details[index]
                              .uploaded_doc_string
                          }
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values.a_annexure_details.length >= 2 ? (
                        <button
                          onClick={(e) => removeAnnexureDetails(index, e)}
                          type="button"
                          className="btn btn-outline-danger"
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                ))}
                <tr className="border-bottom-0">
                  <td className="text-right" colspan="7">
                    <button
                      onClick={(e) => addAnnexureDetails(e)}
                      type="button"
                      className="btn btn-danger"
                    >
                      {lang == "english"
                        ? "Add"
                        : lang == "hindi"
                        ? "जोड़ें"
                        : "संयोजयतु"}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Page7_annexure_attached_to_the_application;
