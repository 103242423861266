import style from "./home.module.css";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axiosPrivate from "../../Hooks/axiosPrivate";
import LoaderContext from "../../context/Loader/LoaderContext";
import Loader from "../../context/Loader/Loader.js";

const object = {
  english: {
    sub_line:
      "Please fill the form only in selected language, otherwise your form will not be accepted.",
    form_type_heading: "For whom are you making the application?",
    option1: "Yourself",
    option2: "Other",
    user1: "Vedavidyarthi",
    user2: "Vedaadhyapaka",
    user3: "Vedavidyalaya",
    des_heading: "Eligibility Criteria for Application",
    button_text: "Fill form",
  },
  hindi: {
    sub_line:
      "कृपया फॉर्म केवल चयनित भाषा में ही भरें, अन्यथा आपका फॉर्म स्वीकार नहीं किया जाएगा।",
    form_type_heading: "आप किस के लिये आवेदन कर रहे है?",
    option1: "स्वयं के लिए",
    option2: "अन्य के लिए",
    user1: "वेदविद्यार्थी",
    user2: "वेदाध्यापक",
    user3: "वेदविद्यालय",
    des_heading: "आवेदन सम्बन्धित योग्यता  मापदण्ड",
    button_text: "आवेदन भरें",
  },
  sanskrit: {
    sub_line:
      "कृपया चितायां भाषायाम् एव प्रपत्रं पूरयन्तु अन्यथा भवतः प्रपत्रं स्वीकृतं न भविष्यति।",
    form_type_heading: "भवन्तः कस्मै/केषां कृते आवेदनं कुर्वन्ति?",
    option1: "स्वस्य कृते",
    option2: "अन्येषां कृते",
    user1: "वेदविद्यार्थी",
    user2: "वेदाध्यापकः",
    user3: "वेदविद्यालयः",
    des_heading: "आवेदनस्य पात्रतामापदण्डः",
    button_text: "आवेदनपत्रं पूरयन्तु",
  },
};

function Home() {
  const [user, setUser] = useState("");
  const [lang, setLang] = useState("english");
  const [applyType, setApplyType] = useState("");
  const [filledForm, setFilledForm] = useState([]);
  const [data, setData] = useState(null);
  const [acknowledgementVerify, setAcknowledgementVerify] = useState(false);
  const navigate = useNavigate();

  const login = localStorage.getItem("userData");
  const { loaderCheck, setLoaderCheck } = useContext(LoaderContext);

  useEffect(() => {
    setLoaderCheck(true);
    async function checkPage() {
      const responseAnswers = await axiosPrivate.get(`user-answer-data`);
      console.log("responseAnswers", responseAnswers);
      if (
        responseAnswers.status == 200 &&
        responseAnswers?.data?.data &&
        responseAnswers?.data?.data.length > 0 &&
        responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
          .status == "In Progress"
      ) {
        let url = "";
        if (
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .type == "Vedavidyarthi"
        ) {
          url = "/student_form";
        } else if (
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .type == "Vedaadhyapaka"
        ) {
          url = "/teacher_form";
        } else if (
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .type == "Vedavidyalaya"
        ) {
          url = "/school_form";
        }
        setLoaderCheck(false);
        navigate(url, {
          state: {
            lang: responseAnswers.data.data[
              responseAnswers?.data?.data?.length - 1
            ].language,
            user_type:
              responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
                .type,
            apply_type:
              responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
                .user_option,
            oldData:
              responseAnswers.data.data[
                responseAnswers?.data?.data?.length - 1
              ],
          },
        });
      }
    }
    if (login) {
      checkPage();
    } else {
      setLoaderCheck(false);
    }
  }, []);

  useEffect(() => {
    axiosPrivate
      .get(`questions/${user}/${lang}`)
      .then((response) => {
        setData(response?.data?.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user, lang]);

  const handleFillForm = () => {
    const url =
      user == "Vedavidyarthi"
        ? "/student_form"
        : user == "Vedaadhyapaka"
        ? "/teacher_form"
        : "/school_form";
    navigate(url, {
      state: {
        lang: lang,
        user_type: user,
        apply_type: applyType,
      },
    });
  };

  const handlePreview = (e) => {
    e.preventDefault();
    window.open(
      process.env.REACT_APP_BACKEND_URL + filledForm[0]?.preview,
      "Download"
    );
  };

  useEffect(() => {
    axiosPrivate
      .get(`user-answer-data-formlist`)
      .then((response) => {
        setFilledForm(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      {/* language section */}
      <div>
        {/* <h3 className="font-bold text-[14px] text-[#4F4F4F] leading-5">
                    Select Input Language
                </h3> */}
        <form>
          <div className="row">
            <div className="col-auto">
              <label htmlFor="english">
                <input
                  value="english"
                  type="radio"
                  name="lang"
                  id="english"
                  checked={lang === "english" ? true : false}
                  onChange={(e) => setLang(e.target.value)}
                  style={{
                    accentColor: "var(--primary-background-color)",
                  }}
                />
                English
              </label>
            </div>
            <div className="col-auto">
              <label htmlFor="hindi">
                <input
                  value="hindi"
                  type="radio"
                  name="lang"
                  id="hindi"
                  checked={lang === "hindi" ? true : false}
                  onClick={(e) => setLang(e.target.value)}
                  style={{
                    accentColor: "var(--primary-background-color)",
                  }}
                />
                हिन्दी
              </label>
            </div>
            <div className="col-auto">
              <input
                value="sanskrit"
                type="radio"
                name="lang"
                id="sanskrit"
                checked={lang === "sanskrit" ? true : false}
                onClick={(e) => setLang(e.target.value)}
                style={{
                  accentColor: "var(--primary-background-color)",
                }}
              />
              <label htmlFor="sanskrit">संस्कृतम्</label>
            </div>
          </div>
        </form>
        <div>
          {lang == "english" && <p>{object?.english?.sub_line}</p>}
          {lang == "hindi" && <p>{object?.hindi?.sub_line}</p>}
          {lang == "sanskrit" && <p>{object?.sanskrit?.sub_line}</p>}
        </div>
      </div>
      {/* language section */}
      <div className="mt-30">
        {lang == "english" && <h5>{object?.english?.form_type_heading}</h5>}
        {lang == "hindi" && <h5>{object?.hindi?.form_type_heading}</h5>}
        {lang == "sanskrit" && <h5>{object?.sanskrit?.form_type_heading}</h5>}
        <div className="row">
          <div className="col-auto">
            <input
              value="yourself"
              type="radio"
              name="type"
              id="youself"
              checked={applyType === "yourself" ? true : false}
              onChange={(e) => setApplyType(e.target.value)}
              style={{
                accentColor: "var(--primary-background-color)",
              }}
            />
            <label htmlFor="youself" className="mb-0">
              {lang == "english"
                ? object?.english?.option1
                : lang == "hindi"
                ? object?.hindi?.option1
                : object?.sanskrit?.option1}
            </label>
          </div>
          <div className="col-auto">
            <input
              value="other"
              type="radio"
              name="type"
              id="other"
              checked={applyType === "other" ? true : false}
              onClick={(e) => setApplyType(e.target.value)}
              style={{
                accentColor: "var(--primary-background-color)",
              }}
            />
            <label htmlFor="other" className="mb-0">
              {lang == "english"
                ? object?.english?.option2
                : lang == "hindi"
                ? object?.hindi?.option2
                : object?.sanskrit?.option2}
            </label>
          </div>
        </div>
      </div>
      {/* user btn section */}
      {applyType && (
        <div className="mt-30 home-button-parent">
          <button
            type="button"
            className={
              user === "Vedavidyarthi"
                ? `${style.homebtn_active}`
                : `${style.homebtn}`
            }
            onClick={() => setUser("Vedavidyarthi")}
          >
            <p>
              {" "}
              {lang == "english"
                ? object?.english?.user1
                : lang == "hindi"
                ? object?.hindi?.user1
                : object?.sanskrit?.user1}
            </p>
          </button>
          <button
            type="button"
            className={
              user === "Vedaadhyapaka"
                ? `${style.homebtn_active}`
                : `${style.homebtn}`
            }
            onClick={() => setUser("Vedaadhyapaka")}
          >
            <p>
              {" "}
              {lang == "english"
                ? object?.english?.user2
                : lang == "hindi"
                ? object?.hindi?.user2
                : object?.sanskrit?.user2}
            </p>
          </button>
          <button
            type="button"
            className={
              user === "Vedavidyalaya"
                ? `${style.homebtn_active}`
                : `${style.homebtn}`
            }
            onClick={() => setUser("Vedavidyalaya")}
          >
            <p>
              {" "}
              {lang == "english"
                ? object?.english?.user3
                : lang == "hindi"
                ? object?.hindi?.user3
                : object?.sanskrit?.user3}
            </p>
          </button>
        </div>
      )}

      {/* Application related qualifications
       */}
      {user && (
        <div>
          <div>
            <h5 className="mt-30">
              {" "}
              {lang == "english"
                ? object?.english?.des_heading
                : lang == "hindi"
                ? object?.hindi?.des_heading
                : object?.sanskrit?.des_heading}
            </h5>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="instuctions">${data?.description}</div>`,
              }}
            />
            {data?.description ? (
              <>
                {" "}
                <div className="mt-30 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="acknowledgement_verify"
                    name="acknowledgement_verify"
                    onChange={(e) => {
                      setAcknowledgementVerify(!acknowledgementVerify);
                    }}
                    checked={acknowledgementVerify}
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => {
                      setAcknowledgementVerify(!acknowledgementVerify);
                    }}
                  >
                    {lang == "english"
                      ? "I have read, understood and agree with the eligibility criteria and guidelines listed above. "
                      : lang == "hindi"
                      ? "मैंने उपर्युक्त योग्यता मापदण्डों को तथा दिशानिर्देशों को ध्यानपूर्वक पढ़कर समझ लिया है, और मैं इन से सहमत हूँ।"
                      : "अहम् उपर्युक्तयोग्यतामापदण्डान् दिशानिर्देशाञ्च ध्यानेन पठित्वा अवगतोऽस्मि तथा च अहम् एतान् अङ्गीकरोमि।"}
                  </label>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          {/* submit button */}

          <div className="mt-30">
            {applyType == "yourself" &&
            filledForm[0]?.user_option == "yourself" ? (
              <button
                type="button"
                onClick={(e) => handlePreview(e)}
                className="btn btn-danger"
              >
                {lang == "english"
                  ? "Preview"
                  : lang == "hindi"
                  ? "पूर्वावलोकन करे"
                  : "पूर्वावलोकनं करोतु"}
              </button>
            ) : (
              <button
                type="button"
                onClick={() => handleFillForm()}
                className="btn btn-danger"
                disabled={!acknowledgementVerify}
              >
                {lang == "english"
                  ? object?.english?.button_text
                  : lang == "hindi"
                  ? object?.hindi?.button_text
                  : object?.sanskrit?.button_text}
              </button>
            )}
          </div>
        </div>
      )}

      {applyType == "other" && (
        <div className="table-responsive mt-30">
          {filledForm?.filter((item) => item?.user_option !== "yourself")
            .length > 0 && <h5>Filled form(s)</h5>}
          <table className="table w-auto">
            {filledForm
              ?.filter((item) => item?.user_option !== "yourself")
              .map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td valign="middle">
                    {item?.answerdata?.p_name ||
                      item?.answerdata?.pi_name ||
                      item?.answerdata?.name}
                  </td>
                  <td>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          process.env.REACT_APP_BACKEND_URL + item?.preview,
                          "Download"
                        );
                      }}
                      className="btn btn-outline-danger ms-4"
                    >
                      Preview Form
                    </button>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      )}
    </div>
  );
}

export default Home;
