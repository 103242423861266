import axiosPrivate from "../Hooks/axiosPrivate";
import { showErrorToast } from "./Toaster";

const uploadFile = async (file) => {
  console.log("hello");
  const reqData = {
    document: file,
  };
  const formData = new FormData();
  Object.keys(reqData).forEach((key) => {
    formData.append(key, reqData[key]);
  });
  try {
    const response = await axiosPrivate.post("upload-file", formData);
    return response.data;
    // if (response.status === 200) {
    //   return response.data;
    // } else {
    //   showErrorToast(response.data.message);
    // }
  } catch (error) {
    showErrorToast(error?.response?.data?.errors[0]?.msg);
  }
};

export { uploadFile };
