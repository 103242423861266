import React, { useEffect, useState } from "react";

function Section_4({
  formik,
  lang,
  handleInputSave,
  handleInputChange,
  addHonoursDetails,
  removeHonoursDetails,
  isPageComplete,
  fileUploadFunction,
}) {
  const [totalCount, setTotalCount] = useState(6);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(
      formik.values.purpose_of_establishing_the_vedavidyalaya,
      count
    );
    count = handleInputValue(formik.values.special_achievements, count);
    count = handleInputValue(
      formik.values.desc_of_veda_related_education,
      count
    );
    count = handleInputValue(
      formik.values.details_of_veda_related_programs,
      count
    );
    count = handleInputValue(formik.values.mention_five_achievements, count);
    count = handleInputValue(formik.values.list_of_awards_of_honours, count);
    setActualCount(count);
    // setTotalCount(total_count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(4, totalCount, actualCount);
  }, [actualCount, totalCount]);
  return (
    <div className="mb-30">
      <h3>
        {lang == "english"
          ? "Other significant information"
          : lang == "hindi"
          ? "अन्य उल्लेखनीय जानकारी"
          : "अन्याः महत्त्वपूर्णाः सूचनाः"}
      </h3>
      <p className="text-primary mb-30">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
          ? "सभी प्रश्‍न अनिवार्य हैं।"
          : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>

      <label>
        {lang == "english"
          ? "Purpose of establishing of the vedavidyalaya"
          : lang == "hindi"
          ? "वेदविद्यालय की स्थापना का उद्देश्य"
          : "वेदविद्यालयस्य स्थापना-प्रयोजनम्"}
      </label>
      <div className="form-group">
        <textarea
          className="form-control"
          name="purpose_of_establishing_the_vedavidyalaya"
          id="purpose_of_establishing_the_vedavidyalaya"
          value={formik?.values?.purpose_of_establishing_the_vedavidyalaya}
          rows={2}
          onChange={(e) => handleInputChange("", e)}
          onBlur={(e) => handleInputSave("", e)}
          maxLength={700}
        ></textarea>
        {formik.touched &&
          formik.errors?.purpose_of_establishing_the_vedavidyalaya ? (
          <div className="text-danger">
            {formik.errors?.purpose_of_establishing_the_vedavidyalaya}
          </div>
        ) : null}
        <p className="text-right mb-3">
          {formik?.values?.purpose_of_establishing_the_vedavidyalaya?.length}
          /700
          {lang == "english"
            ? " Characters"
            : lang == "hindi"
            ? " शब्द"
            : " शब्दः"}
        </p>
      </div>

      <label>
        {lang == "english"
          ? "Special achievements of the institute in the field of vedaprasar or vedaraksha which makes it eligible for this award"
          : lang == "hindi"
          ? "संस्थान की वेदप्रसार या वेदरक्षा क्षेत्र की कोई विशेष उपलब्धियाँ जो उसे इस पुरस्कार के योग्य बनाती हों"
          : "वेदप्रसारस्य वेदरक्षणस्य वा क्षेत्रे संस्थायाः ताः विशेषाः उपलब्धयः याभिः अस्य पुरस्कारस्य प्राप्तिनिमित्तं योग्यता सिद्ध्येत्"}
      </label>
      <div className="from-group">
        <textarea
          className="form-control"
          name="special_achievements"
          id="special_achievements"
          value={formik?.values?.special_achievements}
          rows={2}
          onChange={(e) => handleInputChange("", e)}
          onBlur={(e) => handleInputSave("", e)}
          maxLength={700}
        ></textarea>
        {formik.touched && formik.errors?.special_achievements ? (
          <div className="text-danger">
            {formik.errors?.special_achievements}
          </div>
        ) : null}
        <p className="text-right mb-3">
          {formik?.values?.special_achievements?.length}
          /700
          {lang == "english"
            ? " Characters"
            : lang == "hindi"
            ? " शब्द"
            : " शब्दः"}
        </p>
      </div>

      <label>
        {lang == "english"
          ? "Description of veda-related education initiatives for the general public"
          : lang == "hindi"
          ? "जनसाधारण के लिये वेदविषयक प्रबोधन उपक्रमों का विवरण"
          : "सामान्यजनानां कृते वेदसम्बद्धशिक्षापरिकल्पनानां वर्णनम्"}
      </label>
      <div className="from-group">
        <textarea
          className="form-control"
          name="desc_of_veda_related_education"
          id="desc_of_veda_related_education"
          value={formik?.values?.desc_of_veda_related_education}
          rows={2}
          onChange={(e) =>
            handleInputChange("desc_of_veda_related_education", e)
          }
          onBlur={(e) => handleInputSave(e)}
          maxLength={700}
        ></textarea>
        {formik.touched && formik.errors?.desc_of_veda_related_education ? (
          <div className="text-danger">
            {formik.errors?.desc_of_veda_related_education}
          </div>
        ) : null}
        <p className="text-right mb-3">
          {formik?.values?.desc_of_veda_related_education?.length}
          /700
          {lang == "english"
            ? " Characters"
            : lang == "hindi"
            ? " शब्द"
            : " शब्दः"}
        </p>
      </div>

      <label>
        {lang == "english"
          ? "Details of veda related programs (parayan, swahakar etc.) performed in the last five years. "
          : lang == "hindi"
          ? "पिछले पाँच वर्षों में निष्पादित वेद सम्बन्धित कार्यक्रमों (पारायण, स्वाहाकार इत्यादि) का विवरण"
          : "विगतपञ्चवर्षेषु निष्पादितानां वेदसम्बद्धानां कार्यक्रमाणां (पारायणम्, स्वाहाकारः इत्यादयः) विवरणम्"}
      </label>
      <div className="from-group">
        <textarea
          className="form-control"
          name="details_of_veda_related_programs"
          id="details_of_veda_related_programs"
          value={formik?.values?.details_of_veda_related_programs}
          rows={2}
          onChange={(e) => handleInputChange("", e)}
          onBlur={(e) => handleInputSave("", e)}
          maxLength={700}
        ></textarea>
        {formik.touched && formik.errors?.details_of_veda_related_programs ? (
          <div className="text-danger">
            {formik.errors?.details_of_veda_related_programs}
          </div>
        ) : null}
        <p className="text-right mb-3">
          {formik?.values?.details_of_veda_related_programs?.length}
          /700
          {lang == "english"
            ? " Characters"
            : lang == "hindi"
            ? " शब्द"
            : " शब्दः"}
        </p>
      </div>

      <label>
        {lang == "english"
          ? "Mention five achievements of your school of which you yourself are proud "
          : lang == "hindi"
          ? "आपके विद्यालय की पाँच ऐसी उपलब्धियाँ बतायें जिनसे आप स्वयं गौरवान्वित हैं।"
          : "भवतः विद्यालयस्य एतादृशीः पञ्चोपलब्धीः उल्लेखयतु याभि: भवन्तः स्वयमपि गर्विताः सन्ति"}
      </label>
      <div className="form-group">
        <textarea
          className="form-control"
          name="mention_five_achievements"
          id="mention_five_achievements"
          value={formik?.values?.mention_five_achievements}
          rows={2}
          onChange={(e) => handleInputChange("", e)}
          onBlur={(e) => handleInputSave("", e)}
          maxLength={700}
        ></textarea>
        {formik.touched && formik.errors?.mention_five_achievements ? (
          <div className="text-danger">
            {formik.errors?.mention_five_achievements}
          </div>
        ) : null}
        <p className="text-right mb-3">
          {formik?.values?.mention_five_achievements?.length}
          /700
          {lang == "english"
            ? " Characters"
            : lang == "hindi"
            ? " शब्द"
            : " शब्दः"}
        </p>
      </div>

      <div className="form-group">
        <label>
          {lang == "english"
            ? "List of awards or honours received by the vedavidyalaya. Attach Annexure-8?"
            : lang == "hindi"
            ? "विद्यालय द्वारा प्राप्त पुरस्कार अथवा सम्मानों की सूची. अनुबन्ध-८ संलग्न करें?"
            : "विद्यालयेन प्राप्तानां पुरस्काराणां  सम्मानानां वा सूची. अनुबन्धम्-८ संलग्नं करोतु?"}
        </label>
        <select
          className="form-control mb-10"
          id="list_of_awards_of_honours"
          name="list_of_awards_of_honours"
          onChange={(e) => {
            handleInputChange("", e);
            if (e.target.value == "yes") {
              formik.setFieldValue(`list_of_awards_of_honours_details`, [
                {
                  name_of_award_honor: "",
                  name_of_institution: "",
                  year: "",
                  attached_document_string: "",
                },
              ]);
            }
            if (e.target.value == "no") {
              formik.setFieldValue(`list_of_awards_of_honours_details`, [
                {
                  name_of_award_honor: "N/A",
                  name_of_institution: "N/A",
                  year: "N/A",
                  attached_document_string: "N/A",
                },
              ]);
            }
          }}
          onBlur={(e) => handleInputSave("", e)}
          value={formik?.values?.list_of_awards_of_honours}
        >
          <option className="mb-10">
            ---
            {lang == "english" ? "Select" : lang == "hindi" ? "चुने" : "चिनोतु"}
            ---
          </option>
          <option value="yes">
            {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
          </option>
          <option value="no">
            {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
          </option>
        </select>
        {formik.touched && formik.errors?.list_of_awards_of_honours ? (
          <div className="text-danger">
            {formik.errors?.list_of_awards_of_honours}
          </div>
        ) : null}
      </div>

      {formik?.values?.list_of_awards_of_honours == "yes" ? (
        <div className="responsive-table form-group">
          <table className="table">
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                  ? "क्रम संख्या "
                  : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "Name of the award / honor"
                  : lang == "hindi"
                  ? "पुरस्कार अथवा सम्मान का नाम "
                  : "पुरस्कारस्य सम्मानस्य वा नाम  "}
              </th>
              <th>
                {lang == "english"
                  ? "Name of the institution"
                  : lang == "hindi"
                  ? "संस्था का नाम "
                  : "संस्थानाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Year"
                  : lang == "hindi"
                  ? "वर्ष "
                  : "वर्षम् "}
              </th>
              <th>
                {lang == "english"
                  ? "Attach certificate"
                  : lang == "hindi"
                  ? "प्रमाण पत्र अपलोड करे "
                  : "प्रमाणपत्रम् अपलोड इति करोतु"}
              </th>
              <th width="120">
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                  ? "क्रिया"
                  : "क्रिया"}
              </th>
            </tr>
            {formik?.values?.list_of_awards_of_honours_details?.map(
              (data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      type="text"
                      name={`list_of_awards_of_honours_details[${index}].name_of_award_honor`}
                      id={`list_of_awards_of_honours_details[${index}].name_of_award_honor`}
                      className="form-control"
                      value={
                        formik?.values?.list_of_awards_of_honours_details[index]
                          .name_of_award_honor || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `list_of_awards_of_honours_details[${index}].name_of_award_honor`,
                          e
                        )
                      }
                      onBlur={(e) =>
                        handleInputSave(
                          `list_of_awards_of_honours_details[${index}].name_of_award_honor`,
                          e
                        )
                      }
                    />
                    {formik.touched &&
                    formik.errors?.list_of_awards_of_honours_details &&
                    formik.errors?.list_of_awards_of_honours_details[index]
                      ?.name_of_award_honor ? (
                      <div className="text-danger">
                        {formik.errors?.list_of_awards_of_honours_details[index]
                          .name_of_award_honor &&
                          formik.errors?.list_of_awards_of_honours_details[
                            index
                          ].name_of_award_honor}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      name={`list_of_awards_of_honours_details[${index}].name_of_institution`}
                      id={`list_of_awards_of_honours_details[${index}].name_of_institution`}
                      className="form-control"
                      value={
                        formik?.values?.list_of_awards_of_honours_details[index]
                          .name_of_institution || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `list_of_awards_of_honours_details[${index}].name_of_institution`,
                          e
                        )
                      }
                      onBlur={(e) =>
                        handleInputSave(
                          `list_of_awards_of_honours_details[${index}].name_of_institution`,
                          e
                        )
                      }
                    />
                    {formik.touched &&
                    formik.errors?.list_of_awards_of_honours_details &&
                    formik.errors?.list_of_awards_of_honours_details[index]
                      ?.name_of_institution ? (
                      <div className="text-danger">
                        {formik.errors?.list_of_awards_of_honours_details[index]
                          .name_of_institution &&
                          formik.errors?.list_of_awards_of_honours_details[
                            index
                          ].name_of_institution}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="number"
                      name={`list_of_awards_of_honours_details[${index}].year`}
                      id={`list_of_awards_of_honours_details[${index}].year`}
                      className="form-control"
                      value={
                        formik?.values?.list_of_awards_of_honours_details[index]
                          .year || ""
                      }
                      onChange={(e) => {
                        if (e.target.value <= 2025) {
                          handleInputChange(
                            `list_of_awards_of_honours_details[${index}].year`,
                            e
                          );
                        }
                      }}
                      onBlur={(e) =>
                        handleInputSave(
                          `list_of_awards_of_honours_details[${index}].year`,
                          e
                        )
                      }
                    />
                    {formik.touched &&
                    formik.errors?.list_of_awards_of_honours_details &&
                    formik.errors?.list_of_awards_of_honours_details[index]
                      ?.year ? (
                      <div className="text-danger">
                        {formik.errors?.list_of_awards_of_honours_details[index]
                          .year &&
                          formik.errors?.list_of_awards_of_honours_details[
                            index
                          ].year}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      name={`list_of_awards_of_honours_details[${index}].attached_document`}
                      id={`list_of_awards_of_honours_details[${index}].attached_document`}
                      className="form-control"
                      onChange={async (e) => {
                        if (e.target.files.length > 0) {
                          await fileUploadFunction(
                            `list_of_awards_of_honours_details[${index}].attached_document_string`,
                            e.target.files[0]
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik?.values?.list_of_awards_of_honours_details[index]
                      ?.attached_document_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.list_of_awards_of_honours_details[index].attached_document_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik?.values?.list_of_awards_of_honours_details[
                          index
                        ].attached_document_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                    {formik.touched &&
                    formik.errors?.list_of_awards_of_honours_details &&
                    formik.errors?.list_of_awards_of_honours_details[index]
                      ?.attached_document_string ? (
                      <div className="text-danger">
                        {formik.errors?.list_of_awards_of_honours_details[index]
                          .attached_document_string &&
                          formik.errors?.list_of_awards_of_honours_details[
                            index
                          ].attached_document_string}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values.list_of_awards_of_honours_details.length >=
                    2 ? (
                      <button
                        onClick={(e) => removeHonoursDetails(index, e)}
                        type="button"
                        className="btn btn-outline-danger"
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              )
            )}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="7">
                <button
                  onClick={(e) => addHonoursDetails(e)}
                  type="button"
                  className="btn btn-danger"
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                    ? "जोड़ें"
                    : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <></>
      )}
      {/* <h5>
                {lang == "english"
                    ? "Dsescribe the daily routine of the vidyarthi"
                    : lang == "hindi"
                    ? "विद्यार्थियों की दिनचर्या का विवरण दीजिए"
                    : "विद्यार्थिनां दिनचर्यायाः विवरणं ददातु"}
            </h5>
            <textarea
                className="form-control"
                name="daily_routine_of_the_student"
                id="daily_routine_of_the_student"
                value={formik?.values?.daily_routine_of_the_student}
                rows={2}
                onChange={(e) => handleInputChange('',e)}
                onBlur={(e) => handleInputSave('', e)}
                maxLength={700}
            ></textarea>
            <p className="text-right mb-3">
                {formik?.values?.daily_routine_of_the_student?.length}/700
                {lang == "english"
                    ? " Characters"
                    : lang == "hindi"
                    ? " शब्द"
                    : " शब्दः"}
            </p> */}
    </div>
  );
}

export default Section_4;
