import React, { useEffect, useState } from "react";
import axiosPrivate from "../../Hooks/axiosPrivate";
import "./profile.css";

import { useFormik } from "formik";
import { showErrorToast, showSuccessToast } from "../../Utils/Toaster";

const initialValues = {
  name: "",
  father_name: "",
  DOB: "",
  mobile: "",
  adhaar_no: "",
  email: "",
};

function Profile() {
  const [data, setData] = useState({});

  const {
    values,
    error,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    onSubmit:async (values) => {
      try {
        const userData = {
          name: values.name,
          father_name: values.father_name,
          DOB: values.DOB,
        };
        const response = await axiosPrivate.put("users-update-profile", values);
        console.log(response);
        if (response.status === 200) {
          showSuccessToast("Profile Updated Successfully");
        }
      } catch (error) {
        showErrorToast(error.response.data.message);
      }
    },
  });

  const [editbtn, setEditbtn] = useState(false);

  const onEdit = () => {
    setEditbtn(!editbtn);
  };

  useEffect(() => {
    console.log(values);
  }, [values]);

  const onSubmit = () => {
    console.log(values);
    onEdit();
  };

  const getUserData = async () => {
    const response = await axiosPrivate.get("users/get");
    const { DOB, ...rest } = response.data.data[0];
    setValues(rest);
    setFieldValue("DOB", new Date(DOB).toISOString().split("T")[0]);
    // console.log(response);
    setData(response);
  };

  // `http:backend.bharat-aatma.brandtalks.in/images/users/${data.data.data.profile}`

  useEffect(() => {
    getUserData();
  }, []);

  // console.log(values);

  console.log(data?.data?.data[0]?.profile);

  //"2024-04-15"

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-auto">
          <div className="text-center profile">
            <img
              // src={`${process.env.REACT_MEDIA_URL}/users/${data?.data?.data[0]?.profile}`}
              src={
                process.env.REACT_APP_MEDIA_URL +
                `/register/${data?.data?.data[0]?.profile}`
              }
              className="d-block"
            />
          </div>
        </div>
        <div className="col-lg">
          <div className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                className="form-control"
                // {...(editbtn ? {} : { readOnly: "readOnly" })}
              />
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="father_name">Father&apos;s name</label>
              <input
                type="text"
                id="father_name"
                name="father_name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.father_name}
                className="form-control"
                // {...(editbtn ? {} : { readOnly: "readOnly" })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="DOB">Date of birth</label>
              <input
                type="date"
                id="DOB"
                name="DOB"
                maxLength={10}
                max={new Date()?.toISOString()?.slice(0, 10)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.DOB}
                className="form-control"
                // {...(editbtn ? {} : { readOnly: "readOnly" })}
              />
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="mobile">Mobile number</label>
              <input
                type="number"
                id="mobile"
                name="mobile"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="aadhaarCardNo">Aadhaar card number</label>
              <input
                type="text"
                id="adhaar_no"
                name="adhaar_no"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.adhaar_no}
                className="form-control"
                disabled
              />
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                className="form-control"
                disabled
              />
            </div>
          </div>

          <div>
            <button className="btn btn-danger" type="submit">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Profile;
