import React, { useEffect } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
});
const Test = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <h1 style={{ color: "black" }}>Hello lakhan sharma</h1>
        </View>
      </Page>
    </Document>
  );
};

export default Test;
