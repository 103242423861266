import React, { useEffect, useState, useContext } from "react";
import Page1_General_information from "../../Components/QuestionPages/Page1_General_information.js";
import Page2_information_of_vedic_education from "../../Components/QuestionPages/page2_information_of_vedic_educaiton.js";
import Page3_General_ingormation from "../../Components/QuestionPages/Page3_General_ingormation.js";
import Page4_affidavit_given_by_the_applicant from "../../Components/QuestionPages/Page4_affidavit_given_by_the_applicant.js";
import Page5_information_given_by_your_present_guruji from "../../Components/QuestionPages/Page5_information_given_by_your_present_guruji.js";
import Page6_affidavit_given_by_guruji_of_the_applicant from "../../Components/QuestionPages/Page6_affidavit_given_by_guruji_of_the_applicant.js";
import Page7_annexure_attached_to_the_application from "../../Components/QuestionPages/Page7_annexure_attached_to_the_application.js";
import Page8_preview_your_application from "../../Components/QuestionPages/Page8_preview_your_application.js";
import { FaCheck, FaDotCircle, FaExclamation } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import Page1FormField from "./Page1FormField.js";
import Page2FormField from "./Page2FormField.js";
import Page3FormField from "./Page3FormField.js";
import Page4FormField from "./Page4FormField.js";
import Page5FormField from "./Page5FormField.js";
import Page6FormField from "./Page6FormField.js";
import Page7FormField from "./Page7FormField.js";
import validationRules from "./validations.js";
import "./questions.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import axiosPrivate from "../../Hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../Utils/Toaster";
import { uploadFile } from "../../Utils/fileUpload";
import LoaderContext from "../../context/Loader/LoaderContext.js";
import Loader from "../../context/Loader/Loader.js";

function QuestionPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
  });

  // Access state data passed from the previous page
  const { loaderCheck, setLoaderCheck } = useContext(LoaderContext);

  let { lang, user_type, apply_type, oldData } = location.state || {};
  const [language, setLanguage] = useState(lang);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (language == "english") {
      setEvents([
        "Personal info",
        "Vedic info",
        "General info",
        "Student affidavit",
        "Guruji",
        "Guruji affidavit",
        "Annexure",
        "Preview",
      ]);
    } else if (language == "hindi") {
      setEvents([
        "व्यक्तिगत जानकारी",
        "वैदिक जानकारी",
        "सामान्य जानकारी",
        "आवेदक शपथपत्र",
        "गुरुजी",
        "गुरुजी शपथपत्र",
        "संलग्न",
        "पूर्वावलोकन",
      ]);
    } else {
      setEvents([
        "व्यक्तिगतसूचना",
        "वैदिक सूचना",
        "सामान्य सूचना",
        "आवेदकेन शपथपत्रम्",
        "गुरुजी",
        "गुरुणा शपथपत्रम्",
        "संलग्न",
        "पूर्वावलोकनं",
      ]);
    }
  }, [language]);

  const eventsCount = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
  const [formData, setFormData] = useState({});
  const [currStep, setCurrStep] = useState(1);
  const [id, setId] = useState("");

  const [current, setCurrent] = useState(1);
  const handleSaveandNext = (e) => {
    e.preventDefault();
    setCurrStep((prevStep) => (prevStep < 8 ? prevStep + 1 : prevStep));
    handleInputSave();
  };

  const handleBack = (e) => {
    e.preventDefault();
    setCurrStep((prevStep) => (prevStep !== 1 ? prevStep - 1 : prevStep));
    handleInputSave();
  };

  /* 
  New code by Lakhan
  */

  /* Formik Start */
  const formik = useFormik({
    initialValues: {
      // Personal Information Fields
      ...Page1FormField,

      // Vedic Information Fields
      ...Page2FormField,

      // General Information Fields
      ...Page3FormField,

      // Student Affidavit Fields
      ...Page4FormField,

      // Guruji  Fields
      ...Page5FormField,

      // Guruji Affidavit Fields
      ...Page6FormField,

      // Annexure Fields
      ...Page7FormField,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      // Handle form submission
      let userData = localStorage.getItem("userData");
      userData = userData ? JSON.parse(userData) : {};
      if (userData?.userId) {
        try {
          const formData = {
            id: id ? id : "",
            user_id: apply_type == "yourself" ? userData.userId : "",
            filled_by_id: apply_type == "other" ? userData.userId : "",
            user_option: apply_type,
            type: user_type,
            current_page: currStep,
            answerdata: formik.values,
            language: language,
            status: "Pending",
            remarks: "",
            progress: pageStatus,
          };
          const response = await axiosPrivate.post(
            "user-answer-data",
            formData
          );
          if (response.status == 200) {
            showSuccessToast("Your form is submitted successfully");
            navigate("/home");
          } else {
            showErrorToast(response.data.message);
          }
        } catch (error) {
          showErrorToast(error?.response?.data?.errors[0]?.msg);
        }
      }
    },
  });
  /* Formik End */

  /* Add Row for brother sister start */
  const addBrotherSisterRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.pi_brothers_sisters,
      {
        name: "",
        gender: "",
        age: "",
        study_level: "",
        studying_the_vedas: "",
      },
    ];
    formik.setFieldValue("pi_brothers_sisters", newRowContent);
    return true;
  };
  /* Add Row for brother sister end */

  /* Remove Row for brother sister start */
  const removeBrotherSisterRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.pi_brothers_sisters.length > 1) {
      let newRowContent = formik.values.pi_brothers_sisters.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("pi_brothers_sisters", newRowContent);
    }

    return true;
  };
  /* Remove Row for brother sister end */

  /* Add Row for other venture details start */
  const addOtherVentureDetails = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.gi_other_venture_details,
      {
        undertaking_name: "",
        organiser: "",
        location: "",
        year: "",
      },
    ];
    formik.setFieldValue("gi_other_venture_details", newRowContent);
    return true;
  };
  /* Add Row for other venture details end */

  /* Remove Row for other venture details start */
  const removeOtherVentureDetails = (index, eve) => {
    eve.preventDefault();
    if (formik.values.gi_other_venture_details.length > 1) {
      let newRowContent = formik.values.gi_other_venture_details.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("gi_other_venture_details", newRowContent);
    }

    return true;
  };
  /* Remove Row for other venture details end */

  /* Add Row for other venture details start */
  const addAnnexureDetails = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.a_annexure_details,
      {
        year: "",
        certificate_details: "",
        uploaded_doc: "",
        uploaded_doc_string: "",
      },
    ];
    formik.setFieldValue("a_annexure_details", newRowContent);
    return true;
  };
  /* Add Row for other venture details end */

  /* Remove Row for other venture details start */
  const removeAnnexureDetails = (index, eve) => {
    eve.preventDefault();
    if (formik.values.a_annexure_details.length > 1) {
      let newRowContent = formik.values.a_annexure_details.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("a_annexure_details", newRowContent);
    }

    return true;
  };
  /* Remove Row for other venture details end */

  /* Add Row for vedadhyapaka vedvidyalaya details start */
  const addVedadhyapakaVedvidyalayaDetails = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vedadhyapaka_vedvidyalaya_details,
      {
        from: "",
        till: "",
        vedadhyapak_name: "",
        vedadhyapak_mobile: "",
        vedvidyalaya_name: "",
      },
    ];
    formik.setFieldValue("vedadhyapaka_vedvidyalaya_details", newRowContent);
    return true;
  };
  /* Add Row for vedadhyapaka vedvidyalaya details end */

  /* Remove Row for vedadhyapaka vedvidyalaya details start */
  const removeVedadhyapakaVedvidyalayaDetails = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vedadhyapaka_vedvidyalaya_details.length > 1) {
      let newRowContent =
        formik.values.vedadhyapaka_vedvidyalaya_details.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue("vedadhyapaka_vedvidyalaya_details", newRowContent);
    }
    return true;
  };
  /* Remove Row for vedadhyapaka vedvidyalaya details end */

  /* Add Row for relative details details start */
  const addRelativeDetails = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.relative_details,
      {
        serial_no: "",
        relation: "",
      },
    ];
    formik.setFieldValue("relative_details", newRowContent);
    return true;
  };
  /* Add Row for relative details details end */

  /* Remove Row for relative details details start */
  const removeRelativeDetails = (index, eve) => {
    eve.preventDefault();
    if (formik.values.relative_details.length > 1) {
      let newRowContent = formik.values.relative_details.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("relative_details", newRowContent);
    }
    return true;
  };
  /* Remove Row for relative details details end */

  /* Add Row for grantha details details start */
  const addGranthaDetails = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.grantha_details,
      {
        grantha_name: "",
        start_study: "",
        end_study: "",
        exam_month_year: "",
        certificate: "",
        certificate_string: "",
      },
    ];
    formik.setFieldValue("grantha_details", newRowContent);
    return true;
  };
  /* Add Row for grantha details details end */

  /* Remove Row for grantha details details start */
  const removeGranthaDetails = (index, eve) => {
    eve.preventDefault();
    if (formik.values.grantha_details.length > 1) {
      let newRowContent = formik.values.grantha_details.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("grantha_details", newRowContent);
    }
    return true;
  };
  /* Remove Row for grantha details details end */

  /* Add Row for award details details start */
  const addAwardDetails = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.award_details,
      {
        award_month_year: "",
        award_name: "",
        awarding_organisation: "",
        certificate: "",
        certificate_string: "",
      },
    ];
    formik.setFieldValue("award_details", newRowContent);
    return true;
  };
  /* Add Row for award details details end */

  /* Remove Row for award details details start */
  const removeAwardDetails = (index, eve) => {
    eve.preventDefault();
    if (formik.values.award_details.length > 1) {
      let newRowContent = formik.values.award_details.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("award_details", newRowContent);
    }
    return true;
  };
  /* Remove Row for award details details end */

  const addvi_ved_and_passed_of_ved_examinations = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vi_ved_and_passed_of_ved_examinations,
      {
        vi_month_and_year: "",
        vi_passed_exam: "",
        vi_exam_board: "",
        vi_exam_division: "",
        vi_exam_certificate: "",
        vi_exam_certificate_string: "",
      },
    ];
    formik.setFieldValue(
      "vi_ved_and_passed_of_ved_examinations",
      newRowContent
    );
    return true;
  };

  const removevi_ved_and_passed_of_ved_examinations = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vi_ved_and_passed_of_ved_examinations.length > 1) {
      let newRowContent =
        formik.values.vi_ved_and_passed_of_ved_examinations.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vi_ved_and_passed_of_ved_examinations",
        newRowContent
      );
    }
    return true;
  };

  const handleInputChange = async (label, event) => {
    formik.handleChange(event);
  };

  const handleInputSave = async () => {
    let userData = localStorage.getItem("userData");
    userData = userData ? JSON.parse(userData) : {};
    if (userData?.userId) {
      try {
        const formData = {
          id: id ? id : "",
          user_id: apply_type == "yourself" ? userData.userId : "",
          filled_by_id: apply_type == "other" ? userData.userId : "",
          user_option: apply_type,
          type: user_type,
          current_page: currStep,
          answerdata: formik.values,
          language: language,
          status: "In Progress",
          remarks: "",
          progress: pageStatus,
        };
        const response = await axiosPrivate.post("user-answer-data", formData);
        if (response.status === 200 && response.data.data) {
          if (id == "") {
            setId(response.data.data);
          }
        } else {
          showErrorToast(response.data.message);
        }
      } catch (error) {
        showErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    }
  };

  /* File Upload Code start */
  const fileUploadFunction = async (field_name, file) => {
    setLoaderCheck(true);
    const fileUploadResponse = await uploadFile(file);
    if (fileUploadResponse.code === 200) {
      formik.setFieldValue(`${field_name}`, fileUploadResponse.data);
      setLoaderCheck(false);
    } else {
      showErrorToast(fileUploadResponse.data.message);
      setLoaderCheck(false);
    }
    return true;
  };
  /* File Upload Code end */

  /* 
  New code by Lakhan
  */

  //get loaction by pincode
  const getLoaction = async () => {
    setLoaderCheck(true);
    try {
      const locationData = await axiosPrivate(
        `locations/${formik.values?.pi_full_correspondence_address_pincode}`
      );
      const location = await locationData?.data;
      formik.setFieldValue(
        "pi_full_correspondence_address_state",
        location?.location?.data[0]?.state
      );
      formik.setFieldValue(
        "pi_full_correspondence_address_district",
        location?.location?.data[0]?.city
      );
      setLoaderCheck(false);
    } catch (e) {
      console.log(e);
      setLoaderCheck(false);
    }
    setLoaderCheck(false);
  };
  useEffect(() => {
    let timer;
    if (formik.values?.pi_full_correspondence_address_pincode) {
      timer = setTimeout(() => {
        getLoaction();
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [formik.values?.pi_full_correspondence_address_pincode]);

  const getPermanentLoaction = async () => {
    try {
      setLoaderCheck(true);

      const locationData = await axiosPrivate(
        `locations/${formik.values?.pi_permanent_address_pincode}`
      );
      const location = await locationData?.data;
      formik.setFieldValue(
        "pi_permanent_address_state",
        location?.location?.data[0]?.state
      );
      formik.setFieldValue(
        "pi_permanent_address_district",
        location?.location?.data[0]?.city
      );
      setLoaderCheck(false);
    } catch (e) {
      console.log(e);
      setLoaderCheck(false);
    }
    setLoaderCheck(false);
  };
  useEffect(() => {
    let timer;
    if (formik.values?.pi_permanent_address_pincode) {
      timer = setTimeout(() => {
        getPermanentLoaction();
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [formik.values?.pi_permanent_address_pincode]);

  // useEffect(() => {
  //   setLoaderCheck(true);
  //   console.log("oldData", oldData);
  //    if (oldData) {
  //      formik.setValues(oldData.answerdata);
  //      setLanguage(oldData.language);
  //      setId(oldData.id);
  //      setCurrStep(oldData.current_page);
  //      setLoaderCheck(false);
  //    }
  //   setLoaderCheck(false);
  // }, [oldData]);

  useEffect(() => {
    async function checkOldPage() {
      const responseAnswers = await axiosPrivate.get(`user-answer-data`);
      if (
        responseAnswers.status == 200 &&
        responseAnswers?.data?.data &&
        responseAnswers?.data?.data.length > 0 &&
        responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
          .status == "In Progress" &&
        responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
          .type == "Vedavidyarthi"
      ) {
        setLoaderCheck(false);
        setLanguage(
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .language
        );
        user_type =
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .type;
        apply_type =
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .user_option;
        formik.setValues(
          JSON.parse(
            responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
              .answerdata
          )
        );
        setId(
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1].id
        );
        setCurrStep(
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .current_page
        );
        let progress = JSON.parse(
          responseAnswers?.data?.data[responseAnswers?.data?.data?.length - 1]
            ?.progress
        );
        if (progress != null) {
          setPageStatus(progress);
        }
      }
      setLoaderCheck(false);
    }
    if (localStorage.getItem("accessToken")) {
      checkOldPage();
    }
  }, []);

  const handleStepClick = (step) => {
    setCurrStep(step);
  };

  const isPageComplete = (page_no, totalCount, actualCount) => {
    if (totalCount === actualCount) {
      pageStatus[page_no] = "complete";
    } else {
      pageStatus[page_no] = "pending";
    }
  };

  // css code for progress bar

  useEffect(() => {
    let currentElement = document.querySelector(".current");
    let nextElement = currentElement?.nextElementSibling;

    if (nextElement?.classList.contains("pending")) {
      currentElement?.classList.add("recheck");
    }
    if (nextElement?.classList.contains("complete")) {
      currentElement?.classList.add("recheck_complete");
    }
  }, [currStep]);

  const renderCurrentPage = () => {
    switch (currStep) {
      case 1:
        return (
          <Page1_General_information
            formData={formData}
            handleInputChange={handleInputChange}
            formik={formik}
            addBrotherSisterRow={addBrotherSisterRow}
            removeBrotherSisterRow={removeBrotherSisterRow}
            handleInputSave={handleInputSave}
            lang={language}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );
      case 2:
        return (
          <Page2_information_of_vedic_education
            formData={formData}
            formik={formik}
            addVedadhyapakaVedvidyalayaDetails={
              addVedadhyapakaVedvidyalayaDetails
            }
            removeVedadhyapakaVedvidyalayaDetails={
              removeVedadhyapakaVedvidyalayaDetails
            }
            addRelativeDetails={addRelativeDetails}
            removeRelativeDetails={removeRelativeDetails}
            addGranthaDetails={addGranthaDetails}
            removeGranthaDetails={removeGranthaDetails}
            addAwardDetails={addAwardDetails}
            removeAwardDetails={removeAwardDetails}
            handleInputChange={handleInputChange}
            lang={language}
            fileUploadFunction={fileUploadFunction}
            handleInputSave={handleInputSave}
            addvi_ved_and_passed_of_ved_examinations={
              addvi_ved_and_passed_of_ved_examinations
            }
            removevi_ved_and_passed_of_ved_examinations={
              removevi_ved_and_passed_of_ved_examinations
            }
            isPageComplete={isPageComplete}
          />
        );
      case 3:
        return (
          <Page3_General_ingormation
            formData={formData}
            formik={formik}
            addOtherVentureDetails={addOtherVentureDetails}
            removeOtherVentureDetails={removeOtherVentureDetails}
            handleInputChange={handleInputChange}
            lang={language}
            fileUploadFunction={fileUploadFunction}
            handleInputSave={handleInputSave}
            isPageComplete={isPageComplete}
          />
        );
      case 4:
        return (
          <Page4_affidavit_given_by_the_applicant
            formData={formData}
            formik={formik}
            handleInputChange={handleInputChange}
            lang={language}
            fileUploadFunction={fileUploadFunction}
            handleInputSave={handleInputSave}
            isPageComplete={isPageComplete}
          />
        );
      case 5:
        return (
          <Page5_information_given_by_your_present_guruji
            formData={formData}
            formik={formik}
            handleInputChange={handleInputChange}
            lang={language}
            fileUploadFunction={fileUploadFunction}
            handleInputSave={handleInputSave}
            isPageComplete={isPageComplete}
          />
        );
      case 6:
        return (
          <Page6_affidavit_given_by_guruji_of_the_applicant
            formData={formData}
            formik={formik}
            handleInputChange={handleInputChange}
            lang={language}
            fileUploadFunction={fileUploadFunction}
            handleInputSave={handleInputSave}
            isPageComplete={isPageComplete}
          />
        );
      case 7:
        return (
          <Page7_annexure_attached_to_the_application
            formData={formData}
            formik={formik}
            addAnnexureDetails={addAnnexureDetails}
            removeAnnexureDetails={removeAnnexureDetails}
            handleInputChange={handleInputChange}
            lang={language}
            fileUploadFunction={fileUploadFunction}
            handleInputSave={handleInputSave}
            isPageComplete={isPageComplete}
          />
        );
      case 8:
        return (
          <Page8_preview_your_application
            formData={formData}
            formik={formik}
            handleInputChange={handleInputChange}
            lang={language}
            id={id}
            fileUploadFunction={fileUploadFunction}
            handleInputSave={handleInputSave}
            isPageComplete={isPageComplete}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {loaderCheck ? (
        <Loader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="">
            <div className="flex justify-between items-center mb-5 stepsBar">
              {Array.from({ length: 8 }, (_, index) => (
                <div
                  key={index}
                  className={`step ${
                    index === currStep - 1 ? "current" : pageStatus[index + 1]
                  }`}
                  onClick={() => {
                    setCurrStep(index + 1);
                    handleInputSave();
                  }}
                >
                  <div className={`step-icon`}>
                    {index + 1 === currStep && (
                      <div className="step-circle"></div>
                    )}
                    {pageStatus[index + 1] == "complete" &&
                      index + 1 !== currStep && (
                        <FaCheck style={{ color: "white" }} />
                      )}
                    {pageStatus[index + 1] == "pending" &&
                      index + 1 !== currStep && (
                        <FaExclamation style={{ color: "white" }} />
                      )}
                    {pageStatus[index + 1] == "" && index + 1 !== currStep && (
                      <div className="step-circle"></div>
                    )}
                  </div>
                  <span className="text-xs mt-2 d-none d-lg-block">
                    {events[index]}
                  </span>
                  <span className="text-xs mt-2 d-lg-none">
                    {eventsCount[index]}
                  </span>
                </div>
              ))}
            </div>
            {renderCurrentPage()}

            <div className="">
              <div>
                {currStep !== 1 && (
                  <button
                    onClick={(e) => handleBack(e)}
                    type="button"
                    className="btn btn-outline-danger"
                  >
                    {language == "english"
                      ? "Back"
                      : language == "hindi"
                      ? "पिछला "
                      : "पूर्वतनम्‌"}
                  </button>
                )}
                {currStep < 8 ? (
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={(e) => handleSaveandNext(e)}
                  >
                    {language == "english"
                      ? "Save & next"
                      : language == "hindi"
                      ? "सहेजें और आगे बढ़ें "
                      : "आरक्षतु अग्रे गच्छतु च"}
                  </button>
                ) : (
                  <button type="submit" className="btn btn-danger">
                    {language == "english"
                      ? "Submit application"
                      : language == "hindi"
                      ? "आवेदनपत्र जमा करे"
                      : "आवेदनपत्रं समर्पयतु"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default QuestionPage;
