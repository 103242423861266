import React, { useEffect, useState } from "react";

function Section_5({
  formik,
  lang,
  handleInputSave,
  handleInputChange,
  isPageComplete,
  fileUploadFunction,
}) {
  const [totalCount, setTotalCount] = useState(2);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik.values.af_name, count);
    count = handleInputValue(formik.values.af_years, count);
    setActualCount(count);
    // setTotalCount(total_count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(5, totalCount, actualCount);
  }, [actualCount, totalCount]);
  return (
    <div>
      <h3>
        {lang == "english"
          ? "Affidavit "
          : lang == "hindi"
          ? "शपथपत्र "
          : "शपथपत्रम् "}
      </h3>
      <p className="text-primary mb-30">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
          ? "सभी प्रश्‍न अनिवार्य हैं।"
          : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>
      <div>
        <label>
          {lang == "english"
            ? "I (Full name of the Head of the Institution)"
            : lang == "hindi"
            ? "मैं (संस्था प्रमुख का पूरा नाम)"
            : "अहं (संस्थाध्यक्षस्य पूर्णनाम) "}
        </label>
        <input
          type="text"
          className="form-control col-6"
          id="af_name"
          name="af_name"
          value={formik?.values?.af_name}
          onChange={(e) => handleInputChange(`af_name`, e)}
          onBlur={(e) => handleInputSave()}
        />
        {formik.touched && formik.errors?.af_name ? (
          <div className="text-danger">{formik.errors?.af_name}</div>
        ) : null}
      </div>
      <p className="mb-30">
        <br />
        {lang == "english"
          ? "do hereby solemnly certify that:"
          : lang == "hindi"
          ? "एतद् द्वारा सत्यनिष्ठा से यह प्रमाणित करता हूँ कि:"
          : "एतत् सत्यनिष्ठया प्रमाणयामि यत्"}
        <br />
        <br />
        {lang == "english"
          ? "Veda teaching is going on continuously for the last"
          : lang == "hindi"
          ? "- इस वेद विद्यालय में वेदाध्यापन पिछले"
          : "- अस्मिन् वेदविद्यालये वेदाध्यापनं विगत"}
        <input
          className="inline-block form-control col-2"
          style={{
            width: "50px",
            height: "30px",
            margin: "0 10px",
          }}
          type="number"
          id="af_years"
          name="af_years"
          value={formik?.values?.af_years}
          onChange={(e) => {
            if (e.target.value <= 2025) {
              handleInputChange(`af_years`, e);
            }
          }}
          onBlur={(e) => handleInputSave()}
        />
        {formik.touched && formik.errors?.af_years ? (
          <div className="text-danger">{formik.errors?.af_years}</div>
        ) : null}
        {lang == "english"
          ? "years in this vedavidyalaya"
          : lang == "hindi"
          ? "वर्षों से निरन्तर चल रहा है"
          : "वर्षेभ्यः निरन्तरं प्रचलन् अस्ति"}
        <br />
        <br />
        {lang == "english"
          ? "All the information given in this application form is completely true "
          : lang == "hindi"
          ? "- इस आवेदन पत्र में दी गई सभी जानकारी पूर्णतया सत्य है; तथा"
          : "- अस्मिन् आवेदनपत्रे दत्ताः सर्वाः सूचनाः पूर्णतया सत्याः सन्ति; "}
        <br />
        <br />
        {lang == "english"
          ? "- and the information given in all the annexures and certificates attached to this application is true."
          : lang == "hindi"
          ? "- इस आवेदन से संलग्न सभी अनुबन्धों व प्रमाण-पत्रों में दी गई जानकारी सत्य है।"
          : "-अस्मिन् आवेदनपत्रे संलग्नेषु सर्वेषु अनुबन्धेषु प्रमाणपत्रेषु च दत्ताः सूचनाः सत्याः सन्ति। "}
        <br />
        <br />
        {lang == "english"
          ? "I accept that if any information given in this affidavit is found to be false, then this school, all the teachers working in it and all the students studying in it can be considered ineligible for Bharatatma Puraskar forever."
          : lang == "hindi"
          ? "मैं स्वीकार करता हूँ कि यदि इस शपथ-पत्र में F दी गई कोई भी जानकारी असत्य पाई गई तो यह विद्यालय, इसमें कार्यरत सभी अध्यापक व अध्ययन कर रहे सभी विद्यार्थी हमेशा के लिये भारतात्मा पुरस्कार के लिये अयोग्य माने जा सकते हैं। मैंने सभी तथ्यों की पुष्टि करने के बाद इस प्रमाण-पत्र पर हस्ताक्षर किये हैं।"
          : "अहं स्वीकरोमि यत् यदि एतत् शपथपत्रम् असत्यं ज्ञायते तर्हि एषः विद्यालयः अस्मिन् विद्यालये अध्यापनरताः सर्वे अध्यापकाः सर्वे छात्राः च भारतात्मापुरस्कारस्य कृते सदा अयोग्याः भवेयुः। मया सर्वाणि तथ्यानि स्वप्रमाणीकृत्य अस्मिन् शपथपत्रे हस्ताक्षरं कृतम्। "}

        <br />
        <br />
        {lang == "english"
          ? "I have signed this certificate after verifying all the facts.I fully understand the importance and implications of signing this affidavit."
          : lang == "hindi"
          ? "मैं इस शपथपत्र पर हस्ताक्षर करने के महत्व व निहितार्थ को पूरी तरह से समझता हूँ।"
          : "अस्मिन् शपथपत्रे विहितस्य हस्ताक्षरस्य महत्त्वं निहितार्थं च अहं पूर्णतया अवगच्छामि।"}
      </p>
      <div className="mb-10">
        <p className="d-flex mt-30 mb-10">
          {lang == "english"
            ? "Full name of the head of the institution"
            : lang == "hindi"
            ? "संस्था प्रमुख का पूरा नाम "
            : "संस्थाप्रमुखस्य पूर्णं नाम  "}
          <p> &nbsp; ____________________________</p>

          {/* <input
            type="text"
            className="form-control col-4 ml-2"
            id="af_full_name"
            name="af_full_name"
            value={formik?.values?.af_full_name}
            onChange={(e) => handleInputChange(`af_full_name`, e)}
            onBlur={(e) => handleInputSave()}
          />
          {formik.touched && formik.errors.af_full_name ? (
            <div className="text-danger">{formik.errors.af_full_name}</div>
          ) : null} */}
        </p>
        <p className="d-flex mt-30 mb-10">
          {lang == "english"
            ? "Signature of the head of the institution"
            : lang == "hindi"
            ? "संस्थाप्रमुख के हस्ताक्षर"
            : "संस्थाप्रमुखस्य हस्ताक्षरम् "}
          <p> &nbsp; ____________________________</p>
          {/* <input
            type="file"
            className="form-control col-4 ml-2"
            id="af_signature"
            name="af_signature"
            value={formik?.values?.af_signature}
            onChange={(e) => handleInputChange(`af_signature`, e)}
            onBlur={(e) => handleInputSave()}
          />
          {formik.touched && formik.errors.af_signature ? (
            <div className="text-danger">{formik.errors.af_signature}</div>
          ) : null} */}
        </p>
        <p className="d-flex mt-30 mb-10">
          {lang == "english"
            ? "Location"
            : lang == "hindi"
            ? "स्थान"
            : "स्थानम्  "}
          <p> &nbsp; ____________________________</p>

          {/* <input
            type="text"
            className="form-control col-4 ml-2"
            id="af_location"
            name="af_location"
            value={formik?.values?.af_location}
            onChange={(e) => handleInputChange(`af_location`, e)}
            onBlur={(e) => handleInputSave()}
          />
          {formik.touched && formik.errors.af_location ? (
            <div className="text-danger">{formik.errors.af_location}</div>
          ) : null} */}
        </p>
        <p className="d-flex mt-30 mb-10">
          {lang == "english"
            ? "Date     "
            : lang == "hindi"
            ? "दिनांक  "
            : "दिनाङ्कः"}
          <p> &nbsp; ____________________________</p>
          {/* <input
            type="date"
            className="form-control col-4 ml-2"
            id="af_date"
            name="af_date"
            value={formik?.values?.af_date}
            onChange={(e) => handleInputChange(`af_date`, e)}
            onBlur={(e) => handleInputSave()}
          />
          {formik.touched && formik.errors.af_date ? (
            <div className="text-danger">{formik.errors.af_date}</div>
          ) : null} */}
        </p>
      </div>
      <div className="d-flex mb-30">
        <div className="noPrint">
          <label className="d-block">Download Affidavit</label>
          <button
            className={`btn btn-outline-danger`}
            onClick={(e) => {
              e.preventDefault();
              window.print();
            }}
          >
            Download
          </button>
        </div>
        <div className="col-lg-4 col-md-6 noPrint">
          <label>Upload affidavit</label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
            className={`form-control`}
            id="af_affidavit"
            name="af_affidavit"
            onChange={async (e) => {
              e.preventDefault();
              if (e.target.files.length > 0) {
                await fileUploadFunction(
                  "af_affidavit_string",
                  e.target.files[0]
                );
              }
            }}
            onBlur={(e) => {
              handleInputSave(e);
            }}
          />
          {formik.values?.af_affidavit_string && (
            <button
              className="uploadLink"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values.af_affidavit_string}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              {`${formik.values.af_affidavit_string.replace(
                "upload-file/",
                ""
              )}`}
            </button>
          )}
          {formik.touched && formik.errors?.af_affidavit_string ? (
            <div className="text-danger">
              {formik.errors?.af_affidavit_string}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Section_5;
