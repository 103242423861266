import React, { useEffect, useState } from "react";
import "./style.css";

function Page6_affidavit_given_by_guruji_of_the_applicant({
  formData,
  formik,
  handleInputChange,
  handleInputSave,
  lang,
  fileUploadFunction,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(3);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.ga_teacher_full_name, count);
    count = handleInputValue(formik?.values?.ga_applicant_name, count);
    count = handleInputValue(formik?.values?.ga_applicant_file_string, count);
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(6, totalCount, actualCount);
  }, [actualCount, totalCount]);
  return (
    <div>
      <h3 className="mb-30">
        {lang == "english"
          ? "Affidavit given by Guruji of the applicant"
          : lang == "hindi"
          ? "आवेदक के गुरुजी द्वारा दिया गया शपथपत्र"
          : "आवेदकस्य गुरुणा दत्तं शपथपत्रम्"}
      </h3>
      <div className="d-flex justify-content-start mb-30 align-items-center">
        <p>
          {lang == "english"
            ? "I (full name of the applicant vedadhyapak)"
            : lang == "hindi"
            ? "मैं (आवेदक के वेदाध्यापक का पूरा नाम)"
            : "अहं (आवेदकस्य वेदाध्यापकस्य  नाम)"}
        </p>

        <input
          type="text"
          name="ga_teacher_full_name"
          id="ga_teacher_full_name"
          className="form-control"
          style={{ width: "20%", marginLeft: "10px" }}
          value={formik.values.ga_teacher_full_name}
          onChange={(e) => handleInputChange("ga_teacher_full_name", e)}
          onBlur={(e) => handleInputSave()}
        />
        {formik.touched && formik.errors.ga_teacher_full_name ? (
          <div className="text-danger">
            {formik.errors.ga_teacher_full_name}
          </div>
        ) : null}
      </div>
      <div className="d-flex justify-content-start mb-30 align-items-center">
        <p>
          {lang == "english"
            ? "I do hereby certify that: (Name of the applicant) "
            : lang == "hindi"
            ? "एतद् द्वारा सत्यनिष्ठा से यह प्रमाणित करता हूँ कि: (आवेदक का नाम)"
            : "एतत् सत्यनिष्ठया प्रमाणयामि यत् : (आवेदकस्य नाम)"}
        </p>
        <input
          type="text"
          name="ga_applicant_name"
          id="ga_applicant_name"
          className="form-control"
          style={{ width: "20%", marginLeft: "10px" }}
          onChange={(e) => handleInputChange("ga_applicant_name", e)}
          onBlur={(e) => handleInputSave()}
          value={formik.values.ga_applicant_name}
        />
        {formik.touched && formik.errors.ga_applicant_name ? (
          <div className="text-danger">{formik.errors.ga_applicant_name}</div>
        ) : null}
      </div>
      <div className="mb-30">
        <p>
          {lang == "english"
            ? "is currently my full time student studying with me;"
            : lang == "hindi"
            ? "वर्तमान में मेरा पूर्णकालिक वेदविद्यार्थी है;"
            : "अधुना मम पूर्णकालिकः वेदविद्यार्थी अस्ति;"}
        </p>
        <ul style={{ listStyle: "square", marginLeft: "30px" }}>
          <li>
            {lang == "english"
              ? "All the information given in this application form is completely true;"
              : lang == "hindi"
              ? "इस आवेदन पत्र में दी गई सभी जानकारियाँ पूर्णतया सत्य हैं:"
              : "अस्मिन् आवेदनपत्रे दत्ताः सर्वाः सूचनाः सर्वथा सत्याः सन्ति;"}
          </li>
          <li>
            {lang == "english"
              ? "The information given in all the annexures and certificates attached to this application is true."
              : lang == "hindi"
              ? "इस आवेदन से संलग्न सभी अनुबन्धों व प्रमाणपत्रों में दी गई जानकारियाँ सत्य है।"
              : "अस्मिन् आवेदनपत्रे संलग्नेषु सर्वेषु अनुबन्धेषु प्रमाणपत्रेषु च दत्ताः सूचनाः सत्याः सन्ति।"}
          </li>
          <li>
            {lang == "english"
              ? "I accept that if this affidavit is found to be untrue, then I and all my students may be considered ineligible for Bharatatma Puraskar forever."
              : lang == "hindi"
              ? "मैं स्वीकार करता हूँ कि यदि यह शपथपत्र असत्य पाया गया तो मैं व मेरे सभी वेदविद्यार्थी हमेशा के लिये भारतात्मा पुरस्कार के लिये अयोग्य माने जा सकते हैं।"
              : "अहं स्वीकरोमि यत् यदि एतत् शपथपत्रं मिथ्या इति ज्ञायते तर्हि अहं मम च सर्वे शिष्याः सदा भारतात्मापुरस्कारस्य कृते अयोग्याः इति मन्तव्याः सन्ति। "}
          </li>
        </ul>
        <br />
        <p>
          {lang == "english"
            ? "Have put my signature on this affidavit after verifying all the facts . I fully understand the importance and implications of signing this affidavit. "
            : lang == "hindi"
            ? "मैंने सभी तथ्यों की पुष्टि करने के बाद इस शपथपत्र पर अपने हस्ताक्षर किये हैं। मैं इस शपथपत्र पर हस्ताक्षर करने के महत्त्व व निहितार्थ को पूरी तरह से समझता हूँ।"
            : "अहं सर्वाणि तथ्यानि स्वप्रमाणीकृत्य अस्मिन् शपथपत्रे मम हस्ताक्षरं कृतवान्। अस्मिन् शपथपत्रे हस्ताक्षरस्य महत्त्वं निहितार्थं च अहं पूर्णतया अवगच्छामि। "}
        </p>
      </div>
      <div className="mb-30 ">
        <p className="d-flex">
          {lang == "english"
            ? "Full name of the vedadhyapak"
            : lang == "hindi"
            ? "वेदाध्यापक का पूरा नाम"
            : " गुरोः पूर्णनाम"}
          {/* <input
            type="text"
            name="ga_applicant_full_name"
            id="ga_applicant_full_name"
            className="form-control"
            style={{ width: "20%", marginLeft: "10px" }}
            onChange={(e) => handleInputChange("ga_applicant_full_name", e)}
          />
          {formik.touched && formik.errors.ga_applicant_full_name ? (
            <div className="text-danger">{formik.errors.ga_applicant_full_name}</div>
          ) : null} */}
          {formik.values.ga_teacher_full_name ? (
            <p> &nbsp; - {formik.values.ga_teacher_full_name}</p>
          ) : (
            <p> &nbsp; ____________________________</p>
          )}
        </p>
        <p className="d-flex mt-30">
          {lang == "english"
            ? "Signature of vedadhyapak"
            : lang == "hindi"
            ? "वेदाध्यापक के हस्ताक्षर"
            : "गुरोः हस्ताक्षरम्"}
          {/* <input
            type="text"
            name="ga_applicant_signature"
            id="ga_applicant_signature"
            className="form-control"
            style={{ width: "20%", marginLeft: "10px" }}
            onChange={(e) => handleInputChange("ga_applicant_signature", e)}
          />
          {formik.touched && formik.errors.ga_applicant_signature ? (
            <div className="text-danger">{formik.errors.ga_applicant_signature}</div>
          ) : null} */}
          <p> &nbsp; ____________________________</p>
        </p>
        <p className="d-flex mt-30 mb-10">
          {lang == "english"
            ? "Location"
            : lang == "hindi"
            ? "स्थान"
            : "स्थानम्"}
          {/* <input
            type="text"
            name="ga_applicant_location"
            id="ga_applicant_location"
            className="form-control"
            style={{ width: "20%", marginLeft: "10px" }}
            onChange={(e) => handleInputChange("ga_applicant_location", e)}
          />
          {formik.touched && formik.errors.ga_applicant_location ? (
            <div className="text-danger">{formik.errors.ga_applicant_location}</div>
          ) : null} */}
          <p> &nbsp; ____________________________</p>
        </p>
        <p className="d-flex mt-30 mb-10">
          {lang == "english" ? "Date" : lang == "hindi" ? "दिनांक" : "दिनाङ्कः"}
          <p> &nbsp; ____________________________</p>
          {/* <input
            type="date"
            name="ga_applicant_date"
            id="ga_applicant_date"
            className="form-control"
            style={{ width: "20%", marginLeft: "10px" }}
            onChange={(e) => handleInputChange("ga_applicant_date", e)}
          />
          {formik.touched && formik.errors.ga_applicant_date ? (
            <div className="text-danger">{formik.errors.ga_applicant_date}</div>
          ) : null} */}
        </p>
      </div>
      <div className="d-flex mb-30 noPrint">
        <div>
          <label className="d-block">Download Affidavit</label>
          <button
            className={`btn btn-outline-danger noPrint`}
            onClick={(e) => {
              e.preventDefault();
              window.print();
            }}
          >
            Download
          </button>
        </div>
        <div className="col-lg-4 col-md-6">
          <label>Upload affidavit</label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
            className={`form-control noPrint`}
            id="ga_applicant_file"
            name="ga_applicant_file"
            onChange={async (e) => {
              e.preventDefault();
              if (e.target.files.length > 0) {
                await fileUploadFunction(
                  "ga_applicant_file_string",
                  e.target.files[0]
                );
              }
            }}
            onBlur={(e) => {
              handleInputSave(e);
            }}
          />
          {formik.values?.ga_applicant_file_string && (
            <button
              className="uploadLink"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values.ga_applicant_file_string}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              {`${formik.values.ga_applicant_file_string.replace(
                "upload-file/",
                ""
              )}`}
            </button>
          )}
          {formik.touched && formik.errors.ga_applicant_file_string ? (
            <div className="text-danger">
              {formik.errors.ga_applicant_file_string}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Page6_affidavit_given_by_guruji_of_the_applicant;
