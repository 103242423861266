import React, { useEffect, useState } from "react";

function Section_3({
  formik,
  lang,
  handleInputSave,
  handleInputChange,
  addVidyarthiRow,
  removeVidyarthiRow,
  addStudentRow,
  removeStudentRow,
  addFiveRow,
  removeFiveRow,
  addVedvidyalayaRow,
  removeVedvidyalayaRow,
  addLast5Row,
  removeLast5Row,
  addVedadhyapakasRow,
  removeVedadhyapakasRow,
  addVedvidyalayRow,
  removeVedvidyalayRow,
  isPageComplete,
  fileUploadFunction,
}) {
  const [totalCount, setTotalCount] = useState(15);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(
      formik.values.vt_information_of_passed_student[0].level,
      count
    );
    count = handleInputValue(
      formik.values.vt_information_of_passed_student[0].rigveda,
      count
    );
    count = handleInputValue(
      formik.values.vt_information_of_passed_student[0].shuklayajurve,
      count
    );
    count = handleInputValue(
      formik.values.vt_information_of_passed_student[0].krishnayajurve,
      count
    );
    count = handleInputValue(
      formik.values.vt_information_of_passed_student[0].samveda,
      count
    );
    count = handleInputValue(
      formik.values.vt_information_of_passed_student[0].atharvaveda,
      count
    );
    count = handleInputValue(
      formik.values.vt_list_of_student_passed_the_kramanta_ghananta,
      count
    );
    count = handleInputValue(
      formik.values.vt_list_of_awards_recived_by_students,
      count
    );
    count = handleInputValue(
      formik.values.vt_daily_routine_of_the_student,
      count
    );
    count = handleInputValue(
      formik.values.vt_list_of_full_time_and_part_time,
      count
    );
    count = handleInputValue(formik.values.vt_left_the_vedvidyalaya, count);
    count = handleInputValue(formik.values.vt_former_vedavidyarthi, count);
    count = handleInputValue(
      formik.values.vt_list_of_awards_receive_by_teacher,
      count
    );
    count = handleInputValue(
      formik.values.vt_daily_routine_of_the_vedadhyapaks,
      count
    );
    count = handleInputValue(
      formik.values.vt_efforts_being_made_by_the_teacher,
      count
    );
    setActualCount(count);
    // setTotalCount(total_count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(3, totalCount, actualCount);
  }, [actualCount, totalCount]);

  return (
    <div>
      <h3>
        {lang == "english"
          ? "Teacher related information"
          : lang == "hindi"
          ? "अध्यापक सम्बन्धित जानकारी"
          : "अध्यापकसम्बद्धसूचनाः"}
      </h3>
      <p className="text-primary ">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
          ? "सभी प्रश्‍न अनिवार्य हैं।"
          : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>

      <label>
        {lang == "english"
          ? "Information of vedavidyarthi who have passed the examination in the last five years. (Please count only the names of vedavidyarthi who have passed the higher qualification in these lists, i.e. the names of moolapathi vedavidyarthi who have passed the examination of kramant, should not be added to the moolapathis list.)"
          : lang == "hindi"
          ? "पिछले पाँच वर्षों में रीक्षा उत्तीर्ण हुए वेदविद्यार्थीयों की जानकारी। (कृपया इन सूचियों में उच्चतर योग्यता में उत्तीर्ण वेदविद्यार्थीयों के नाम ही गिनें, अर्थात् जो मूलपाठी आगे पढ़कर क्रमपाठ की परीक्षा भी उत्तीर्ण कर चुके हैं, उनके नाम मूलपाठियों की गिनती में न जोड़ें)"
          : "विगतपञ्चवर्षेषु परीक्षोत्तीर्णानां छात्राणां सूचना। (कृपया एतासु चीषु उच्चतरयोग्यतासु उत्तीर्णानां छात्राणामेव नामगणनं कार्यम्, अर्थात् ये मूलपाठिनः क्रमान्तपरीक्षायामपि उत्तीर्णाः सन्ति, तेषां नामानि मूलपाठिनां सूच्यां न गणयतु) "}
      </label>
      <div className="table_responsive form-group">
        <table className="table">
          <tr>
            <th>
              {lang == "english"
                ? "Level "
                : lang == "hindi"
                ? "स्तर "
                : "स्तर"}
            </th>
            <th>
              {lang == "english"
                ? "Rigveda"
                : lang == "hindi"
                ? "ऋग्वेद "
                : "ऋग्वेदः"}
            </th>
            <th>
              {lang == "english"
                ? "Shuklayajurveda"
                : lang == "hindi"
                ? "शुक्लयजुर्वेद"
                : "शुक्लयजुर्वेदः "}
            </th>
            <th>
              {lang == "english"
                ? "Krishnayajurveda"
                : lang == "hindi"
                ? "कृष्णयजुर्वेद"
                : "कृष्णयजुर्वेदः "}
            </th>
            <th>
              {lang == "english"
                ? "Samveda"
                : lang == "hindi"
                ? "सामवेद "
                : "सामवेदः "}
            </th>
            <th>
              {lang == "english"
                ? "Atharvaveda"
                : lang == "hindi"
                ? "अथर्ववेद "
                : "अथर्ववेदः "}
            </th>
            <th width="120">
              {lang == "english"
                ? "Action"
                : lang == "hindi"
                ? "क्रिया"
                : "क्रिया"}
            </th>
          </tr>
          {formik.values?.vt_information_of_passed_student?.map(
            (son, index) => (
              <tr key={index}>
                <td>
                  <select
                    className="form-control"
                    id={`vt_information_of_passed_student[${index}].level`}
                    name={`vt_information_of_passed_student[${index}].level`}
                    value={
                      formik.values.vt_information_of_passed_student[index]
                        .level || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vt_information_of_passed_student[${index}].level`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                  >
                    <option>
                      ---
                      {lang == "english"
                        ? "Select level "
                        : lang == "hindi"
                        ? "स्तर का चयन करे"
                        : "स्तरं चिनोतु"}
                      {/* {lang == "english"
                        ? "Select"
                        : lang == "hindi"
                        ? "चुने"
                        : "चिनोतु"} */}
                      ---
                    </option>
                    <option value="Moolapathi">
                      {lang == "english"
                        ? "Moolapathi "
                        : lang == "hindi"
                        ? "मूलपाठी "
                        : "मूलपाठयः "}
                    </option>
                    <option value="Kramapathi">
                      {lang == "english"
                        ? "Kramapathi "
                        : lang == "hindi"
                        ? "क्रमपाठी  "
                        : "क्रमपाठयः :"}
                    </option>
                    <option value="Ghanapathi">
                      {lang == "english"
                        ? "Ghanapathi "
                        : lang == "hindi"
                        ? "घनपाठी "
                        : "घनपाठयः "}
                    </option>
                  </select>
                  {formik.touched &&
                  formik.errors?.vt_information_of_passed_student &&
                  formik.errors?.vt_information_of_passed_student[index]
                    .level ? (
                    <div className="text-danger">
                      {formik.errors?.vt_information_of_passed_student[index]
                        .level &&
                        formik.errors?.vt_information_of_passed_student[index]
                          .level}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id={`vt_information_of_passed_student[${index}].rigveda`}
                    name={`vt_information_of_passed_student[${index}].rigveda`}
                    value={
                      formik.values.vt_information_of_passed_student[index]
                        .rigveda || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vt_information_of_passed_student[${index}].rigveda`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                  />
                  {formik.touched &&
                  formik.errors?.vt_information_of_passed_student &&
                  formik.errors?.vt_information_of_passed_student[index]
                    .rigveda ? (
                    <div className="text-danger">
                      {formik.errors?.vt_information_of_passed_student[index]
                        .rigveda &&
                        formik.errors?.vt_information_of_passed_student[index]
                          .rigveda}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id={`vt_information_of_passed_student[${index}].shuklayajurve`}
                    name={`vt_information_of_passed_student[${index}].shuklayajurve`}
                    value={
                      formik.values.vt_information_of_passed_student[index]
                        .shuklayajurve || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vt_information_of_passed_student[${index}].shuklayajurve`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                  />
                  {formik.touched &&
                  formik.errors?.vt_information_of_passed_student &&
                  formik.errors?.vt_information_of_passed_student[index]
                    .shuklayajurve ? (
                    <div className="text-danger">
                      {formik.errors?.vt_information_of_passed_student[index]
                        .shuklayajurve &&
                        formik.errors?.vt_information_of_passed_student[index]
                          .shuklayajurve}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id={`vt_information_of_passed_student[${index}].krishnayajurve`}
                    name={`vt_information_of_passed_student[${index}].krishnayajurve`}
                    value={
                      formik.values.vt_information_of_passed_student[index]
                        .krishnayajurve || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vt_information_of_passed_student[${index}].krishnayajurve`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                  />
                  {formik.touched &&
                  formik.errors?.vt_information_of_passed_student &&
                  formik.errors?.vt_information_of_passed_student[index]
                    .krishnayajurve ? (
                    <div className="text-danger">
                      {formik.errors?.vt_information_of_passed_student[index]
                        .krishnayajurve &&
                        formik.errors?.vt_information_of_passed_student[index]
                          .krishnayajurve}
                    </div>
                  ) : null}
                </td>

                <td>
                  <input
                    type="text"
                    className="form-control"
                    id={`vt_information_of_passed_student[${index}].samveda`}
                    name={`vt_information_of_passed_student[${index}].samveda`}
                    value={
                      formik.values.vt_information_of_passed_student[index]
                        .samveda || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vt_information_of_passed_student[${index}].samveda`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                  />
                  {formik.touched &&
                  formik.errors?.vt_information_of_passed_student &&
                  formik.errors?.vt_information_of_passed_student[index]
                    .samveda ? (
                    <div className="text-danger">
                      {formik.errors?.vt_information_of_passed_student[index]
                        .samveda &&
                        formik.errors?.vt_information_of_passed_student[index]
                          .samveda}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id={`vt_information_of_passed_student[${index}].atharvaveda`}
                    name={`vt_information_of_passed_student[${index}].atharvaveda`}
                    value={
                      formik.values.vt_information_of_passed_student[index]
                        .atharvaveda || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vt_information_of_passed_student[${index}].atharvaveda`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                  />
                  {formik.touched &&
                  formik.errors?.vt_information_of_passed_student &&
                  formik.errors?.vt_information_of_passed_student[index]
                    .atharvaveda ? (
                    <div className="text-danger">
                      {formik.errors?.vt_information_of_passed_student[index]
                        .atharvaveda &&
                        formik.errors?.vt_information_of_passed_student[index]
                          .atharvaveda}
                    </div>
                  ) : null}
                </td>
                <td>
                  {formik.values.vt_information_of_passed_student.length >=
                  2 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={(e) => removeVidyarthiRow(index, e)}
                    >
                      {lang == "english"
                        ? "Remove"
                        : lang == "hindi"
                        ? "हटाएँ"
                        : "अपाकरोतु"}
                    </button>
                  ) : null}
                </td>
              </tr>
            )
          )}
          <tr className="border-bottom-0">
            <td className="text-right" colspan="7">
              <button
                className="btn btn-outline-danger"
                onClick={(e) => addVidyarthiRow(e)}
              >
                {lang == "english"
                  ? "Add"
                  : lang == "hindi"
                  ? "जोड़ें"
                  : "संयोजयतु"}
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div className="form-group">
        <label>
          {lang == "english"
            ? "List of vedavidyarthis who have passed the kramanta, ghananta or higher examinations in the last five years. Attach Annexure-2?"
            : lang == "hindi"
            ? "पिछले पाँच वर्षों में क्रमान्त, घनान्त या उच्च परीक्षा उत्तीर्ण करने वाले द्यार्थियों की सूची. अनुबन्ध-२ संलग्न करें?"
            : "विगतपञ्चवर्षेषु क्रमान्तं, घनान्तं वा दुच्चस्तरीयपरीक्षायाम् उत्तीर्णच्छात्राणां सूची. अनुबन्धम्-२ संलग्नं करोतु?"}
        </label>
        <select
          className="form-control col-4"
          id="vt_list_of_student_passed_the_kramanta_ghananta"
          name="vt_list_of_student_passed_the_kramanta_ghananta"
          onChange={(e) => {
            handleInputChange(
              "vt_list_of_student_passed_the_kramanta_ghananta",
              e
            );
            if (e.target.value === "yes") {
              formik.setFieldValue(
                `vt_list_of_student_passed_the_kramanta_ghananta_detail`,
                [
                  {
                    vedavidyarthi_name: "",
                    level_of_vedadhyaya: "",
                    other: "",
                    name_of_the_examine_institution: "",
                    year: "",
                    division: "",
                    attached_document_string: "",
                  },
                ]
              );
            }
            if (e.target.value === "no") {
              formik.setFieldValue(
                `vt_list_of_student_passed_the_kramanta_ghananta_detail`,
                [
                  {
                    vedavidyarthi_name: "N/A",
                    level_of_vedadhyaya: "N/A",
                    other: "N/A",
                    name_of_the_examine_institution: "N/A",
                    year: "N/A",
                    division: "N/A",
                    attached_document_string: "N/A",
                  },
                ]
              );
            }
          }}
          onBlur={(e) => handleInputSave()}
          value={formik.values.vt_list_of_student_passed_the_kramanta_ghananta}
        >
          <option>
            ---
            {lang == "english" ? "Select" : lang == "hindi" ? "चुने" : "चिनोतु"}
            ---
          </option>
          <option value="yes">
            {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
          </option>
          <option value="no">
            {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
          </option>
        </select>
        {formik.touched &&
        formik.errors?.vt_list_of_student_passed_the_kramanta_ghananta ? (
          <div className="text-danger">
            {formik.errors?.vt_list_of_student_passed_the_kramanta_ghananta &&
              formik.errors?.vt_list_of_student_passed_the_kramanta_ghananta}
          </div>
        ) : null}
      </div>
      {formik.values?.vt_list_of_student_passed_the_kramanta_ghananta ==
      "yes" ? (
        <div className="table-responsive form-group">
          <table className="table">
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                  ? "क्रम संख्या "
                  : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "Vedavidyarthi name"
                  : lang == "hindi"
                  ? "विद्यार्थी का नाम "
                  : "विद्यार्थिनः नाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Level of vedadhyaya"
                  : lang == "hindi"
                  ? "वेदाध्यन स्तर "
                  : "वेदाध्ययनस्तरः "}
              </th>
              <th>
                {lang == "english"
                  ? "Other"
                  : lang == "hindi"
                  ? "अन्य  "
                  : "अन्यः  "}
              </th>
              <th>
                {lang == "english"
                  ? "Name of the examine institution"
                  : lang == "hindi"
                  ? "परीक्षा संस्था का नाम "
                  : "परीक्षासंस्था-नाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Year"
                  : lang == "hindi"
                  ? "वर्ष "
                  : "वर्षम् "}
              </th>
              <th>
                {lang == "english"
                  ? "Division"
                  : lang == "hindi"
                  ? "श्रेणी"
                  : "श्रेणी"}
              </th>
              <th>
                {lang == "english"
                  ? "Attach certificate"
                  : lang == "hindi"
                  ? "प्रमाण पत्र अपलोड करे "
                  : "प्रमाणपत्रम् अपलोड इति करोतु"}
              </th>
              <th width="120">
                {" "}
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                  ? "क्रिया"
                  : "क्रिया"}
              </th>
            </tr>
            {formik.values.vt_list_of_student_passed_the_kramanta_ghananta_detail?.map(
              (row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].vedavidyarthi_name`}
                      name={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].vedavidyarthi_name`}
                      value={
                        formik.values
                          .vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].vedavidyarthi_name || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].vedavidyarthi_name`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ]?.vedavidyarthi_name ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].vedavidyarthi_name &&
                          formik.errors
                            ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                            index
                          ].vedavidyarthi_name}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      className="form-control"
                      id={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].level_of_vedadhyaya`}
                      name={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].level_of_vedadhyaya`}
                      value={
                        formik.values
                          .vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].level_of_vedadhyaya || ""
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].level_of_vedadhyaya`,
                          e
                        );
                        if (e.target.value == "Other") {
                          formik.setFieldValue(
                            `vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].other`,
                            ""
                          );
                        }
                        if (e.target.value != "Other") {
                          formik.setFieldValue(
                            `vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].other`,
                            "N/A"
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    >
                      <option>
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="Mulanta">
                        {lang == "english"
                          ? "Mulanta"
                          : lang == "hindi"
                          ? "मूलांत"
                          : "मूलान्तः"}
                      </option>
                      <option value="Kramanta">
                        {lang == "english"
                          ? "Kramanta"
                          : lang == "hindi"
                          ? "क्रमांत "
                          : "क्रमान्त:"}
                      </option>
                      <option value="Ghananta">
                        {lang == "english"
                          ? "Ghananta"
                          : lang == "hindi"
                          ? "घनांत"
                          : "घनान्तः"}
                      </option>
                      <option value="Shadanga">
                        {lang == "english"
                          ? "Shadanga"
                          : lang == "hindi"
                          ? "षडङ्ग"
                          : "षडङ्गानि"}
                      </option>
                      <option value="Lakshana">
                        {lang == "english"
                          ? "Lakshana"
                          : lang == "hindi"
                          ? "लक्षण"
                          : "लक्षणम्"}
                      </option>
                      <option value="Bhashya">
                        {lang == "english"
                          ? "Bhashya"
                          : lang == "hindi"
                          ? "भाष्य"
                          : "भाष्यम्"}
                      </option>
                      <option value="Other">
                        {lang == "english"
                          ? "Other"
                          : lang == "hindi"
                          ? "अन्य  "
                          : "अन्यः  "}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ]?.level_of_vedadhyaya ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].level_of_vedadhyaya &&
                          formik.errors
                            ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                            index
                          ].level_of_vedadhyaya}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values
                      .vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ].level_of_vedadhyaya == "Other" ? (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          id={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].other`}
                          name={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].other`}
                          value={
                            formik.values
                              .vt_list_of_student_passed_the_kramanta_ghananta_detail[
                              index
                            ].other || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].other`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                        />
                        {formik.touched &&
                        formik.errors
                          ?.vt_list_of_student_passed_the_kramanta_ghananta_detail &&
                        formik.errors
                          ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ]?.other ? (
                          <div className="text-danger">
                            {formik.errors
                              ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                              index
                            ].other &&
                              formik.errors
                                ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                                index
                              ].other}
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].name_of_the_examine_institution`}
                      name={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].name_of_the_examine_institution`}
                      value={
                        formik.values
                          .vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].name_of_the_examine_institution || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].name_of_the_examine_institution`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ]?.name_of_the_examine_institution ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].name_of_the_examine_institution &&
                          formik.errors
                            ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                            index
                          ].name_of_the_examine_institution}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].year`}
                      name={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].year`}
                      value={
                        formik.values
                          .vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].year || ""
                      }
                      onChange={(e) => {
                        if (e.target.value <= 2025) {
                          handleInputChange(
                            `vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].year`,
                            e
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ]?.year ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].year &&
                          formik.errors
                            ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                            index
                          ].year}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      className="form-control"
                      id={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].division`}
                      name={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].division`}
                      value={
                        formik.values
                          .vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].division || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].division`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    >
                      <option>
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="First">
                        {lang == "english"
                          ? "First "
                          : lang == "hindi"
                          ? "प्रथम "
                          : "प्रथमः "}
                      </option>
                      <option value="Second">
                        {lang == "english"
                          ? "Second"
                          : lang == "hindi"
                          ? "द्वितीय  "
                          : "द्वितीयः :"}
                      </option>
                      <option value="Third">
                        {lang == "english"
                          ? "Third "
                          : lang == "hindi"
                          ? "तृतीय "
                          : "तृतीयः "}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ]?.division ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].division &&
                          formik.errors
                            ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                            index
                          ].division}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      name={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].attached_document`}
                      id={`vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].attached_document`}
                      className="form-control"
                      onChange={async (e) => {
                        if (e.target.files.length > 0) {
                          await fileUploadFunction(
                            `vt_list_of_student_passed_the_kramanta_ghananta_detail[${index}].attached_document_string`,
                            e.target.files[0]
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik?.values
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ]?.attached_document_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.vt_list_of_student_passed_the_kramanta_ghananta_detail[index].attached_document_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik?.values?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].attached_document_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail &&
                    formik.errors
                      ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                      index
                    ]?.attached_document_string ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                          index
                        ].attached_document_string &&
                          formik.errors
                            ?.vt_list_of_student_passed_the_kramanta_ghananta_detail[
                            index
                          ].attached_document_string}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values
                      .vt_list_of_student_passed_the_kramanta_ghananta_detail
                      .length >= 2 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => removeStudentRow(index, e)}
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              )
            )}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="9">
                <button
                  className="btn btn-outline-danger"
                  onClick={(e) => addStudentRow(e)}
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                    ? "जोड़ें"
                    : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <></>
      )}
      <div className="form-group">
        <label>
          {lang == "english"
            ? "List of awards / honours received by the vidyarthi in the last five years.  Attach Annexure-3?"
            : lang == "hindi"
            ? "पिछले पाँच वर्षों में वेदविद्यार्थीयों द्वारा प्राप्त पुरस्कार / सम्मानों की सूची. अनुबन्ध-३ संलग्न करें?"
            : "विगतपञ्चवर्षेषु छात्रैः प्राप्तानां पुरस्काराणां/ सम्मानानां सूची.अनुबन्धम्-३ संलग्नं करोतु?"}
        </label>
        <select
          className="form-control col-4"
          id="vt_list_of_awards_recived_by_students"
          name="vt_list_of_awards_recived_by_students"
          onChange={(e) => {
            handleInputChange("vt_list_of_awards_recived_by_students", e);
            if (e.target.value == "yes") {
              formik.setFieldValue(
                `vt_list_of_awards_recived_by_students_detail`,
                [
                  {
                    student_name: "",
                    name_of_the_award: "",
                    name_of_the_institute: "",
                    year: "",
                    attached_document_string: "",
                  },
                ]
              );
            }
            if (e.target.value == "no") {
              formik.setFieldValue(
                `vt_list_of_awards_recived_by_students_detail`,
                [
                  {
                    student_name: "N/A",
                    name_of_the_award: "N/A",
                    name_of_the_institute: "N/A",
                    year: "N/A",
                    attached_document_string: "N/A",
                  },
                ]
              );
            }
          }}
          onBlur={(e) => handleInputSave()}
          value={formik.values.vt_list_of_awards_recived_by_students}
        >
          <option>
            ---
            {lang == "english" ? "Select" : lang == "hindi" ? "चुने" : "चिनोतु"}
            ---
          </option>
          <option value="yes">
            {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
          </option>
          <option value="no">
            {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
          </option>
        </select>
        {formik.touched &&
        formik.errors?.vt_list_of_awards_recived_by_students ? (
          <div className="text-danger">
            {formik.errors?.vt_list_of_awards_recived_by_students &&
              formik.errors?.vt_list_of_awards_recived_by_students}
          </div>
        ) : null}
      </div>

      {formik.values?.vt_list_of_awards_recived_by_students == "yes" ? (
        <div className="table-responsive form-group">
          <table className="table">
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                  ? "क्रम संख्या "
                  : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "Vedavidyarthi name"
                  : lang == "hindi"
                  ? "विद्यार्थी का नाम "
                  : "विद्यार्थिनः नाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Name of the award / honor"
                  : lang == "hindi"
                  ? "पुरस्कार अथवा सम्मान का नाम "
                  : "पुरस्कारस्य सम्मानस्य वा नाम  "}
              </th>
              <th>
                {lang == "english"
                  ? "Name of the institution"
                  : lang == "hindi"
                  ? "संस्था का नाम "
                  : "संस्थानाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Year"
                  : lang == "hindi"
                  ? "वर्ष "
                  : "वर्षम् "}
              </th>
              <th>
                {lang == "english"
                  ? "Attach certificate"
                  : lang == "hindi"
                  ? "प्रमाण पत्र अपलोड करे "
                  : "प्रमाणपत्रम् अपलोड इति करोतु"}
              </th>
              <th width="120">
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                  ? "क्रिया"
                  : "क्रिया"}
              </th>
            </tr>
            {formik.values.vt_list_of_awards_recived_by_students_detail?.map(
              (row, index) => (
                <tr key={index}>
                  <td>{1 + index}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_list_of_awards_recived_by_students_detail[${index}].student_name`}
                      name={`vt_list_of_awards_recived_by_students_detail[${index}].student_name`}
                      value={
                        formik.values
                          .vt_list_of_awards_recived_by_students_detail[index]
                          .student_name || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_awards_recived_by_students_detail[${index}].student_name`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_awards_recived_by_students_detail &&
                    formik.errors?.vt_list_of_awards_recived_by_students_detail[
                      index
                    ]?.student_name ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_awards_recived_by_students_detail[index]
                          .student_name &&
                          formik.errors
                            ?.vt_list_of_awards_recived_by_students_detail[
                            index
                          ].student_name}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      id={`vt_list_of_awards_recived_by_students_detail[${index}].name_of_the_award`}
                      name={`vt_list_of_awards_recived_by_students_detail[${index}].name_of_the_award`}
                      value={
                        formik.values
                          .vt_list_of_awards_recived_by_students_detail[index]
                          .name_of_the_award || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_awards_recived_by_students_detail[${index}].name_of_the_award`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_awards_recived_by_students_detail &&
                    formik.errors?.vt_list_of_awards_recived_by_students_detail[
                      index
                    ]?.name_of_the_award ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_awards_recived_by_students_detail[index]
                          .name_of_the_award &&
                          formik.errors
                            ?.vt_list_of_awards_recived_by_students_detail[
                            index
                          ].name_of_the_award}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_list_of_awards_recived_by_students_detail[${index}].name_of_the_institute`}
                      name={`vt_list_of_awards_recived_by_students_detail[${index}].name_of_the_institute`}
                      value={
                        formik.values
                          .vt_list_of_awards_recived_by_students_detail[index]
                          .name_of_the_institute || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_awards_recived_by_students_detail[${index}].name_of_the_institute`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_awards_recived_by_students_detail &&
                    formik.errors?.vt_list_of_awards_recived_by_students_detail[
                      index
                    ]?.name_of_the_institute ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_awards_recived_by_students_detail[index]
                          .name_of_the_institute &&
                          formik.errors
                            ?.vt_list_of_awards_recived_by_students_detail[
                            index
                          ].name_of_the_institute}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`vt_list_of_awards_recived_by_students_detail[${index}].year`}
                      name={`vt_list_of_awards_recived_by_students_detail[${index}].year`}
                      value={
                        formik.values
                          .vt_list_of_awards_recived_by_students_detail[index]
                          .year || ""
                      }
                      onChange={(e) => {
                        if (e.target.value <= 2025) {
                          handleInputChange(
                            `vt_list_of_awards_recived_by_students_detail[${index}].year`,
                            e
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_awards_recived_by_students_detail &&
                    formik.errors?.vt_list_of_awards_recived_by_students_detail[
                      index
                    ]?.year ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_awards_recived_by_students_detail[index]
                          .year &&
                          formik.errors
                            ?.vt_list_of_awards_recived_by_students_detail[
                            index
                          ].year}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      name={`vt_list_of_awards_recived_by_students_detail[${index}].attached_document`}
                      id={`vt_list_of_awards_recived_by_students_detail[${index}].attached_document`}
                      className="form-control"
                      onChange={async (e) => {
                        if (e.target.files.length > 0) {
                          await fileUploadFunction(
                            `vt_list_of_awards_recived_by_students_detail[${index}].attached_document_string`,
                            e.target.files[0]
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik?.values
                      ?.vt_list_of_awards_recived_by_students_detail[index]
                      ?.attached_document_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.vt_list_of_awards_recived_by_students_detail[index].attached_document_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik?.values?.vt_list_of_awards_recived_by_students_detail[
                          index
                        ].attached_document_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_awards_recived_by_students_detail &&
                    formik.errors?.vt_list_of_awards_recived_by_students_detail[
                      index
                    ]?.attached_document_string ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_awards_recived_by_students_detail[index]
                          .attached_document_string &&
                          formik.errors
                            ?.vt_list_of_awards_recived_by_students_detail[
                            index
                          ].attached_document_string}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values.vt_list_of_awards_recived_by_students_detail
                      .length >= 2 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => removeFiveRow(index, e)}
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              )
            )}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="7">
                <button
                  className="btn btn-outline-danger"
                  onClick={(e) => addFiveRow(e)}
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                    ? "जोड़ें"
                    : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <></>
      )}

      <label>
        {lang == "english"
          ? "Describe the daily routine of the vedavidyarthi"
          : lang == "hindi"
          ? "विद्यार्थियों की दिनचर्या का विवरण दीजिए"
          : "विद्यार्थिनां दिनचर्यायाः विवरणं ददातु"}
      </label>
      <div className="form-group">
        <textarea
          className="form-control"
          rows={2}
          maxLength={700}
          id="vt_daily_routine_of_the_student"
          name="vt_daily_routine_of_the_student"
          onChange={(e) =>
            handleInputChange("vt_daily_routine_of_the_student", e)
          }
          onBlur={(e) => handleInputSave()}
          value={formik.values.vt_daily_routine_of_the_student}
        ></textarea>
        {formik.touched && formik.errors?.vt_daily_routine_of_the_student ? (
          <div className="text-danger">
            {formik.errors?.vt_daily_routine_of_the_student &&
              formik.errors?.vt_daily_routine_of_the_student}
          </div>
        ) : null}
        <p className=" text-right">
          {formik.values.vt_daily_routine_of_the_student?.length}/700
          {lang == "english"
            ? " Characters"
            : lang == "hindi"
            ? " शब्द"
            : " शब्दः"}
        </p>
      </div>

      <h5>
        {lang == "english"
          ? "Teacher related information"
          : lang == "hindi"
          ? "अध्यापक सम्बन्धित जानकारी"
          : "अध्यापकसम्बद्धसूचनाः"}
      </h5>
      <div className="form-group">
        <label>
          {lang == "english"
            ? "List of full time / part time vedaadhyapaka currently teaching at the vedavidyalaya. Attach Annexure-4?"
            : lang == "hindi"
            ? "वर्तमान में संस्था में पूर्णकालिक / अंशकालिक अध्यापकों की सूची. अनुबन्ध-४ संलग्न करें?"
            : "सम्प्रति संस्थायां पूर्णकालिकानां / अंशकालिकानां अध्यापकानां सूची. अनुबन्धम्-४ संलग्नं करोतु?"}
        </label>
        <select
          className="form-control col-4"
          id="vt_list_of_full_time_and_part_time"
          name="vt_list_of_full_time_and_part_time"
          onChange={(e) => {
            handleInputChange("vt_list_of_full_time_and_part_time", e);
            if (e.target.value == "yes") {
              formik.setFieldValue(
                `vt_list_of_full_time_and_part_time_details`,
                [
                  {
                    Adhyapaka_name: "",
                    vedshakha: "",
                    level_of_vedgyan: "",
                    other_vedgyan: "",
                    age: "",
                    fulltime_parttime: "",
                    attached_document_string: "",
                  },
                ]
              );
            }
            if (e.target.value == "no") {
              formik.setFieldValue(
                `vt_list_of_full_time_and_part_time_details`,
                [
                  {
                    Adhyapaka_name: "N/A",
                    vedshakha: "N/A",
                    level_of_vedgyan: "N/A",
                    other_vedgyan: "N/A",
                    age: "N/A",
                    fulltime_parttime: "N/A",
                    attached_document_string: "N/A",
                  },
                ]
              );
            }
          }}
          onBlur={(e) => handleInputSave()}
          value={formik.values.vt_list_of_full_time_and_part_time}
        >
          <option className="mb-5px">
            ---
            {lang == "english" ? "Select" : lang == "hindi" ? "चुने" : "चिनोतु"}
            ---
          </option>
          <option value="yes">
            {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
          </option>
          <option value="no">
            {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
          </option>
        </select>
        {formik.touched && formik.errors?.vt_list_of_full_time_and_part_time ? (
          <div className="text-danger">
            {formik.errors?.vt_list_of_full_time_and_part_time &&
              formik.errors?.vt_list_of_full_time_and_part_time}
          </div>
        ) : null}
      </div>

      {formik.values.vt_list_of_full_time_and_part_time == "yes" ? (
        <div className="table-responsive form-group">
          <table className="table">
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                  ? "क्रम संख्या "
                  : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "Adhyapaka name"
                  : lang == "hindi"
                  ? "अध्यापक का नाम "
                  : "अध्यापकस्य नाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Vedashakha"
                  : lang == "hindi"
                  ? "वेदशाखा "
                  : "वेदशाखा "}
              </th>
              <th>
                {lang == "english"
                  ? "Level of vedajnana"
                  : lang == "hindi"
                  ? "वेदज्ञान स्तर "
                  : "वेदज्ञान-स्तरः "}
              </th>
              <th>
                {lang == "english"
                  ? "Other"
                  : lang == "hindi"
                  ? "अन्य  "
                  : "अन्यः  "}
              </th>
              <th>
                {lang == "english"
                  ? "Age"
                  : lang == "hindi"
                  ? "आयु  "
                  : "आयु: "}
              </th>
              <th>
                {lang == "english"
                  ? "Full time or part time"
                  : lang == "hindi"
                  ? "पूर्णकालिक अथवा अंशकालिक   "
                  : "पूर्णकालिकः अथवा अंशकालिकः  "}
              </th>
              <th>
                {lang == "english"
                  ? "Attach certificate"
                  : lang == "hindi"
                  ? "प्रमाण पत्र अपलोड करे "
                  : "प्रमाणपत्रम् अपलोड इति करोतु"}
              </th>
              <th width="120">
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                  ? "क्रिया"
                  : "क्रिया"}
              </th>
            </tr>
            {formik.values.vt_list_of_full_time_and_part_time_details?.map(
              (row, index) => (
                <tr key={index}>
                  <td>{1 + index}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_list_of_full_time_and_part_time_details[${index}].Adhyapaka_name`}
                      name={`vt_list_of_full_time_and_part_time_details[${index}].Adhyapaka_name`}
                      value={
                        formik.values
                          .vt_list_of_full_time_and_part_time_details[index]
                          .Adhyapaka_name || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_full_time_and_part_time_details[${index}].Adhyapaka_name`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details[
                      index
                    ]?.Adhyapaka_name ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_full_time_and_part_time_details[index]
                          .Adhyapaka_name &&
                          formik.errors
                            ?.vt_list_of_full_time_and_part_time_details[index]
                            .Adhyapaka_name}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      className="form-control"
                      id={`vt_list_of_full_time_and_part_time_details[${index}].vedshakha`}
                      name={`vt_list_of_full_time_and_part_time_details[${index}].vedshakha`}
                      value={
                        formik.values
                          .vt_list_of_full_time_and_part_time_details[index]
                          .vedshakha || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_full_time_and_part_time_details[${index}].vedshakha`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    >
                      <option>
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="Shakalshakha">
                        {lang == "english"
                          ? "Shakalshakha"
                          : lang == "hindi"
                          ? "शाकलशाखा "
                          : "शाकलशाखा "}
                      </option>
                      <option value="Ashvalayanshakha">
                        {lang == "english"
                          ? "Ashvalayanshakha"
                          : lang == "hindi"
                          ? "आश्वलायनशाखा "
                          : "आश्वलायनशाखा "}
                      </option>
                      <option value="Bashkalshakha">
                        {lang == "english"
                          ? "Bashkalshakha"
                          : lang == "hindi"
                          ? "बाष्कलशाखा "
                          : "बाष्कलशाखा "}
                      </option>
                      <option value="Shankhayanshakha">
                        {lang == "english"
                          ? "Shankhayanshakha"
                          : lang == "hindi"
                          ? "शांखायनशाखा "
                          : "शाङ्खायनशाखा "}
                      </option>
                      <option value="Kanvashakha ">
                        {lang == "english"
                          ? "Kanvashakha"
                          : lang == "hindi"
                          ? "काण्वशाखा "
                          : "काण्वशाखा "}
                      </option>
                      <option value="Madhyandinshakha">
                        {lang == "english"
                          ? "Madhyandinshakha"
                          : lang == "hindi"
                          ? "माध्यन्दिनशाखा "
                          : "माध्यन्दिनशाखा "}
                      </option>
                      <option value="Taittiriyashakha ">
                        {lang == "english"
                          ? "Taittiriyashakha"
                          : lang == "hindi"
                          ? "तैत्तिरीयशाखा "
                          : "तैत्तिरीयशाखा "}
                      </option>
                      <option value="Maitrayanishakha ">
                        {lang == "english"
                          ? "Maitrayanishakha"
                          : lang == "hindi"
                          ? "मैत्रायणीशाखा "
                          : "मैत्रायणीशाखा "}
                      </option>
                      <option value="Kathashakha ">
                        {lang == "english"
                          ? "Kathashakha"
                          : lang == "hindi"
                          ? "कठशाखा "
                          : "कठशाखा "}
                      </option>
                      <option value="Kapishthalshakha ">
                        {lang == "english"
                          ? "Kapishthalshakha"
                          : lang == "hindi"
                          ? "कपिष्ठलशाखा "
                          : "कपिष्ठलशाखा "}
                      </option>
                      <option value="Kouthumshakha ">
                        {lang == "english"
                          ? "Kouthumshakha"
                          : lang == "hindi"
                          ? "कौथुमशाखा "
                          : "कौथुमशाखा "}
                      </option>
                      <option value="Ranayaniyashakha ">
                        {lang == "english"
                          ? "Ranayaniyashakha"
                          : lang == "hindi"
                          ? "राणायनीयशाखा "
                          : "राणायनीयशाखा "}
                      </option>
                      <option value="Jaiminiyashakha ">
                        {lang == "english"
                          ? "Jaiminiyashakha"
                          : lang == "hindi"
                          ? "जैमिनीयशाखा "
                          : "जैमिनीयशाखा "}
                      </option>
                      <option value="Shounkashakha ">
                        {lang == "english"
                          ? "Shounkashakha"
                          : lang == "hindi"
                          ? "शौनकशाखा "
                          : "शौनकशाखा "}
                      </option>
                      <option value="Paippaladshakha ">
                        {lang == "english"
                          ? "Paippaladshakha"
                          : lang == "hindi"
                          ? "पैप्पलादशाखा "
                          : "पैप्पलादशाखा"}
                      </option>
                      <option value="Anyashakha">
                        {lang == "english"
                          ? "Anyashakha "
                          : lang == "hindi"
                          ? "अन्यशाखा"
                          : "अन्यशाखा "}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details[
                      index
                    ]?.vedshakha ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_full_time_and_part_time_details[index]
                          .vedshakha &&
                          formik.errors
                            ?.vt_list_of_full_time_and_part_time_details[index]
                            .vedshakha}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      className="form-control"
                      id={`vt_list_of_full_time_and_part_time_details[${index}].level_of_vedgyan`}
                      name={`vt_list_of_full_time_and_part_time_details[${index}].level_of_vedgyan`}
                      value={
                        formik.values
                          .vt_list_of_full_time_and_part_time_details[index]
                          .level_of_vedgyan || ""
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `vt_list_of_full_time_and_part_time_details[${index}].level_of_vedgyan`,
                          e
                        );
                        if (e.target.value == "Other") {
                          formik.setFieldValue(
                            `vt_list_of_full_time_and_part_time_details[${index}].other_vedgyan`,
                            ""
                          );
                        }
                        if (e.target.value != "Other") {
                          formik.setFieldValue(
                            `vt_list_of_full_time_and_part_time_details[${index}].other_vedgyan`,
                            "N/A"
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    >
                      <option>
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="Mulanta">
                        {lang == "english"
                          ? "Mulanta"
                          : lang == "hindi"
                          ? "मूलांत"
                          : "मूलान्तः"}
                      </option>
                      <option value="Kramanta">
                        {lang == "english"
                          ? "Kramanta"
                          : lang == "hindi"
                          ? "क्रमांत "
                          : "क्रमान्त:"}
                      </option>
                      <option value="Ghananta">
                        {lang == "english"
                          ? "Ghananta"
                          : lang == "hindi"
                          ? "घनांत"
                          : "घनान्तः"}
                      </option>
                      <option value="Shadanga">
                        {lang == "english"
                          ? "Shadanga"
                          : lang == "hindi"
                          ? "षडङ्ग"
                          : "षडङ्गानि"}
                      </option>
                      <option value="Lakshana">
                        {lang == "english"
                          ? "Lakshana"
                          : lang == "hindi"
                          ? "लक्षण"
                          : "लक्षणम्"}
                      </option>
                      <option value="Bhashya">
                        {lang == "english"
                          ? "Bhashya"
                          : lang == "hindi"
                          ? "भाष्य"
                          : "भाष्यम्"}
                      </option>
                      <option value="Other">
                        {lang == "english"
                          ? "Other"
                          : lang == "hindi"
                          ? "अन्य  "
                          : "अन्यः  "}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details[
                      index
                    ]?.level_of_vedgyan ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_full_time_and_part_time_details[index]
                          .level_of_vedgyan &&
                          formik.errors
                            ?.vt_list_of_full_time_and_part_time_details[index]
                            .level_of_vedgyan}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values.vt_list_of_full_time_and_part_time_details[
                      index
                    ]?.level_of_vedgyan == "Other" ? (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          id={`vt_list_of_full_time_and_part_time_details[${index}].other_vedgyan`}
                          name={`vt_list_of_full_time_and_part_time_details[${index}].other_vedgyan`}
                          value={
                            formik.values
                              .vt_list_of_full_time_and_part_time_details[index]
                              .other_vedgyan || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `vt_list_of_full_time_and_part_time_details[${index}].other_vedgyan`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                        />
                        {formik.touched &&
                        formik.errors
                          ?.vt_list_of_full_time_and_part_time_details &&
                        formik.errors
                          ?.vt_list_of_full_time_and_part_time_details[index]
                          ?.other_vedgyan ? (
                          <div className="text-danger">
                            {formik.errors
                              ?.vt_list_of_full_time_and_part_time_details[
                              index
                            ].other_vedgyan &&
                              formik.errors
                                ?.vt_list_of_full_time_and_part_time_details[
                                index
                              ].other_vedgyan}
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`vt_list_of_full_time_and_part_time_details[${index}].age`}
                      name={`vt_list_of_full_time_and_part_time_details[${index}].age`}
                      value={
                        formik.values
                          .vt_list_of_full_time_and_part_time_details[index]
                          .age || ""
                      }
                      onChange={(e) => {
                        if (e.target.value <= 120) {
                          handleInputChange(
                            `vt_list_of_full_time_and_part_time_details[${index}].age`,
                            e
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details[
                      index
                    ]?.age ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_full_time_and_part_time_details[index]
                          .age &&
                          formik.errors
                            ?.vt_list_of_full_time_and_part_time_details[index]
                            .age}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      className="form-control"
                      id={`vt_list_of_full_time_and_part_time_details[${index}].fulltime_parttime`}
                      name={`vt_list_of_full_time_and_part_time_details[${index}].fulltime_parttime`}
                      value={
                        formik.values
                          .vt_list_of_full_time_and_part_time_details[index]
                          .fulltime_parttime || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_full_time_and_part_time_details[${index}].fulltime_parttime`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    >
                      <option value="">
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="full-time">
                        {lang == "english"
                          ? "Full time "
                          : lang == "hindi"
                          ? "पूर्णकालिक"
                          : "पूर्णकालिकः"}
                      </option>
                      <option value="part-time">
                        {lang == "english"
                          ? "Part time"
                          : lang == "hindi"
                          ? "अंशकालिक"
                          : "अंशकालिक:"}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details[
                      index
                    ]?.fulltime_parttime ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_full_time_and_part_time_details[index]
                          .fulltime_parttime &&
                          formik.errors
                            ?.vt_list_of_full_time_and_part_time_details[index]
                            .fulltime_parttime}
                      </div>
                    ) : null}
                  </td>

                  {/* <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`vt_list_of_full_time_and_part_time_details[${index}].fulltime_parttime`}
                      name={`vt_list_of_full_time_and_part_time_details[${index}].fulltime_parttime`}
                      value={
                        formik.values
                          .vt_list_of_full_time_and_part_time_details[index]
                          .fulltime_parttime || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_full_time_and_part_time_details[${index}].fulltime_parttime`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details[
                      index
                    ].fulltime_parttime ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_full_time_and_part_time_details[index]
                          .fulltime_parttime &&
                          formik.errors
                            ?.vt_list_of_full_time_and_part_time_details[index]
                            .fulltime_parttime}
                      </div>
                    ) : null}
                  </td> */}
                  <td>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      name={`vt_list_of_full_time_and_part_time_details[${index}].attached_document`}
                      id={`vt_list_of_full_time_and_part_time_details[${index}].attached_document`}
                      className="form-control"
                      onChange={async (e) => {
                        if (e.target.files.length > 0) {
                          await fileUploadFunction(
                            `vt_list_of_full_time_and_part_time_details[${index}].attached_document_string`,
                            e.target.files[0]
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik?.values?.vt_list_of_full_time_and_part_time_details[
                      index
                    ]?.attached_document_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.vt_list_of_full_time_and_part_time_details[index].attached_document_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik?.values?.vt_list_of_full_time_and_part_time_details[
                          index
                        ].attached_document_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                    {formik.touched &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details &&
                    formik.errors?.vt_list_of_full_time_and_part_time_details[
                      index
                    ]?.attached_document_string ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_full_time_and_part_time_details[index]
                          .attached_document_string &&
                          formik.errors
                            ?.vt_list_of_full_time_and_part_time_details[index]
                            .attached_document_string}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values.vt_list_of_full_time_and_part_time_details
                      .length >= 2 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => removeVedvidyalayaRow(index, e)}
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              )
            )}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="9">
                <button
                  className="btn btn-outline-danger"
                  onClick={(e) => {
                    addVedvidyalayaRow(e);
                  }}
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                    ? "जोड़ें"
                    : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <></>
      )}
      <div className="form-group">
        <label>
          {lang == "english"
            ? "List of vedaadhyapaka who left the vedavidyalaya in the last 5 years. Attach Annexure-5?"
            : lang == "hindi"
            ? "पिछले ५ वर्षों में वेदविद्यालय छोड़ने वाले अध्यापकों की सूची. अनुबन्ध-५ संलग्न करें?"
            : "विगतपञ्चवर्षेषु वेदविद्यालयं त्यक्तवतां  शिक्षकाणां सूची. अनुबन्धम्-५ संलग्नं करोतु?"}
        </label>
        <select
          className="form-control col-4"
          id="vt_left_the_vedvidyalaya"
          name="vt_left_the_vedvidyalaya"
          onChange={(e) => {
            handleInputChange("vt_left_the_vedvidyalaya", e);
            if (e.target.value == "yes") {
              formik.setFieldValue(`vt_left_the_vedvidyalaya_details`, [
                {
                  teacher_name: "",
                  vedshakha: "",
                  when_started: "",
                  when_end: "",
                  reason_for_leaving: "",
                },
              ]);
            }
            if (e.target.value == "no") {
              formik.setFieldValue(`vt_left_the_vedvidyalaya_details`, [
                {
                  teacher_name: "N/A",
                  vedshakha: "N/A",
                  when_started: "N/A",
                  when_end: "N/A",
                  reason_for_leaving: "N/A",
                },
              ]);
            }
          }}
          onBlur={(e) => handleInputSave()}
          value={formik.values.vt_left_the_vedvidyalaya}
        >
          <option>
            ---
            {lang == "english" ? "Select" : lang == "hindi" ? "चुने" : "चिनोतु"}
            ---
          </option>
          <option value="yes">
            {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
          </option>
          <option value="no">
            {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
          </option>
        </select>
        {formik.touched && formik.errors?.vt_left_the_vedvidyalaya ? (
          <div className="text-danger">
            {formik.errors?.vt_left_the_vedvidyalaya &&
              formik.errors?.vt_left_the_vedvidyalaya}
          </div>
        ) : null}
      </div>

      {formik.values.vt_left_the_vedvidyalaya == "yes" ? (
        <div className="table-responsive form-group">
          <table className="table">
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                  ? "क्रम संख्या "
                  : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "Adhyapaka name"
                  : lang == "hindi"
                  ? "अध्यापक का नाम "
                  : "अध्यापकस्य नाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Vedashakha"
                  : lang == "hindi"
                  ? "वेदशाखा "
                  : "वेदशाखा "}
              </th>
              <th>
                {lang == "english"
                  ? "When they started adhyayan at the your institution "
                  : lang == "hindi"
                  ? "संस्था में अध्यापन का कार्यकाल से"
                  : "संस्थायाम् अध्यापन-कार्यकालस्य प्रारम्भः "}
              </th>
              <th>
                {lang == "english"
                  ? "When they ended adhyayan at your institution"
                  : lang == "hindi"
                  ? "संस्था में अध्यापन का कार्यकाल-तक"
                  : "संस्थायाम् अध्यापन-कार्यकालस्य समाप्तिः"}
              </th>
              <th>
                {lang == "english"
                  ? "Reason for leaving"
                  : lang == "hindi"
                  ? "छोड़ने का कारण"
                  : "गमनस्य कारणम्"}
              </th>
              <th width="120">
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                  ? "क्रिया"
                  : "क्रिया"}
              </th>
            </tr>
            {formik.values.vt_left_the_vedvidyalaya_details?.map(
              (row, index) => (
                <tr key={index}>
                  <td>{1 + index}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_left_the_vedvidyalaya_details[${index}].teacher_name`}
                      name={`vt_left_the_vedvidyalaya_details[${index}].teacher_name`}
                      value={
                        formik.values.vt_left_the_vedvidyalaya_details[index]
                          .teacher_name || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_left_the_vedvidyalaya_details[${index}].teacher_name`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_left_the_vedvidyalaya_details &&
                    formik.errors?.vt_left_the_vedvidyalaya_details[index]
                      ?.teacher_name ? (
                      <div className="text-danger">
                        {formik.errors?.vt_left_the_vedvidyalaya_details[index]
                          .teacher_name &&
                          formik.errors?.vt_left_the_vedvidyalaya_details[index]
                            .teacher_name}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      className="form-control"
                      id={`vt_left_the_vedvidyalaya_details[${index}].vedshakha`}
                      name={`vt_left_the_vedvidyalaya_details[${index}].vedshakha`}
                      value={
                        formik.values.vt_left_the_vedvidyalaya_details[index]
                          .vedshakha || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_left_the_vedvidyalaya_details[${index}].vedshakha`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    >
                      <option>
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="Shakalshakha">
                        {lang == "english"
                          ? "Shakalshakha"
                          : lang == "hindi"
                          ? "शाकलशाखा "
                          : "शाकलशाखा "}
                      </option>
                      <option value="Ashvalayanshakha">
                        {lang == "english"
                          ? "Ashvalayanshakha"
                          : lang == "hindi"
                          ? "आश्वलायनशाखा "
                          : "आश्वलायनशाखा "}
                      </option>
                      <option value="Bashkalshakha">
                        {lang == "english"
                          ? "Bashkalshakha"
                          : lang == "hindi"
                          ? "बाष्कलशाखा "
                          : "बाष्कलशाखा "}
                      </option>
                      <option value="Shankhayanshakha">
                        {lang == "english"
                          ? "Shankhayanshakha"
                          : lang == "hindi"
                          ? "शांखायनशाखा "
                          : "शाङ्खायनशाखा "}
                      </option>
                      <option value="Kanvashakha ">
                        {lang == "english"
                          ? "Kanvashakha"
                          : lang == "hindi"
                          ? "काण्वशाखा "
                          : "काण्वशाखा "}
                      </option>
                      <option value="Madhyandinshakha">
                        {lang == "english"
                          ? "Madhyandinshakha"
                          : lang == "hindi"
                          ? "माध्यन्दिनशाखा "
                          : "माध्यन्दिनशाखा "}
                      </option>
                      <option value="Taittiriyashakha ">
                        {lang == "english"
                          ? "Taittiriyashakha"
                          : lang == "hindi"
                          ? "तैत्तिरीयशाखा "
                          : "तैत्तिरीयशाखा "}
                      </option>
                      <option value="Maitrayanishakha ">
                        {lang == "english"
                          ? "Maitrayanishakha"
                          : lang == "hindi"
                          ? "मैत्रायणीशाखा "
                          : "मैत्रायणीशाखा "}
                      </option>
                      <option value="Kathashakha ">
                        {lang == "english"
                          ? "Kathashakha"
                          : lang == "hindi"
                          ? "कठशाखा "
                          : "कठशाखा "}
                      </option>
                      <option value="Kapishthalshakha ">
                        {lang == "english"
                          ? "Kapishthalshakha"
                          : lang == "hindi"
                          ? "कपिष्ठलशाखा "
                          : "कपिष्ठलशाखा "}
                      </option>
                      <option value="Kouthumshakha ">
                        {lang == "english"
                          ? "Kouthumshakha"
                          : lang == "hindi"
                          ? "कौथुमशाखा "
                          : "कौथुमशाखा "}
                      </option>
                      <option value="Ranayaniyashakha ">
                        {lang == "english"
                          ? "Ranayaniyashakha"
                          : lang == "hindi"
                          ? "राणायनीयशाखा "
                          : "राणायनीयशाखा "}
                      </option>
                      <option value="Jaiminiyashakha ">
                        {lang == "english"
                          ? "Jaiminiyashakha"
                          : lang == "hindi"
                          ? "जैमिनीयशाखा "
                          : "जैमिनीयशाखा "}
                      </option>
                      <option value="Shounkashakha ">
                        {lang == "english"
                          ? "Shounkashakha"
                          : lang == "hindi"
                          ? "शौनकशाखा "
                          : "शौनकशाखा "}
                      </option>
                      <option value="Paippaladshakha ">
                        {lang == "english"
                          ? "Paippaladshakha"
                          : lang == "hindi"
                          ? "पैप्पलादशाखा "
                          : "पैप्पलादशाखा"}
                      </option>
                      <option value="Anyashakha">
                        {lang == "english"
                          ? "Anyashakha "
                          : lang == "hindi"
                          ? "अन्यशाखा"
                          : "अन्यशाखा "}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors?.vt_left_the_vedvidyalaya_details &&
                    formik.errors?.vt_left_the_vedvidyalaya_details[index]
                      ?.vedshakha ? (
                      <div className="text-danger">
                        {formik.errors?.vt_left_the_vedvidyalaya_details[index]
                          .vedshakha &&
                          formik.errors?.vt_left_the_vedvidyalaya_details[index]
                            .vedshakha}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      id={`vt_left_the_vedvidyalaya_details[${index}].when_started`}
                      name={`vt_left_the_vedvidyalaya_details[${index}].when_started`}
                      value={
                        formik.values.vt_left_the_vedvidyalaya_details[index]
                          .when_started || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_left_the_vedvidyalaya_details[${index}].when_started`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_left_the_vedvidyalaya_details &&
                    formik.errors?.vt_left_the_vedvidyalaya_details[index]
                      ?.when_started ? (
                      <div className="text-danger">
                        {formik.errors?.vt_left_the_vedvidyalaya_details[index]
                          .when_started &&
                          formik.errors?.vt_left_the_vedvidyalaya_details[index]
                            .when_started}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      id={`vt_left_the_vedvidyalaya_details[${index}].when_end`}
                      name={`vt_left_the_vedvidyalaya_details[${index}].when_end`}
                      value={
                        formik.values.vt_left_the_vedvidyalaya_details[index]
                          .when_end || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_left_the_vedvidyalaya_details[${index}].when_end`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_left_the_vedvidyalaya_details &&
                    formik.errors?.vt_left_the_vedvidyalaya_details[index]
                      ?.when_end ? (
                      <div className="text-danger">
                        {formik.errors?.vt_left_the_vedvidyalaya_details[index]
                          .when_end &&
                          formik.errors?.vt_left_the_vedvidyalaya_details[index]
                            .when_end}
                      </div>
                    ) : null}
                  </td>

                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_left_the_vedvidyalaya_details[${index}].reason_for_leaving`}
                      name={`vt_left_the_vedvidyalaya_details[${index}].reason_for_leaving`}
                      value={
                        formik.values.vt_left_the_vedvidyalaya_details[index]
                          .reason_for_leaving || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_left_the_vedvidyalaya_details[${index}].reason_for_leaving`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_left_the_vedvidyalaya_details &&
                    formik.errors?.vt_left_the_vedvidyalaya_details[index]
                      ?.reason_for_leaving ? (
                      <div className="text-danger">
                        {formik.errors?.vt_left_the_vedvidyalaya_details[index]
                          .reason_for_leaving &&
                          formik.errors?.vt_left_the_vedvidyalaya_details[index]
                            .reason_for_leaving}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values.vt_left_the_vedvidyalaya_details.length >=
                    2 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => removeLast5Row(index, e)}
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              )
            )}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="8">
                <button
                  className="btn btn-outline-danger"
                  onClick={(e) => addLast5Row(e)}
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                    ? "जोड़ें"
                    : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <></>
      )}
      <div className="form-group">
        <label>
          {lang == "english"
            ? "Names of former vedavidyarthi who are currently full time as. Attach Annexure-6?"
            : lang == "hindi"
            ? "पूर्व वेदविद्यार्थीयों के नाम जो वर्तमान में पूर्णकालिक वेदाध्यापक हैं. अनुबन्ध-६ संलग्न करें?"
            : "पूर्व वेदविद्यार्थिनां नामानि ये सम्प्रति पूर्णकालिकाः वेदाध्यापकाः सन्ति. अनुबन्धम्-६ संलग्नं करोतु?"}
        </label>
        <select
          className="form-control col-4"
          id="vt_former_vedavidyarthi"
          name="vt_former_vedavidyarthi"
          onChange={(e) => {
            handleInputChange("vt_former_vedavidyarthi", e);
            if (e.target.value == "yes") {
              formik.setFieldValue(`vt_former_vedavidyarthi_details`, [
                {
                  student_name: "",
                  vedadgyapan_finish: "",
                  vedshakha: "",
                  institution_begin_vedadhyapan: "",
                },
              ]);
            }
            if (e.target.value == "no") {
              formik.setFieldValue(`vt_former_vedavidyarthi_details`, [
                {
                  student_name: "N/A",
                  vedadgyapan_finish: "N/A",
                  vedshakha: "N/A",
                  institution_begin_vedadhyapan: "N/A",
                },
              ]);
            }
          }}
          onBlur={(e) => handleInputSave()}
          value={formik.values.vt_former_vedavidyarthi}
        >
          <option className="mb-5px">
            ---
            {lang == "english" ? "Select" : lang == "hindi" ? "चुने" : "चिनोतु"}
            ---
          </option>
          <option value="yes">
            {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
          </option>
          <option value="no">
            {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
          </option>
        </select>
        {formik.touched && formik.errors?.vt_former_vedavidyarthi ? (
          <div className="text-danger">
            {formik.errors?.vt_former_vedavidyarthi &&
              formik.errors?.vt_former_vedavidyarthi}
          </div>
        ) : null}
      </div>

      {formik.values.vt_former_vedavidyarthi == "yes" ? (
        <div className="table-responsive form-group">
          <table className="table">
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                  ? "क्रम संख्या "
                  : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "Vedavidyarthi name"
                  : lang == "hindi"
                  ? "वेदविद्यार्थी का नाम"
                  : "वेदविद्यार्थिनः नाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Year of Vedadgyapan finish"
                  : lang == "hindi"
                  ? "वेदाध्यापन समाप्ति का वर्ष "
                  : "वेदाध्यापनसमाप्तेः वर्षम् "}
              </th>
              <th>
                {lang == "english"
                  ? "Vedashakha"
                  : lang == "hindi"
                  ? "वेदशाखा "
                  : "वेदशाखा "}
              </th>
              <th>
                {lang == "english"
                  ? "Institution where is being vedadhyapan done today"
                  : lang == "hindi"
                  ? "संस्था जहाँ आज अध्यापन कर रहे हैं "
                  : "संस्था यत्र अधुना अध्यापयति "}
              </th>
              <th width="120">
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                  ? "क्रिया"
                  : "क्रिया"}
              </th>
            </tr>
            {formik.values.vt_former_vedavidyarthi_details?.map(
              (row, index) => (
                <tr key={index}>
                  <td>{1 + index}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_former_vedavidyarthi_details[${index}].student_name`}
                      name={`vt_former_vedavidyarthi_details[${index}].student_name`}
                      value={
                        formik.values.vt_former_vedavidyarthi_details[index]
                          .student_name || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_former_vedavidyarthi_details[${index}].student_name`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_former_vedavidyarthi_details &&
                    formik.errors?.vt_former_vedavidyarthi_details[index]
                      ?.student_name ? (
                      <div className="text-danger">
                        {formik.errors?.vt_former_vedavidyarthi_details[index]
                          .student_name &&
                          formik.errors?.vt_former_vedavidyarthi_details[index]
                            .student_name}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      id={`vt_former_vedavidyarthi_details[${index}].vedadgyapan_finish`}
                      name={`vt_former_vedavidyarthi_details[${index}].vedadgyapan_finish`}
                      value={
                        formik.values.vt_former_vedavidyarthi_details[index]
                          .vedadgyapan_finish || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_former_vedavidyarthi_details[${index}].vedadgyapan_finish`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_former_vedavidyarthi_details &&
                    formik.errors?.vt_former_vedavidyarthi_details[index]
                      ?.vedadgyapan_finish ? (
                      <div className="text-danger">
                        {formik.errors?.vt_former_vedavidyarthi_details[index]
                          .vedadgyapan_finish &&
                          formik.errors?.vt_former_vedavidyarthi_details[index]
                            .vedadgyapan_finish}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <select
                      className="form-control"
                      id={`vt_former_vedavidyarthi_details[${index}].vedshakha`}
                      name={`vt_former_vedavidyarthi_details[${index}].vedshakha`}
                      value={
                        formik.values.vt_former_vedavidyarthi_details[index]
                          .vedshakha || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_former_vedavidyarthi_details[${index}].vedshakha`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    >
                      <option>
                        ---
                        {lang == "english"
                          ? "Select"
                          : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                        ---
                      </option>
                      <option value="Shakalshakha">
                        {lang == "english"
                          ? "Shakalshakha"
                          : lang == "hindi"
                          ? "शाकलशाखा "
                          : "शाकलशाखा "}
                      </option>
                      <option value="Ashvalayanshakha">
                        {lang == "english"
                          ? "Ashvalayanshakha"
                          : lang == "hindi"
                          ? "आश्वलायनशाखा "
                          : "आश्वलायनशाखा "}
                      </option>
                      <option value="Bashkalshakha">
                        {lang == "english"
                          ? "Bashkalshakha"
                          : lang == "hindi"
                          ? "बाष्कलशाखा "
                          : "बाष्कलशाखा "}
                      </option>
                      <option value="Shankhayanshakha">
                        {lang == "english"
                          ? "Shankhayanshakha"
                          : lang == "hindi"
                          ? "शांखायनशाखा "
                          : "शाङ्खायनशाखा "}
                      </option>
                      <option value="Kanvashakha ">
                        {lang == "english"
                          ? "Kanvashakha"
                          : lang == "hindi"
                          ? "काण्वशाखा "
                          : "काण्वशाखा "}
                      </option>
                      <option value="Madhyandinshakha">
                        {lang == "english"
                          ? "Madhyandinshakha"
                          : lang == "hindi"
                          ? "माध्यन्दिनशाखा "
                          : "माध्यन्दिनशाखा "}
                      </option>
                      <option value="Taittiriyashakha ">
                        {lang == "english"
                          ? "Taittiriyashakha"
                          : lang == "hindi"
                          ? "तैत्तिरीयशाखा "
                          : "तैत्तिरीयशाखा "}
                      </option>
                      <option value="Maitrayanishakha ">
                        {lang == "english"
                          ? "Maitrayanishakha"
                          : lang == "hindi"
                          ? "मैत्रायणीशाखा "
                          : "मैत्रायणीशाखा "}
                      </option>
                      <option value="Kathashakha ">
                        {lang == "english"
                          ? "Kathashakha"
                          : lang == "hindi"
                          ? "कठशाखा "
                          : "कठशाखा "}
                      </option>
                      <option value="Kapishthalshakha ">
                        {lang == "english"
                          ? "Kapishthalshakha"
                          : lang == "hindi"
                          ? "कपिष्ठलशाखा "
                          : "कपिष्ठलशाखा "}
                      </option>
                      <option value="Kouthumshakha ">
                        {lang == "english"
                          ? "Kouthumshakha"
                          : lang == "hindi"
                          ? "कौथुमशाखा "
                          : "कौथुमशाखा "}
                      </option>
                      <option value="Ranayaniyashakha ">
                        {lang == "english"
                          ? "Ranayaniyashakha"
                          : lang == "hindi"
                          ? "राणायनीयशाखा "
                          : "राणायनीयशाखा "}
                      </option>
                      <option value="Jaiminiyashakha ">
                        {lang == "english"
                          ? "Jaiminiyashakha"
                          : lang == "hindi"
                          ? "जैमिनीयशाखा "
                          : "जैमिनीयशाखा "}
                      </option>
                      <option value="Shounkashakha ">
                        {lang == "english"
                          ? "Shounkashakha"
                          : lang == "hindi"
                          ? "शौनकशाखा"
                          : "शौनकशाखा"}
                      </option>
                      <option value="Paippaladshakha">
                        {lang == "english"
                          ? "Paippaladshakha"
                          : lang == "hindi"
                          ? "पैप्पलादशाखा"
                          : "पैप्पलादशाखा"}
                      </option>
                      <option value="Anyashakha">
                        {lang == "english"
                          ? "Anyashakha "
                          : lang == "hindi"
                          ? "अन्यशाखा"
                          : "अन्यशाखा "}
                      </option>
                    </select>
                    {formik.touched &&
                    formik.errors?.vt_former_vedavidyarthi_details &&
                    formik.errors?.vt_former_vedavidyarthi_details[index]
                      ?.vedshakha ? (
                      <div className="text-danger">
                        {formik.errors?.vt_former_vedavidyarthi_details[index]
                          .vedshakha &&
                          formik.errors?.vt_former_vedavidyarthi_details[index]
                            .vedshakha}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_former_vedavidyarthi_details[${index}].institution_begin_vedadhyapan`}
                      name={`vt_former_vedavidyarthi_details[${index}].institution_begin_vedadhyapan`}
                      value={
                        formik.values.vt_former_vedavidyarthi_details[index]
                          .institution_begin_vedadhyapan || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_former_vedavidyarthi_details[${index}].institution_begin_vedadhyapan`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_former_vedavidyarthi_details &&
                    formik.errors?.vt_former_vedavidyarthi_details[index]
                      ?.institution_begin_vedadhyapan ? (
                      <div className="text-danger">
                        {formik.errors?.vt_former_vedavidyarthi_details[index]
                          .institution_begin_vedadhyapan &&
                          formik.errors?.vt_former_vedavidyarthi_details[index]
                            .institution_begin_vedadhyapan}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values.vt_former_vedavidyarthi_details.length >=
                    2 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => removeVedadhyapakasRow(index, e)}
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              )
            )}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="7">
                <button
                  className="btn btn-outline-danger"
                  onClick={(e) => addVedadhyapakasRow(e)}
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                    ? "जोड़ें"
                    : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <></>
      )}

      <div className="form-group">
        <label>
          {lang == "english"
            ? "List of awards / honours received by the vedaadhyapaka of the vedavidyalay. Attach Annexure-7?"
            : lang == "hindi"
            ? "विद्यालय के अध्यापकों द्वारा प्राप्त पुरस्कार / सम्मानों की सूची. अनुबन्ध-७ संलग्न करें?"
            : "विद्यालयस्य शिक्षकैः प्राप्तानां पुरस्काराणां/ सम्मानानां सूची. अनुबन्धम्-७ संलग्नं करोतु?"}
        </label>
        <select
          className="form-control col-4"
          id="vt_list_of_awards_receive_by_teacher"
          name="vt_list_of_awards_receive_by_teacher"
          onChange={(e) => {
            handleInputChange("vt_list_of_awards_receive_by_teacher", e);
            if (e.target.value == "yes") {
              formik.setFieldValue(
                `vt_list_of_awards_receive_by_teacher_details`,
                [
                  {
                    teacher_name: "",
                    name_of_award: "",
                    name_of_institution: "",
                    year: "",
                    attached_document_string: "",
                  },
                ]
              );
            }
            if (e.target.value == "no") {
              formik.setFieldValue(
                `vt_list_of_awards_receive_by_teacher_details`,
                [
                  {
                    teacher_name: "N/A",
                    name_of_award: "N/A",
                    name_of_institution: "N/A",
                    year: "N/A",
                    attached_document_string: "N/A",
                  },
                ]
              );
            }
          }}
          onBlur={(e) => handleInputSave()}
          value={formik.values.vt_list_of_awards_receive_by_teacher}
        >
          <option className="mb-5px">
            ---
            {lang == "english" ? "Select" : lang == "hindi" ? "चुने" : "चिनोतु"}
            ---
          </option>
          <option value="yes">
            {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
          </option>
          <option value="no">
            {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
          </option>
        </select>
        {formik.touched &&
        formik.errors?.vt_list_of_awards_receive_by_teacher ? (
          <div className="text-danger">
            {formik.errors?.vt_list_of_awards_receive_by_teacher &&
              formik.errors?.vt_list_of_awards_receive_by_teacher}
          </div>
        ) : null}
      </div>

      {formik.values.vt_list_of_awards_receive_by_teacher == "yes" ? (
        <div className="table-responsive form-group">
          <table className="table">
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                  ? "क्रम संख्या "
                  : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "vedaadhyapaka name"
                  : lang == "hindi"
                  ? "अध्यापक का नाम"
                  : "अध्यापकस्य नाम"}
              </th>
              <th>
                {lang == "english"
                  ? "Name of the award / honor"
                  : lang == "hindi"
                  ? "पुरस्कार अथवा सम्मान का नाम "
                  : "पुरस्कारस्य सम्मानस्य वा नाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Name of the institution"
                  : lang == "hindi"
                  ? "संस्था का नाम  "
                  : "संस्थानाम  "}
              </th>
              <th>
                {lang == "english"
                  ? "Year"
                  : lang == "hindi"
                  ? "वर्ष "
                  : "वर्षम् "}
              </th>
              <th>
                {lang == "english"
                  ? "Attach certificate"
                  : lang == "hindi"
                  ? "प्रमाण पत्र अपलोड करे "
                  : "प्रमाणपत्रम् अपलोड इति करोतु"}
              </th>
              <th width="120">
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                  ? "क्रिया"
                  : "क्रिया"}
              </th>
            </tr>
            {formik.values.vt_list_of_awards_receive_by_teacher_details?.map(
              (row, index) => (
                <tr key={index}>
                  <td>{1 + index}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_list_of_awards_receive_by_teacher_details[${index}].teacher_name`}
                      name={`vt_list_of_awards_receive_by_teacher_details[${index}].teacher_name`}
                      value={
                        formik.values
                          .vt_list_of_awards_receive_by_teacher_details[index]
                          .teacher_name || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_awards_receive_by_teacher_details[${index}].teacher_name`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_awards_receive_by_teacher_details &&
                    formik.errors?.vt_list_of_awards_receive_by_teacher_details[
                      index
                    ]?.teacher_name ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_awards_receive_by_teacher_details[index]
                          .teacher_name &&
                          formik.errors
                            ?.vt_list_of_awards_receive_by_teacher_details[
                            index
                          ].teacher_name}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_list_of_awards_receive_by_teacher_details[${index}].name_of_award`}
                      name={`vt_list_of_awards_receive_by_teacher_details[${index}].name_of_award`}
                      value={
                        formik.values
                          .vt_list_of_awards_receive_by_teacher_details[index]
                          .name_of_award || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_awards_receive_by_teacher_details[${index}].name_of_award`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_awards_receive_by_teacher_details &&
                    formik.errors?.vt_list_of_awards_receive_by_teacher_details[
                      index
                    ]?.name_of_award ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_awards_receive_by_teacher_details[index]
                          .name_of_award &&
                          formik.errors
                            ?.vt_list_of_awards_receive_by_teacher_details[
                            index
                          ].name_of_award}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_list_of_awards_receive_by_teacher_details[${index}].name_of_institution`}
                      name={`vt_list_of_awards_receive_by_teacher_details[${index}].name_of_institution`}
                      value={
                        formik.values
                          .vt_list_of_awards_receive_by_teacher_details[index]
                          .name_of_institution || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_list_of_awards_receive_by_teacher_details[${index}].name_of_institution`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_awards_receive_by_teacher_details &&
                    formik.errors?.vt_list_of_awards_receive_by_teacher_details[
                      index
                    ]?.name_of_institution ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_awards_receive_by_teacher_details[index]
                          .name_of_institution &&
                          formik.errors
                            ?.vt_list_of_awards_receive_by_teacher_details[
                            index
                          ].name_of_institution}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id={`vt_list_of_awards_receive_by_teacher_details[${index}].year`}
                      name={`vt_list_of_awards_receive_by_teacher_details[${index}].year`}
                      value={
                        formik.values
                          .vt_list_of_awards_receive_by_teacher_details[index]
                          .year || ""
                      }
                      onChange={(e) => {
                        if (e.target.value <= 2025) {
                          handleInputChange(
                            `vt_list_of_awards_receive_by_teacher_details[${index}].year`,
                            e
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors
                      ?.vt_list_of_awards_receive_by_teacher_details &&
                    formik.errors?.vt_list_of_awards_receive_by_teacher_details[
                      index
                    ]?.year ? (
                      <div className="text-danger">
                        {formik.errors
                          ?.vt_list_of_awards_receive_by_teacher_details[index]
                          .year &&
                          formik.errors
                            ?.vt_list_of_awards_receive_by_teacher_details[
                            index
                          ].year}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      name={`vt_list_of_awards_receive_by_teacher_details[${index}].attached_document`}
                      id={`vt_list_of_awards_receive_by_teacher_details[${index}].attached_document`}
                      className="form-control"
                      onChange={async (e) => {
                        if (e.target.files.length > 0) {
                          await fileUploadFunction(
                            `vt_list_of_awards_receive_by_teacher_details[${index}].attached_document_string`,
                            e.target.files[0]
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik?.values
                      ?.vt_list_of_awards_receive_by_teacher_details[index]
                      ?.attached_document_string && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.vt_list_of_awards_receive_by_teacher_details[index].attached_document_string}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik?.values?.vt_list_of_awards_receive_by_teacher_details[
                          index
                        ].attached_document_string.replace(
                          "upload-file/",
                          ""
                        )}`}
                      </button>
                    )}
                    {formik.touched &&
                    formik.errors?.vt_list_of_awards_receive_by_teacher &&
                    formik.errors?.vt_list_of_awards_receive_by_teacher[index]
                      ?.attached_document_string ? (
                      <div className="text-danger">
                        {formik.errors?.vt_list_of_awards_receive_by_teacher[
                          index
                        ].attached_document_string &&
                          formik.errors?.vt_list_of_awards_receive_by_teacher[
                            index
                          ].attached_document_string}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values.vt_list_of_awards_receive_by_teacher_details
                      .length >= 2 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => {
                          removeVedvidyalayRow(index, e);
                        }}
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              )
            )}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="7">
                {" "}
                <button
                  className="btn btn-outline-danger"
                  onClick={(e) => addVedvidyalayRow(e)}
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                    ? "जोड़ें"
                    : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <></>
      )}

      <label>
        {lang == "english"
          ? "Give details of the daily routine of vedadhyapaks"
          : lang == "hindi"
          ? "अध्यापकों की दिनचर्या का विवरण दीजिए"
          : "आचार्याणां दैनन्दिनकार्यक्रमस्य विवरणं ददातु"}
      </label>
      <div className="form-group">
        <textarea
          className="form-control"
          rows={2}
          maxLength={700}
          id="vt_daily_routine_of_the_vedadhyapaks"
          name="vt_daily_routine_of_the_vedadhyapaks"
          onChange={(e) =>
            handleInputChange("vt_daily_routine_of_the_vedadhyapaks", e)
          }
          onBlur={(e) => handleInputSave()}
          value={formik.values.vt_daily_routine_of_the_vedadhyapaks}
        ></textarea>
        {formik.touched &&
        formik.errors?.vt_daily_routine_of_the_vedadhyapaks ? (
          <div className="text-danger">
            {formik.errors?.vt_daily_routine_of_the_vedadhyapaks &&
              formik.errors?.vt_daily_routine_of_the_vedadhyapaks}
          </div>
        ) : null}
        <p className=" text-right">
          {formik.values.vt_daily_routine_of_the_vedadhyapaks?.length}/700
          {lang == "english"
            ? " Characters"
            : lang == "hindi"
            ? " शब्द"
            : " शब्दः"}
        </p>
      </div>

      <label>
        {lang == "english"
          ? "Give details of the efforts being made by the vedaadhyapaka for the study / teaching of rare vedshakha or higher vedgyan"
          : lang == "hindi"
          ? "अध्यापकों द्वारा दुर्लभ वेदशाखा या उच्च वेदज्ञान के अध्ययनाध्यापन हेतु किये जा रहे प्रयासों का विवरण दीजिए "
          : "दुर्लभवेदशाखायाः उच्चवेदज्ञानस्य वा अध्ययनाध्यापनार्थम् आचार्यैः क्रियमाणानां प्रयत्नानां विवरणं ददातु "}
      </label>
      <div className="form-group">
        <textarea
          className="form-control"
          rows={2}
          maxLength={700}
          id="vt_efforts_being_made_by_the_teacher"
          name="vt_efforts_being_made_by_the_teacher"
          onChange={(e) =>
            handleInputChange("vt_efforts_being_made_by_the_teacher", e)
          }
          onBlur={(e) => handleInputSave()}
          value={formik.values.vt_efforts_being_made_by_the_teacher}
        ></textarea>
        {formik.touched &&
        formik.errors?.vt_efforts_being_made_by_the_teacher ? (
          <div className="text-danger">
            {formik.errors?.vt_efforts_being_made_by_the_teacher &&
              formik.errors?.vt_efforts_being_made_by_the_teacher}
          </div>
        ) : null}
        <p className="text-right">
          {formik.values.vt_efforts_being_made_by_the_teacher?.length}/700
          {lang == "english"
            ? " Characters"
            : lang == "hindi"
            ? " शब्द"
            : " शब्दः"}
        </p>
      </div>
    </div>
  );
}

export default Section_3;
