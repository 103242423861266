import React, { useState, useEffect, useContext } from "react";
import Section_1 from "../../Components/TeacherForm/Section_1.js";

import Section_2 from "../../Components/TeacherForm/Section_2";
import Section_3 from "../../Components/TeacherForm/Section_3";
import Section_4 from "../../Components/TeacherForm/Section_4";
import Section_5 from "../../Components/TeacherForm/Section_5";
import Section_6 from "../../Components/TeacherForm/Section_6";
import Section_7 from "../../Components/TeacherForm/Section_7";
import Section_8 from "../../Components/TeacherForm/Section_8";
import { FaCheck, FaDotCircle, FaExclamation } from "react-icons/fa";
import * as Yup from "yup";
import { useFormik } from "formik";
import validationRules from "./validations.js";
import { useLocation, useNavigate } from "react-router-dom";
import axiosPrivate from "../../Hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../Utils/Toaster";
import piFields from "./p.js";
import viFields from "./vi.js";
import oiFields from "./oi.js";
import vlFields from "./vl.js";
import alFields from "./al.js";
import adFields from "./ad.js";
import vtFields from "./vt.js";
import { uploadFile } from "../../Utils/fileUpload";
import Loader from "../../context/Loader/Loader.js";
import LoaderContext from "../../context/Loader/LoaderContext.js";

function TeacherFormPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
  });

  const { loaderCheck, setLoaderCheck } = useContext(LoaderContext);
  // Access state data passed from the previous page
  let { lang, user_type, apply_type, oldData } = location.state || {};

  let events = [];
  if (lang === "english") {
    events = [
      "Personal info",
      "Vedic info",
      "Ved teaching",
      "Ved and literature",
      "Other info",
      "Affidavit",
      "Artical desc.",
      "Preview",
    ];
  } else if (lang === "hindi") {
    events = [
      "व्यक्तिगत जानकारी",
      "वैदिक जानकारी",
      "वैदिक शिक्षण",
      "वेद व साहित्य",
      "अन्य जानकारी",
      "शपथपत्र",
      "आवेदन विवरण",
      "पूर्वावलोकन",
    ];
  } else {
    events = [
      "व्यक्तिगतसूचना",
      "वैदिक सूचना",
      "वेद अध्यापनम्",
      "वेद तथा साहित्य",
      "अन्ये सूचना",
      "शपथपत्रम्",
      "आवेदन विवरण",
      "पूर्वावलोकनं",
    ];
  }

  const [formData, setFormData] = useState({});
  const [completionStatus, setCompletionStatus] = useState(
    Array(8).fill(false)
  );
  const [currStep, setCurrStep] = useState(1);
  const [id, setId] = useState("");
  const [newValidationRules, setNewValidationsRules] =
    useState(validationRules);

  const handleSaveandNext = (e) => {
    e.preventDefault();

    setCompletionStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[currStep - 1] = true;
      return newStatus;
    });
    setCurrStep((prevStep) => (prevStep < 11 ? prevStep + 1 : prevStep));
    handleInputSave();
    // Handle validation errors
  };

  const handleBack = (e) => {
    e.preventDefault();
    setCurrStep((prevStep) => (prevStep !== 1 ? prevStep - 1 : prevStep));
    handleInputSave();
  };

  /* 
  New code by Lakhan
  */

  /* Formik Start */
  const formik = useFormik({
    initialValues: {
      // Personal Information Fields
      ...piFields,

      // Vedic Information Fields
      ...viFields,

      // Veda Teaching Fields
      ...vtFields,

      // Other Information Fields
      ...oiFields,

      // Veda Literature Fields
      ...vlFields,

      // Affidavit Fields
      ...adFields,

      // Artical Description Fields
      ...alFields,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object(newValidationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      // Handle form submission
      let userData = localStorage.getItem("userData");
      userData = userData ? JSON.parse(userData) : {};
      if (userData?.userId) {
        try {
          const formData = {
            id: id ? id : "",
            user_id: apply_type == "yourself" ? userData.userId : "",
            filled_by_id: apply_type == "other" ? userData.userId : "",
            user_option: apply_type,
            type: user_type,
            current_page: currStep,
            answerdata: formik.values,
            language: lang,
            status: "Pending",
            remarks: "",
            progress: pageStatus,
          };
          const response = await axiosPrivate.post(
            "user-answer-data",
            formData
          );
          if (response.status == 200) {
            showSuccessToast("Your form is submitted successfully");
            setId(response.data.data);
            navigate("/home");
          } else {
            showErrorToast(response.data.message);
          }
        } catch (error) {
          showErrorToast(error?.response?.data?.errors[0]?.msg);
        }
      }
    },
  });
  /* Formik End */

  // location function starts
  const getLocation = async (field, stateField, cityField) => {
    try {
      const locationData = await axiosPrivate(`locations/${field}`);
      const location = locationData.data;
      formik?.setFieldValue(stateField, location?.location?.data[0]?.state);
      formik?.setFieldValue(cityField, location?.location?.data[0]?.city);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let timer;
    if (formik?.values.p_full_correspondence_pincode) {
      timer = setTimeout(() => {
        getLocation(
          formik?.values.p_full_correspondence_pincode,
          "p_full_correspondence_state",
          "p_full_correspondence_district"
        );
      }, 600);
    }
    if (formik?.values?.vt_pincode_present_organization) {
      timer = setTimeout(() => {
        getLocation(
          formik?.values?.vt_pincode_present_organization,
          "vt_state_present_organization",
          "vt_district_present_organization"
        );
      }, 600);
    }

    return () => clearTimeout(timer);
  }, [
    formik?.values.p_full_correspondence_pincode,
    formik?.values?.vt_pincode_present_organization,
  ]);

  useEffect(() => {
    let timer;
    if (formik?.values?.p_paternal_home_pincode) {
      timer = setTimeout(() => {
        getLocation(
          formik?.values.p_paternal_home_pincode,
          "p_paternal_home_state",
          "p_paternal_home_district"
        );
      }, 600);
    }
    return () => clearTimeout(timer);
  }, [formik?.values.p_paternal_home_pincode]);

  // location function ends

  /* Add Row for brother sister start */
  const addBrotherSisterRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.p_add_son_daughter,
      {
        name: "",
        gender: "",
        age: "",
        veda_education_level: "",
      },
    ];
    formik.setFieldValue("p_add_son_daughter", newRowContent);
    return true;
  };
  /* Add Row for brother sister end */

  /* Remove Row for brother sister start */
  const removeBrotherSisterRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.p_add_son_daughter.length > 1) {
      let newRowContent = formik.values.p_add_son_daughter.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("p_add_son_daughter", newRowContent);
    }

    return true;
  };
  /* Remove Row for brother sister end */

  /*  Add Row examinations Pass row start */
  const addExamPassRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vi_list_of_exam_passed,
      {
        vi_exam_monthAndYear: "",
        vi_name_of_exam: "",
        vi_exam_body: "",
        vi_exam_certificate: "",
      },
    ];
    formik.setFieldValue("vi_list_of_exam_passed", newRowContent);
    return true;
  };
  /*  Add Row examinations Pass row end */

  /*  Remove Row examinations Pass row end */
  const removeExamPassRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vi_list_of_exam_passed.length > 1) {
      let newRowContent = formik.values.vi_list_of_exam_passed.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vi_list_of_exam_passed", newRowContent);
    }

    return true;
  };
  /*  Remove Row examinations Pass row end */

  /*  Add Row for vedadhyapaka start */
  const addVedadhyapakaRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vi_list_teacher_entries,
      {
        vi_teacher_name: "",
        vi_exam_name: "",
        vi_gurukul_name: "",
      },
    ];
    formik.setFieldValue("vi_list_teacher_entries", newRowContent);
    return true;
  };
  /*  Add Row for vedadhyapaka end */

  /*  Remove Row for vedadhyapaka start */
  const removeVedadhyapakaRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vi_list_teacher_entries.length > 1) {
      let newRowContent = formik.values.vi_list_teacher_entries.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vi_list_teacher_entries", newRowContent);
    }

    return true;
  };
  /*  Remove Row for vedadhyapaka end */

  /* Add Row studied_veda_details start */
  const addStudiedVedaDetailsRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vi_list_studied_veda_details,
      {
        vi_subject: "",
        vi_name_of_granth: "",
        vi_year: "",
        vi_exam_body: "",
        vi_exam_certificate: "",
      },
    ];
    formik.setFieldValue("vi_list_studied_veda_details", newRowContent);
    return true;
  };
  /* Add Row studied_veda_details end */

  /* Remove Row studied_veda_details start */

  const removeStudiedVedaDetailsRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vi_list_studied_veda_details.length > 1) {
      let newRowContent = formik.values.vi_list_studied_veda_details.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vi_list_studied_veda_details", newRowContent);
    }

    return true;
  };
  /* Remove Row studied_veda_details end */

  /* Add Row Ved including literature start  */
  const addVedLiteratureRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vl_list_of_attendance_in_veda_sabha_table,
      {
        meeting_or_conference: "",
        organizer: "",
        year: "",
        certificate: "",
      },
    ];
    formik.setFieldValue(
      "vl_list_of_attendance_in_veda_sabha_table",
      newRowContent
    );
    return true;
  };
  /* Add Ved including literature end  */

  /* Remove Row Ved including literature start  */
  const removeVedLiteratureRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vl_list_of_attendance_in_veda_sabha_table.length > 1) {
      let newRowContent =
        formik.values.vl_list_of_attendance_in_veda_sabha_table.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vl_list_of_attendance_in_veda_sabha_table",
        newRowContent
      );
    }

    return true;
  };
  /* Remove Row Ved including literature start  */

  /* Add Row Vedic sShrauta start */
  const addVedicSShrautaRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vl_have_you_participated_in_vedic_shrauta_yajna_table,
      {
        yajna: "",
        the_host: "",
        year: "",
        certificate: "",
      },
    ];
    formik.setFieldValue(
      "vl_have_you_participated_in_vedic_shrauta_yajna_table",
      newRowContent
    );
    return true;
  };
  /* Add Row Vedic sShrauta start */

  /* Remove Row Vedic sShrauta start */
  const removeVedicSShrautaRow = (index, eve) => {
    eve.preventDefault();
    if (
      formik.values.vl_have_you_participated_in_vedic_shrauta_yajna_table
        .length > 1
    ) {
      let newRowContent =
        formik.values.vl_have_you_participated_in_vedic_shrauta_yajna_table.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vl_have_you_participated_in_vedic_shrauta_yajna_table",
        newRowContent
      );
    }

    return true;
  };

  /* Add Row Relative start */
  const addRelativeRow = (eve) => {
    eve.preventDefault();
    const newRow = {
      vi_serial_number: "",
      vi_relation: "",
      vi_other_relative: "",
    };
    formik.setFieldValue("vi_list_close_relative_teacher", [
      ...formik.values.vi_list_close_relative_teacher,
      newRow,
    ]);
  };

  /* Remove Row Relative start */
  const removeRelativeRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vi_list_close_relative_teacher.length > 1) {
      const updatedList = formik.values.vi_list_close_relative_teacher.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vi_list_close_relative_teacher", updatedList);
    }
  };

  /* Add Row testimonials and awards start */
  const addTestimonialsAndAwardsRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vl_information_on_testimonials_and_awards_received_table,
      {
        award_name: "",
        who_gave_it: "",
        year: "",
        certificate: "",
      },
    ];
    formik.setFieldValue(
      "vl_information_on_testimonials_and_awards_received_table",
      newRowContent
    );
    return true;
  };
  /* Add Row testimonials and awards end */

  /* Remove Row testimonials and awards end */
  const removeTestimonialsAndAwardsRow = (index, eve) => {
    eve.preventDefault();
    if (
      formik.values.vl_information_on_testimonials_and_awards_received_table
        .length > 1
    ) {
      let newRowContent =
        formik.values.vl_information_on_testimonials_and_awards_received_table.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vl_information_on_testimonials_and_awards_received_table",
        newRowContent
      );
    }

    return true;
  };
  /* Remove Row testimonials and awards end */

  /* Add Row research papers start */
  const addResearchPapersRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vl_information_about_books_table,
      {
        topic: "",
        publisherISBN: "",
        year: "",
        certificate: "",
      },
    ];
    formik.setFieldValue("vl_information_about_books_table", newRowContent);
    return true;
  };
  /* Add Row research papers end */

  /* Remove Row research papers end */
  const removeResearchPapersRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vl_information_about_books_table.length > 1) {
      let newRowContent = formik.values.vl_information_about_books_table.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vl_information_about_books_table", newRowContent);
    }

    return true;
  };
  /* Remove Row research papers end */

  /* Add Row articles attached start */
  const addArticlesAttachedRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.al_description_of_articles,
      {
        details_of_document: "",
        attach_file: "",
      },
    ];
    formik.setFieldValue("al_description_of_articles", newRowContent);
    return true;
  };
  /* Add Row articles attached start */

  /* Remove Row articles attached start */
  const removeArticlesAttachedRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.al_description_of_articles.length > 1) {
      let newRowContent = formik.values.al_description_of_articles.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("al_description_of_articles", newRowContent);
    }

    return true;
  };
  /* Remove Row articles attached start */

  /* Add Row veda teaching start */
  const addVedaTeachingRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_veda_teaching,
      {
        from_month_year: "",
        till_month_year: "",
        name_vedavidyalaya: "",
        location_and_state: "",
      },
    ];
    formik.setFieldValue("vt_veda_teaching", newRowContent);
    return true;
  };
  /* Add Row veda teaching end */

  /* Remove Row veda teaching end */
  const removeVedaTeachingRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vt_veda_teaching.length > 1) {
      let newRowContent = formik.values.vt_veda_teaching.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vt_veda_teaching", newRowContent);
    }

    return true;
  };
  /* Remove Row veda teaching end */

  /* Add Row attendance in veda sabhas table start */
  const addAttendanceInVedaSabhasRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_attendance_in_veda_sabhas_table,
      {
        student_name: "",
        study: "",
        name_examination_body: "",
        year: "",
        certificate: "",
      },
    ];
    formik.setFieldValue("vt_attendance_in_veda_sabhas_table", newRowContent);
    return true;
  };
  /* Add Row attendance in veda sabhas table end */

  /* Remove Row attendance in veda sabhas table start */
  const removeAttendanceInVedaSabhasRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vt_attendance_in_veda_sabhas_table.length > 1) {
      let newRowContent =
        formik.values.vt_attendance_in_veda_sabhas_table.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue("vt_attendance_in_veda_sabhas_table", newRowContent);
    }

    return true;
  };
  /* Remove Row attendance in veda sabhas table end */

  /* Add Row veda_students_currently_being_taught_by_you_table start*/
  const addVedaStudentsCurrentlyBeingTaughtByYouTableRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_veda_students_currently_being_taught_by_you_table,
      {
        student_name: "",
        age: "",
        exam_level: "",
        other: "",
        year: "",
        certificate: "",
      },
    ];
    formik.setFieldValue(
      "vt_veda_students_currently_being_taught_by_you_table",
      newRowContent
    );
    return true;
  };
  /* Add Row veda_students_currently_being_taught_by_you_table end*/

  /* Remove Row veda_students_currently_being_taught_by_you_table end*/
  const removeVedaStudentsCurrentlyBeingTaughtByYouTableRow = (index, eve) => {
    eve.preventDefault();
    if (
      formik.values.vt_veda_students_currently_being_taught_by_you_table
        .length > 1
    ) {
      let newRowContent =
        formik.values.vt_veda_students_currently_being_taught_by_you_table.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vt_veda_students_currently_being_taught_by_you_table",
        newRowContent
      );
    }

    return true;
  };
  /* Remove Row veda_students_currently_being_taught_by_you_table end*/

  /* Add Row vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table start */
  const addVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values
        .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table,
      {
        vedvidyarthi_name: "",
        last_year_of_vedadhyayan: "",
        student_vedshakha: "",
        institution_name: "",
      },
    ];
    formik.setFieldValue(
      "vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table",
      newRowContent
    );
    return true;
  };
  /* Add Row vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table end */

  const vt_family_members_taught_by_youAddRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_family_members_taught_by_you,
      {
        family_member_name: "",
        relationship: "",
        level_of_study: "",
        other: "",
        family_member_occupation: "",
      },
    ];
    formik.setFieldValue("vt_family_members_taught_by_you", newRowContent);
    return true;
  };

  const vt_family_members_taught_by_youRemoveRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vt_family_members_taught_by_you.length > 1) {
      let newRowContent = formik.values.vt_family_members_taught_by_you.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vt_family_members_taught_by_you", newRowContent);
    }

    return true;
  };

  /* Remove Row vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table end */
  const removeVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow = (
    index,
    eve
  ) => {
    eve.preventDefault();
    if (
      formik.values
        .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table.length >
      1
    ) {
      let newRowContent =
        formik.values.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table",
        newRowContent
      );
    }

    return true;
  };
  /* Remove Row vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table end */

  const handleInputChange = async (label, event) => {
    formik.handleChange(event);
    return true;
  };

  const handleInputSave = async () => {
    let userData = localStorage.getItem("userData");
    userData = userData ? JSON.parse(userData) : {};
    if (userData?.userId) {
      try {
        const formData = {
          id: id ? id : "",
          user_id: apply_type == "yourself" ? userData.userId : "",
          filled_by_id: apply_type == "other" ? userData.userId : "",
          user_option: apply_type,
          type: user_type,
          current_page: currStep,
          answerdata: formik.values,
          language: lang,
          status: "In Progress",
          remarks: "",
          progress: pageStatus,
        };
        const response = await axiosPrivate.post("user-answer-data", formData);
        if (response.status === 200 && response.data.data) {
          if (id == "") {
            setId(response.data.data);
          }
        } else {
          showErrorToast(response.data.message);
        }
      } catch (error) {
        showErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    }
  };

  useEffect(() => {
    if (oldData) {
      formik.setValues(
        typeof oldData?.answerdata === "string"
          ? JSON.parse(oldData.answerdata)
          : oldData.answerdata
      );
      setId(oldData.id);
      setCurrStep(oldData.current_page);
    }
  }, [oldData]);

  useEffect(() => {
    async function checkOldPage() {
      setLoaderCheck(true);
      const responseAnswers = await axiosPrivate.get(`user-answer-data`);
      if (
        responseAnswers.status == 200 &&
        responseAnswers?.data?.data &&
        responseAnswers?.data?.data.length > 0 &&
        responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
          .status == "In Progress" &&
        responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
          .type == "Vedaadhyapaka"
      ) {
       
        lang =
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .language;
        user_type =
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .type;
        apply_type =
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .user_option;
        formik.setValues(
          typeof responseAnswers.data.data[
            responseAnswers?.data?.data?.length - 1
          ]?.answerdata === "string"
            ? JSON.parse(
                responseAnswers.data.data[
                  responseAnswers?.data?.data?.length - 1
                ].answerdata
              )
            : responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
        );
        setId(
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1].id
        );
        setCurrStep(
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .current_page
        );
        setLoaderCheck(false);
        let progress = JSON.parse(
          responseAnswers?.data?.data[responseAnswers?.data?.data?.length - 1]
            ?.progress
        );
        if (progress != null) {
          setPageStatus(progress);
        }
      }
    }
    if (localStorage.getItem("accessToken")) {
      checkOldPage();
    }
    setLoaderCheck(false);
  }, []);

  /* File Upload Code start */
  const fileUploadFunction = async (field_name, file) => {
    const fileUploadResponse = await uploadFile(file);
    if (fileUploadResponse.code === 200) {
      formik.setFieldValue(`${field_name}`, fileUploadResponse.data);
    } else {
      showErrorToast(fileUploadResponse.data.message);
    }
    return true;
  };
  /* File Upload Code end */

  /* 
  New code by Lakhan
  */
  const isPageComplete = (page_no, totalCount, actualCount) => {
    if (totalCount === actualCount) {
      pageStatus[page_no] = "complete";
    } else {
      pageStatus[page_no] = "pending";
    }
  };

  useEffect(() => {
    let currentElement = document.querySelector(".current");
    let nextElement = currentElement?.nextElementSibling;

    if (nextElement?.classList.contains("pending")) {
      currentElement?.classList.add("recheck");
    }
    if (nextElement?.classList.contains("complete")) {
      currentElement?.classList.add("recheck_complete");
    }
  }, [currStep]);

  useEffect(() => {
    console.log("formik.errors", formik.errors);
  }, [formik]);

  const renderCurrentPage = () => {
    switch (currStep) {
      case 1:
        return (
          <Section_1
            formData={formData}
            handleInputChange={handleInputChange}
            formik={formik}
            addBrotherSisterRow={addBrotherSisterRow}
            removeBrotherSisterRow={removeBrotherSisterRow}
            handleInputSave={handleInputSave}
            lang={lang}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );
      case 2:
        return (
          <Section_2
            formData={formData}
            handleInputChange={handleInputChange}
            formik={formik}
            addExamPassRow={addExamPassRow}
            removeExamPassRow={removeExamPassRow}
            addVedadhyapakaRow={addVedadhyapakaRow}
            removeVedadhyapakaRow={removeVedadhyapakaRow}
            addStudiedVedaDetailsRow={addStudiedVedaDetailsRow}
            removeStudiedVedaDetailsRow={removeStudiedVedaDetailsRow}
            addRelativeRow={addRelativeRow}
            removeRelativeRow={removeRelativeRow}
            handleInputSave={handleInputSave}
            lang={lang}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );
      case 3:
        return (
          <Section_3
            formData={formData}
            handleInputChange={handleInputChange}
            handleInputSave={handleInputSave}
            formik={formik}
            addVedaTeachingRow={addVedaTeachingRow}
            removeVedaTeachingRow={removeVedaTeachingRow}
            addAttendanceInVedaSabhasRow={addAttendanceInVedaSabhasRow}
            removeAttendanceInVedaSabhasRow={removeAttendanceInVedaSabhasRow}
            addVedaStudentsCurrentlyBeingTaughtByYouTableRow={
              addVedaStudentsCurrentlyBeingTaughtByYouTableRow
            }
            removeVedaStudentsCurrentlyBeingTaughtByYouTableRow={
              removeVedaStudentsCurrentlyBeingTaughtByYouTableRow
            }
            addVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow={
              addVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow
            }
            removeVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow={
              removeVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow
            }
            vt_family_members_taught_by_youRemoveRow={
              vt_family_members_taught_by_youRemoveRow
            }
            vt_family_members_taught_by_youAddRow={
              vt_family_members_taught_by_youAddRow
            }
            lang={lang}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );

      case 4:
        return (
          <Section_4
            formData={formData}
            handleInputChange={handleInputChange}
            handleInputSave={handleInputSave}
            addVedLiteratureRow={addVedLiteratureRow}
            removeVedLiteratureRow={removeVedLiteratureRow}
            addVedicSShrautaRow={addVedicSShrautaRow}
            removeVedicSShrautaRow={removeVedicSShrautaRow}
            removeTestimonialsAndAwardsRow={removeTestimonialsAndAwardsRow}
            addTestimonialsAndAwardsRow={addTestimonialsAndAwardsRow}
            addResearchPapersRow={addResearchPapersRow}
            removeResearchPapersRow={removeResearchPapersRow}
            formik={formik}
            lang={lang}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );
      case 5:
        return (
          <Section_5
            formData={formData}
            handleInputChange={handleInputChange}
            handleInputSave={handleInputSave}
            formik={formik}
            lang={lang}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );
      case 6:
        return (
          <Section_6
            formData={formData}
            formik={formik}
            handleInputSave={handleInputSave}
            handleInputChange={handleInputChange}
            lang={lang}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );
      case 7:
        return (
          <Section_7
            formData={formData}
            handleInputChange={handleInputChange}
            handleInputSave={handleInputSave}
            addArticlesAttachedRow={addArticlesAttachedRow}
            removeArticlesAttachedRow={removeArticlesAttachedRow}
            formik={formik}
            lang={lang}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );
      case 8:
        return (
          <Section_8
            formData={formData}
            formik={formik}
            id={id}
            handleInputChange={handleInputChange}
            lang={lang}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {loaderCheck ? (
        <Loader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className=''>
            <div className='flex justify-between items-center mb-5 stepsBar'>
              {events.map((eventName, index) => (
                <div
                  key={index}
                  className={`step ${
                    index === currStep - 1 ? "current" : pageStatus[index + 1]
                  }`}
                  onClick={() => {
                    setCurrStep(index + 1);
                    handleInputSave();
                  }}
                >
                  <div className={`step-icon`}>
                    {index + 1 === currStep && (
                      <div className='step-circle'></div>
                    )}
                    {pageStatus[index + 1] == "complete" &&
                      index + 1 !== currStep && (
                        <FaCheck style={{ color: "white" }} />
                      )}
                    {pageStatus[index + 1] == "pending" &&
                      index + 1 !== currStep && (
                        <FaExclamation style={{ color: "white" }} />
                      )}
                    {pageStatus[index + 1] == "" &&
                      !completionStatus[index] &&
                      index + 1 !== currStep && (
                        <div className='step-circle'></div>
                      )}
                  </div>
                  <span className='text-xs mt-2 d-none d-lg-block'>
                    {eventName}
                  </span>
                  <span className='text-xs mt-2 d-lg-none'>{index + 1}</span>
                </div>
              ))}
            </div>
            {renderCurrentPage()}
            <div className=''>
              <div>
                {currStep !== 1 && (
                  <button
                    onClick={(e) => handleBack(e)}
                    type='button'
                    className='btn btn-outline-danger'
                  >
                    {lang == "english"
                      ? "Back"
                      : lang == "hindi"
                      ? "पिछला "
                      : "पूर्वतनम्‌"}
                  </button>
                )}
                {currStep < 8 ? (
                  <button
                    type='button'
                    className='btn btn-danger'
                    onClick={(e) => handleSaveandNext(e)}
                  >
                    {lang == "english"
                      ? "Save & next"
                      : lang == "hindi"
                      ? "सहेजें और आगे बढ़ें "
                      : "आरक्षतु अग्रे गच्छतु च"}
                  </button>
                ) : (
                  <button
                    type='submit'
                    className='btn btn-danger'
                  >
                    {lang == "english"
                      ? "Submit application"
                      : lang == "hindi"
                      ? "आवेदनपत्र जमा करे"
                      : "आवेदनपत्रं समर्पयतु"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default TeacherFormPage;
