import style from "./Navbar.module.css";
import logoImage from "../../assets/logo.png";
import { FaUser } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { useState } from "react";
import { useRefreshToken } from "../../context/RefreshTokenContext";

const AdminNavbar = () => {
  const { refreshToken, setRefreshToken } = useRefreshToken();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const logoutHandler = (e) => {
    e.preventDefault();
    setRefreshToken(null);
    localStorage.clear();
    navigate("/");
  };

  const [btnCss, setBtnCss] = useState('d-none');
  const onProfileBtnClick = (type) => {
    if (type == 'btn') {
      setBtnCss(btnCss === 'd-none' ? 'd-block' : 'd-none');
    }
    if (type == 'nav') {
      if (btnCss === 'd-block') {
        setBtnCss('d-none');
      }
    }
  }

  // const handleProfile = () => {
  //   navigate("/profile");
  // };

  return (
    <>
      {pathname === "/" ? (
        <div className={style.NavbarContainerLogin}>
          <div className={style.wrapper}>
            <img src={logoImage} alt="Logo" className={style.logo} />
          </div>
        </div>
      ) : (
        <div className={style.NavbarContainer} onClick={() => onProfileBtnClick("nav")}>
          <div className={style.navbarWrapper}>
            <img src={logoImage} alt="Logo" className={style.logo} />
            <div className="user-dropdown" onClick={() => onProfileBtnClick("btn")}>
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <FaUser size={17} />
              </button>
              <div className={`profle-dropdown ${btnCss}`}>
                <button onClick={() => navigate("/admin/profile")}>
                  Profile
                </button>
                <button onClick={() => navigate("/admin/dashboard")}>
                  Dashboard
                </button>
                <button onClick={() => navigate("/admin/timeline")}>
                  Form timeline
                </button>
                <button onClick={() => navigate("/admin/add")}>
                  Add admin
                </button>
                <button onClick={(e) => logoutHandler(e)}>
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  );
};

export default AdminNavbar;
