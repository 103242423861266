const Page2FormField = {
  vi_studied_veda: "",
  vi_studied_vedasakha: "",
  vi_studied_vedasakha_other: "",
  vi_more_than_one_veda_option: "",
  vi_more_than_one_veda: "",
  vi_more_than_one_veda_other: "",
  vi_ved_and_passed_of_ved_examinations: [
    {
      vi_month_and_year: "",
      vi_passed_exam: "Upnanayan sanskar",
      vi_exam_board: "",
      vi_exam_division: "",
      vi_exam_certificate: "",
      vi_exam_certificate_string: "",
    },
    {
      vi_month_and_year: "",
      vi_passed_exam: "Started Samhita study",
      vi_exam_board: "",
      vi_exam_division: "",
      vi_exam_certificate: "",
      vi_exam_certificate_string: "",
    },
    {
      vi_month_and_year: "",
      vi_passed_exam: "Moolanta exam",
      vi_exam_board: "",
      vi_exam_division: "",
      vi_exam_certificate: "",
      vi_exam_certificate_string: "",
    },
  ],
  vedadhyapaka_vedvidyalaya_details: [
    {
      from: "",
      till: "",
      vedadhyapak_name: "",
      vedadhyapak_mobile: "",
      vedvidyalaya_name: "",
    },
  ],
  is_relative: "",
  relative_details: [
    {
      serial_no: "",
      vedadhyapakName: "",
      relation: "",
      other_relation: "",
    },
  ],
  grantha_details: [
    {
      grantha_name: "",
      start_study: "",
      end_study: "",
      exam_month_year: "",
      certificate: "",
      certificate_string: "",
    },
  ],
  have_received_ward: "",
  award_details: [
    {
      award_month_year: "",
      award_name: "",
      awarding_organisation: "",
      certificate: "",
      certificate_string: "",
    },
  ],
};

export default Page2FormField;
