import style from "./Login.module.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showErrorToast, showSuccessToast } from "../../Utils/Toaster";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useRef, useState } from "react";
import "./Login.module.css";
import axiosPrivate from "../../Hooks/axiosPrivate";

const Register = () => {
  const aadhaarImageInput = useRef(null);
  const passportImageInput = useRef(null);
  const [showPassword, setShowPassword] = useState(false); // State to track password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to track confirm password visibility
  const [aadhaarImage, setAadhaarImage] = useState();
  const [passportPhoto, setPassportPhoto] = useState();
  const initialValues = {
    name: "",
    fatherName: "",
    dob: "",
    adharCardNo: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
    adharCardImage: null,
    language: "",
    passportPhoto: null,
  };

  const registrationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    fatherName: Yup.string().required("Father's name is required"),
    dob: Yup.string().required("Date of birth is required"),
    adharCardNo: Yup.string()
      .required("Aadhaar card number is required")
      .min(12, "Aadhaar card number must be of 12 digits"),
    language: Yup.string().required("Preferred language is required"),
    mobile: Yup.string()
      .required("Mobile number is required")
      .min(10, "Mobile number must be of 10 digits")
      .max(10, "Mobile number must be of 10 digits"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { resetForm }) => {
      const reqData = {
        name: values.name,
        father_name: values.fatherName,
        DOB: values.dob,
        adhaar_no: values.adharCardNo,
        mobile: values.mobile.toString(),
        email: values.email,
        password: values.password,
        adhaar_image: aadhaarImage,
        language: values.language,
        profile: passportPhoto,
      };
      const formData = new FormData();

      Object.keys(reqData).forEach((key) => {
        formData.append(key, reqData[key]);
      });
      try {
        const response = await axiosPrivate.post("register", formData);
        if (response.status === 201) {
          showSuccessToast(response.data.message + ", kindly login");
          resetForm();
          aadhaarImageInput.current.value = null;
          passportImageInput.current.value = null;
        } else {
          showErrorToast(
            response.data.message || response?.data?.errors[0]?.msg
          );
        }
      } catch (error) {
        showErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Function to toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className={`${style["RegisterContainer"]}`}>
      {/* <div className={style["RegisterContainer","col-md-6"]}> */}
      <div className={style.RegisterWrapper}>
        <div className="flex justify-between flex-col">
          <h3 className="text-xl font-bold mb-5px">Sign up</h3>
          <p className="text-primary mb-30">All fields are mandatory!</p>
        </div>
        <form onSubmit={formik.handleSubmit} className={style.registerForm}>
          <div className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name || ""}
                className="form-control"
              />
              {formik.touched.name && formik.errors.name ? (
                <div className={style.error}>{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="fatherName">Father&apos;s name</label>
              <input
                type="text"
                id="fatherName"
                name="fatherName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fatherName || ""}
                className="form-control"
              />
              {formik.touched.fatherName && formik.errors.fatherName ? (
                <div className={style.error}>{formik.errors.fatherName}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="dob">Date of birth</label>
              <input
                type="date"
                id="dob"
                name="dob"
                maxLength={10}
                max={new Date()?.toISOString()?.slice(0, 10)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dob || ""}
                className="form-control"
              />
              {formik.touched.dob && formik.errors.dob ? (
                <div className={style.error}>{formik.errors.dob}</div>
              ) : null}
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="mobile">Mobile number</label>
              <input
                type="number"
                id="mobile"
                name="mobile"
                maxLength={10}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    formik.setFieldValue("mobile", e.target.value);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.mobile || ""}
                className="form-control"
              />
              {formik.touched.mobile && formik.errors.mobile ? (
                <div className={style.error}>{formik.errors.mobile}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="aadhaarCardNo">Aadhaar card number</label>
              <input
                type="number"
                id="adharCardNo"
                name="adharCardNo"
                onChange={(e) => {
                  if (e.target.value.length <= 12) {
                    formik.setFieldValue("adharCardNo", e.target.value);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.adharCardNo || ""}
                className="form-control"
              />
              {formik.touched.adharCardNo && formik.errors.adharCardNo ? (
                <div className={style.error}>{formik.errors.adharCardNo}</div>
              ) : null}
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="adharCardNo">Upload Aadhaar card</label>
              <input
                type="file"
                id="adharCardImage"
                name="adharCardImage"
                onChange={(e) => setAadhaarImage(e.target.files[0])}
                onBlur={formik.handleBlur}
                className="form-control p-0"
                required
                ref={aadhaarImageInput}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="passportPhoto">Upload passport photo</label>
              <input
                type="file"
                id="passportPhoto"
                name="passportPhoto"
                onChange={(e) => setPassportPhoto(e.target.files[0])}
                onBlur={formik.handleBlur}
                className="form-control p-0"
                required
                ref={passportImageInput}
              />
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email || ""}
                className="form-control"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className={style.error}>{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label htmlFor="password">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password || ""}
                className="form-control"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className={style.eyeButton}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              {formik.touched.password && formik.errors.password ? (
                <div className={style.error}>{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="col-md-6 form-group">
              <label htmlFor="confirmPassword">Confirm password</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword || ""}
                className="form-control"
              />
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className={style.eyeButton}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <div className={style.error}>
                  {formik.errors.confirmPassword}
                </div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 form-group">
              <label htmlFor="language">Preferred language</label>
              <select
                id="language"
                name="language"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.language || ""}
                className="form-control"
                // style={{
                //   border: "1px solid #d9d9d9",
                //   height: "48px !important",
                // }}
              >
                <option value="">Select</option>
                <option value="english">English</option>
                <option value="hindi">Hindi</option>
                <option value="sanskrit">Sanskrit</option>
              </select>
              {formik.touched.language && formik.errors.language ? (
                <div className={style.error}>{formik.errors.language}</div>
              ) : null}
            </div>
          </div>
          <button type="submit" className=" text-white py-2 bg-primary w-full">
            Create account
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
