import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import { showErrorToast, showSuccessToast } from '../../Utils/Toaster.js';
import axiosPrivate from '../../Hooks/axiosPrivate.js';

const validationRules = {
    start_date: Yup.date().required("This field is required"),
    end_date: Yup.date().required("This field is required"),
}
const FormTimeLine = () => {
    const [id, setId] = useState("");
    useEffect(() => {
        checkFormTimeLine();
    }, []);

    const checkFormTimeLine = async () => {
        const response = await axiosPrivate.get("form-timeline");
        formik.setFieldValue("start_date", response?.data?.data[0]?.start_date);
        formik.setFieldValue("end_date", response?.data?.data[0]?.end_date);
        setId(response?.data?.data[0]?.id);
    }
    const formik = useFormik({
        initialValues: {
            start_date: "",
            end_date: "",
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object(validationRules),
        onSubmit: async (values, { setSubmitting, setFieldError }) => {
            // Handle form submission
            let userData = localStorage.getItem("userData");
            userData = userData ? JSON.parse(userData) : {};
            if (userData?.userId) {
                try {
                    let response = null;
                    if (id) {
                        const formData = {
                            start_date: values.start_date,
                            end_date: values.end_date,
                        };
                        response = await axiosPrivate.put(
                            `form-timeline/${id}`,
                            formData
                        );
                    } else {
                        const formData = {
                            start_date: values.start_date,
                            end_date: values.end_date,
                        };
                        response = await axiosPrivate.post(
                            "form-timeline",
                            formData
                        );
                    }
                    if (response.status == 200) {
                        showSuccessToast(response.data.message);
                    } else {
                        showErrorToast(response.data.message);
                    }
                } catch (error) {
                    showErrorToast(error?.response?.data?.errors[0]?.msg);
                }
            }
        },
    });

    return (
        <div className='row justify-center'>
            <div className='col-md-4'>
                <h3>Form Timeline</h3>
                <form onSubmit={formik.handleSubmit}>
                    <label htmlFor="start_date">
                        Start date
                    </label>
                    <input
                        id="start_date"
                        name="start_date"
                        type="date"
                        className="form-control"
                        value={formik.values.start_date || ""}
                        onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.touched && formik.errors.start_date ? (
                        <div className="text-danger">{formik.errors.start_date}</div>
                    ) : null}
                    <br />
                    <label htmlFor="end_date">
                        End date
                    </label>
                    <input
                        id="end_date"
                        name="end_date"
                        type="date"
                        className="form-control"
                        value={formik.values.end_date || ""}
                        onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.touched && formik.errors.end_date ? (
                        <div className="text-danger">{formik.errors.end_date}</div>
                    ) : null}
                    <br />
                    <button type="submit" className="btn btn-danger" style={{ width: '100%' }}>Submit</button>
                </form>
            </div>
        </div>
    )
}

export default FormTimeLine
