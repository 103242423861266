import { useEffect, useState } from "react";
import axiosPrivate from "../../Hooks/axiosPrivate";
import Modal from "react-bootstrap/Modal";

const PreviewForm = ({
  onClose,
  id,
  formStatus,
  setStatusUpdated,
  statusUpdated,
}) => {
  const [path, setPath] = useState("");
  const [remark, setRemark] = useState("");
  const [data, setData] = useState([]);
  const handlePreview = async () => {
    const response = await axiosPrivate.get(`pdf/${id}`);
    if (response?.status === 200 && response?.data?.path) {
      setPath(process.env.REACT_APP_BACKEND_URL + response?.data?.path);
    }
  };

  const handleGetData = async () => {
    const response = await axiosPrivate.get(`user-answer-data/remark/${id}`);
    if (response?.status === 200 && response?.data?.data) {
      setData(response?.data?.data[0]);
      setRemark(response?.data?.data[0].remarks);
    }
  };

  useEffect(() => {
    handleGetData();
    handlePreview();
  }, [id]);

  const handleSubmit = async (status) => {
    const formData = {
      remarks: remark,
      status,
    };
    const response = await axiosPrivate.put(
      `user-answer-data/remark/${id}`,
      formData
    );
    if (response?.status === 200 && response?.data?.data) {
      onClose();
      setStatusUpdated(statusUpdated === true ? false : true);
    }
  };

  return (
    <Modal
      show={onClose}
      onHide={onClose}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Preview</Modal.Title>
        <p className={`status ${data?.status?.toLowerCase()}`}>{data.status}</p>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={path}
          title='Form Preview'
          width='100%'
          height='400px'
        ></iframe>
        <textarea
          className='form-control mt-30'
          placeholder='Comments*'
          onChange={(e) => setRemark(e.target.value)}
          onBlur={(e) => setRemark(e.target.value)}
          value={remark}
        />
      </Modal.Body>
      <Modal.Footer className='justify-content-start'>
        <button
          className={`btn btn-danger`}
          onClick={() => handleSubmit("Approved")}
        >
          Approve
        </button>
        <button
          className={`btn btn-outline-danger`}
          onClick={() => handleSubmit("Rejected")}
        >
          Reject
        </button>
        {formStatus === "Approved" ? (
          <button
            className={`btn btn-danger`}
            onClick={() => handleSubmit("Selected")}
          >
            Select
          </button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

export default PreviewForm;
