import axios from "axios";
import { axiosPublic } from "./axiosPublic";
import { showErrorToast } from "../Utils/Toaster";

const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

var userToken = JSON.parse(localStorage.getItem("userData") || "{}");
console.log(userToken);

async function checkUpdateToken() {
  return new Promise((resolve) => {
    userToken = JSON.parse(localStorage.getItem("userData") || "{}");
    resolve(true);
  });
}

axiosPrivate.interceptors.request.use(
  async (config) => {
    await checkUpdateToken();
    config.headers = {
      Authorization: `Bearer ${userToken?.token}`,
      Accept: "application/json",
    };
    return config;
  },
  (error) => { }
  
);

axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 422) {
      showErrorToast(error?.response?.data?.message);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      showErrorToast(error?.response?.data?.message);
      localStorage.clear();
      // window.location.href("/");
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      const newToken = JSON.parse(localStorage.getItem("userData") || "{}");
      userToken = newToken;
      axiosPrivate.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${newToken?.token}`;
      originalRequest.headers.Authorization = `Bearer ${newToken?.token}`;
      return axiosPrivate(originalRequest);
    }
    // return Promise.reject(error);
  }
);

const refreshAccessToken = () => {
  return new Promise((resolve, reject) => {
    const refreshToken = JSON.parse(localStorage.getItem("userData") || "{}");
    if (refreshToken.refresh_token !== undefined) {
      axiosPublic
        .post("refresh-token", { refresh_token: refreshToken.refresh_token })
        .then(
          (response) => {
            localStorage.setItem("userData", JSON.stringify(response.data));
            resolve(response.data.token);
          },
          (error) => {
            reject(error.message);
          }
        );
    } else {
      if (
        localStorage.getItem("userData") ||
        localStorage.getItem("userDetails")
      ) {
        localStorage.removeItem("userData");
        localStorage.removeItem("userDetails");
      }
    }
  });
};

export default axiosPrivate;
