const page6FormFields = {
    document_details: [
        {
            details_of_document: "",
            attached_document: "",
            attached_document_string: "",
        }
    ]
};

export default page6FormFields;