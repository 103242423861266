const page1FormFields = {
    name: "",
    full_address: "",
    name_of_the_head: "",
    mobile: "",
    email: "",
    website: "",
    contact_person_name: "",
    contact_person_email: "",
    contact_person_mobile: "",
    full_correspondence_pincode: "",
    full_correspondence_district: "",
    full_correspondence_state: "",
    full_correspondence_address: "",
    communication_language: "",
    founder_institution: "",
    trust_name: "",
    registration_no: "",
    chief_trustee_name: "",
    trustee_mobile: "",
    sponsor_name: "",
    sponsor_address: "",
    head_sponsor_name: "",
    founder_name: "",
    founder_address: "",
    founder_mobile: "",
    starting_date: "",
    was_school_closed: "",
    from_date: "",
    till_date: ""
};
export default page1FormFields;