import style from "./Login.module.css";
import Register from "./Register";
import LoginDetail from "./LoginDetail";

const Auth = ({ setIsLoggedIn }) => {
  return (
    <div className="row">
      <div className={`${style["leftPanel"]} col-lg`}>
        <LoginDetail setIsLoggedIn={setIsLoggedIn} />
      </div>
      <div className={`${style["divider"]} d-none d-lg-block`}></div>
      <div className={`${style["rightPanel"]} col-lg`}>
        <Register />
      </div>
    </div>
  );
};

export default Auth;
