const viFields = {
  vl_list_of_attendance_in_veda_sabha: "",
  vl_list_of_attendance_in_veda_sabha_table: [
    {
      meeting_or_conference: "",
      organizer: "",
      year: "",
      certificate: "",
      certificate_string: "",
    },
  ],
  vl_have_you_participated_in_vedic_shrauta_yajna: "",
  vl_have_you_participated_in_vedic_shrauta_yajna_table: [
    {
      yajna: "",
      the_host: "",
      year: "",
      certificate: "",
      certificate_string: "",
    },
  ],
  vl_information_on_testimonials_and_awards_received: "",
  vl_information_on_testimonials_and_awards_received_table: [
    {
      award_name: "",
      who_gave_it: "",
      year: "",
      certificate: "",
      certificate_string: "",
    },
  ],
  vl_information_about_books: "",
  vl_information_about_books_table: [
    {
      topic: "",
      publisherISBN: "",
      year: "",
      certificate: "",
      certificate_string: "",
    },
  ],
  vl_have_you_performed_soma_yajna: "",
  vl_have_you_performed_soma_yajna_detail: "",
  vl_is_your_conduct_according_to_vedas: "",
  vl_is_your_conduct_according_to_vedas_details: "",
};
export default viFields;
