const page2FormFields = {
  si_studied_veda_and_vedshakha: [],
  si_ved_select: [],
  si_present_student_count: [
    {
      si_vedas_and_vedshakhas: "",
      si_vidhyarthi_study_sanhita: "",
      si_vidhyarthi_study_krama: "",
      si_vidhyarthi_study_ghana: "",
      si_vidhyarthi_study_beyond_ghana: "",
      si_vidhyarthi_study_total_count: "",
    }
  ],
  si_list_students_studying_at_vedvidyalay: "",
  si_present_students_studying_at_vedvidyalay: [
    {
      student_name: "",
      age: "",
      vedashakha: "",
      year: "",
      exam_level: "",
      other: "",
    },
  ],
  si_student_status: [
    {
      session_start_student_count: "",
      this_session_student_count: "",
      education_complete_student_count: "",
      before_education_complete_student_count: "",
      session_end_student_count: "",
    },
    {
      session_start_student_count: "",
      this_session_student_count: "",
      education_complete_student_count: "",
      before_education_complete_student_count: "",
      session_end_student_count: "",
    },
    {
      session_start_student_count: "",
      this_session_student_count: "",
      education_complete_student_count: "",
      before_education_complete_student_count: "",
      session_end_student_count: "",
    },
    {
      session_start_student_count: "",
      this_session_student_count: "",
      education_complete_student_count: "",
      before_education_complete_student_count: "",
      session_end_student_count: "",
    },
    {
      session_start_student_count: "",
      this_session_student_count: "",
      education_complete_student_count: "",
      before_education_complete_student_count: "",
      session_end_student_count: "",
    },
  ],
};
export default page2FormFields;
