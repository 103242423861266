import React, { createContext, useState, useContext } from "react";

// Create the context
const RefreshTokenContext = createContext({
  refreshToken: null,
  setRefreshToken: () => {},
});

// Provider component
export const RefreshTokenProvider = ({ children }) => {
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken") || null
  );
  return (
    <RefreshTokenContext.Provider value={{ refreshToken, setRefreshToken }}>
      {children}
    </RefreshTokenContext.Provider>
  );
};

// Custom hook to access refreshToken and setRefreshToken
export const useRefreshToken = () => {
  return useContext(RefreshTokenContext);
};
