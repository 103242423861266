const Page1FormField = {
  pi_name: "",
  pi_adhar_card_no: "",
  pi_date_of_birth: "",
  pi_place_of_birth: "",
  pi_fathers_name: "",
  pi_mothers_name: "",
  pi_rishi_gotra: "",
  pi_rishi_gotra_other: "",
  pi_Swavedshakha: "",
  pi_anyashakha: "",
  pi_full_corresponding_address_line_1: "",
  pi_full_correspondence_address_district: "",
  pi_full_correspondence_address_state: "",
  pi_full_correspondence_address_pincode: "",
  pi_same_as: "",
  pi_permanent_home_address_line_1: "",
  pi_permanent_address_district: "",
  pi_permanent_address_state: "",
  pi_permanent_address_pincode: "",
  pi_mobile_no: "",
  pi_email_address: "",
  pi_language: "",
  pi_have_brothers_sisters: "",
  pi_brothers_sisters: [
    {
      name: "",
      gender: "",
      age: "",
      other: "",
      study_level: "",
      studying_the_vedas: "",
    },
  ],
  pi_present_guruji_name: "",
  pi_guruji_mobile: "",
  pi_passport_photo: "",
  pi_passport_photo_string: "",
  pi_date_of_receipt_of_application: "",
  pi_application_verification_date: "",
  pi_investigation_officer: "",
  pi_code_number: "",
  pi_other_comments: "",
};

export default Page1FormField;
