import style from "./Navbar.module.css";
import logoImage from "../../assets/logo.png";
import { FaUser } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const logoutHandler = (e) => {
    e.preventDefault();
    alert();
    localStorage.clear();
    alert();
    navigate("/");
  };

  return (
    <>
      <div className={style.NavbarContainer}>
        <div className={style.navbarWrapper}>
          <img src={logoImage} alt="Logo" className={style.logo} />
          <div className="user-dropdown">
            <button
              className="btn btn-secondary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FaUser size={17} />
            </button>
            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to="/profile">
                  Profile
                </Link>
              </li>
              <li>
                <span
                  className="dropdown-item cursor-pointer"
                  onClick={(e) => logoutHandler(e)}
                >
                  Logout
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
