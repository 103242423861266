const viFields = {
  vi_studied_veda_and_vedshakha: "",
  vi_ved_select: "",
  vi_list_of_exam_passed: [
    {
      vi_exam_monthAndYear: "",
      vi_name_of_exam: "",
      vi_exam_body: "",
      vi_exam_certificate: "",
      vi_exam_certificate_string: "",
    },
  ],
  vi_list_teacher_entries: [
    {
      vi_teacher_name: "",
      vi_exam_name: "",
      vi_gurukul_name: "",
    },
  ],
  vi_list_close_relative_teacher_option: "yes",
  vi_list_close_relative_teacher: [
    {
      vi_serial_number:"",
      vi_relation: "",
      vi_other_relative: "",
    },
  ],
  vi_veda_knowledge: "",
  vi_veda_knowledge_detail: "",
  vi_list_studied_veda_details: [
    {
      vi_subject: "",
      vi_name_of_granth: "",
      vi_year: "",
      vi_exam_board: "",
      vi_exam_certificate: "",
      vi_exam_certificate_string: "",
    },
  ],
};
export default viFields;
