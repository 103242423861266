const page4FormFields = {
    purpose_of_establishing_the_vedavidyalaya: "",
    special_achievements: "",
    desc_of_veda_related_education: "",
    details_of_veda_related_programs: "",
    mention_five_achievements: "",
    list_of_awards_of_honours: "",
    list_of_awards_of_honours_details: [
        {
            name_of_award_honor: "",
            name_of_institution: "",
            year: "",
            attached_document_string: "",
        }
    ]
};

export default page4FormFields;