import {
  FaAngleLeft,
  FaAngleRight,
  FaAnglesLeft,
  FaAnglesRight,
  FaX,
} from "react-icons/fa6";
import { useContext, useEffect, useState } from "react";
import style from "./dashboard.module.css";
import axiosPrivate from "../../Hooks/axiosPrivate";
import { showErrorToast } from "../../Utils/Toaster";
import PreviewForm from "./PreviewForm";
import { createPortal } from "react-dom";
import SetAlertModal from "./SetAlertModal";
import LoaderContext from "../../context/Loader/LoaderContext";

function Dashboard() {
  const [isActive, setIsActive] = useState("Vedavidyarthi");
  const [countPerPage, setCountPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState([1]);
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [shownPages, setShownPages] = useState([]);
  const [vedAdhyapakCount, setvedAdhyapakCount] = useState(0);
  const [vedVidyalayaCount, setvedVidyalayaCount] = useState(0);
  const [vedVidyarthiCount, setvedVidyarthiCount] = useState(0);
  const [registeredCount, setRegisteredCount] = useState(0);
  const [filter, setFilter] = useState("");
  const [showPreviewForm, setShowPreviewForm] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [id, setId] = useState("");
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [formStatus, setformStatus] = useState("");
  const [selectedPDFId, setSelectedPDFId] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);



  const { loaderCheck, setLoaderCheck } = useContext(LoaderContext);

  const handleApiChange = async () => {
    const formData = {
      type: isActive,
    };
    const response = await axiosPrivate.post("get-dashboard-data", formData);
    if (response?.status === 200 && response?.data?.data) {
      setApiData(
        filter === ""
          ? response?.data?.data?.listData
          : response?.data?.data?.listData?.filter(
              (item) => item.status === filter
            )
      );
      setvedAdhyapakCount(response?.data?.data?.vedadhyapakCount);
      setvedVidyalayaCount(response?.data?.data?.vedvidyalayaCount);
      setvedVidyarthiCount(response?.data?.data?.vedvidyarthiCount);
      setRegisteredCount(response?.data?.data?.registeredCount);
    } else {
      showErrorToast(response?.data?.message);
    }
  };

  const handleZipDownload = async (name) => {
    const fileURL = process.env.REACT_APP_MEDIA_URL + name; // Change the URL accordingly
    try {
      const response = await fetch(fileURL);
      const responseData = await response.arrayBuffer(); // Convert response to ArrayBuffer
      const blob = new Blob([responseData], { type: "application/zip" }); // Correct MIME type for zip files

      const fileName = name.slice(9) || "downloaded-file.zip"; // Default filename
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };

  const handlePdfDownload = async (name) => {
    const fileURL = process.env.REACT_APP_MEDIA_URL + name; // Change the URL accordingly
    try {
      const response = await fetch(fileURL);
      const responseData = await response.arrayBuffer(); // Convert response to ArrayBuffer
      const blob = new Blob([responseData], { type: "application/zip" }); // Correct MIME type for zip files

      const fileName = name.slice(6) || "downloaded-file.zip"; // Default filename
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };

  const handleExcelDownload = async (name) => {
    const fileURL = process.env.REACT_APP_MEDIA_URL + name;
    try {
      const response = await fetch(fileURL);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.blob();
      const fileName = name.slice(6) || "downloaded-file.xlsx"; // Default filename with .xlsx extension
      const url = window.URL.createObjectURL(responseData);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching the file:", error);
    }
  };

  const exportZip = async () => {
    if (selectedPDFId.length === 0) {
      showErrorToast("Please select at least one user");
      return;
    }

    try {
      setLoaderCheck(true);
      const response = await axiosPrivate.post("/archive", {
        ids: selectedPDFId,
      });
      if (response?.status === 200) {
        const name = response?.data?.data;
        handleZipDownload(name);
      } else {
        showErrorToast(response?.data?.message);
      }
    } catch (error) {
      console.error("Error during the request:", error);
      showErrorToast("An error occurred while exporting the zip file.");
    } finally {
      setLoaderCheck(false);
      setSelectedIds([]);
      setSelectedPDFId([]);
    }
  };

  const exportPdf = async () => {
    if (selectedPDFId.length === 0) {
      showErrorToast("Please select at least one user");
      return;
    }

    try {
      setLoaderCheck(true);
      const response = await axiosPrivate.post("/pdf", {
        ids: selectedPDFId,
      });
      if (response?.status === 200) {
        const name = response?.data?.data;
        handlePdfDownload(name);
      } else {
        showErrorToast(response?.data?.message);
      }
    } catch (error) {
      console.error("Error during the request:", error);
      showErrorToast("An error occurred while exporting the zip file.");
    } finally {
      setLoaderCheck(false);
      
      setSelectedIds([]);
      setSelectedPDFId([]);
    }
  };

  const exportPdfWithoutAffidavit = async () => {
    if (selectedPDFId.length === 0) {
      showErrorToast("Please select at least one user");
      return;
    }

    try {
      setLoaderCheck(true);
      const response = await axiosPrivate.post("/pdf-without-affidavit", {
        ids: selectedPDFId,
      });
      if (response?.status === 200) {
        const name = response?.data?.data;
        handlePdfDownload(name);
      } else {
        showErrorToast(response?.data?.message);
      }
    } catch (error) {
      console.error("Error during the request:", error);
      showErrorToast("An error occurred while exporting the zip file.");
    } finally {
      setLoaderCheck(false);
      setSelectedIds([]);
      setSelectedPDFId([]);
    }
  };

  const exportExcel = async () => {
    if (selectedPDFId.length === 0) {
      showErrorToast("Please select at least one user");
      return;
    }

    try {
      setLoaderCheck(true);
      const response = await axiosPrivate.post("/csv", {
        ids: selectedPDFId,
      });
      if (response?.status === 200) {
        const name = response?.data?.data;
        handleExcelDownload(name);
      } else {
        showErrorToast(response?.data?.message);
      }
    } catch (error) {
      console.error("Error during the request:", error);
      showErrorToast("An error occurred while exporting the  file.");
    } finally {
            setLoaderCheck(false);
            setSelectedIds([]);
            setSelectedPDFId([]);

    }
  };

  useEffect(() => {
    handleApiChange();
  }, [isActive, filter, statusUpdated]);

  useEffect(() => {
    let totalPageCount = Math.ceil(apiData.length / countPerPage);
    let page_no = [];
    let shown_pages = [];
    for (let i = 1; i <= totalPageCount; i++) {
      page_no.push(i);
      if (i <= 3) {
        shown_pages.push(i);
      }
    }
    let start_point = countPerPage * pageNo - countPerPage;
    let end_point = parseInt(start_point) + parseInt(countPerPage);
    let slice_data = apiData.slice(start_point, end_point);
    setTotalPages(page_no);
    setShownPages(shown_pages);
    setData(slice_data);
  }, [countPerPage, pageNo, apiData, isActive, filter]);

  useEffect(() => {
    setPageNo(1);
  }, [filter]);

  const onCountChange = (val) => {
    setCountPerPage(val);
    setPageNo(totalPages[0]);
    onPageClick(totalPages[0]);
  };
  const baseUrl = window.location.origin;

  const onTabClick = async (val) => {
    setIsActive(val);
    setPageNo(1);
    setSelectedIds([]);
    setSelectedPDFId([]);
    setFilter("");
  };

  const onPageClick = (val) => {
    if (val < 1) val = 1;
    if (val > totalPages[totalPages.length - 1])
      val = totalPages[totalPages.length - 1];

    let shown_pages = [];
    if (val === totalPages[0]) {
      shown_pages = [val, val + 1, val + 2];
    } else if (val === totalPages[totalPages.length - 1]) {
      shown_pages = [val - 2, val - 1, val];
    } else {
      shown_pages = [val - 1, val, val + 1];
    }

    shown_pages = shown_pages.filter(
      (page) =>
        page >= totalPages[0] && page <= totalPages[totalPages.length - 1]
    );

    setShownPages(shown_pages);
    setPageNo(val);
    setSelectAllChecked(false);
    setSelectedIds([]);
    setSelectedPDFId([]);
  };

  useEffect(() => {
    let totalPageCount = Math.ceil(apiData.length / countPerPage);
    let page_no = [];
    for (let i = 1; i <= totalPageCount; i++) {
      page_no.push(i);
    }

    let start_point = countPerPage * pageNo - countPerPage;
    let end_point = parseInt(start_point) + parseInt(countPerPage);
    let slice_data = apiData.slice(start_point, end_point);

    setTotalPages(page_no);

    // Initialize shownPages
    let shown_pages = [];
    if (pageNo === page_no[0]) {
      shown_pages = [pageNo, pageNo + 1, pageNo + 2];
    } else if (pageNo === page_no[page_no.length - 1]) {
      shown_pages = [pageNo - 2, pageNo - 1, pageNo];
    } else {
      shown_pages = [pageNo - 1, pageNo, pageNo + 1];
    }
    shown_pages = shown_pages.filter(
      (page) => page >= page_no[0] && page <= page_no[page_no.length - 1]
    );

    setShownPages(shown_pages);
    setData(slice_data);
  }, [countPerPage, pageNo, apiData, isActive, filter]);

  // Add this to reset the pagination when filter changes
  useEffect(() => {
    setPageNo(1);
  }, [filter]);

 

  const handleSelectAll = () => {
    if (!selectAllChecked) {
      const selectableIds = data
        // .filter((item) => item.status !== "In Progress")
        .map((item) => item.user_id);
      setSelectedIds(selectableIds);
      const selectablePDFIds = data
        // .filter((item) => item.status !== "In Progress")
        .map((item) => item.id);
      setSelectedPDFId(selectablePDFIds);
    } else {
      setSelectedIds([]);
      setSelectedPDFId([]);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const tabValue = [
    "Vedavidyarthi",
    "Vedaadhyapaka",
    "Vedavidyalaya",
    "Registered",
  ];
  const paginationCount = [10, 25, 50, 100];
  const status = ["Approved", "Rejected", "Pending", "In Progress", "Selected"];


 
  return (
    <>
      {loaderCheck ? (
        <div className='loader'></div>
      ) : (
        <>
          <div className='row'>
            <div className='col-md-3'>
              <div className={`border text-center ${style.card_bg_color} p-4`}>
                <h1
                  className={`font-weight-bold ${style.card_count_text_color}`}
                >
                  {vedVidyarthiCount}
                </h1>
                <p>Total vedvidyarthi</p>
              </div>
            </div>
            <div className='col-md-3 mt-2 mt-md-0'>
              <div className={`border text-center ${style.card_bg_color} p-4`}>
                <h1
                  className={`font-weight-bold ${style.card_count_text_color}`}
                >
                  {vedAdhyapakCount}
                </h1>
                <p>Total vedadhyapak</p>
              </div>
            </div>
            <div className='col-md-3 mt-2 mt-md-0'>
              <div className={`border text-center ${style.card_bg_color} p-4`}>
                <h1
                  className={`font-weight-bold ${style.card_count_text_color}`}
                >
                  {vedVidyalayaCount}
                </h1>
                <p>Total vedavidyalaya</p>
              </div>
            </div>
            <div className='col-md-3 mt-2 mt-md-0'>
              <div className={`border text-center ${style.card_bg_color} p-4`}>
                <h1
                  className={`font-weight-bold ${style.card_count_text_color}`}
                >
                  {registeredCount}
                </h1>
                <p>Only Registered</p>
              </div>
            </div>
          </div>
          <div className='row mt-30'>
            <div className='col-md-12'>
              {tabValue.map((value, index) => (
                <button
                  key={index}
                  className={`${style.tab} ${
                    isActive === value ? style.active_tab : ""
                  }`}
                  onClick={() => onTabClick(value)}
                >
                  <p
                    className={`${style.tab_text} ${
                      isActive === value ? style.active_tab_text : ""
                    }`}
                  >
                    {value}
                  </p>
                </button>
              ))}
            </div>
            <div className='col-md-12'>
              <hr className={`${style.red_line}`} />
            </div>
          </div>
          <div className='row mt-30'>
            <div className='col-md-auto d-flex'>
              <select
                id='form_status'
                name='form_status'
                className={`${style.filter_element} ${style.filter_dropdown}`}
                onChange={(e) => setFilter(e.target.value)}
                value={filter}
              >
                <option value=''>--- Select ---</option>
                {status.map((value) => (
                  <option
                    key={value}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </select>
              <button
                className={`${style.clear_btn}`}
                onClick={() => {
                  setFilter("");
                  setPageNo(1);
                }}
              >
                <FaX />
                <p style={{ paddingLeft: "5px" }}>Clear</p>
              </button>
            </div>
            <div className='col-md d-flex justify-content-lg-end mt-2 mt-md-0'>
              <button
                className={`${style.doc_icon}`}
                onClick={exportExcel}
              >
                <img
                  src={baseUrl + "/csv.png"}
                  alt='csv'
                />
              </button>
              <button
                className={`${style.doc_icon} `}
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
              >
                <img
                  src={baseUrl + "/pdf.png"}
                  alt='pdf'
                />
              </button>
              <div
                className='dropdown-menu pdf_dropdown'
                style={{ backgroundColor: "#E83f3f" }}
              >
                <a
                  className='dropdown-item '
                  href='#'
                  onClick={exportPdf}
                >
                  Export Pdf with Affidavit
                </a>
                <a
                  className='dropdown-item'
                  href='#'
                  onClick={exportPdfWithoutAffidavit}
                >
                  Export Pdf without Affidavit
                </a>
                <a
                  className='dropdown-item '
                  href='#'
                  onClick={exportZip}
                >
                  Export Documents
                </a>
              </div>
              <button
                className={`${style.clear_btn}`}
                onClick={() => {
                  if (selectedIds?.length) {
                    setShowAlertModal(true);
                  } else {
                    showErrorToast("Please select at least one User!");
                  }
                }}
              >
                <p>Set alert</p>
              </button>
            </div>
          </div>
          <div className='row mt-30'>
            <div className='col-md-12 table-responsive'>
              <table width='100%'>
                <thead>
                  <tr>
                    <th>
                      <input
                        type='checkbox'
                        onChange={handleSelectAll}
                        checked={selectAllChecked}
                      />
                    </th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    {isActive !== "Registered" ? (
                      <th
                        width='100px'
                        className='text-center'
                      >
                        Actions
                      </th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <input
                          type='checkbox'
                          name='user'
                          id='user'
                          value={item.user_id}
                          checked={selectedPDFId.includes(item.id)}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            const newSelectedPDFId = [...selectedPDFId];
                            const newSelectedIds = [...selectedIds];

                            if (isChecked) {
                              newSelectedPDFId.push(item.id);
                              newSelectedIds.push(item.user_id);
                            } else {
                              const idIndex = newSelectedPDFId.indexOf(item.id);
                              const userIdIndex = newSelectedIds.indexOf(
                                item.user_id
                              );

                              if (idIndex > -1) {
                                newSelectedPDFId.splice(idIndex, 1);
                              }
                              if (userIdIndex > -1) {
                                newSelectedIds.splice(userIdIndex, 1);
                              }
                            }

                            setSelectedPDFId(newSelectedPDFId);
                            setSelectedIds(newSelectedIds);
                          }}
                        />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>
                        <div
                          className={`status ${item.status
                            .toLowerCase()
                            .split(" ")
                            .join("_")}`}
                        >
                          {item.status}
                        </div>
                      </td>
                      {isActive !== "Registered" ? (
                        <td className='text-center'>
                          <button
                            className={`${style.clear_btn} py-0 ${
                              item.status !== "In Progress"
                                ? "d-block"
                                : "d-none"
                            }`}
                            onClick={() => {
                              setShowPreviewForm(true);
                              setId(item.id);
                              setformStatus(item.status);
                            }}
                          >
                            View
                          </button>
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className='row mt-30'>
            <div className='col-md-auto d-flex justify-center align-items-center'>
              <p>Rows Per Page</p>
              <select
                id='pagination_dropdown'
                name='pagination_dropdown'
                className={`${style.pagination_dropdown}`}
                onChange={(e) => onCountChange(e.target.value)}
              >
                {paginationCount.map((count) => (
                  <option
                    key={count}
                    value={count}
                  >
                    {count}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md d-flex justify-end'>
              <button
                className={`${style.pagination_count}`}
                onClick={() => onPageClick(totalPages[0])}
              >
                <FaAnglesLeft />
              </button>
              <button
                className={`${style.pagination_count}`}
                onClick={() => onPageClick(pageNo - 1)}
              >
                <FaAngleLeft />
              </button>
              {shownPages.map((page, index) => (
                <button
                  className={`${style.pagination_count} ${
                    pageNo === page ? style.page_active_count : ""
                  } pl-3 pr-3`}
                  key={index}
                  onClick={() => onPageClick(page)}
                >
                  <p>{page}</p>
                </button>
              ))}
              <button
                className={`${style.pagination_count}`}
                onClick={() => onPageClick(pageNo + 1)}
              >
                <FaAngleRight />
              </button>
              <button
                className={`${style.pagination_count}`}
                onClick={() => onPageClick(totalPages[totalPages.length - 1])}
              >
                <FaAnglesRight />
              </button>
            </div>
          </div>
          {showPreviewForm &&
            createPortal(
              <PreviewForm
                onClose={() => setShowPreviewForm(false)}
                id={id}
                formStatus={formStatus}
                setStatusUpdated={setStatusUpdated}
                statusUpdated={statusUpdated}
              />,
              document.getElementById("modal")
            )}
          {showAlertModal &&
            createPortal(
              <SetAlertModal
                onClose={() => {setShowAlertModal(false)
                  setSelectedIds([]);
                  setSelectedPDFId([]);
                }
                }
                ids={selectedIds}
              />,
              document.getElementById("modal")
            )}
        </>
      )}
    </>
  );
}

export default Dashboard;
