const page3FormFields = {
  vt_information_of_passed_student: [
    {
      level: "",
      rigveda: "",
      shuklayajurve: "",
      krishnayajurve: "",
      samveda: "",
      atharvaveda: "",
    },
  ],
  vt_list_of_student_passed_the_kramanta_ghananta: "",
  vt_list_of_student_passed_the_kramanta_ghananta_detail: [
    {
      vedavidyarthi_name: "",
      level_of_vedadhyaya: "",
      other: "",
      name_of_the_examine_institution: "",
      year: "",
      division: "",
      attached_document_string: "",
    },
  ],

  vt_list_of_awards_recived_by_students: "",
  vt_list_of_awards_recived_by_students_detail: [
    {
      student_name: "",
      name_of_the_award: "",
      name_of_the_institute: "",
      year: "",
      attached_document_string: "",
    },
  ],
  vt_daily_routine_of_the_student: "",
  vt_list_of_full_time_and_part_time: "",
  vt_list_of_full_time_and_part_time_details: [
    {
      Adhyapaka_name: "",
      vedshakha: "",
      level_of_vedgyan: "",
      other_vedgyan: "",
      age: "",
      fulltime_parttime: "",
      attached_document_string: "",
    },
  ],

  vt_left_the_vedvidyalaya: "",
  vt_left_the_vedvidyalaya_details: [
    {
      teacher_name: "",
      vedshakha: "",
      when_started: "",
      when_end: "",
      reason_for_leaving: "",
    },
  ],
  vt_former_vedavidyarthi: "",
  vt_former_vedavidyarthi_details: [
    {
      student_name: "",
      vedadgyapan_finish: "",
      vedshakha: "",
      institution_begin_vedadhyapan: "",
    },
  ],
  vt_list_of_awards_receive_by_teacher: "",
  vt_list_of_awards_receive_by_teacher_details: [
    {
      teacher_name: "",
      name_of_award: "",
      name_of_institution: "",
      year: "",
      attached_document_string: "",
    },
  ],
  vt_daily_routine_of_the_vedadhyapaks: "",
  vt_efforts_being_made_by_the_teacher: "",
};
export default page3FormFields;
