const Page3FormField = {
  gi_marriage_status: "",
  gi_carry_dand: "",
  gi_sandhyavandan_frequency: "",
  gi_place_of_living: "",
  gi_agnikarya: "",
  gi_practice_yoga: "",
  gi_routine_in_vedavidyalay: "",
  gi_ved_level: "",
  gi_interest_in: "",
  gi_career_goal: "",
  gi_life_purpose: "",
  gi_other_venture: "",
  gi_other_venture_details: [
    {
      undertaking_name: "",
      organiser: "",
      location: "",
      year: "",
    }
  ],
  gi_reason: "",
};

export default Page3FormField;
