const vtFields = {
  vt_veda_teaching: [
    {
      from_month_year: "",
      till_month_year: "",
      name_vedavidyalaya: "",
      location_and_state: "",
    },
  ],
  vt_address_present_organization: "",
  vt_pincode_present_organization: "",
  vt_district_present_organization: "",
  vt_state_present_organization: "",
  vt_name_head_of_the_institution: "",
  vt_mobile_head_of_the_institution: "",
  vt_last_five_years_students_passed: [
    {
      yindex: 0,
      mulanta: "",
      kramanta: "",
      ghananta: "",
      total: "",
    },
    {
      yindex: 1,
      mulanta: "",
      kramanta: "",
      ghananta: "",
      total: "",
    },
    {
      yindex: 2,
      mulanta: "",
      kramanta: "",
      ghananta: "",
      total: "",
    },
    {
      yindex: 3,
      mulanta: "",
      kramanta: "",
      ghananta: "",
      total: "",
    },
    {
      yindex: 4,
      mulanta: "",
      kramanta: "",
      ghananta: "",
      total: "",
    },
  ],
  vt_students_studying_with_you_at_present: {
    mulanta: "",
    kramanta: "",
    ghananta: "",
    shadanga: "",
    lakshana: "",
    bhashya: "",
    total: "",
  },
  vt_students_taught_by_you_entire_time_as_vedadhyapak: {
    mulanta: "",
    kramanta: "",
    ghananta: "",
    total: "",
  },
  vt_did_not_complete_the_study_of_veda: "",
  vt_attendance_in_veda_sabhas: "",
  vt_attendance_in_veda_sabhas_table: [
    {
      student_name: "",
      study: "",
      name_examination_body: "",
      year: "",
      certificate: "",
      certificate_string: "",
    },
  ],
  vt_veda_students_currently_being_taught_by_you: "",
  vt_veda_students_currently_being_taught_by_you_table: [
    {
      student_name: "",
      age: "",
      exam_level: "",
      other: "",
      year: "",
      certificate: "",
      certificate_string: "",
    },
  ],
  vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka: "",
  vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table: [
    {
      vedvidyarthi_name: "",
      last_year_of_vedadhyayan: "",
      student_vedshakha: "",
      student_vedshakha_other: "",
      institution_name: "",
    },
  ],
  vt_shakhas_that_are_dying: "",
  vt_teaching_preservation_details: "",
  vt_have_you_taught_any_member_detail: "",
  vt_family_members_taught_by_you: [
    {
      family_member_name: "",
      relationship: "",
      level_of_study: "",
      other: "",
      family_member_occupation: "",
    },
  ],
};

export default vtFields;
