import React, { useEffect, useState } from "react";
import "./style.css";

function Page5_information_given_by_your_present_guruji({
  formData,
  formik,
  handleInputChange,
  handleInputSave,
  lang,
  fileUploadFunction,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(4);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.g_guruji_name_info, count);
    count = handleInputValue(formik?.values?.g_guruji_education_name, count);
    count = handleInputValue(formik?.values?.g_applicant_conduct_comments, count);
    count = handleInputValue(formik?.values?.g_affidavit_file_string, count);
    // count = handleInputValue(formik?.values?.heard_from_applicant, count);
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(5, totalCount, actualCount);
  }, [actualCount, totalCount]);
  return (
    <div>
      <h3>
        {lang == "english"
          ? "Information given by your present vedadhyapak"
          : lang == "hindi"
            ? "आपके वर्तमान वेदाध्यापक द्वारा दी गई जानकारी"
            : "भवतः वर्तमानवेदाध्यापकेन दत्ता सूचना"}
      </h3>
      <p className="text-primary mb-30">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
            ? "सभी प्रश्न अनिवार्य हैं।"
            : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>
      <div className="row">
        <div className="col-md-6 col-lg-4 form-group">
          <label htmlFor="g_guruji_name_info">
            {lang == "english"
              ? "Vedaadhyapaka name"
              : lang == "hindi"
                ? "वेदाध्यापक का नाम"
                : "वेदाध्यापकस्य नाम"}
          </label>
          <input
            type="text"
            className="form-control"
            id="g_guruji_name_info"
            name="g_guruji_name_info"
            value={formik.values.g_guruji_name_info || ""}
            onChange={(e) => {
              handleInputChange("g_guruji_name_info", e);
              formik.setFieldValue("ga_teacher_full_name", e.target.value);
            }}
            onBlur={(e) => handleInputSave()}
          />
          {formik.touched && formik.errors.g_guruji_name_info ? (
            <div className="text-danger">
              {formik.errors.g_guruji_name_info}
            </div>
          ) : null}
        </div>
        <div className="col-md-6 col-lg-4 form-group">
          <label htmlFor="g_guruji_education_name">
            {lang == "english"
              ? "The highest education level attained by your Vedadhyapak"
              : lang == "hindi"
                ? "वेदाध्यापक का उच्चतम शिक्षास्तर"
                : "वेदाध्यापकस्य उच्चतमः शिक्षास्तरः"}
          </label>
          <input
            type="text"
            className="form-control"
            id="g_guruji_education_name"
            name="g_guruji_education_name"
            onChange={(e) => handleInputChange("g_guruji_education_name", e)}
            onBlur={(e) => {
              handleInputSave(e);
            }}
            value={formik.values.g_guruji_education_name || ""}
          />
          {formik.touched && formik.errors.g_guruji_education_name ? (
            <div className="text-danger">
              {formik.errors.g_guruji_education_name}
            </div>
          ) : null}
        </div>
        <div className="col-md-6 col-lg-4 form-group">
          <label htmlFor="g_guruji_education">
            {lang == "english"
              ? "if any, attach the certificate"
              : lang == "hindi"
                ? "प्रमाणपत्र यदि हो तो, संलग्न कीजिये"
                : "प्रमाणपत्रम् अस्ति चेत् संलग्नं करोतु"}
          </label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
            className="form-control"
            id="g_guruji_education"
            name="g_guruji_education"
            onChange={async (e) => {
              e.preventDefault();
              if (e.target.files.length > 0) {
                await fileUploadFunction(
                  "g_guruji_education_string",
                  e.target.files[0]
                );
              }
            }}
            onBlur={(e) => {
              handleInputSave(e);
            }}
          />
          {formik.values?.g_guruji_education_string && (
            <button
              className="uploadLink"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values.g_guruji_education_string}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              {`${formik.values.g_guruji_education_string.replace(
                "upload-file/",
                ""
              )}`}
            </button>
          )}
          {formik.touched && formik.errors.g_guruji_education_string ? (
            <div className="text-danger">
              {formik.errors.g_guruji_education_string}
            </div>
          ) : null}
        </div>
        <div className="col-lg-12 form-group">
          <label htmlFor="g_applicant_conduct_comments">
            {lang == "english"
              ? "Has the conduct of the applicant been in accordance of vedic principles? Comment"
              : lang == "hindi"
                ? "क्या आवेदक का आचरण वेदानुकूल रहा है? अपनी टिप्पणी दीजिये।"
                : "आवेदकस्य आचरणं वेदानुरूपं जातम् वा? इति स्वटिप्पणीं ददातु।"}
          </label>

          <textarea
            name="g_applicant_conduct_comments"
            id="g_applicant_conduct_comments"
            value={formik.values.g_applicant_conduct_comments || ""}
            onChange={(e) =>
              handleInputChange("g_applicant_conduct_comments", e)
            }
            className="form-control"
            maxLength={700}
            onBlur={(e) => {
              handleInputSave();
            }}
          ></textarea>
          <p className="text-right mb-3">
            {formik.values.g_applicant_conduct_comments.length}
            /700{" "}
            {lang == "english"
              ? " Characters"
              : lang == "hindi"
                ? " शब्द"
                : " शब्दः"}
          </p>
          {formik.touched && formik.errors.g_applicant_conduct_comments ? (
            <div className="text-danger">
              {formik.errors.g_applicant_conduct_comments}
            </div>
          ) : null}
        </div>
        <div className="col-md-12 col-lg-12 form-group">
          <label htmlFor="g_vikrati_paths_comments">
            {lang == "english"
              ? "Have you heard the applicant chant all the vikrati and / or ghan paath, for the level which he had passed exams, from start to finish? If yes, are you satisfied that your student is fully proficient in the  veda to the level at which he has sat the exam?"
              : lang == "hindi"
                ? "आवेदक ने जिस-जिस स्तर की परीक्षा उत्तीर्ण की हैं, उन सभी विकृति पाठ / गान को क्या आपने आवेदक से आद्योपान्त सुना है? यदि हाँ तो क्या आप सन्तुष्ट हैं कि आपका विद्यार्थी उस स्तर के पाठ में पूर्णतया निपुण है?"
                : "आवेदकः येषु स्तरेषु उत्तीर्णः अस्ति तेषु सर्वेषु स्तरेषु आवेदकस्य सर्वाणि विकृतिपाठानि/गानानि भवद्भिः श्रुतानि वा? यदि आम् तर्हि भवतां विद्यार्थी तस्य स्तरस्य पाठे पूर्णतया प्रवीणः इति भवन्तः सन्तुष्टाः सन्ति वा?"}
          </label>
          <div class="table-responsive">
            <table width="100%" class="table">
              <tr>
                <th width="200">
                  {lang == "english"
                    ? "Education level / Vikriti / Gaana"
                    : lang == "hindi"
                      ? " शिक्षा  स्तर / विकृति / गान"
                      : " शिक्षास्तरः / विकृतिः / गानम्"}
                </th>
                <th colspan="2" class="text-center">
                  {lang == "english"
                    ? "Have you heard from the applicant completely?"
                    : lang == "hindi"
                      ? "आवेदक से आद्योपान्त सुना है?"
                      : "किं भवान् आवेदकात् आद्योपान्तं श्रुतवान्?"}
                </th>
                <th>
                  {lang == "english"
                    ? "Comment on applicant's inadequacy"
                    : lang == "hindi"
                      ? "आवेदक की पूर्णता पर टिप्पणी"
                      : "आवेदकस्य पूर्णताविषयिणी टिप्पणी"}
                </th>
              </tr>
              <tr>
                <td>
                  {lang == "english"
                    ? "Samhita patha"
                    : lang == "hindi"
                      ? "संहिता पाठ"
                      : "संहितापाठः"}
                </td>
                <td width="100" className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td width="100" className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  {lang == "english"
                    ? "Pada patha"
                    : lang == "hindi"
                      ? "पद पाठ"
                      : "पदपाठः"}
                </td>
                <td className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td className="nowrap text-center">
                  ⬜{" "}
                  {lang == "english"
                    ? "Yes "
                    : lang == "hindi"
                      ? "हाँ "
                      : "आम् "}
                </td>
                <td className="nowrap text-center">
                  ⬜ {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
                </td>
                <td>&nbsp;</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className="row justify-content-center align-items-start">
        <div className="col-md-4 col-lg-4 form-group noPrint">
          <label className="d-block">
            {lang == "english"
              ? "Download PDF"
              : lang == "hindi"
                ? "पीडीएफ डाउनलोड करे"
                : "पीडीएफ इति डाउनलोड इति करोतु"}
          </label>
          <button
            className="btn btn-outline-danger"
            onClick={(e) => {
              e.preventDefault();
              window.print();
            }}
          >
            Download
          </button>
        </div>
        <div className="col-md-4 col-lg-4 form-group noPrint">
          <label htmlFor="g_affidavit_file">
            {lang == "english"
              ? "Upload affidavit"
              : lang == "hindi"
                ? "शपथपत्र अपलोड करे"
                : "शपथपत्रम् अपलोड इति करोतु"}
          </label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
            className="form-control"
            id="g_affidavit_file"
            name="g_affidavit_file"
            onChange={async (e) => {
              e.preventDefault();
              if (e.target.files.length > 0) {
                await fileUploadFunction(
                  "g_affidavit_file_string",
                  e.target.files[0]
                );
              }
            }}
            onBlur={(e) => {
              handleInputSave(e);
            }}
          />
          {formik.values?.g_affidavit_file_string && (
            <button
              className="uploadLink"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values.g_affidavit_file_string}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              {`${formik.values.g_affidavit_file_string.replace(
                "upload-file/",
                ""
              )}`}
            </button>
          )}
          {formik.touched && formik.errors.g_affidavit_file_string ? (
            <div className="text-danger">
              {formik.errors.g_affidavit_file_string}
            </div>
          ) : null}
        </div>
        {/* <div className="col-md-4 col-lg-4 form-group">
          <label htmlFor="heard_from_applicant">
            {lang == "english"
              ? "Heard from the applicant"
              : lang == "hindi"
                ? "आवेदक से आद्योपान्त सुना है?"
                : "आवेदकात् आद्योपान्तं श्रुतम्?"}
          </label>
          <input
            type="text"
            className="form-control"
            id="heard_from_applicant"
            name="heard_from_applicant"
            value={formik.values.heard_from_applicant || ""}
            onChange={(e) => handleInputChange("heard_from_applicant", e)}
            onBlur={(e) => handleInputSave()}
          />
          {formik.touched && formik.errors.heard_from_applicant ? (
            <div className="text-danger">
              {formik.errors.heard_from_applicant}
            </div>
          ) : null}
        </div> */}
      </div>
    </div>
  );
}

export default Page5_information_given_by_your_present_guruji;
