import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { useFormik } from "formik";
import { showErrorToast, showSuccessToast } from '../../Utils/Toaster.js';
import axiosPrivate from '../../Hooks/axiosPrivate.js';

const validationRules = {
    name: Yup.string().required("This field is required"),
    email: Yup.string().email("Invalid email address").required("This field is required"),
    mobile: Yup.number().required("This field is required").min(10, "Mobile no. must be of 10 digits"),
    password: Yup.string().required("This field is required").min(8, "Password must be at least 8 characters long"),
}

const AddAdmin = () => {
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            mobile: "",
            password: "",
            role: "admin",
        },
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object(validationRules),
        onSubmit: async (values, { setSubmitting, setFieldError, resetForm }) => {
            let userData = localStorage.getItem("userData");
            userData = userData ? JSON.parse(userData) : {};
            if (userData?.userId) {
                try {
                    const formData = {
                        name: values.name,
                        email: values.email,
                        mobile: values.mobile,
                        password: values.password,
                        role: "admin",
                    };
                    const response = await axiosPrivate.post(
                        "register-admin",
                        formData
                    );
                    if (response.status == 201) {
                        showSuccessToast(response.data.message);
                        resetForm();
                    } else {
                        showErrorToast(response.data.message);
                    }
                } catch (error) {
                    showErrorToast(error?.response?.data?.errors[0]?.msg);
                }
            }
        },
    });
    return (
        <div className='row justify-center'>
            <div className="col-md-4">
                <h3>Add Admin</h3>
                <form onSubmit={formik.handleSubmit}>
                    <label htmlFor="name">
                        Name
                    </label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        value={formik.values.name || ""}
                        onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.touched && formik.errors.name ? (
                        <div className="text-danger">{formik.errors.name}</div>
                    ) : null}
                    <br />
                    <label htmlFor="email">
                        Email
                    </label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        value={formik.values.email || ""}
                        onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.touched && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                    <br />
                    <label htmlFor="mobile">
                        Mobile No.
                    </label>
                    <input
                        id="mobile"
                        name="mobile"
                        type="number"
                        className="form-control"
                        value={formik.values.mobile || ""}
                        onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.touched && formik.errors.mobile ? (
                        <div className="text-danger">{formik.errors.mobile}</div>
                    ) : null}
                    <br />
                    <label htmlFor="password">
                        Password
                    </label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        className="form-control"
                        value={formik.values.password || ""}
                        onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.touched && formik.errors.password ? (
                        <div className="text-danger">{formik.errors.password}</div>
                    ) : null}
                    <br />
                    <button type="submit" className="btn btn-danger" style={{ width: '100%' }}>Submit</button>
                </form>
            </div>
        </div>
    )
}

export default AddAdmin
