const Page7FormField = {
  a_annexure_details: [
    {
      year: "",
      certificate_details: "",
      uploaded_doc: "",
      uploaded_doc_string: "",
    },
  ]
};

export default Page7FormField;
