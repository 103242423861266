import React, { useEffect, useState } from "react";
function Section_1_personal_information({
  formik,
  lang,
  handleInputSave,
  handleInputChange,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(28);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.name, count);
    count = handleInputValue(formik?.values?.full_address, count);
    count = handleInputValue(formik?.values?.name_of_the_head, count);
    count = handleInputValue(formik?.values?.mobile, count);
    count = handleInputValue(formik?.values?.email, count);
    // count = handleInputValue(formik?.values?.website, count);
    count = handleInputValue(formik?.values?.contact_person_name, count);
    count = handleInputValue(formik?.values?.contact_person_email, count);
    count = handleInputValue(formik?.values?.contact_person_mobile, count);
    count = handleInputValue(
      formik?.values?.full_correspondence_pincode,
      count
    );
    count = handleInputValue(
      formik?.values?.full_correspondence_district,
      count
    );
    count = handleInputValue(formik?.values?.full_correspondence_state, count);
    count = handleInputValue(
      formik?.values?.full_correspondence_address,
      count
    );
    count = handleInputValue(formik?.values?.communication_language, count);
    count = handleInputValue(formik?.values?.founder_institution, count);
    count = handleInputValue(formik?.values?.trust_name, count);
    count = handleInputValue(formik?.values?.registration_no, count);
    count = handleInputValue(formik?.values?.chief_trustee_name, count);
    count = handleInputValue(formik?.values?.trustee_mobile, count);
    count = handleInputValue(formik?.values?.sponsor_name, count);
    count = handleInputValue(formik?.values?.sponsor_address, count);
    count = handleInputValue(formik?.values?.head_sponsor_name, count);
    count = handleInputValue(formik?.values?.founder_name, count);
    count = handleInputValue(formik?.values?.founder_address, count);
    count = handleInputValue(formik?.values?.founder_mobile, count);
    count = handleInputValue(formik?.values?.starting_date, count);
    count = handleInputValue(formik?.values?.was_school_closed, count);
    count = handleInputValue(formik?.values?.from_date, count);
    count = handleInputValue(formik?.values?.till_date, count);
    setActualCount(count);
    // setTotalCount(total_count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(1, totalCount, actualCount);
  }, [actualCount, totalCount]);

  return (
    <div>
      <div className="mt-0 form">
        <h3>
          {lang == "english"
            ? "General information (Profile)"
            : lang == "hindi"
              ? "सामान्य स्वरूप (प्रोफाइल)"
              : "सामान्यस्वरूपः (प्रोफाइल इति)"}
        </h3>
        <p className="text-primary mb-30">
          {lang == "english"
            ? "All fields are mandatory"
            : lang == "hindi"
              ? "सभी प्रश्‍न अनिवार्य हैं।"
              : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
        </p>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="name">
              {lang == "english"
                ? "Name of vedavidyalaya"
                : lang == "hindi"
                  ? "वेदविद्यालय का नाम"
                  : "वेदविद्यालयस्य नाम"}
            </label>
            <input
              type="text"
              name="name"
              id="name"
              onChange={(e) => handleInputChange("name", e)}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.name}
              className="form-control"
            />
            {formik?.errors?.name && (
              <div className="text-danger">{formik?.errors?.name}</div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="full_address">
              {lang == "english"
                ? "Full address of vedavidyalaya"
                : lang == "hindi"
                  ? "वेदविद्यालय का पूर्ण पता"
                  : "वेदविद्यालयस्य पूर्णपत्रसङ्केतः"}
            </label>
            <input
              type="text"
              name="full_address"
              id="full_address"
              onChange={(e) => handleInputChange("full_address", e)}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.full_address}
              className="form-control"
            />
            {formik?.errors?.full_address && (
              <div className="text-danger">{formik?.errors?.full_address}</div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="name_of_the_head">
              {lang == "english"
                ? "Name of the head of vedavidyalaya"
                : lang == "hindi"
                  ? "संस्था प्रमुख का नाम"
                  : "संस्थाप्रमुखस्य नाम"}
            </label>
            <input
              type="text"
              name="name_of_the_head"
              id="name_of_the_head"
              onChange={(e) => {
                handleInputChange("name_of_the_head", e);
                formik.setFieldValue("af_name", e.target.value);
              }}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.name_of_the_head}
              className="form-control"
            />
            {formik?.errors?.name_of_the_head && (
              <div className="text-danger">
                {formik?.errors?.name_of_the_head}
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="mobile">
              {lang == "english"
                ? "Mobile number"
                : lang == "hindi"
                  ? "मोबाइल न॰"
                  : "चलदूरवाणीसङ्ख्या"}
            </label>
            <input
              type="number"
              name="mobile"
              id="mobile"
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  handleInputChange("mobile", e);
                }
              }}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.mobile}
              className="form-control"
            />
            {formik?.errors?.mobile && (
              <div className="text-danger">{formik?.errors?.mobile}</div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="email">
              {lang == "english"
                ? "Email id"
                : lang == "hindi"
                  ? "ईमेल आई॰ डी॰"
                  : "अन्तर्जालीयपत्रसङ्केतः"}
            </label>
            <input
              type="text"
              name="email"
              id="email"
              onChange={(e) => handleInputChange("email", e)}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.email}
              className="form-control"
            />
            {formik?.errors?.email && (
              <div className="text-danger">{formik?.errors?.email}</div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="website">
              {lang == "english"
                ? "Website"
                : lang == "hindi"
                  ? "वेबसाइट"
                  : "अन्तर्जालपुटम्"}
            </label>
            <input
              type="text"
              name="website"
              id="website"
              onChange={(e) => handleInputChange("website", e)}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.website}
              className="form-control"
            />
            {formik?.errors?.website && (
              <div className="text-danger">{formik?.errors?.website}</div>
            )}
          </div>
        </div>
        <h5>
          {lang == "english"
            ? "Principal contact officer / vedavidyalaya head related to this application"
            : lang == "hindi"
              ? "इस आवेदन से सम्बन्धित प्रमुख सम्पर्क अधिकारी / संस्था प्रमुख"
              : "अनेन आवेदनपत्रेण सम्बद्धः प्रमुखः सम्पर्काधिकारी / संस्थाप्रमुखः"}
        </h5>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="contact_person_name">
              {lang == "english" ? "Full Name" : lang == "hindi" ? "पूरा नाम" : "सम्पूर्ण नाम"}
            </label>
            <input
              type="text"
              id="contact_person_name"
              name="contact_person_name"
              className="form-control"
              onChange={(e) => handleInputChange("contact_person_name", e)}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.contact_person_name}
            />
            {formik?.errors?.contact_person_name && (
              <div className="text-danger">
                {formik?.errors?.contact_person_name}
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="contact_person_email">
              {lang == "english"
                ? "Email id"
                : lang == "hindi"
                  ? "ईमेल आई॰ डी॰"
                  : "अन्तर्जालीयपत्रसङ्केतः"}
            </label>
            <input
              type="text"
              id="contact_person_email"
              name="contact_person_email"
              className="form-control"
              onChange={(e) => handleInputChange("contact_person_email", e)}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.contact_person_email}
            />
            {formik?.errors?.contact_person_email && (
              <div className="text-danger">
                {formik?.errors?.contact_person_email}
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="contact_person_mobile">
              {lang == "english"
                ? "Mobile number"
                : lang == "hindi"
                  ? "मोबाइल न॰"
                  : "चलदूरवाणीसङ्ख्या"}
            </label>
            <input
              type="number"
              id="contact_person_mobile"
              name="contact_person_mobile"
              className="form-control"
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  handleInputChange("contact_person_mobile", e);
                }
              }}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.contact_person_mobile}
            />
            {formik?.errors?.contact_person_mobile && (
              <div className="text-danger">
                {formik?.errors?.contact_person_mobile}
              </div>
            )}
          </div>
        </div>

        <h5>
          {lang == "english"
            ? "Full correspondence address"
            : lang == "hindi"
              ? "पत्राचार का पूर्ण पता"
              : "पूर्णपत्रसङ्केतः"}
        </h5>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="full_correspondence_pincode">
              {lang == "english"
                ? "Pin code"
                : lang == "hindi"
                  ? "पिन कोड"
                  : "पिनकोड-क्रमाङ्क:"}
            </label>
            <input
              type="number"
              name="full_correspondence_pincode"
              id="full_correspondence_pincode"
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  handleInputChange("full_correspondence_pincode", e);
                }
              }}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.full_correspondence_pincode}
              className="form-control"
            />
            {formik?.errors?.full_correspondence_pincode && (
              <div className="text-danger">
                {formik?.errors?.full_correspondence_pincode}
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor=" full_correspondence_district">
              {lang == "english"
                ? "District"
                : lang == "hindi"
                  ? "जिला "
                  : "जनपदः"}
            </label>
            <input
              type="text"
              name="full_correspondence_district"
              id="full_correspondence_district"
              onChange={(e) =>
                handleInputChange("full_correspondence_district", e)
              }
              onBlur={(e) => handleInputSave(e)}
              value={formik.values?.full_correspondence_district}
              className="form-control"
            />
            {formik?.errors?.full_correspondence_district && (
              <div className="text-danger">
                {formik?.errors?.full_correspondence_district}
              </div>
            )}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="full_correspondence_state">
              {lang == "english"
                ? "State"
                : lang == "hindi"
                  ? "राज्य "
                  : "राज्यम् "}
            </label>
            <input
              type="text"
              name="full_correspondence_state"
              id="full_correspondence_state"
              onChange={(e) =>
                handleInputChange("full_correspondence_state", e)
              }
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values.full_correspondence_state}
              className="form-control"
            />
            {formik?.errors?.full_correspondence_state && (
              <div className="text-danger">
                {formik?.errors?.full_correspondence_state}
              </div>
            )}
          </div>
          <div className="col-12 form-group">
            <label htmlFor="full_correspondence_address">
              {lang == "english" ? "Address" : lang == "hindi" ? "पता" : "पता"}
            </label>
            <input
              name="full_correspondence_address"
              type="text"
              id="full_correspondence_address"
              className="form-control"
              onChange={(e) =>
                handleInputChange("full_correspondence_address", e)
              }
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.full_correspondence_address}
            />
            {formik?.errors?.full_correspondence_address && (
              <div className="text-danger">
                {formik?.errors?.full_correspondence_address}
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12 form-group">
            <label htmlFor="communication_language">
              {lang == "english" ? "" : lang == "hindi" ? "" : ""}
              {lang == "english"
                ? "In which language should the communication related to the application be done with you?"
                : lang == "hindi"
                  ? "आवेदन सम्बन्धित वार्ता आपसे किस भाषा में की जाए?"
                  : "आवेदनसम्बद्धा वार्ता भवता सह कया भाषया विधातव्या?"}
            </label>
            <select
              id="communication_language"
              name="communication_language"
              className="form-control"
              onChange={(e) => handleInputChange("communication_language", e)}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.communication_language}
            >
              <option>
                ---
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                    ? "चुने"
                    : "चिनोतु"}
                ---
              </option>
              <option value="हिन्दी">
                {lang == "english"
                  ? "हिन्दी"
                  : lang == "hindi"
                    ? "हिन्दी"
                    : "हिन्दी"}
              </option>
              <option value="संस्कृतम्">
                {lang == "english"
                  ? "संस्कृतम्"
                  : lang == "hindi"
                    ? "संस्कृतम्"
                    : "संस्कृतम्"}
              </option>
              <option value="English">
                {lang == "english"
                  ? "English"
                  : lang == "hindi"
                    ? "English"
                    : "English"}
              </option>
            </select>
            {formik?.errors?.communication_language && (
              <div className="text-danger">
                {formik?.errors?.communication_language}
              </div>
            )}
          </div>
        </div>

        <h6>
          <b>
            {lang == "english"
              ? "Information related to founders"
              : lang == "hindi"
                ? "संस्थापकों से सम्बन्धित जानकारी"
                : "संस्थापक-सम्बद्धा सूचना"}
          </b>
        </h6>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="founder_institution">
              {lang == "english"
                ? "What is the institution of Vedavidyalaya? "
                : lang == "hindi"
                  ? "वेदविद्यालय की संस्था क्या है?"
                  : "वेदविद्यालयस्य का संस्था वर्तते?"}
            </label>
            <select
              id="founder_institution"
              name="founder_institution"
              className="form-control"
              onChange={(e) => {
                handleInputChange("founder_institution", e);
                if (e.target.value == "trust") {
                  formik.setFieldValue("trust_name", "");
                  formik.setFieldValue("registration_no", "");
                  formik.setFieldValue("chief_trustee_name", "");
                  formik.setFieldValue("trustee_mobile", "");
                } else {
                  formik.setFieldValue("trust_name", "N/A");
                  formik.setFieldValue("registration_no", "N/A");
                  formik.setFieldValue("chief_trustee_name", "N/A");
                  formik.setFieldValue("trustee_mobile", "0000000000");
                }

                if (e.target.value == "other_institution") {
                  formik.setFieldValue("sponsor_name", "");
                  formik.setFieldValue("sponsor_address", "");
                  formik.setFieldValue("head_sponsor_name", "");
                } else {
                  formik.setFieldValue("sponsor_name", "N/A");
                  formik.setFieldValue("sponsor_address", "N/A");
                  formik.setFieldValue("head_sponsor_name", "N/A");
                }

                if (e.target.value == "individual_founder") {
                  formik.setFieldValue("founder_name", "");
                  formik.setFieldValue("founder_address", "");
                  formik.setFieldValue("founder_mobile", "");
                } else {
                  formik.setFieldValue("founder_name", "N/A");
                  formik.setFieldValue("founder_address", "N/A");
                  formik.setFieldValue("founder_mobile", "0000000000");
                }
                if (e.target.value == "") {
                  // trust
                  formik.setFieldValue("trust_name", "N/A");
                  formik.setFieldValue("registration_no", "N/A");
                  formik.setFieldValue("chief_trustee_name", "N/A");
                  formik.setFieldValue("trustee_mobile", "0000000000");
                  // other_institute
                  formik.setFieldValue("sponsor_name", "N/A");
                  formik.setFieldValue("sponsor_address", "N/A");
                  formik.setFieldValue("head_sponsor_name", "N/A");
                  // individual_founder
                  formik.setFieldValue("founder_name", "N/A");
                  formik.setFieldValue("founder_address", "N/A");
                  formik.setFieldValue("founder_mobile", "0000000000");
                }
              }}
              onBlur={(e) => handleInputSave(e)}
              value={formik.values?.founder_institution}
            >
              <option>
                ---{" "}
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                    ? "चुने"
                    : "चिनोतु"}
                ---
              </option>
              <option value="trust">
                {lang == "english"
                  ? "Trust"
                  : lang == "hindi"
                    ? "न्यास"
                    : "न्यासः"}
              </option>
              <option value="other_institution">
                {lang == "english"
                  ? "Other institution"
                  : lang == "hindi"
                    ? "अन्य संस्था"
                    : "अन्या संस्था"}
              </option>
              <option value="individual_founder">
                {lang == "english"
                  ? "Individual founder"
                  : lang == "hindi"
                    ? "व्यक्तिगत संस्था"
                    : "व्यक्तिगतसंस्था"}
              </option>
            </select>
            {formik?.errors?.founder_institution && (
              <div className="text-danger">
                {formik?.errors?.founder_institution}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display:
              formik.values?.founder_institution == "trust" ? "block" : "none",
          }}
        >
          <div className="row">
            <div className="col-md-6 col-lg-3 form-group">
              <label htmlFor="trust_name">
                {lang == "english"
                  ? "Name of The Trust?"
                  : lang == "hindi"
                    ? "न्यास का नाम"
                    : "न्यासस्य नाम"}
              </label>
              <input
                type="text"
                id="trust_name"
                name="trust_name"
                className="form-control"
                onChange={(e) => handleInputChange("trust_name", e)}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.trust_name}
              />
              {formik?.errors?.trust_name && (
                <div className="text-danger">{formik?.errors?.trust_name}</div>
              )}
            </div>
            <div className="col-md-6 col-lg-3 form-group">
              <label htmlFor="registration_no">
                {lang == "english"
                  ? "Registration number"
                  : lang == "hindi"
                    ? "पञ्जीकरण संख्या"
                    : "पञ्जीकरणक्रमाङ्कः"}
              </label>
              <input
                type="text"
                id="registration_no"
                name="registration_no"
                className="form-control"
                onChange={(e) => handleInputChange("registration_no", e)}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.registration_no}
              />
              {formik?.errors?.registration_no && (
                <div className="text-danger">
                  {formik?.errors?.registration_no}
                </div>
              )}
            </div>
            <div className="col-md-6 col-lg-3 form-group">
              <label htmlFor="chief_trustee_name">
                {lang == "english"
                  ? "Name of chief Trustee"
                  : lang == "hindi"
                    ? "मुख्य न्यासी का नाम"
                    : "न्यासप्रमुख-नाम"}
              </label>
              <input
                type="text"
                id="chief_trustee_name"
                name="chief_trustee_name"
                className="form-control"
                onChange={(e) => handleInputChange("chief_trustee_name", e)}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.chief_trustee_name}
              />
              {formik?.errors?.chief_trustee_name && (
                <div className="text-danger">
                  {formik?.errors?.chief_trustee_name}
                </div>
              )}
            </div>
            <div className="col-md-6 col-lg-3 form-group">
              <label htmlFor="trustee_mobile">
                {lang == "english"
                  ? "Mobile number"
                  : lang == "hindi"
                    ? "मोबाइल न॰"
                    : "चलदूरवाणीसङ्ख्या"}
              </label>
              <input
                type="number"
                id="trustee_mobile"
                name="trustee_mobile"
                className="form-control"
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    handleInputChange("trustee_mobile", e);
                  }
                }}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.trustee_mobile}
              />
              {formik?.errors?.trustee_mobile && (
                <div className="text-danger">
                  {formik?.errors?.trustee_mobile}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display:
              formik.values?.founder_institution == "other_institution"
                ? "block"
                : "none",
          }}
        >
          <div className="row">
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="sponsor_name">
                {lang == "english"
                  ? "Name of The sponsor"
                  : lang == "hindi"
                    ? "प्रायोजक का नाम"
                    : "प्रायोजकस्य नाम"}
              </label>
              <input
                type="text"
                id="sponsor_name"
                name="sponsor_name"
                className="form-control"
                onChange={(e) => handleInputChange("sponsor_name", e)}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.sponsor_name}
              />
              {formik?.errors?.sponsor_name && (
                <div className="text-danger">
                  {formik?.errors?.sponsor_name}
                </div>
              )}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="sponsor_address">
                {lang == "english"
                  ? "Sponsor's address"
                  : lang == "hindi"
                    ? "प्रायोजक का पता"
                    : "प्रायोजकपत्रसङ्केतः"}
              </label>
              <input
                type="text"
                id="sponsor_address"
                name="sponsor_address"
                className="form-control"
                onChange={(e) => handleInputChange("sponsor_address", e)}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.sponsor_address}
              />
              {formik?.errors?.sponsor_address && (
                <div className="text-danger">
                  {formik?.errors?.sponsor_address}
                </div>
              )}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="head_sponsor_name">
                {lang == "english"
                  ? "Name of sponsoring institution head"
                  : lang == "hindi"
                    ? "प्रायोजक संस्थाप्रमुख का नाम"
                    : "प्रायोजक-संस्थाप्रमुखस्य नाम"}
              </label>
              <input
                type="text"
                id="head_sponsor_name"
                name="head_sponsor_name"
                className="form-control"
                onChange={(e) => handleInputChange("head_sponsor_name", e)}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.head_sponsor_name}
              />
              {formik?.errors?.head_sponsor_name && (
                <div className="text-danger">
                  {formik?.errors?.head_sponsor_name}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display:
              formik.values?.founder_institution == "individual_founder"
                ? "block"
                : "none",
          }}
        >
          <div className="row">
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="founder_name">
                {lang == "english"
                  ? "Name of The principal founder"
                  : lang == "hindi"
                    ? "प्रमुख संस्थापक का नाम"
                    : "प्रधान-संस्थापकस्य नाम"}
              </label>
              <input
                type="text"
                id="founder_name"
                name="founder_name"
                className="form-control"
                onChange={(e) => handleInputChange("founder_name", e)}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.founder_name}
              />
              {formik?.errors?.founder_name && (
                <div className="text-danger">
                  {formik?.errors?.founder_name}
                </div>
              )}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="founder_address">
                {lang == "english"
                  ? "Full address of the founder"
                  : lang == "hindi"
                    ? "संस्थापक का पूरा पता"
                    : "संस्थापकस्य पूर्णपत्रसङ्केतः"}
              </label>
              <input
                type="text"
                id="founder_address"
                name="founder_address"
                className="form-control"
                onChange={(e) => handleInputChange("founder_address", e)}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.founder_address}
              />
              {formik?.errors?.founder_address && (
                <div className="text-danger">
                  {formik?.errors?.founder_address}
                </div>
              )}
            </div>
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="founder_mobile">
                {lang == "english"
                  ? "Mobile number"
                  : lang == "hindi"
                    ? "मोबाइल न॰"
                    : "चलदूरवाणीसङ्ख्या"}
              </label>
              <input
                type="number"
                id="founder_mobile"
                name="founder_mobile"
                className="form-control"
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    handleInputChange("founder_mobile", e);
                  }
                }}
                onBlur={(e) => handleInputSave(e)}
                value={formik?.values?.founder_mobile}
              />
              {formik?.errors?.founder_mobile && (
                <div className="text-danger">
                  {formik?.errors?.founder_mobile}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="starting_date">
              {lang == "english"
                ? "Date of starting vedadhyayana in vedavidyalaya"
                : lang == "hindi"
                  ? "वेदविद्यालय में अध्यापन प्रारम्भ होने की दिनांक"
                  : "वेदविद्यालये अध्यापनारम्भस्य दिनाङ्कः"}
            </label>
            <input
              type="date"
              name="starting_date"
              maxLength={10}
              max={new Date()?.toISOString()?.slice(0, 10)}
              id="starting_date"
              onChange={(e) => handleInputChange("starting_date", e)}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.starting_date}
              className="form-control"
            />
            {formik?.errors?.starting_date && (
              <div className="text-danger">{formik?.errors?.starting_date}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="was_school_closed">
              {lang == "english"
                ? "Was the school completely closed during any period ?"
                : lang == "hindi"
                  ? "क्या किसी कालखण्ड में विद्यालय पूर्णतया बन्द रहा?"
                  : "कस्मिन् अपि अवधिकाले विद्यालयः पूर्णतया पिहितः आसीत् वा?"}
            </label>
            <select
              id="was_school_closed"
              name="was_school_closed"
              className="form-control"
              onChange={(e) => {
                handleInputChange("was_school_closed", e);
                if (e.target.value === "yes") {
                  formik.setFieldValue("from_date", "");
                  formik.setFieldValue("till_date", "");
                }
                if (e.target.value === "no") {
                  formik.setFieldValue("from_date", "N/A");
                  formik.setFieldValue("till_date", "N/A");
                }
              }}
              onBlur={(e) => handleInputSave(e)}
              value={formik.values?.was_school_closed}
            >
              <option>
                ---{" "}
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                    ? "चुने"
                    : "चिनोतु"}
                ---
              </option>
              <option value="yes">
                {lang == "english" ? "Yes" : lang == "hindi" ? "हाँ" : "आम्"}
              </option>
              <option value="no">
                {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
              </option>
            </select>
            {formik?.errors?.was_school_closed && (
              <div className="text-danger">
                {formik?.errors?.was_school_closed}
              </div>
            )}
          </div>
          <div
            style={{
              display:
                formik.values?.was_school_closed == "yes" ? "block" : "none",
            }}
            className="col-12"
          >
            <label htmlFor="starting_date">
              {lang == "english"
                ? "From when to when?"
                : lang == "hindi"
                  ? "कब से कब तक?"
                  : "पिहितावधिः कोऽस्ति?"}
            </label>
          </div>

          <div
            className="col-md-6 col-lg-4 form-group"
            style={{
              display:
                formik.values?.was_school_closed == "yes" ? "block" : "none",
            }}
          >
            <label htmlFor="from_date">
              {lang == "english"
                ? "From the date of"
                : lang == "hindi"
                  ? "दिनांक से"
                  : "दिनाङ्कतः"}
            </label>

            <input
              type="date"
              name="from_date"
              id="from_date"
              onChange={(e) => {
                handleInputChange("from_date", e);
              }}
              onBlur={(e) => {
                handleInputSave(e);
              }}
              value={formik?.values.from_date}
              className="form-control"
            />
            {formik?.errors?.from_date && (
              <div className="text-danger">{formik?.errors?.from_date}</div>
            )}
          </div>
          <div
            className="col-md-6 col-lg-4 form-group"
            style={{
              display:
                formik.values?.was_school_closed == "yes" ? "block" : "none",
            }}
          >
            <label htmlFor="till_date">
              {lang == "english"
                ? "Till date"
                : lang == "hindi"
                  ? "दिनांक तक"
                  : "दिनाङ्कपर्यन्तम्"}
            </label>
            <input
              type="date"
              name="till_date"
              id="till_date"
              onChange={(e) => handleInputChange("till_date", e)}
              onBlur={(e) => handleInputSave(e)}
              value={formik?.values?.till_date}
              className="form-control"
            />
            {formik?.errors?.till_date && (
              <div className="text-danger">{formik?.errors?.till_date}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section_1_personal_information;
