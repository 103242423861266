import React, { useEffect, useState } from "react";

function Section_4({
  handleInputChange,
  formik,
  handleInputSave,
  removeVedLiteratureRow,
  addVedLiteratureRow,
  addVedicSShrautaRow,
  removeVedicSShrautaRow,
  addTestimonialsAndAwardsRow,
  removeTestimonialsAndAwardsRow,
  addResearchPapersRow,
  removeResearchPapersRow,
  lang,
  fileUploadFunction,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(6);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(
      formik.values.vl_list_of_attendance_in_veda_sabha,
      count
    );
    count = handleInputValue(
      formik.values.vl_have_you_participated_in_vedic_shrauta_yajna,
      count
    );
    count = handleInputValue(
      formik.values.vl_information_on_testimonials_and_awards_received,
      count
    );
    count = handleInputValue(formik.values.vl_information_about_books, count);
    count = handleInputValue(
      formik.values.vl_have_you_performed_soma_yajna,
      count
    );
    count = handleInputValue(
      formik.values.vl_is_your_conduct_according_to_vedas,
      count
    );
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(4, totalCount, actualCount);
  }, [actualCount, totalCount]);

  return (
    <div>
      <h3>
        {lang == "english"
          ? "Please talk about your work on the ved including literature"
          : lang == "hindi"
          ? "वेद सम्बन्धित कार्य व साहित्य की जानकारी"
          : "वेद-सम्बद्धलेखनस्य वेदसम्बन्धस्य कार्याणां च सूचना"}
      </h3>
      <div className='row'>
        <div className='col-lg-8 form-group'>
          <label>
            {lang == "english"
              ? "Give a list of the attendance in ved sabhas, seminars, ved related seminars, workshops etc. attended by you."
              : lang == "hindi"
              ? "वेदसभाओं, संगोष्ठियों, कार्यशालाओं इत्यादि में उपस्थिति"
              : "वेदसभा-संगोष्ठी-कार्यशालासु उपस्थितिः"}
          </label>
          <select
            id='vl_list_of_attendance_in_veda_sabha'
            name='vl_list_of_attendance_in_veda_sabha'
            onChange={(e) => {
              handleInputChange("vl_list_of_attendance_in_veda_sabha", e);
              if (e.target.value == "no") {
                formik.setFieldValue(
                  "vl_list_of_attendance_in_veda_sabha_table",
                  [
                    {
                      meeting_or_conference: "N/A",
                      organizer: "N/A",
                      year: "N/A",
                      certificate: "N/A",
                      certificate_string: "N/A",
                    },
                  ]
                );
              }
              if (e.target.value == "yes") {
                formik.setFieldValue(
                  "vl_list_of_attendance_in_veda_sabha_table",
                  [
                    {
                      meeting_or_conference: "",
                      organizer: "",
                      year: "",
                      certificate: "",
                      certificate_string: "",
                    },
                  ]
                );
              }
            }}
            onBlur={(e) => handleInputSave()}
            value={formik.values.vl_list_of_attendance_in_veda_sabha}
            className='form-control'
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value='yes'>
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value='no'>
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched &&
          formik.errors?.vl_list_of_attendance_in_veda_sabha ? (
            <div className='text-danger'>
              {formik.errors?.vl_list_of_attendance_in_veda_sabha &&
                formik.errors?.vl_list_of_attendance_in_veda_sabha}
            </div>
          ) : null}
        </div>
        {formik.values.vl_list_of_attendance_in_veda_sabha == "yes" ? (
          <div className='table-responsive form-group col-12'>
            <table className='table'>
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रमांक "
                    : "क्रमाङ्कः"}
                </th>
                <th>
                  {lang == "english"
                    ? "Meeting or conference"
                    : lang == "hindi"
                    ? "सभा या सम्मेलन"
                    : "सभा सम्मेलनं वा"}
                </th>
                <th>
                  {lang == "english"
                    ? "Organizer"
                    : lang == "hindi"
                    ? "आयोजक "
                    : "आयोजकः "}
                </th>
                <th>
                  {lang == "english"
                    ? "Year"
                    : lang == "hindi"
                    ? "वर्ष "
                    : "वर्षम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Upload certificates  "
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु "}
                </th>
                <th width='120'>
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
              {formik.values?.vl_list_of_attendance_in_veda_sabha_table.map(
                (son, index) => (
                  <tr>
                    <td>{1 + index}</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`vl_list_of_attendance_in_veda_sabha_table[${index}].meeting_or_conference`}
                        name={`vl_list_of_attendance_in_veda_sabha_table[${index}].meeting_or_conference`}
                        value={
                          formik.values
                            .vl_list_of_attendance_in_veda_sabha_table[index]
                            .meeting_or_conference || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vl_list_of_attendance_in_veda_sabha_table[${index}].meeting_or_conference`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors
                        ?.vl_list_of_attendance_in_veda_sabha_table &&
                      formik.errors?.vl_list_of_attendance_in_veda_sabha_table[
                        index
                      ]?.meeting_or_conference ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_list_of_attendance_in_veda_sabha_table[index]
                            .meeting_or_conference &&
                            formik.errors
                              ?.vl_list_of_attendance_in_veda_sabha_table[index]
                              .meeting_or_conference}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`vl_list_of_attendance_in_veda_sabha_table[${index}].organizer`}
                        name={`vl_list_of_attendance_in_veda_sabha_table[${index}].organizer`}
                        value={
                          formik.values
                            .vl_list_of_attendance_in_veda_sabha_table[index]
                            .organizer || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vl_list_of_attendance_in_veda_sabha_table[${index}].organizer`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors
                        ?.vl_list_of_attendance_in_veda_sabha_table &&
                      formik.errors?.vl_list_of_attendance_in_veda_sabha_table[
                        index
                      ]?.organizer ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_list_of_attendance_in_veda_sabha_table[index]
                            .organizer &&
                            formik.errors
                              ?.vl_list_of_attendance_in_veda_sabha_table[index]
                              .organizer}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='number'
                        className='form-control'
                        id={`vl_list_of_attendance_in_veda_sabha_table[${index}].year`}
                        name={`vl_list_of_attendance_in_veda_sabha_table[${index}].year`}
                        value={
                          formik.values
                            .vl_list_of_attendance_in_veda_sabha_table[index]
                            .year || ""
                        }
                        onChange={(e) => {
                          if (e.target.value < 2099) {
                            handleInputChange(
                              `vl_list_of_attendance_in_veda_sabha_table[${index}].year`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors
                        ?.vl_list_of_attendance_in_veda_sabha_table &&
                      formik.errors?.vl_list_of_attendance_in_veda_sabha_table[
                        index
                      ]?.year ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_list_of_attendance_in_veda_sabha_table[index]
                            ?.year &&
                            formik.errors
                              ?.vl_list_of_attendance_in_veda_sabha_table[index]
                              ?.year}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='file'
                        accept='.jpg, .jpeg, .png, .pdf'
                        className='form-control'
                        id={`vl_list_of_attendance_in_veda_sabha_table[${index}].certificate`}
                        name={`vl_list_of_attendance_in_veda_sabha_table[${index}].certificate`}
                        onChange={async (e) => {
                          if (e.target.files.length > 0) {
                            await fileUploadFunction(
                              `vl_list_of_attendance_in_veda_sabha_table[${index}].certificate_string`,
                              e.target.files[0]
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.values?.vl_list_of_attendance_in_veda_sabha_table[
                        index
                      ]?.certificate_string && (
                        <button
                          className='uploadLink'
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vl_list_of_attendance_in_veda_sabha_table[index].certificate_string}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {`${formik.values?.vl_list_of_attendance_in_veda_sabha_table[
                            index
                          ].certificate_string.replace("upload-file/", "")}`}
                        </button>
                      )}
                      {formik.touched &&
                      formik.errors
                        ?.vl_list_of_attendance_in_veda_sabha_table &&
                      formik.errors?.vl_list_of_attendance_in_veda_sabha_table[
                        index
                      ]?.certificate_string ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_list_of_attendance_in_veda_sabha_table[index]
                            .certificate_string &&
                            formik.errors
                              ?.vl_list_of_attendance_in_veda_sabha_table[index]
                              .certificate_string}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values.vl_list_of_attendance_in_veda_sabha_table
                        .length >= 2 ? (
                        <button
                          className='btn btn-outline-danger'
                          onClick={(e) => removeVedLiteratureRow(index, e)}
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className='border-bottom-0'>
                <td
                  className='text-right'
                  colspan='7'
                >
                  <button
                    className='btn btn-outline-danger'
                    onClick={(e) => addVedLiteratureRow(e)}
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className='row'>
        <div className='col-lg-8 form-group'>
          <label>
            {lang == "english"
              ? "Have you participated in vedic shrauta yajna"
              : lang == "hindi"
              ? "वैदिक श्रौत यज्ञादि में आपकी गतिविधि"
              : "वैदिक-श्रौत-यज्ञादिषु भवतः गतिविधयः"}
          </label>
          <select
            id='vl_have_you_participated_in_vedic_shrauta_yajna'
            name='vl_have_you_participated_in_vedic_shrauta_yajna'
            onChange={(e) => {
              handleInputChange(
                "vl_have_you_participated_in_vedic_shrauta_yajna",
                e
              );
              if (e.target.value == "no") {
                formik.setFieldValue(
                  "vl_have_you_participated_in_vedic_shrauta_yajna_table",
                  [
                    {
                      yajna: "N/A",
                      the_host: "N/A",
                      year: "N/A",
                      certificate: "N/A",
                      certificate_string: "N/A",
                    },
                  ]
                );
              }
              if (e.target.value == "yes") {
                formik.setFieldValue(
                  "vl_have_you_participated_in_vedic_shrauta_yajna_table",
                  [
                    {
                      yajna: "",
                      the_host: "",
                      year: "",
                      certificate: "",
                      certificate_string: "",
                    },
                  ]
                );
              }
            }}
            onBlur={(e) => handleInputSave()}
            value={
              formik.values.vl_have_you_participated_in_vedic_shrauta_yajna
            }
            className='form-control'
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value='yes'>
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value='no'>
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched &&
          formik.errors?.vl_have_you_participated_in_vedic_shrauta_yajna ? (
            <div className='text-danger'>
              {formik.errors?.vl_have_you_participated_in_vedic_shrauta_yajna &&
                formik.errors?.vl_have_you_participated_in_vedic_shrauta_yajna}
            </div>
          ) : null}
        </div>
        {formik.values.vl_have_you_participated_in_vedic_shrauta_yajna ==
        "yes" ? (
          <div className='table-responsive col-12 form-group'>
            <table className='table'>
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रमांक "
                    : "क्रमाङ्कः"}
                </th>
                <th>
                  {lang == "english"
                    ? "Yajna"
                    : lang == "hindi"
                    ? "यज्ञकार्य "
                    : "यज्ञकार्यम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "The host"
                    : lang == "hindi"
                    ? "यजमान  "
                    : "यजमानः  "}
                </th>
                <th>
                  {lang == "english"
                    ? "Year"
                    : lang == "hindi"
                    ? "वर्ष "
                    : "वर्षम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Upload certificates  "
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु "}
                </th>
                <th width='120'>
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
              {formik.values?.vl_have_you_participated_in_vedic_shrauta_yajna_table.map(
                (son, index) => (
                  <tr>
                    <td>{1 + index}</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].yajna`}
                        name={`vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].yajna`}
                        value={
                          formik.values
                            .vl_have_you_participated_in_vedic_shrauta_yajna_table[
                            index
                          ]?.yajna || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].yajna`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors
                        ?.vl_have_you_participated_in_vedic_shrauta_yajna_table &&
                      formik.errors
                        ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                        index
                      ]?.yajna ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                            index
                          ]?.yajna &&
                            formik.errors
                              ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                              index
                            ]?.yajna}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].the_host`}
                        name={`vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].the_host`}
                        value={
                          formik.values
                            .vl_have_you_participated_in_vedic_shrauta_yajna_table[
                            index
                          ].the_host || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].the_host`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors
                        ?.vl_have_you_participated_in_vedic_shrauta_yajna_table &&
                      formik.errors
                        ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                        index
                      ]?.the_host ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                            index
                          ]?.the_host &&
                            formik.errors
                              ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                              index
                            ]?.the_host}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].year`}
                        name={`vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].year`}
                        value={
                          formik.values
                            .vl_have_you_participated_in_vedic_shrauta_yajna_table[
                            index
                          ].year || ""
                        }
                        onChange={(e) => {
                          if (e.target.value < 2099) {
                            handleInputChange(
                              `vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].year`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors
                        ?.vl_have_you_participated_in_vedic_shrauta_yajna_table &&
                      formik.errors
                        ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                        index
                      ]?.year ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                            index
                          ]?.year &&
                            formik.errors
                              ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                              index
                            ]?.year}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='file'
                        accept='.jpg, .jpeg, .png, .pdf'
                        className='form-control'
                        id={`vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].certificate`}
                        name={`vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].certificate`}
                        onChange={async (e) => {
                          if (e.target.files.length > 0) {
                            await fileUploadFunction(
                              `vl_have_you_participated_in_vedic_shrauta_yajna_table[${index}].certificate_string`,
                              e.target.files[0]
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.values
                        ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                        index
                      ].certificate_string && (
                        <button
                          className='uploadLink'
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vl_have_you_participated_in_vedic_shrauta_yajna_table[index].certificate_string}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {`${formik.values?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                            index
                          ].certificate_string.replace("upload-file/", "")}`}
                        </button>
                      )}
                      {formik.touched &&
                      formik.errors
                        ?.vl_have_you_participated_in_vedic_shrauta_yajna_table &&
                      formik.errors
                        ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                        index
                      ]?.certificate_string ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                            index
                          ]?.certificate_string &&
                            formik.errors
                              ?.vl_have_you_participated_in_vedic_shrauta_yajna_table[
                              index
                            ]?.certificate_string}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values
                        .vl_have_you_participated_in_vedic_shrauta_yajna_table
                        .length >= 2 ? (
                        <button
                          className='btn btn-outline-danger'
                          onClick={(e) => {
                            removeVedicSShrautaRow(index, e);
                          }}
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className='border-bottom-0'>
                <td
                  className='text-right'
                  colspan='7'
                >
                  <button
                    className='btn btn-outline-danger'
                    onClick={(e) => {
                      addVedicSShrautaRow(e);
                    }}
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='row'>
        <div className='col-lg-8 form-group'>
          <label>
            {lang == "english"
              ? "Have you received any award or testimonial?"
              : lang == "hindi"
              ? "पुरस्कारों अथवा प्रशस्तिपत्रों की जानकारी"
              : "पुरस्काराणां प्रशस्तिपत्राणां वा सूचना"}
          </label>
          <select
            id='vl_information_on_testimonials_and_awards_received'
            name='vl_information_on_testimonials_and_awards_received'
            onChange={(e) => {
              handleInputChange(
                "vl_information_on_testimonials_and_awards_received",
                e
              );
              if (e.target.value == "no") {
                formik.setFieldValue(
                  "vl_information_on_testimonials_and_awards_received_table",
                  [
                    {
                      award_name: "N/A",
                      who_gave_it: "N/A",
                      year: "N/A",
                      certificate: "N/A",
                      certificate_string: "N/A",
                    },
                  ]
                );
              }
              if (e.target.value == "yes") {
                formik.setFieldValue(
                  "vl_information_on_testimonials_and_awards_received_table",
                  [
                    {
                      award_name: "",
                      who_gave_it: "",
                      year: "",
                      certificate: "",
                      certificate_string: "",
                    },
                  ]
                );
              }
            }}
            onBlur={(e) => handleInputSave()}
            value={
              formik.values.vl_information_on_testimonials_and_awards_received
            }
            className='form-control'
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value='yes'>
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value='no'>
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched &&
          formik.errors?.vl_information_on_testimonials_and_awards_received ? (
            <div className='text-danger'>
              {formik.errors
                ?.vl_information_on_testimonials_and_awards_received &&
                formik.errors
                  ?.vl_information_on_testimonials_and_awards_received}
            </div>
          ) : null}
        </div>
        {formik.values?.vl_information_on_testimonials_and_awards_received ==
        "yes" ? (
          <div className='table-responsive form-group col-12'>
            <table className='table'>
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रमांक "
                    : "क्रमाङ्कः"}
                </th>
                <th>
                  {lang == "english"
                    ? "Award name"
                    : lang == "hindi"
                    ? "पुरस्कार का नाम  "
                    : "पुरस्कार-नाम  "}
                </th>
                <th>
                  {lang == "english"
                    ? "Who gave it"
                    : lang == "hindi"
                    ? "प्रदाता "
                    : "प्रदाता "}
                </th>
                <th>
                  {lang == "english"
                    ? "Year"
                    : lang == "hindi"
                    ? "वर्ष "
                    : "वर्षम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Upload certificates  "
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु "}
                </th>
                <th width='120'>
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
              {formik.values?.vl_information_on_testimonials_and_awards_received_table.map(
                (son, index) => (
                  <tr>
                    <td>{1 + index}</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`vl_information_on_testimonials_and_awards_received_table[${index}].award_name`}
                        name={`vl_information_on_testimonials_and_awards_received_table[${index}].award_name`}
                        value={
                          formik.values
                            .vl_information_on_testimonials_and_awards_received_table[
                            index
                          ].award_name || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vl_information_on_testimonials_and_awards_received_table[${index}].award_name`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors
                        ?.vl_information_on_testimonials_and_awards_received_table &&
                      formik.errors
                        ?.vl_information_on_testimonials_and_awards_received_table[
                        index
                      ].award_name ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_information_on_testimonials_and_awards_received_table[
                            index
                          ].award_name &&
                            formik.errors
                              ?.vl_information_on_testimonials_and_awards_received_table[
                              index
                            ].award_name}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`vl_information_on_testimonials_and_awards_received_table[${index}].who_gave_it`}
                        name={`vl_information_on_testimonials_and_awards_received_table[${index}].who_gave_it`}
                        value={
                          formik.values
                            .vl_information_on_testimonials_and_awards_received_table[
                            index
                          ].who_gave_it || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vl_information_on_testimonials_and_awards_received_table[${index}].who_gave_it`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors
                        ?.vl_information_on_testimonials_and_awards_received_table &&
                      formik.errors
                        ?.vl_information_on_testimonials_and_awards_received_table[
                        index
                      ].who_gave_it ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_information_on_testimonials_and_awards_received_table[
                            index
                          ].who_gave_it &&
                            formik.errors
                              ?.vl_information_on_testimonials_and_awards_received_table[
                              index
                            ].who_gave_it}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='number'
                        className='form-control'
                        id={`vl_information_on_testimonials_and_awards_received_table[${index}].year`}
                        name={`vl_information_on_testimonials_and_awards_received_table[${index}].year`}
                        value={
                          formik.values
                            .vl_information_on_testimonials_and_awards_received_table[
                            index
                          ].year || ""
                        }
                        onChange={(e) => {
                          if (e.target.value < 2099) {
                            handleInputChange(
                              `vl_information_on_testimonials_and_awards_received_table[${index}].year`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors
                        ?.vl_information_on_testimonials_and_awards_received_table &&
                      formik.errors
                        ?.vl_information_on_testimonials_and_awards_received_table[
                        index
                      ].year ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_information_on_testimonials_and_awards_received_table[
                            index
                          ].year &&
                            formik.errors
                              ?.vl_information_on_testimonials_and_awards_received_table[
                              index
                            ].year}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='file'
                        accept='.jpg, .jpeg, .png, .pdf'
                        className='form-control'
                        id={`vl_information_on_testimonials_and_awards_received_table[${index}].certificate`}
                        name={`vl_information_on_testimonials_and_awards_received_table[${index}].certificate`}
                        onChange={async (e) => {
                          if (e.target.files.length > 0) {
                            await fileUploadFunction(
                              `vl_information_on_testimonials_and_awards_received_table[${index}].certificate_string`,
                              e.target.files[0]
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.values
                        ?.vl_information_on_testimonials_and_awards_received_table[
                        index
                      ].certificate_string && (
                        <button
                          className='uploadLink'
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vl_information_on_testimonials_and_awards_received_table[index].certificate_string}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {`${formik.values?.vl_information_on_testimonials_and_awards_received_table[
                            index
                          ].certificate_string.replace("upload-file/", "")}`}
                        </button>
                      )}
                      {formik.touched &&
                      formik.errors
                        ?.vl_information_on_testimonials_and_awards_received_table &&
                      formik.errors
                        ?.vl_information_on_testimonials_and_awards_received_table[
                        index
                      ].certificate_string ? (
                        <div className='text-danger'>
                          {formik.errors
                            ?.vl_information_on_testimonials_and_awards_received_table[
                            index
                          ].certificate_string &&
                            formik.errors
                              ?.vl_information_on_testimonials_and_awards_received_table[
                              index
                            ].certificate_string}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values
                        .vl_information_on_testimonials_and_awards_received_table
                        .length >= 2 ? (
                        <button
                          className='btn btn-outline-danger'
                          onClick={(e) => {
                            removeTestimonialsAndAwardsRow(index, e);
                          }}
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className='border-bottom-0'>
                <td
                  className='text-right'
                  colspan='7'
                >
                  {" "}
                  <button
                    className='btn btn-outline-danger'
                    onClick={(e) => {
                      addTestimonialsAndAwardsRow(e);
                    }}
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='row'>
        <div className='col-lg-8 form-group'>
          <label>
            {lang == "english"
              ? "Have you written any book, article or research paper?"
              : lang == "hindi"
              ? "स्वलिखित ग्रंथ, लेख, शोधपत्र प्रकाशन की जानकारी"
              : "स्वलिखितग्रन्थ-लेख-शोधपत्राणां प्रकाशन-विषयिणी सूचना"}
          </label>
          <select
            id='vl_information_about_books'
            name='vl_information_about_books'
            onChange={(e) => {
              handleInputChange("vl_information_about_books", e);
              if (e.target.value == "no") {
                formik.setFieldValue("vl_information_about_books_table", [
                  {
                    topic: "N/A",
                    publisherISBN: "N/A",
                    year: "N/A",
                    certificate: "N/A",
                    certificate_string: "N/A",
                  },
                ]);
              }
              if (e.target.value == "yes") {
                formik.setFieldValue("vl_information_about_books_table", [
                  {
                    topic: "",
                    publisherISBN: "",
                    year: "",
                    certificate: "",
                    certificate_string: "",
                  },
                ]);
              }
            }}
            onBlur={(e) => handleInputSave()}
            value={formik.values.vl_information_about_books}
            className='form-control'
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value='yes'>
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value='no'>
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched && formik.errors?.vl_information_about_books ? (
            <div className='text-danger'>
              {formik.errors?.vl_information_about_books &&
                formik.errors?.vl_information_about_books}
            </div>
          ) : null}
        </div>
        {formik.values.vl_information_about_books == "yes" ? (
          <div className='table-responsive form-group col-12'>
            <table className='table'>
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रमांक "
                    : "क्रमाङ्कः"}
                </th>
                <th>
                  {lang == "english"
                    ? "Topic"
                    : lang == "hindi"
                    ? "शीर्षक  "
                    : "शीर्षकम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Publisher & ISBN"
                    : lang == "hindi"
                    ? "प्रकाशक और ISBN"
                    : "प्रकाशकः ISBN इति च "}
                </th>
                <th>
                  {lang == "english"
                    ? "Year"
                    : lang == "hindi"
                    ? "वर्ष "
                    : "वर्षम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Upload certificates  "
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु "}
                </th>
                <th width='120'>
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
              {formik.values?.vl_information_about_books_table.map(
                (son, index) => (
                  <tr>
                    <td>{1 + index}</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`vl_information_about_books_table[${index}].topic`}
                        name={`vl_information_about_books_table[${index}].topic`}
                        value={
                          formik.values.vl_information_about_books_table[index]
                            .topic || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vl_information_about_books_table[${index}].topic`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors?.vl_information_about_books_table &&
                      formik.errors?.vl_information_about_books_table[index]
                        .topic ? (
                        <div className='text-danger'>
                          {formik.errors?.vl_information_about_books_table[
                            index
                          ].topic &&
                            formik.errors?.vl_information_about_books_table[
                              index
                            ].topic}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        id={`vl_information_about_books_table[${index}].publisherISBN`}
                        name={`vl_information_about_books_table[${index}].publisherISBN`}
                        value={
                          formik.values.vl_information_about_books_table[index]
                            .publisherISBN || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vl_information_about_books_table[${index}].publisherISBN`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors?.vl_information_about_books_table &&
                      formik.errors?.vl_information_about_books_table[index]
                        .publisherISBN ? (
                        <div className='text-danger'>
                          {formik.errors?.vl_information_about_books_table[
                            index
                          ].publisherISBN &&
                            formik.errors?.vl_information_about_books_table[
                              index
                            ].publisherISBN}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='number'
                        className='form-control'
                        id={`vl_information_about_books_table[${index}].year`}
                        name={`vl_information_about_books_table[${index}].year`}
                        value={
                          formik.values.vl_information_about_books_table[index]
                            .year || ""
                        }
                        onChange={(e) => {
                          if (e.target.value < 2099) {
                            handleInputChange(
                              `vl_information_about_books_table[${index}].year`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.touched &&
                      formik.errors?.vl_information_about_books_table &&
                      formik.errors?.vl_information_about_books_table[index]
                        .year ? (
                        <div className='text-danger'>
                          {formik.errors?.vl_information_about_books_table[
                            index
                          ].year &&
                            formik.errors?.vl_information_about_books_table[
                              index
                            ].year}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type='file'
                        accept='.jpg, .jpeg, .png, .pdf'
                        className='form-control'
                        id={`vl_information_about_books_table[${index}].certificate`}
                        name={`vl_information_about_books_table[${index}].certificate`}
                        onChange={async (e) => {
                          if (e.target.files.length > 0) {
                            await fileUploadFunction(
                              `vl_information_about_books_table[${index}].certificate_string`,
                              e.target.files[0]
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      />
                      {formik.values?.vl_information_about_books_table[index]
                        .certificate_string && (
                        <button
                          className='uploadLink'
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vl_information_about_books_table[index].certificate_string}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {`${formik.values?.vl_information_about_books_table[
                            index
                          ].certificate_string.replace("upload-file/", "")}`}
                        </button>
                      )}
                      {formik.touched &&
                      formik.errors?.vl_information_about_books_table &&
                      formik.errors?.vl_information_about_books_table[index]
                        .certificate_string ? (
                        <div className='text-danger'>
                          {formik.errors?.vl_information_about_books_table[
                            index
                          ].certificate_string &&
                            formik.errors?.vl_information_about_books_table[
                              index
                            ].certificate_string}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values.vl_information_about_books_table.length >=
                      2 ? (
                        <button
                          className='btn btn-outline-danger'
                          onClick={(e) => removeResearchPapersRow(index, e)}
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className='border-bottom-0'>
                <td
                  className='text-right'
                  colspan='7'
                >
                  <button
                    className='btn btn-outline-danger'
                    onClick={(e) => addResearchPapersRow(e)}
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='row'>
        <div className='col-lg-8 form-group'>
          <label>
            {lang == "english"
              ? "Have you performed soma yajna ?"
              : lang == "hindi"
              ? "क्या आपने सोम यज्ञ किया है?"
              : "किं भवता सोमयज्ञः कृतः वा ? "}
          </label>
          <select
            id='vl_have_you_performed_soma_yajna'
            name='vl_have_you_performed_soma_yajna'
            onChange={(e) => {
              handleInputChange("vl_have_you_performed_soma_yajna", e);
              if (e.target.value == "no") {
                formik.setFieldValue(
                  "vl_have_you_performed_soma_yajna_detail",
                  "N/A"
                );
              }
              if (e.target.value == "yes") {
                formik.setFieldValue(
                  "vl_have_you_performed_soma_yajna_detail",
                  ""
                );
              }
            }}
            onBlur={(e) => handleInputSave()}
            value={formik.values.vl_have_you_performed_soma_yajna}
            className='form-control'
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value='yes'>
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value='no'>
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched && formik.errors?.vl_have_you_performed_soma_yajna ? (
            <div className='text-danger'>
              {formik.errors?.vl_have_you_performed_soma_yajna &&
                formik.errors?.vl_have_you_performed_soma_yajna}
            </div>
          ) : null}
        </div>
        {formik.values.vl_have_you_performed_soma_yajna == "yes" ? (
          <div className='form-group col-12'>
            <h5>
              {lang == "english"
                ? "Give details"
                : lang == "hindi"
                ? "विवरण दीजिये।"
                : "विवरणं ददातु "}
            </h5>
            <textarea
              className='form-control'
              name='vl_have_you_performed_soma_yajna_detail'
              rows={2}
              onChange={(e) =>
                handleInputChange("vl_have_you_performed_soma_yajna_detail", e)
              }
              onBlur={(e) => handleInputSave()}
              value={formik.values?.vl_have_you_performed_soma_yajna_detail}
              maxLength={700}
            ></textarea>
            <p className='text-right'>
              {formik.values?.vl_have_you_performed_soma_yajna_detail?.length}
              /700{" "}
              {lang == "english"
                ? " Characters"
                : lang == "hindi"
                ? " शब्द"
                : " शब्दः"}
            </p>
            {formik.touched &&
            formik.errors?.vl_have_you_performed_soma_yajna_detail ? (
              <div className='text-danger'>
                {formik.errors?.vl_have_you_performed_soma_yajna_detail &&
                  formik.errors?.vl_have_you_performed_soma_yajna_detail}
              </div>
            ) : null}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className='row'>
        <div className='col-lg-8 form-group'>
          <label>
            {lang == "english"
              ? "Is your conduct according to the veds ?"
              : lang == "hindi"
              ? "क्या आपका आचरण वेदानुसार है? "
              : "किं भवतः आचरणं वेदानुरूपं वा ? "}
          </label>
          <select
            id='vl_is_your_conduct_according_to_vedas'
            name='vl_is_your_conduct_according_to_vedas'
            onChange={(e) => {
              handleInputChange("vl_is_your_conduct_according_to_vedas", e);
              if (e.target.value == "no") {
                formik.setFieldValue(
                  "vl_is_your_conduct_according_to_vedas_details",
                  "N/A"
                );
              }
              if (e.target.value == "yes") {
                formik.setFieldValue(
                  "vl_is_your_conduct_according_to_vedas_details",
                  ""
                );
              }
            }}
            onBlur={(e) => handleInputSave()}
            value={formik.values.vl_is_your_conduct_according_to_vedas}
            className='form-control'
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value='yes'>
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value='no'>
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched &&
          formik.errors?.vl_is_your_conduct_according_to_vedas ? (
            <div className='text-danger'>
              {formik.errors?.vl_is_your_conduct_according_to_vedas &&
                formik.errors?.vl_is_your_conduct_according_to_vedas}
            </div>
          ) : null}
        </div>
        {formik.values.vl_is_your_conduct_according_to_vedas == "yes" ? (
          <div className='form-group col-12'>
            <h5>
              {lang == "english"
                ? "Give details"
                : lang == "hindi"
                ? "विवरण दीजिये।"
                : "विवरणं ददातु "}
            </h5>
            <textarea
              className='form-control'
              name='vl_is_your_conduct_according_to_vedas_details'
              rows={2}
              onChange={(e) =>
                handleInputChange(
                  "vl_is_your_conduct_according_to_vedas_details",
                  e
                )
              }
              onBlur={(e) => handleInputSave()}
              value={
                formik.values.vl_is_your_conduct_according_to_vedas_details
              }
              maxLength={700}
            ></textarea>

            <p className='col-12 text-right  '>
              {
                formik.values.vl_is_your_conduct_according_to_vedas_details
                  ?.length
              }
              /700{" "}
              {lang == "english"
                ? " Characters"
                : lang == "hindi"
                ? " शब्द"
                : " शब्दः"}
            </p>
            {formik.touched &&
            formik.errors?.vl_is_your_conduct_according_to_vedas_details ? (
              <div className='text-danger'>
                {formik.errors?.vl_is_your_conduct_according_to_vedas_details &&
                  formik.errors?.vl_is_your_conduct_according_to_vedas_details}
              </div>
            ) : null}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Section_4;
