import React, { useEffect, useState } from "react";
import Section_1 from "../../Components/SchoolForm/Section_1";
import Section_2 from "../../Components/SchoolForm/Section_2";
import Section_3 from "../../Components/SchoolForm/Section_3";
import Section_4 from "../../Components/SchoolForm/Section_4";
import Section_5 from "../../Components/SchoolForm/Section_5";
import Section_6 from "../../Components/SchoolForm/Section_6";
import Section_7 from "../../Components/SchoolForm/Section_7";
import { FaCheck, FaExclamation } from "react-icons/fa";
import { useFormik } from "formik";
import page1FormFields from "./page1FormFields";
import page2FormFields from "./page2FormFields";
import page3FormFields from "./page3FormFields";
import page4FormFields from "./page4FormFields";
import page5FormFields from "./page5FormFields";
import page6FormFields from "./page6FormFields";
import axiosPrivate from "../../Hooks/axiosPrivate";
import { uploadFile } from "../../Utils/fileUpload";
import validationSchema from "./schoolvalidations";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../Utils/Toaster";

function SchoolFormPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
  });
  // Access state data passed from the previous page
  let { lang, user_type, apply_type, oldData } = location.state || {};

  let events = [];
  if (lang === "english") {
    events = [
      "General info",
      "Vedavidyarthi Info.",
      "Teacher info.",
      "Other info.",
      "Affidavit ",
      "Documents",
      "Preview",
    ];
  } else if (lang === "hindi") {
    events = [
      "सामान्य स्वरूप",
      "वेदविद्यार्थी जानकारी",
      "अध्यापक जानकारी",
      "अन्य जानकारी",
      "शपथपत्र",
      "संलग्न सूची",
      "पूर्वावलोकन",
    ];
  } else {
    events = [
      "वेदविद्यार्थि सूचनाः",
      "वेदविद्यार्थी सूचनाः",
      "अध्यापक सूचनाः",
      "अन्याः सूचनाः ",
      "शपथपत्रम् ",
      "संलग्नानां सूची",
      "पूर्वावलोकनं",
    ];
  }

  const [formData, setFormData] = useState({});
  const [completionStatus, setCompletionStatus] = useState(
    Array(7).fill(false)
  );
  const [id, setId] = useState("");
  const [currStep, setCurrStep] = useState(1);

  const handleSaveandNext = (e) => {
    e.preventDefault();
    setCompletionStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[currStep - 1] = true;
      return newStatus;
    });
    setCurrStep((prevStep) => (prevStep < 7 ? prevStep + 1 : prevStep));
    handleInputSave();
  };

  const handleBack = (e) => {
    e.preventDefault();
    setCurrStep((prevStep) => (prevStep !== 1 ? prevStep - 1 : prevStep));
    handleInputSave();
  };

  const formik = useFormik({
    initialValues: {
      ...page1FormFields,
      ...page2FormFields,
      ...page3FormFields,
      ...page4FormFields,
      ...page5FormFields,
      ...page6FormFields,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      // Handle form submission
      let userData = localStorage.getItem("userData");
      userData = userData ? JSON.parse(userData) : {};
      if (userData?.userId) {
        try {
          const formData = {
            id: id ? id : "",
            user_id: apply_type == "yourself" ? userData.userId : "",
            filled_by_id: apply_type == "other" ? userData.userId : "",
            user_option: apply_type,
            type: user_type,
            current_page: currStep,
            answerdata: formik.values,
            language: lang,
            status: "Pending",
            remarks: "",
            progress: pageStatus,
          };
          const response = await axiosPrivate.post(
            "user-answer-data",
            formData
          );

          if (response.status == 200) {
            showSuccessToast("Your form is submitted successfully");
            navigate("/home");
          } else {
            showErrorToast(response.data.message);
          }
        } catch (error) {
          showErrorToast(error?.response?.data?.errors[0]?.msg);
        }
      }
    },
  });

  useEffect(() => {
    console.log("formik.errors", formik.errors);
  }, [formik]);

  //get loaction by pincode
  const getLoaction = async () => {
    try {
      const locationData = await axiosPrivate(
        `locations/${formik.values.full_correspondence_pincode}`
      );
      const location = await locationData.data;
      formik.setFieldValue(
        "full_correspondence_state",
        location?.location?.data[0]?.state
      );
      formik.setFieldValue(
        "full_correspondence_district",
        location?.location?.data[0]?.city
      );
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    let timer;
    if (formik?.values.full_correspondence_pincode) {
      timer = setTimeout(() => {
        getLoaction(
          formik.values.full_correspondence_pincode,
          "full_correspondence_state",
          "full_correspondence_district"
        );
      }, 1000);
    }

    return () => clearTimeout(timer);
  }, [formik?.values.full_correspondence_pincode]);

  /* Add Row for student vidyalay details details start */
  const addStudentVidyalay = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.si_present_students_studying_at_vedvidyalay,
      {
        student_name: "",
        age: "",
        vedashakha: "",
        year: "",
        exam_level: "",
        other: "",
      },
    ];
    formik.setFieldValue(
      "si_present_students_studying_at_vedvidyalay",
      newRowContent
    );
    return true;
  };
  /* Add Row for student vidyalay details details end */

  /* Remove Row for student vidyalay details details start */
  const removeStudentVidyalay = (index, eve) => {
    eve.preventDefault();
    if (formik.values.si_present_students_studying_at_vedvidyalay.length > 1) {
      let newRowContent =
        formik.values.si_present_students_studying_at_vedvidyalay.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "si_present_students_studying_at_vedvidyalay",
        newRowContent
      );
    }
    return true;
  };
  /* Remove Row for student vidyalay details details end */

  /* Add Row for honours details details start */
  const addHonoursDetails = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.list_of_awards_of_honours_details,
      {
        name_of_award_honor: "",
        name_of_institution: "",
        year: "",
        attached_document_string: "",
      },
    ];
    formik.setFieldValue("list_of_awards_of_honours_details", newRowContent);
    return true;
  };
  /* Add Row for honours details details end */

  /* Remove Row for honours details details start */
  const removeHonoursDetails = (index, eve) => {
    eve.preventDefault();
    if (formik.values.list_of_awards_of_honours_details.length > 1) {
      let newRowContent =
        formik.values.list_of_awards_of_honours_details.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue("list_of_awards_of_honours_details", newRowContent);
    }
    return true;
  };
  /* Remove Row for honours details details end */

  /* Add Row for document details details start */
  const addDocumentDetails = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.document_details,
      {
        details_of_document: "",
        attached_document: "",
        attached_document_string: "",
      },
    ];
    formik.setFieldValue("document_details", newRowContent);
    return true;
  };
  /* Add Row for document details details end */

  /* Remove Row for document details details start */
  const removeDocumentDetails = (index, eve) => {
    eve.preventDefault();
    if (formik.values.document_details.length > 1) {
      let newRowContent = formik.values.document_details.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("document_details", newRowContent);
    }
    return true;
  };
  /* Remove Row for document details details end */

  //handle data change on Blur
  const handleInputChange = async (label, event) => {
    formik.handleChange(event);
    return true;
  };

  const handleInputSave = async () => {
    let userData = localStorage.getItem("userData");
    userData = userData ? JSON.parse(userData) : {};
    if (userData?.userId) {
      try {
        const formData = {
          id: id ? id : "",
          user_id: apply_type == "yourself" ? userData.userId : "",
          filled_by_id: apply_type == "other" ? userData.userId : "",
          user_option: apply_type,
          type: user_type,
          current_page: currStep,
          answerdata: formik.values,
          language: lang,
          status: "In Progress",
          remarks: "",
          progress: pageStatus,
        };
        const response = await axiosPrivate.post("user-answer-data", formData);
        if (response.status === 200 && response.data.data) {
          if (id == "") {
            setId(response.data.data);
          }
        } else {
          showErrorToast(response.data.message);
        }
      } catch (error) {
        showErrorToast(error?.response?.data?.errors[0]?.msg);
      }
    }
  };

  //handle current page and Old Data

  useEffect(() => {
    if (oldData) {
      formik.setValues( typeof oldData?.answerdata === 'string' ? JSON.parse(oldData.answerdata) : oldData?.answerdata);
      setId(oldData.id);
      setCurrStep(oldData.current_page);
    }
  }, [oldData]);

  /* File Upload Code start */
  const fileUploadFunction = async (field_name, file) => {
    const fileUploadResponse = await uploadFile(file);
    if (fileUploadResponse.code === 200) {
      formik.setFieldValue(`${field_name}`, fileUploadResponse.data);
    } else {
      showErrorToast(fileUploadResponse.data.message);
    }
    return true;
  };

  // render page according tab

  /* Add Lakhan New */
  const addVidyarthiRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_information_of_passed_student,
      {
        level: "",
        rigveda: "",
        shuklayajurve: "",
        krishnayajurve: "",
        samveda: "",
        atharvaveda: "",
      },
    ];
    formik.setFieldValue("vt_information_of_passed_student", newRowContent);
    return true;
  };

  const removeVidyarthiRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vt_information_of_passed_student.length > 1) {
      let newRowContent = formik.values.vt_information_of_passed_student.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vt_information_of_passed_student", newRowContent);
    }

    return true;
  };

  const addStudentRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_list_of_student_passed_the_kramanta_ghananta_detail,
      {
        vedavidyarthi_name: "",
        level_of_vedadhyaya: "",
        other: "",
        name_of_the_examine_institution: "",
        year: "",
        division: "",
        attached_document_string: "",
      },
    ];
    formik.setFieldValue(
      "vt_list_of_student_passed_the_kramanta_ghananta_detail",
      newRowContent
    );
    return true;
  };

  const removeStudentRow = (index, eve) => {
    eve.preventDefault();
    if (
      formik.values.vt_list_of_student_passed_the_kramanta_ghananta_detail
        .length > 1
    ) {
      let newRowContent =
        formik.values.vt_list_of_student_passed_the_kramanta_ghananta_detail.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vt_list_of_student_passed_the_kramanta_ghananta_detail",
        newRowContent
      );
    }

    return true;
  };

  const addFiveRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_list_of_awards_recived_by_students_detail,
      {
        student_name: "",
        name_of_the_award: "",
        name_of_the_institute: "",
        year: "",
        attached_document_string: "",
      },
    ];
    formik.setFieldValue(
      "vt_list_of_awards_recived_by_students_detail",
      newRowContent
    );
    return true;
  };

  const removeFiveRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vt_list_of_awards_recived_by_students_detail.length > 1) {
      let newRowContent =
        formik.values.vt_list_of_awards_recived_by_students_detail.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vt_list_of_awards_recived_by_students_detail",
        newRowContent
      );
    }

    return true;
  };

  const addVedvidyalayaRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_list_of_full_time_and_part_time_details,
      {
        Adhyapaka_name: "",
        vedshakha: "",
        level_of_vedgyan: "",
        other_vedgyan: "",
        age: "",
        fulltime_parttime: "",
        attached_document_string: "",
      },
    ];
    formik.setFieldValue(
      "vt_list_of_full_time_and_part_time_details",
      newRowContent
    );
    return true;
  };

  const removeVedvidyalayaRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vt_list_of_full_time_and_part_time_details.length > 1) {
      let newRowContent =
        formik.values.vt_list_of_full_time_and_part_time_details.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vt_list_of_full_time_and_part_time_details",
        newRowContent
      );
    }

    return true;
  };

  const addLast5Row = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_left_the_vedvidyalaya_details,
      {
        teacher_name: "",
        vedshakha: "",
        when_started: "",
        when_end: "",
        reason_for_leaving: "",
      },
    ];
    formik.setFieldValue("vt_left_the_vedvidyalaya_details", newRowContent);
    return true;
  };

  const removeLast5Row = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vt_left_the_vedvidyalaya_details.length > 1) {
      let newRowContent = formik.values.vt_left_the_vedvidyalaya_details.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vt_left_the_vedvidyalaya_details", newRowContent);
    }

    return true;
  };

  const addVedadhyapakasRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_former_vedavidyarthi_details,
      {
        student_name: "",
        vedadgyapan_finsih: "",
        vedshakha: "",
        institution_begin_vedadhyapan: "",
      },
    ];
    formik.setFieldValue("vt_former_vedavidyarthi_details", newRowContent);
    return true;
  };

  const removeVedadhyapakasRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vt_former_vedavidyarthi_details.length > 1) {
      let newRowContent = formik.values.vt_former_vedavidyarthi_details.filter(
        (_, i) => i !== index
      );
      formik.setFieldValue("vt_former_vedavidyarthi_details", newRowContent);
    }

    return true;
  };

  const addVedvidyalayRow = (eve) => {
    eve.preventDefault();
    let newRowContent = [
      ...formik.values.vt_list_of_awards_receive_by_teacher_details,
      {
        teacher_name: "",
        name_of_award: "",
        name_of_institution: "",
        year: "",
        attached_document_string: "",
      },
    ];
    formik.setFieldValue(
      "vt_list_of_awards_receive_by_teacher_details",
      newRowContent
    );
    return true;
  };

  const removeVedvidyalayRow = (index, eve) => {
    eve.preventDefault();
    if (formik.values.vt_list_of_awards_receive_by_teacher_details.length > 1) {
      let newRowContent =
        formik.values.vt_list_of_awards_receive_by_teacher_details.filter(
          (_, i) => i !== index
        );
      formik.setFieldValue(
        "vt_list_of_awards_receive_by_teacher_details",
        newRowContent
      );
    }

    return true;
  };

  // css code for progress bar

  useEffect(() => {
    let currentElement = document.querySelector(".current");
    let nextElement = currentElement?.nextElementSibling;

    if (nextElement?.classList.contains("pending")) {
      currentElement?.classList.add("recheck");
    }
    if (nextElement?.classList.contains("complete")) {
      currentElement?.classList.add("recheck_complete");
    }
  }, [currStep]);

  //handle current page and Old Data

  useEffect(() => {
    if (oldData) {
      formik.setValues(
        typeof oldData?.answerdata === "string"
          ? JSON.parse(oldData.answerdata)
          : oldData?.answerdata
      );
      setId(oldData.id);
      setCurrStep(oldData.current_page);
    }
  }, [oldData]);

  useEffect(() => {
    async function checkOldPage() {
      const responseAnswers = await axiosPrivate.get(`user-answer-data`);
      if (
        responseAnswers.status == 200 &&
        responseAnswers?.data?.data &&
        responseAnswers?.data?.data.length > 0 &&
        responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
          .status == "In Progress" &&
        responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
          .type == "Vedavidyalaya"
      ) {
        lang =
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .language;
        user_type =
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .type;
        apply_type =
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .user_option;
        formik.setValues(
          typeof responseAnswers.data.data[
            responseAnswers?.data?.data?.length - 1
          ]?.answerdata === "string"
            ? JSON.parse(
                responseAnswers.data.data[
                  responseAnswers?.data?.data?.length - 1
                ].answerdata
              )
            : responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]?.answerdata
        );
        setId(
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1].id
        );
        setCurrStep(
          responseAnswers.data.data[responseAnswers?.data?.data?.length - 1]
            .current_page
        );
        let progress = JSON.parse(
          responseAnswers?.data?.data[responseAnswers?.data?.data?.length - 1]
            ?.progress
        );
        if (progress != null) {
          setPageStatus(progress);
        }
      }
    }
    if (localStorage.getItem("accessToken")) {
      checkOldPage();
    }
  }, []);

  const isPageComplete = (page_no, totalCount, actualCount) => {
    if (totalCount === actualCount) {
      pageStatus[page_no] = "complete";
    } else {
      pageStatus[page_no] = "pending";
    }
  };

  // render page according tab
  const renderCurrentPage = () => {
    switch (currStep) {
      case 1:
        return (
          <Section_1
            lang={lang}
            formik={formik}
            handleInputChange={handleInputChange}
            formData={formData}
            handleInputSave={handleInputSave}
            isPageComplete={isPageComplete}
          />
        );
      case 2:
        return (
          <Section_2
            lang={lang}
            formik={formik}
            formData={formData}
            handleInputChange={handleInputChange}
            handleInputSave={handleInputSave}
            addStudentVidyalay={addStudentVidyalay}
            removeStudentVidyalay={removeStudentVidyalay}
            isPageComplete={isPageComplete}
          />
        );
      case 3:
        return (
          <Section_3
            formData={formData}
            lang={lang}
            formik={formik}
            handleInputChange={handleInputChange}
            handleInputSave={handleInputSave}
            addVidyarthiRow={addVidyarthiRow}
            removeVidyarthiRow={removeVidyarthiRow}
            addStudentRow={addStudentRow}
            removeStudentRow={removeStudentRow}
            addFiveRow={addFiveRow}
            removeFiveRow={removeFiveRow}
            addVedvidyalayaRow={addVedvidyalayaRow}
            removeVedvidyalayaRow={removeVedvidyalayaRow}
            addLast5Row={addLast5Row}
            removeLast5Row={removeLast5Row}
            addVedadhyapakasRow={addVedadhyapakasRow}
            removeVedadhyapakasRow={removeVedadhyapakasRow}
            addVedvidyalayRow={addVedvidyalayRow}
            removeVedvidyalayRow={removeVedvidyalayRow}
            isPageComplete={isPageComplete}
            fileUploadFunction={fileUploadFunction}
          />
        );
      case 4:
        return (
          <Section_4
            lang={lang}
            formik={formik}
            formData={formData}
            handleInputChange={handleInputChange}
            handleInputSave={handleInputSave}
            addHonoursDetails={addHonoursDetails}
            removeHonoursDetails={removeHonoursDetails}
            isPageComplete={isPageComplete}
            fileUploadFunction={fileUploadFunction}
          />
        );
      case 5:
        return (
          <Section_5
            lang={lang}
            formik={formik}
            formData={formData}
            handleInputChange={handleInputChange}
            handleInputSave={handleInputSave}
            isPageComplete={isPageComplete}
            fileUploadFunction={fileUploadFunction}
          />
        );
      case 6:
        return (
          <Section_6
            lang={lang}
            formik={formik}
            formData={formData}
            handleInputChange={handleInputChange}
            handleInputSave={handleInputSave}
            addDocumentDetails={addDocumentDetails}
            removeDocumentDetails={removeDocumentDetails}
            fileUploadFunction={fileUploadFunction}
            isPageComplete={isPageComplete}
          />
        );
      case 7:
        return (
          <Section_7 lang={lang} id={id} isPageComplete={isPageComplete} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="">
      <div className="flex justify-between items-center mb-5 stepsBar">
        {events.map((eventName, index) => (
          <div
            key={index}
            className={`step ${
              index === currStep - 1 ? "current" : pageStatus[index + 1]
            }`}
            onClick={() => {
              setCurrStep(index + 1);
              handleInputSave();
            }}
          >
            <div className={`step-icon`}>
              {index + 1 === currStep && <div className="step-circle"></div>}
              {pageStatus[index + 1] == "complete" &&
                index + 1 !== currStep && (
                  <FaCheck style={{ color: "white" }} />
                )}
              {pageStatus[index + 1] == "pending" && index + 1 !== currStep && (
                <FaExclamation style={{ color: "white" }} />
              )}
              {pageStatus[index + 1] == "" &&
                !completionStatus[index] &&
                index + 1 !== currStep && <div className="step-circle"></div>}
            </div>
            <span className="text-xs mt-2 d-none d-lg-block">{eventName}</span>
            <span className="text-xs mt-2 d-lg-none">{index + 1}</span>
          </div>
        ))}
      </div>
      <form onSubmit={formik.handleSubmit}>
        {renderCurrentPage()}
        <div className="">
          <div>
            {currStep !== 1 && (
              <button
                onClick={(e) => handleBack(e)}
                type="button"
                className="btn btn-outline-danger"
              >
                {lang == "english"
                  ? "Back"
                  : lang == "hindi"
                  ? "पिछला "
                  : "पूर्वतनम्‌"}
              </button>
            )}
            {currStep < 7 ? (
              <button
                type="button"
                className="btn btn-danger"
                onClick={(e) => handleSaveandNext(e)}
              >
                {lang == "english"
                  ? "Save & next"
                  : lang == "hindi"
                  ? "सहेजें और आगे बढ़ें "
                  : "आरक्षतु अग्रे गच्छतु च"}
              </button>
            ) : (
              <button type="submit" className="btn btn-danger">
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default SchoolFormPage;
