import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

function Section_2({
  handleInputChange,
  formik,
  handleInputSave,
  addExamPassRow,
  removeExamPassRow,
  addVedadhyapakaRow,
  removeVedadhyapakaRow,
  addStudiedVedaDetailsRow,
  removeStudiedVedaDetailsRow,
  addRelativeRow,
  removeRelativeRow,
  lang,
  fileUploadFunction,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(16);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

 useEffect(() => {
   let count = 0;
   let total_count = totalCount;

   count = handleInputValue(formik.values.vi_studied_veda_and_vedshakha, count);
   count = handleInputValue(formik.values.vi_ved_select, count);
   count = handleInputValue(
     formik.values.vi_list_of_exam_passed &&
       formik.values.vi_list_of_exam_passed[0]?.vi_exam_monthAndYear,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_of_exam_passed &&
       formik.values.vi_list_of_exam_passed[0]?.vi_name_of_exam,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_of_exam_passed &&
       formik.values.vi_list_of_exam_passed[0]?.vi_exam_body,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_of_exam_passed &&
       formik.values.vi_list_of_exam_passed[0]?.vi_exam_certificate,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_teacher_entries &&
       formik.values.vi_list_teacher_entries[0]?.vi_teacher_name,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_teacher_entries &&
       formik.values.vi_list_teacher_entries[0]?.vi_exam_name,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_teacher_entries &&
       formik.values.vi_list_teacher_entries[0]?.vi_gurukul_name,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_close_relative_teacher_option,
     count
   );
   count = handleInputValue(formik.values.vi_veda_knowledge, count);
   count = handleInputValue(
     formik.values.vi_list_studied_veda_details &&
       formik.values.vi_list_studied_veda_details[0]?.vi_subject,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_studied_veda_details &&
       formik.values.vi_list_studied_veda_details[0]?.vi_name_of_granth,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_studied_veda_details &&
       formik.values.vi_list_studied_veda_details[0]?.vi_year,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_studied_veda_details &&
       formik.values.vi_list_studied_veda_details[0]?.vi_exam_board,
     count
   );
   count = handleInputValue(
     formik.values.vi_list_studied_veda_details &&
       formik.values.vi_list_studied_veda_details[0]?.vi_exam_certificate,
     count
   );

   setActualCount(count);
 }, [formik.values]);


  useEffect(() => {
    isPageComplete(2, totalCount, actualCount);
  }, [actualCount, totalCount]);
  let RigvedEnglish = [
    "Shakalashakha",
    "Ashvalayanashakha",
    "Bashkalashakha",
    "Shankhayanashakha",
  ];

  let RigvedHindi = [
    "शाकलशाखा ",
    "आश्वलायनशाखा ",
    "बाष्कलशाखा ",
    "शांखायनशाखा ",
  ];

  let RigvedSanskrit = [
    "शाकलशाखा ",
    "आश्वलायनशाखा ",
    "बाष्कलशाखा ",
    "शांखायनशाखा ",
  ];

  RigvedEnglish =
    lang == "english"
      ? RigvedEnglish
      : lang == "hindi"
        ? RigvedHindi
        : RigvedSanskrit;

  let ShuklayajurvedEnglish = ["Kanvashakha", "Madhyandinashakha"];
  let ShuklayajurvedHindi = ["काण्वशाखा", "माध्यन्दिनशाखा"];
  let ShuklayajurvedSanskrit = ["काण्वशाखा ", "माध्यन्दिनशाखा "];

  ShuklayajurvedEnglish =
    lang == "english"
      ? ShuklayajurvedEnglish
      : lang == "hindi"
        ? ShuklayajurvedHindi
        : ShuklayajurvedSanskrit;

  let KrishnayajurvedEnglish = [
    "Taittiriyashakha",
    "Maitrayanishakha",
    "Kathashakha",
    "Kapishthal",
  ];
  let KrishnayajurvedHindi = [
    "तैत्तिरीयशाखा",
    "मैत्रायणीशाखा",
    "कठशाखा",
    "कपिष्ठलशाखा",
  ];
  let KrishnayajurvedSanskrit = [
    "तैत्तिरीयशाखा ",
    "मैत्रायणीशाखा ",
    "कठशाखा ",
    "कपिष्ठलशाखा ",
  ];
  KrishnayajurvedEnglish =
    lang == "english"
      ? KrishnayajurvedEnglish
      : lang == "hindi"
        ? KrishnayajurvedHindi
        : KrishnayajurvedSanskrit;

  let SamvedEnglish = [
    "Kauthumashakha",
    "Ranayaniyashakha",
    "Maitrayanishakha",
  ];
  let SamvedHindi = ["कौथुमशाखा", "राणायनीयशाखा", "मैत्रायणीशाखा"];
  let SamvedSanskrit = ["कौथुमशाखा ", "राणायनीयशाखा ", "मैत्रायणीशाखा "];

  SamvedEnglish =
    lang == "english"
      ? SamvedEnglish
      : lang == "hindi"
        ? SamvedHindi
        : SamvedSanskrit;

  let AtharvavedaEnglish = ["Shaunakashakha", "Paippaladashakha"];
  let AtharvavedaHindi = ["शौनकशाखा ", "पैप्पलादशाखा "];
  let AtharvavedaSanskrit = ["शौनकशाखा ", "पैप्पलादशाखा "];

  AtharvavedaEnglish =
    lang == "english"
      ? AtharvavedaEnglish
      : lang == "hindi"
        ? AtharvavedaHindi
        : AtharvavedaSanskrit;

  return (
    <div>
      <h3>
        {lang == "english"
          ? "Information of vedic education"
          : lang == "hindi"
            ? "वैदिक शिक्षा की जानकारी "
            : "वैदिकशिक्षा-सूचना"}
      </h3>
      <p className="text-primary">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
            ? "सभी प्रश्‍न अनिवार्य हैं।"
            : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>
      <div className="row">
        <div className="col-md-6 col-lg-4 form-group">
          <label htmlFor="vi_studied_veda_and_vedshakha">
            {lang == "english"
              ? "Which ved and vedashakha have you studied"
              : lang == "hindi"
                ? "अधीत वेद व वेदशाखा"
                : "अधीतवेदः वेदशाखा च "}
          </label>
          <select
            id="vi_studied_veda_and_vedshakha"
            name="vi_studied_veda_and_vedshakha"
            className="form-control"
            value={formik.values.vi_studied_veda_and_vedshakha}
            onChange={(e) =>
              handleInputChange("vi_studied_veda_and_vedshakha", e)
            }
            onBlur={(e) => handleInputSave()}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
              ---
            </option>
            <option value="Rigved">
              {lang == "english"
                ? "Rigveda"
                : lang == "hindi"
                  ? "ऋग्वेद "
                  : "ऋग्वेदः"}
            </option>
            <option value="Shuklayajurved">
              {lang == "english"
                ? "Shuklayajurveda"
                : lang == "hindi"
                  ? "शुक्लयजुर्वेद"
                  : "शुक्लयजुर्वेदः "}
            </option>
            <option value="Krishnayajurved">
              {lang == "english"
                ? "Krishnayajurveda"
                : lang == "hindi"
                  ? "कृष्णयजुर्वेद"
                  : "कृष्णयजुर्वेदः "}
            </option>
            <option value="Samved">
              {lang == "english"
                ? "Samveda"
                : lang == "hindi"
                  ? "सामवेद "
                  : "सामवेदः "}
            </option>
            <option value="Atharvaveda">
              {lang == "english"
                ? "Atharvaveda"
                : lang == "hindi"
                  ? "अथर्ववेद "
                  : "अथर्ववेदः "}
            </option>
          </select>
          {formik.touched && formik.errors?.vi_studied_veda_and_vedshakha ? (
            <div className="text-danger">
              {formik.errors?.vi_studied_veda_and_vedshakha &&
                formik.errors?.vi_studied_veda_and_vedshakha}
            </div>
          ) : null}
        </div>
        <div className="col-md-6 col-lg-4 form-group">
          <label htmlFor="vi_ved_select">
            {lang == "english"
              ? "Vedashakha "
              : lang == "hindi"
                ? "वेदशाखा "
                : "वेदशाखा "}
          </label>
          <select
            id="vi_ved_select"
            name="vi_ved_select"
            value={formik.values.vi_ved_select}
            className="form-control"
            onChange={(e) => handleInputChange("vi_ved_select", e)}
            onBlur={(e) => handleInputSave()}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
              ---
            </option>
            {formik.values.vi_studied_veda_and_vedshakha === "Rigved" &&
              RigvedEnglish.map((shakha, index) => (
                <option key={index} value={shakha}>
                  {shakha}
                </option>
              ))}
            {formik.values.vi_studied_veda_and_vedshakha === "Shuklayajurved" &&
              ShuklayajurvedEnglish.map((shakha, index) => (
                <option key={index} value={shakha}>
                  {shakha}
                </option>
              ))}
            {formik.values.vi_studied_veda_and_vedshakha ===
              "Krishnayajurved" &&
              KrishnayajurvedEnglish.map((shakha, index) => (
                <option key={index} value={shakha}>
                  {shakha}
                </option>
              ))}
            {formik.values.vi_studied_veda_and_vedshakha === "Samved" &&
              SamvedEnglish.map((shakha, index) => (
                <option key={index} value={shakha}>
                  {shakha}
                </option>
              ))}
            {formik.values.vi_studied_veda_and_vedshakha === "Atharvaveda" &&
              AtharvavedaEnglish.map((shakha, index) => (
                <option key={index} value={shakha}>
                  {shakha}
                </option>
              ))}
          </select>
          {formik.touched && formik.errors?.vi_ved_select ? (
            <div className="text-danger">
              {formik.errors?.vi_ved_select && formik.errors?.vi_ved_select}
            </div>
          ) : null}
        </div>
      </div>
      <h6>
        {lang == "english"
          ? "List of all the examinations you have passed related to vedavidya"
          : lang == "hindi"
            ? "वेदविद्या सम्बन्धित सभी उत्तीर्ण परीक्षाओं की सूची "
            : "वेदविद्यासम्बद्धानां सर्वासाम् उत्तीर्णपरीक्षाणां सूची "}
      </h6>
      <div className="table-responsive form-group">
        <table className="table">
          <thead>
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                    ? "क्रम संख्या "
                    : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "Month & Year"
                  : lang == "hindi"
                    ? "माह व वर्ष"
                    : "मासः वर्षञ्च"}
              </th>
              <th>
                {lang == "english"
                  ? "Level of study or name of the examination"
                  : lang == "hindi"
                    ? "अध्ययनस्तर अथवा परीक्षा का नाम"
                    : "अध्ययनस्तरः अथवा परीक्षानाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Examining body"
                  : lang == "hindi"
                    ? "परीक्षक संस्था"
                    : "परीक्षक-संस्था"}
              </th>
              <th>
                {lang == "english"
                  ? "Attach certificate"
                  : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे "
                    : "प्रमाणपत्रम् अपलोड इति करोतु"}
              </th>
              <th width="120">
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
              </th>
            </tr>
          </thead>
          <tbody>
            {formik.values.vi_list_of_exam_passed?.map((row, index) => (
              <tr key={index}>
                <td>{1 + index}</td>
                <td>
                  <input
                    type="month"
                    id={`vi_list_of_exam_passed[${index}].vi_exam_monthAndYear`}
                    name={`vi_list_of_exam_passed[${index}].vi_exam_monthAndYear`}
                    value={
                      formik.values.vi_list_of_exam_passed[index]
                        .vi_exam_monthAndYear || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vi_list_of_exam_passed[${index}.vi_exam_monthAndYear]`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                    className="form-control"
                  />
                  {formik.touched &&
                    formik.errors?.vi_list_of_exam_passed &&
                    formik.errors?.vi_list_of_exam_passed[index]
                      .vi_exam_monthAndYear ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_of_exam_passed[index]
                        .vi_exam_monthAndYear &&
                        formik.errors?.vi_list_of_exam_passed[index]
                          .vi_exam_monthAndYear}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    id={`vi_list_of_exam_passed[${index}].vi_name_of_exam`}
                    name={`vi_list_of_exam_passed[${index}].vi_name_of_exam`}
                    value={
                      formik.values.vi_list_of_exam_passed[index]
                        .vi_name_of_exam || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vi_list_of_exam_passed[${index}.vi_name_of_exam]`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                    className="form-control"
                  />
                  {formik.touched &&
                    formik.errors?.vi_list_of_exam_passed &&
                    formik.errors?.vi_list_of_exam_passed[index]
                      .vi_name_of_exam ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_of_exam_passed[index]
                        .vi_name_of_exam &&
                        formik.errors?.vi_list_of_exam_passed[index]
                          .vi_name_of_exam}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    id={`vi_list_of_exam_passed[${index}].vi_exam_body`}
                    name={`vi_list_of_exam_passed[${index}].vi_exam_body`}
                    value={
                      formik.values.vi_list_of_exam_passed[index]
                        .vi_exam_body || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vi_list_of_exam_passed[${index}.vi_exam_body]`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                    className="form-control"
                  />
                  {formik.touched &&
                    formik.errors?.vi_list_of_exam_passed &&
                    formik.errors?.vi_list_of_exam_passed[index].vi_exam_body ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_of_exam_passed[index]
                        .vi_exam_body &&
                        formik.errors?.vi_list_of_exam_passed[index]
                          .vi_exam_body}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    id={`vi_list_of_exam_passed[${index}].vi_exam_certificate`}
                    name={`vi_list_of_exam_passed[${index}].vi_exam_certificate`}
                    onChange={async (e) => {
                      if (e.target.files.length > 0) {
                        await fileUploadFunction(
                          `vi_list_of_exam_passed[${index}].vi_exam_certificate`,
                          e.target.files[0]
                        );
                        handleInputSave();
                      }
                    }}
                    className="form-control"
                  />
                  {formik.values?.vi_list_of_exam_passed[index]
                    .vi_exam_certificate && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vi_list_of_exam_passed[index].vi_exam_certificate}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik.values?.vi_list_of_exam_passed[
                          index
                        ].vi_exam_certificate.replace("upload-file/", "")}`}
                      </button>
                    )}
                  {formik.touched &&
                    formik.errors?.vi_list_of_exam_passed &&
                    formik.errors?.vi_list_of_exam_passed[index]
                      .vi_exam_certificate ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_of_exam_passed[index]
                        .vi_exam_certificate &&
                        formik.errors?.vi_list_of_exam_passed[index]
                          .vi_exam_certificate}
                    </div>
                  ) : null}
                </td>
                <td>
                  {formik.values.vi_list_of_exam_passed.length >= 2 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={(e) => removeExamPassRow(index, e)}
                    >
                      {lang == "english"
                        ? "Remove"
                        : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                    </button>
                  ) : null}
                </td>
              </tr>
            ))}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="7">
                <button
                  className="btn btn-outline-danger"
                  onClick={(e) => addExamPassRow(e)}
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <h5>
                {lang == "english" ? "Add vedadhyapak entries" : lang == "hindi" ? "" : ""}
            </h5> */}
      <h6>
        {lang == "english"
          ? "Information related to vedaadhyapaka and vedavidyalaya"
          : lang == "hindi"
            ? "वेदाध्यापक व वेदविद्यालय-सम्बन्धित जानकारी "
            : "वेदाध्यापक-वेदविद्यालययोः सम्बद्धा सूचना"}
      </h6>
      <div className="table-responsive form-group">
        <table className="table">
          <thead>
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                    ? "क्रम संख्या "
                    : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "Vedaadhyapaka's name"
                  : lang == "hindi"
                    ? "वेदाध्यापक का नाम"
                    : "वेदाध्यापक-नाम"}
              </th>
              <th>
                {lang == "english"
                  ? "Exam name"
                  : lang == "hindi"
                    ? "परीक्षा नाम "
                    : "परीक्षा-नाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Vedavidyalaya name"
                  : lang == "hindi"
                    ? "वेदविद्यालय का नाम "
                    : "वेदविद्यालय-नाम "}
              </th>
              <th width="120">
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
              </th>
            </tr>
          </thead>
          <tbody>
            {formik.values.vi_list_teacher_entries?.map((row, index) => (
              <tr key={row.id}>
                <td>{1 + index}</td>
                <td>
                  <input
                    type="text"
                    id={`vi_list_teacher_entries[${index}].vi_teacher_name`}
                    name={`vi_list_teacher_entries[${index}].vi_teacher_name`}
                    value={
                      formik.values.vi_list_teacher_entries[index]
                        .vi_teacher_name || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vi_list_teacher_entries[${index}.vi_teacher_name]`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                    className="form-control"
                  />
                  {formik.touched &&
                    formik.errors?.vi_list_teacher_entries &&
                    formik.errors?.vi_list_teacher_entries[index]
                      .vi_teacher_name ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_teacher_entries[index]
                        .vi_teacher_name &&
                        formik.errors?.vi_list_teacher_entries[index]
                          .vi_teacher_name}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    id={`vi_list_teacher_entries[${index}].vi_exam_name`}
                    name={`vi_list_teacher_entries[${index}].vi_exam_name`}
                    value={
                      formik.values.vi_list_teacher_entries[index]
                        .vi_exam_name || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vi_list_teacher_entries[${index}.vi_exam_name]`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                    className="form-control"
                  />
                  {formik.touched &&
                    formik.errors?.vi_list_teacher_entries &&
                    formik.errors?.vi_list_teacher_entries[index].vi_exam_name ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_teacher_entries[index]
                        .vi_exam_name &&
                        formik.errors?.vi_list_teacher_entries[index]
                          .vi_exam_name}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    id={`vi_list_teacher_entries[${index}].vi_gurukul_name`}
                    name={`vi_list_teacher_entries[${index}].vi_gurukul_name`}
                    value={
                      formik.values.vi_list_teacher_entries[index]
                        .vi_gurukul_name || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vi_list_teacher_entries[${index}.vi_gurukul_name]`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                    className="form-control"
                  />
                  {formik.touched &&
                    formik.errors?.vi_list_teacher_entries &&
                    formik.errors?.vi_list_teacher_entries[index]
                      .vi_gurukul_name ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_teacher_entries[index]
                        .vi_gurukul_name &&
                        formik.errors?.vi_list_teacher_entries[index]
                          .vi_gurukul_name}
                    </div>
                  ) : null}
                </td>
                <td>
                  {formik.values.vi_list_teacher_entries.length >= 2 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={(e) => removeVedadhyapakaRow(index, e)}
                    >
                      {lang == "english"
                        ? "Remove"
                        : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                    </button>
                  ) : null}
                </td>
              </tr>
            ))}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="7">
                <button
                  className="btn btn-outline-danger"
                  onClick={(e) => addVedadhyapakaRow(e)}
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-md-6 col-lg-8 form-group">
          <label htmlFor="vi_list_close_relative_teacher_option">
            {lang == "english"
              ? "If any of the above mentioned ved teachers are your close relatives?"
              : lang == "hindi"
                ? "क्या उपर्युक्त वेदाध्यापक में से कोई आपके निकट परिजन हैं?"
                : "उपर्युक्त-वेदाध्यापकेषु कोऽपि भवतः निकटबान्धवः अस्ति?"}
          </label>
          <select
            id="vi_list_close_relative_teacher_option"
            name="vi_list_close_relative_teacher_option"
            value={formik.values.vi_list_close_relative_teacher_option}
            onChange={(e) => {
              handleInputChange(`vi_list_close_relative_teacher_option`, e);
              if (e.target.value == "no") {
                formik.setFieldValue("vi_list_close_relative_teacher", [
                  {
                    vi_relation: "N/A",
                    vi_other_relative: "N/A",
                  },
                ]);
              }
              if (e.target.value == "yes") {
                formik.setFieldValue("vi_list_close_relative_teacher", [
                  {
                    vi_relation: "",
                    vi_other_relative: "",
                  },
                ]);
              }
            }}
            onBlur={(e) => handleInputSave()}
            className="form-control"
          >
            <option>
              {" "}
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value="no">
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched &&
            formik.errors?.vi_list_close_relative_teacher_option ? (
            <div className="text-danger">
              {formik.errors?.vi_list_close_relative_teacher_option &&
                formik.errors?.vi_list_close_relative_teacher_option}
            </div>
          ) : null}
        </div>
        {formik.values.vi_list_close_relative_teacher_option == "yes" ? (
          <div className="table-responsive form-group col-12">
            <h6>
              {lang == "english"
                ? "Describe the family relationship with serial number."
                : lang == "hindi"
                  ? "क्रम संख्या लिखकर पारिवारिक सम्बन्ध का वर्णन करें।"
                  : "तन्नाम्नः क्रमाङ्कं लिखित्वा पारिवारिकसम्बन्धस्य विवरणं ददातु।"}
            </h6>

            <table className="table">
              <thead>
                <tr>
                  <th width="100">
                    {lang == "english"
                      ? "Serial number"
                      : lang == "hindi"
                        ? "क्रमांक "
                        : "क्रमाङ्कः"}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Relation"
                      : lang == "hindi"
                        ? "सम्बन्ध "
                        : "सम्बन्धः "}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Other"
                      : lang == "hindi"
                        ? "अन्य  "
                        : "अन्यः  "}
                  </th>
                  <th width="120">
                    {lang == "english"
                      ? "Action"
                      : lang == "hindi"
                        ? "क्रिया"
                        : "क्रिया"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {formik?.values?.vi_list_close_relative_teacher?.map(
                  (value, index) => (
                    <tr>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          id={`vi_list_close_relative_teacher[${index}].vi_serial_number`}
                          name={`vi_list_close_relative_teacher[${index}].vi_serial_number`}
                          value={
                            formik.values?.vi_list_close_relative_teacher[index]
                              ?.vi_serial_number
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `vi_list_close_relative_teacher[${index}].vi_serial_number`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave(e)}
                        ></input>
                        {formik.touched &&
                          formik?.errors?.vi_list_close_relative_teacher &&
                          formik?.errors?.vi_list_close_relative_teacher[index]
                            ?.vi_serial_number ? (
                          <div className="text-danger">
                            {
                              formik?.errors?.vi_list_close_relative_teacher[
                                index
                              ]?.vi_serial_number
                            }
                          </div>
                        ) : null}
                      </td>
                      <td>
                        <select
                          id={`vi_list_close_relative_teacher[${index}].vi_relation`}
                          name={`vi_list_close_relative_teacher[${index}].vi_relation`}
                          value={
                            formik.values.vi_list_close_relative_teacher[index]
                              ?.vi_relation
                          }
                          onChange={(e) => {
                            handleInputChange(
                              `vi_list_close_relative_teacher[${index}].vi_relation`,
                              e
                            );
                            if (e.target.value != "other") {
                              formik.setFieldValue(
                                `vi_list_close_relative_teacher[${index}].vi_other_relative`,
                                "N/A"
                              );
                            }
                            if (e.target.value == "other") {
                              formik.setFieldValue(
                                `vi_list_close_relative_teacher[${index}].vi_other_relative`,
                                ""
                              );
                            }
                          }}
                          onBlur={(e) => handleInputSave()}
                          className="form-control"
                        >
                          <option selected>
                            ---
                            {lang == "english"
                              ? "Select"
                              : lang == "hindi"
                                ? "चुने"
                                : "चिनोतु"}
                            ---
                          </option>
                          <option value="father">
                            {lang == "english"
                              ? "Father"
                              : lang == "hindi"
                                ? "पिता "
                                : "पिता "}
                          </option>
                          <option value="brother">
                            {lang == "english"
                              ? "Brother"
                              : lang == "hindi"
                                ? "भाई "
                                : "भ्राता "}
                          </option>
                          <option value="other">
                            {lang == "english"
                              ? "Other"
                              : lang == "hindi"
                                ? "अन्य  "
                                : "अन्यः  "}
                          </option>
                        </select>
                        {formik?.errors?.vi_list_close_relative_teacher &&
                          formik?.errors?.vi_list_close_relative_teacher[index]
                            ?.vi_relation ? (
                          <div className="text-danger">
                            {
                              formik?.errors?.vi_list_close_relative_teacher[
                                index
                              ]?.vi_relation
                            }
                          </div>
                        ) : null}
                      </td>
                      <td>
                        {formik.values.vi_list_close_relative_teacher[index]
                          .vi_relation === "other" ? (
                          <>
                            <input
                              type="text"
                              className="form-control"
                              id={`vi_list_close_relative_teacher[${index}].vi_other_relative`}
                              name={`vi_list_close_relative_teacher[${index}].vi_other_relative`}
                              value={
                                formik.values.vi_list_close_relative_teacher[
                                  index
                                ].vi_other_relative
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  `vi_list_close_relative_teacher[${index}].vi_other_relative`,
                                  e
                                )
                              }
                              onBlur={(e) => handleInputSave()}
                            ></input>
                            {formik.touched &&
                              formik.errors?.vi_list_close_relative_teacher &&
                              formik.errors?.vi_list_close_relative_teacher[index]
                                .vi_other_relative ? (
                              <div className="text-danger">
                                {
                                  formik.errors?.vi_list_close_relative_teacher[
                                    index
                                  ].vi_other_relative
                                }
                              </div>
                            ) : null}
                          </>
                        ) : (
                          ""
                        )}
                      </td>

                      <td>
                        {formik.values.vi_list_close_relative_teacher.length >=
                          2 ? (
                          <button
                            className="btn btn-outline-danger"
                            onClick={(e) => removeRelativeRow(index, e)}
                          >
                            {lang == "english"
                              ? "Remove"
                              : lang == "hindi"
                                ? "हटाएँ"
                                : "अपाकरोतु"}
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  )
                )}
                <tr className="border-bottom-0">
                  <td className="text-right" colspan="7">
                    <button
                      className="btn btn-outline-danger"
                      onClick={(e) => addRelativeRow(e)}
                    >
                      {lang == "english"
                        ? "Add"
                        : lang == "hindi"
                          ? "जोड़ें"
                          : "संयोजयतु"}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
        <div className="col-md-8 form-group">
          <label htmlFor="vi_veda_knowledge">
            {lang == "english"
              ? "Have you got such Ved knowledge that is considered endangered or rare?"
              : lang == "hindi"
                ? "5.	क्या आपने ऐसा वेदज्ञान प्राप्त किया है जो लुप्तप्राय अथवा दुर्लभ माना जाता है?"
                : "किम् एतादृशं वेदज्ञानं भवता प्राप्तं यत् विलुप्तप्रायं दुर्लभं वा मन्यते?"}
          </label>
          <select
            id="vi_veda_knowledge"
            name="vi_veda_knowledge"
            className="form-control"
            value={formik.values.vi_veda_knowledge}
            onChange={(e) => {
              handleInputChange(`vi_veda_knowledge`, e);
              if (e.target.value == "no") {
                formik.setFieldValue("vi_veda_knowledge_detail", "N/A");
              }
              if (e.target.value == "yes") {
                formik.setFieldValue("vi_veda_knowledge_detail", "");
              }
            }}
            onBlur={(e) => handleInputSave()}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value="no">
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched && formik.errors?.vi_veda_knowledge ? (
            <div className="text-danger">
              {formik.errors?.vi_veda_knowledge &&
                formik.errors?.vi_veda_knowledge}
            </div>
          ) : null}
        </div>
        {formik.values.vi_veda_knowledge == "yes" ? (
          <div className=" col-12">
            <h5>
              {lang == "english"
                ? "Give details"
                : lang == "hindi"
                  ? "विवरण दे"
                  : "विवरणं ददातु "}
            </h5>
            <div>
              <textarea
                className="form-control col-12"
                name="vi_veda_knowledge_detail"
                rows={2}
                value={formik.values.vi_veda_knowledge_detail}
                onChange={(e) =>
                  handleInputChange(`vi_veda_knowledge_detail`, e)
                }
                maxLength={700}
                onBlur={(e) => handleInputSave()}
              ></textarea>

              <p className="text-right">
                {" "}
                {formik.values.vi_veda_knowledge_detail.length}
                /700 Characters
              </p>
              {formik.touched && formik.errors?.vi_veda_knowledge_detail ? (
                <div className="text-danger">
                  {formik.errors?.vi_veda_knowledge_detail &&
                    formik.errors?.vi_veda_knowledge_detail}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <h6>
        {lang == "english"
          ? "Give details of education/test acquired by you in the following topics of veda studies"
          : lang == "hindi"
            ? "अधीत वेद के निम्नलिखित विषयों में आप द्वारा अर्जित शिक्षा / परीक्षा का विवरण दीजिये"
            : "अधीतवेदस्य निम्नलिखितविषयेषु भवता प्राप्तायाः शिक्षायाः / परीक्षायाः वा विवरणं ददातु"}
      </h6>
      <div className="table-responsive form-group">
        <table className="table">
          <thead>
            <tr>
              <th>
                {lang == "english"
                  ? "Serial number"
                  : lang == "hindi"
                    ? "क्रम संख्या "
                    : "क्रमसङ्ख्या "}
              </th>
              <th>
                {lang == "english"
                  ? "Subject"
                  : lang == "hindi"
                    ? "विषय "
                    : "विषयः "}
              </th>
              <th>
                {lang == "english"
                  ? "Name of grantha"
                  : lang == "hindi"
                    ? "ग्रन्थ नाम "
                    : "ग्रन्थनाम "}
              </th>
              <th>
                {lang == "english"
                  ? "Year"
                  : lang == "hindi"
                    ? "वर्ष "
                    : "वर्षम् "}
              </th>
              <th>
                {lang == "english"
                  ? "Examination board"
                  : lang == "hindi"
                    ? "परीक्षा बोर्ड "
                    : "परीक्षायाः बोर्ड इति "}
              </th>
              <th>
                {lang == "english"
                  ? "Attach certificate"
                  : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु"}
              </th>
              <th width="120">
                {lang == "english"
                  ? "Action"
                  : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
              </th>
            </tr>
          </thead>
          <tbody>
            {formik.values.vi_list_studied_veda_details?.map((row, index) => (
              <tr>
                <td>{1 + index}</td>
                <td>
                  <select
                    id={`vi_list_studied_veda_details[${index}].vi_subject`}
                    name={`vi_list_studied_veda_details[${index}].vi_subject`}
                    className="form-control"
                    value={
                      formik.values.vi_list_studied_veda_details[index]
                        .vi_subject || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vi_list_studied_veda_details[${index}.vi_subject]`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                  >
                    <option selected>
                      ---
                      {lang == "english"
                        ? "Select"
                        : lang == "hindi"
                          ? "चुने"
                          : "चिनोतु"}
                      ---
                    </option>
                    <option value="Brahmana">
                      {lang == "english"
                        ? "Brahmana"
                        : lang == "hindi"
                          ? "ब्राह्मण "
                          : "ब्राह्मणम् "}
                    </option>
                    <option value="Aaranyaka">
                      {lang == "english"
                        ? "Aaranyaka"
                        : lang == "hindi"
                          ? "आरण्यक "
                          : "आरण्यकम् "}
                    </option>
                    <option value="Upnishad">
                      {lang == "english"
                        ? "Upnishad"
                        : lang == "hindi"
                          ? "उपनिषद् "
                          : "उपनिषद् "}
                    </option>
                    <option value="Shadanga">
                      {lang == "english"
                        ? "Shadanga"
                        : lang == "hindi"
                          ? "षडंग "
                          : "षडङ्गानि "}
                    </option>
                    <option value="Shadanga">
                      {lang == "english"
                        ? "Lakshana"
                        : lang == "hindi"
                          ? "लक्षण  "
                          : "लक्षणम्  "}
                    </option>
                    <option value="Bhashya">
                      {lang == "english"
                        ? "Bhashya"
                        : lang == "hindi"
                          ? "भाष्य "
                          : "भाष्यम् "}
                    </option>
                  </select>
                  {formik.touched &&
                    formik.errors?.vi_list_studied_veda_details &&
                    formik.errors?.vi_list_studied_veda_details[index]
                      ?.vi_subject ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_studied_veda_details[index]
                        ?.vi_subject &&
                        formik.errors?.vi_list_studied_veda_details[index]
                          ?.vi_subject}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    id={`vi_list_studied_veda_details[${index}].vi_name_of_granth`}
                    name={`vi_list_studied_veda_details[${index}].vi_name_of_granth`}
                    className="form-control"
                    value={
                      formik.values.vi_list_studied_veda_details[index]
                        .vi_name_of_granth || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vi_list_studied_veda_details[${index}.vi_name_of_granth]`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                  />
                  {formik.touched &&
                    formik.errors?.vi_list_studied_veda_details &&
                    formik.errors?.vi_list_studied_veda_details[index]
                      .vi_name_of_granth ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_studied_veda_details[index]
                        .vi_name_of_granth &&
                        formik.errors?.vi_list_studied_veda_details[index]
                          .vi_name_of_granth}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="number"
                    id={`vi_list_studied_veda_details[${index}].vi_year`}
                    name={`vi_list_studied_veda_details[${index}].vi_year`}
                    className="form-control"
                    value={
                      formik.values.vi_list_studied_veda_details[index]
                        .vi_year || ""
                    }
                    onChange={(e) => {
                      if (e.target.value < 2099) {
                        handleInputChange(
                          `vi_list_studied_veda_details[${index}.vi_year]`,
                          e
                        );
                      }
                    }}
                    onBlur={(e) => handleInputSave()}
                  ></input>
                  {formik.touched &&
                    formik.errors?.vi_list_studied_veda_details &&
                    formik.errors?.vi_list_studied_veda_details[index].vi_year ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_studied_veda_details[index]
                        .vi_year &&
                        formik.errors?.vi_list_studied_veda_details[index]
                          .vi_year}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="text"
                    id={`vi_list_studied_veda_details[${index}].vi_exam_board`}
                    name={`vi_list_studied_veda_details[${index}].vi_exam_board`}
                    className="form-control"
                    value={
                      formik.values.vi_list_studied_veda_details[index]
                        .vi_exam_board || ""
                    }
                    onChange={(e) =>
                      handleInputChange(
                        `vi_list_studied_veda_details[${index}.vi_exam_board]`,
                        e
                      )
                    }
                    onBlur={(e) => handleInputSave()}
                  ></input>
                  {formik.touched &&
                    formik.errors?.vi_list_studied_veda_details &&
                    formik.errors?.vi_list_studied_veda_details[index]
                      .vi_exam_board ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_studied_veda_details[index]
                        .vi_exam_board &&
                        formik.errors?.vi_list_studied_veda_details[index]
                          .vi_exam_board}
                    </div>
                  ) : null}
                </td>
                <td>
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    id={`vi_list_studied_veda_details[${index}].vi_exam_certificate`}
                    name={`vi_list_studied_veda_details[${index}].vi_exam_certificate`}
                    className="form-control"
                    onChange={async (e) => {
                      if (e.target.files.length > 0) {
                        await fileUploadFunction(
                          `vi_list_studied_veda_details[${index}].vi_exam_certificate`,
                          e.target.files[0]
                        );
                      }
                    }}
                    onBlur={(e) => handleInputSave()}
                  ></input>
                  {formik.values?.vi_list_studied_veda_details[index]
                    .vi_exam_certificate && (
                      <button
                        className="uploadLink"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(
                            `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vi_list_studied_veda_details[index].vi_exam_certificate}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        {`${formik.values?.vi_list_studied_veda_details[
                          index
                        ].vi_exam_certificate.replace("upload-file/", "")}`}
                      </button>
                    )}
                  {formik.touched &&
                    formik.errors?.vi_list_studied_veda_details &&
                    formik.errors?.vi_list_studied_veda_details[index]
                      .vi_exam_certificate ? (
                    <div className="text-danger">
                      {formik.errors?.vi_list_studied_veda_details[index]
                        .vi_exam_certificate &&
                        formik.errors?.vi_list_studied_veda_details[index]
                          .vi_exam_certificate}
                    </div>
                  ) : null}
                </td>
                <td>
                  {formik.values.vi_list_studied_veda_details.length >= 2 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={(e) => {
                        removeStudiedVedaDetailsRow(index, e);
                      }}
                    >
                      {lang == "english"
                        ? "Remove"
                        : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                    </button>
                  ) : null}
                </td>
              </tr>
            ))}
            <tr className="border-bottom-0">
              <td className="text-right" colspan="7">
                <button
                  className="btn btn-outline-danger"
                  onClick={(e) => addStudiedVedaDetailsRow(e)}
                >
                  {lang == "english"
                    ? "Add"
                    : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Section_2;
