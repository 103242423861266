const piFields = {
  p_name: "",
  p_aadhaar_card_number: "",
  p_dob: "",
  p_pob: "",
  p_father_name: "",
  p_mother_name: "",
  p_rishi_gotra: "",
  p_rishi_gotra_other: "",
  p_swavedshakha: "",
  p_swavedshakha_show: false,
  p_anyashakha: "",
  p_full_correspondence_pincode: "",
  p_full_correspondence_district: "",
  p_full_correspondence_state: "",
  p_full_correspondence_address_line: "",
  p_paternal_home_pincode: "",
  p_paternal_home_district: "",
  p_paternal_home_state: "",
  p_paternal_home_address_line: "",
  p_details_of_children_show: "",
  p_add_son_daughter: [
    {
      name: "",
      gender: "",
      age: "",
      veda_education_level: "",
      other_study: "",
    },
  ],
  p_mobile_number: "",
  p_email_address: "",
  p_married_status: "",
  p_wife_name: "",
  p_details_of_school: "",
  p_average: "",
  p_minimum: "",
  p_maximum: "",
  p_food_and_accommodation: "",
  p_self_observance: "",
  p_upload_passport_photo: "",
  p_upload_passport_photo_string: "",
};
export default piFields;
