import * as Yup from "yup";

const validationRules = {
  // Personal Information Fields
  p_name: Yup.string().required("This field is required"),
  p_aadhaar_card_number: Yup.string().required("This field is required"),
  p_dob: Yup.string().required("This field is required"),
  p_pob: Yup.string().required("This field is required"),
  p_father_name: Yup.string().required("This field is required"),
  p_mother_name: Yup.string().required("This field is required"),
  p_rishi_gotra: Yup.string().required("This field is required"),
  p_swavedshakha: Yup.string().required("This field is required"),
  p_swavedshakha_show: false,
  // p_anyashakha: Yup.string().required("This field is required"),
  p_full_correspondence_pincode: Yup.string().required(
    "This field is required"
  ),
  p_full_correspondence_district: Yup.string().required(
    "This field is required"
  ),
  p_full_correspondence_state: Yup.string().required("This field is required"),
  p_full_correspondence_address_line: Yup.string().required(
    "This field is required"
  ),
  p_paternal_home_pincode: Yup.string().required("This field is required"),
  p_paternal_home_district: Yup.string().required("This field is required"),
  p_paternal_home_state: Yup.string().required("This field is required"),
  p_paternal_home_address_line: Yup.string().required("This field is required"),
  p_details_of_children_show: Yup.string().required("This field is required"),
  p_add_son_daughter: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("This field is required"),
      gender: Yup.string().required("This field is required"),
      age: Yup.string().required("This field is required"),
      veda_education_level: Yup.string().required("This field is required"),
      // other_study: Yup.string().required("This field is required"),
    })
  ),
  p_mobile_number: Yup.string().required("This field is required"),
  p_email_address: Yup.string().required("This field is required"),
  p_married_status: Yup.string().required("This field is required"),
  p_wife_name: Yup.string().required("This field is required"),
  p_details_of_school: Yup.string().required("This field is required"),
  p_average: Yup.string().required("This field is required"),
  p_minimum: Yup.string().required("This field is required"),
  p_maximum: Yup.string().required("This field is required"),
  p_food_and_accommodation: Yup.string().required("This field is required"),
  p_self_observance: Yup.string().required("This field is required"),
  p_upload_passport_photo_string: Yup.string().required(
    "This field is required"
  ),

  // Vedic Information fields
  vi_studied_veda_and_vedshakha: Yup.string().required(
    "This field is required"
  ),
  vi_ved_select: Yup.string().required("This field is required"),
  vi_list_of_exam_passed: Yup.array().of(
    Yup.object().shape({
      vi_exam_monthAndYear: Yup.string().required("This field is required"),
      vi_name_of_exam: Yup.string().required("This field is required"),
      vi_exam_body: Yup.string().required("This field is required"),
      // vi_exam_certificate_string: Yup.string().required(
      //   "This field is required"
      // ),
    })
  ),
  vi_list_teacher_entries: Yup.array().of(
    Yup.object().shape({
      vi_teacher_name: Yup.string().required("This field is required"),
      vi_exam_name: Yup.string().required("This field is required"),
      vi_gurukul_name: Yup.string().required("This field is required"),
    })
  ),
  vi_list_close_relative_teacher_option: Yup.string().required(
    "This field is required"
  ),
  vi_list_close_relative_teacher: Yup.array().of(
    Yup.object().shape({
      vi_relation: Yup.string().required("This field is required"),

      // vi_other_relative: Yup.string().required("This field is required"),
    })
  ),
  vi_veda_knowledge: Yup.string().required("This field is required"),
  // vi_veda_knowledge_detail: Yup.string().required("This field is required"),
  vi_list_studied_veda_details: Yup.array().of(
    Yup.object().shape({
      vi_subject: Yup.string().required("This field is required"),
      vi_name_of_granth: Yup.string().required("This field is required"),
      vi_year: Yup.string().required("This field is required"),
      vi_exam_board: Yup.string().required("This field is required"),
      // vi_exam_certificate_string: Yup.string().required(
      //   "This field is required"
      // ),
    })
  ),

  // Vedic Teaching fields
  vt_veda_teaching: Yup.array().of(
    Yup.object().shape({
      from_month_year: Yup.string().required("This field is required"),
      till_month_year: Yup.string().required("This field is required"),
      name_vedavidyalaya: Yup.string().required("This field is required"),
      location_and_state: Yup.string().required("This field is required"),
    })
  ),
  vt_address_present_organization: Yup.string().required(
    "This field is required"
  ),
  vt_pincode_present_organization: Yup.string().required(
    "This field is required"
  ),
  vt_district_present_organization: Yup.string().required(
    "This field is required"
  ),
  vt_state_present_organization: Yup.string().required(
    "This field is required"
  ),
  vt_name_head_of_the_institution: Yup.string().required(
    "This field is required"
  ),
  vt_mobile_head_of_the_institution: Yup.string().required(
    "This field is required"
  ),
  vt_last_five_years_students_passed: Yup.array().of(
    Yup.object().shape({
      mulanta: Yup.string().required("This field is required"),
      kramanta: Yup.string().required("This field is required"),
      ghananta: Yup.string().required("This field is required"),
      total: Yup.string().required("This field is required"),
    })
  ),
  vt_students_studying_with_you_at_present: Yup.object().shape({
    mulanta: Yup.string().required("This field is required"),
    kramanta: Yup.string().required("This field is required"),
    ghananta: Yup.string().required("This field is required"),
    shadanga: Yup.string().required("This field is required"),
    lakshana: Yup.string().required("This field is required"),
    bhashya: Yup.string().required("This field is required"),
    total: Yup.string().required("This field is required"),
  }),
  vt_students_taught_by_you_entire_time_as_vedadhyapak: Yup.object().shape({
    mulanta: Yup.string().required("This field is required"),
    kramanta: Yup.string().required("This field is required"),
    ghananta: Yup.string().required("This field is required"),
    total: Yup.string().required("This field is required"),
  }),
  vt_did_not_complete_the_study_of_veda: Yup.string().required(
    "This field is required"
  ),
  vt_attendance_in_veda_sabhas: Yup.string().required("This field is required"),
  vt_attendance_in_veda_sabhas_table: Yup.array().of(
    Yup.object().shape({
      student_name: Yup.string().required("This field is required"),
      study: Yup.string().required("This field is required"),
      name_examination_body: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
      certificate_string: Yup.string().required("This field is required"),
    })
  ),
  vt_veda_students_currently_being_taught_by_you: Yup.string().required(
    "This field is required"
  ),
  vt_veda_students_currently_being_taught_by_you_table: Yup.array().of(
    Yup.object().shape({
      student_name: Yup.string().required("This field is required"),
      age: Yup.string().required("This field is required"),
      exam_level: Yup.string().required("This field is required"),
      other: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
      certificate_string: Yup.string().required("This field is required"),
    })
  ),
  vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka:
    Yup.string().required("This field is required"),
  vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table: Yup.array().of(
    Yup.object().shape({
      vedvidyarthi_name: Yup.string().required("This field is required"),
      last_year_of_vedadhyayan: Yup.string().required("This field is required"),
      student_vedshakha: Yup.string().required("This field is required"),
      student_vedshakha_other: Yup.string().required("This field is required"),
      institution_name: Yup.string().required("This field is required"),
    })
  ),
  vt_shakhas_that_are_dying: Yup.string().required("This field is required"),
  // vt_teaching_preservation_details: Yup.string().required(
  //   "This field is required"
  // ),

  // Ved and literature fields
  vl_list_of_attendance_in_veda_sabha: Yup.string().required(
    "This field is required"
  ),
  vl_list_of_attendance_in_veda_sabha_table: Yup.array().of(
    Yup.object().shape({
      meeting_or_conference: Yup.string().required("This field is required"),
      organizer: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
      certificate_string: Yup.string().required("This field is required"),
    })
  ),
  vl_have_you_participated_in_vedic_shrauta_yajna: Yup.string().required(
    "This field is required"
  ),
  vl_have_you_participated_in_vedic_shrauta_yajna_table: Yup.array().of(
    Yup.object().shape({
      yajna: Yup.string().required("This field is required"),
      the_host: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
      certificate_string: Yup.string().required("This field is required"),
    })
  ),
  vl_information_on_testimonials_and_awards_received: Yup.string().required(
    "This field is required"
  ),
  vl_information_on_testimonials_and_awards_received_table: Yup.array().of(
    Yup.object().shape({
      award_name: Yup.string().required("This field is required"),
      who_gave_it: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
      certificate_string: Yup.string().required("This field is required"),
    })
  ),
  vl_information_about_books: Yup.string().required("This field is required"),
  vl_information_about_books_table: Yup.array().of(
    Yup.object().shape({
      topic: Yup.string().required("This field is required"),
      publisherISBN: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
      certificate_string: Yup.string().required("This field is required"),
    })
  ),
  vl_have_you_performed_soma_yajna: Yup.string().required(
    "This field is required"
  ),
  vl_have_you_performed_soma_yajna_detail: Yup.string().required(
    "This field is required"
  ),
  vl_is_your_conduct_according_to_vedas: Yup.string().required(
    "This field is required"
  ),
  vl_is_your_conduct_according_to_vedas_details: Yup.string().required(
    "This field is required"
  ),

  // Other info validations
  o_main_efforts_by_you: Yup.string().required("This field is required"),
  o_five_achievements: Yup.string().required("This field is required"),
  o_quality_of_an_vedadhdyapaka: Yup.string().required(
    "This field is required"
  ),
  o_understanding_of_young_generation: Yup.string().required(
    "This field is required"
  ),

  // Affidavit validations
  ad_total_teaching_year: Yup.string().required("This field is required"),
  // ad_signature_vedadhyapak_string: Yup.string().required(
  //   "This field is required"
  // ),
  // ad_location: Yup.string().required("This field is required"),
  // ad_date: Yup.string().required("This field is required"),
  // ad_vedvidyalaya_name: Yup.string().required("This field is required"),
  // ad_head_vedvidyalaya_name: Yup.string().required("This field is required"),
  // ad_head_institution_date: Yup.string().required("This field is required"),
  // ad_signature_head_institution_string: Yup.string().required(
  //   "This field is required"
  // ),
  ad_affidavit_file_string: Yup.string().required("This field is required"),

  vt_have_you_taught_any_member_detail: Yup.string().required(
    "This field is required"
  ),

  // Artical desc. validations
  // al_description_of_articles: Yup.array().of(
  //   Yup.object().shape({
  //     details_of_document: Yup.string().required("This field is required"),
  //     // attach_file_string: Yup.string().required("This field is required"),
  //   })
  // ),

  vt_family_members_taught_by_you: Yup.array().of(
    Yup.object().shape({
      family_member_name: Yup.string().required("This field is required"),
      relationship: Yup.string().required("This field is required"),
      level_of_study: Yup.string().required("This field is required"),
      // other: Yup.string().required("This field is required"),
      family_member_occupation: Yup.string().required("This field is required"),
    })
  ),
};

export default validationRules;
