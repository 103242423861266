import React, { useEffect, useState } from "react";

function Section_1({
  handleInputChange,
  formik,
  handleInputSave,
  addBrotherSisterRow,
  removeBrotherSisterRow,
  lang,
  fileUploadFunction,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(30);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.p_name, count);
    count = handleInputValue(formik?.values?.p_aadhaar_card_number, count);
    count = handleInputValue(formik?.values?.p_dob, count);
    count = handleInputValue(formik?.values?.p_pob, count);
    count = handleInputValue(formik?.values?.p_father_name, count);
    count = handleInputValue(formik?.values?.p_mother_name, count);
    count = handleInputValue(formik?.values?.p_rishi_gotra, count);
    count = handleInputValue(formik?.values?.p_swavedshakha, count);
    count = handleInputValue(formik?.values?.p_swavedshakha_show, count);
    count = handleInputValue(formik?.values?.p_anyashakha, count);
    count = handleInputValue(
      formik?.values?.p_full_correspondence_pincode,
      count
    );
    count = handleInputValue(
      formik?.values?.p_full_correspondence_district,
      count
    );
    count = handleInputValue(
      formik?.values?.p_full_correspondence_state,
      count
    );
    count = handleInputValue(
      formik?.values?.p_full_correspondence_address_line,
      count
    );
    count = handleInputValue(formik?.values?.p_paternal_home_pincode, count);
    count = handleInputValue(formik?.values?.p_paternal_home_district, count);
    count = handleInputValue(formik?.values?.p_paternal_home_state, count);
    count = handleInputValue(
      formik?.values?.p_paternal_home_address_line,
      count
    );
    count = handleInputValue(formik?.values?.p_details_of_children_show, count);
    count = handleInputValue(formik?.values?.p_mobile_number, count);
    count = handleInputValue(formik?.values?.p_email_address, count);
    count = handleInputValue(formik?.values?.p_married_status, count);
    count = handleInputValue(formik?.values?.p_wife_name, count);
    count = handleInputValue(formik?.values?.p_details_of_school, count);
    count = handleInputValue(formik?.values?.p_average, count);
    count = handleInputValue(formik?.values?.p_minimum, count);
    count = handleInputValue(formik?.values?.p_maximum, count);
    count = handleInputValue(formik?.values?.p_food_and_accommodation, count);
    count = handleInputValue(formik?.values?.p_self_observance, count);
    count = handleInputValue(
      formik?.values?.p_upload_passport_photo_string,
      count
    );
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(1, totalCount, actualCount);
  }, [actualCount, totalCount]);

  return (
    <div>
      <div className="mt-0 form">
        <h3>
          {lang == "english"
            ? "Personal information"
            : lang == "hindi"
            ? "व्यक्तिगत जानकारी"
            : "व्यक्तिगतसूचना "}
        </h3>
        <p className="text-primary ">
          {lang == "english"
            ? "All fields are mandatory"
            : lang == "hindi"
            ? "सभी प्रश्‍न अनिवार्य हैं।"
            : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
        </p>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_name">
              {lang == "english"
                ? "Full Name"
                : lang == "hindi"
                ? "पूरा नाम"
                : "सम्पूर्ण नाम"}
            </label>
            <input
              type="text"
              name="p_name"
              id="p_name"
              onChange={(e) => {
                handleInputChange("pi_name", e);
                formik.setFieldValue("ad_name", e.target.value);
                formik.setFieldValue("ad_vedadhyapaka", e.target.value);
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_name}
              className="form-control"
            ></input>
            {formik.touched &&
            formik.errors?.pi_name &&
            formik.error?.pi_name ? (
              <div className="text-danger">
                {formik.errors?.pi_name && formik.errors?.pi_name}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_aadhaar_card_number">
              {lang == "english"
                ? "Aadhaar card number"
                : lang == "hindi"
                ? "आधारपत्र संख्या"
                : "आधारपत्रसङ्ख्या  "}
            </label>
            <input
              type="number"
              name="p_aadhaar_card_number"
              id="p_aadhaar_card_number"
              max={12}
              onChange={(e) => {
                if (e?.target?.value?.length <= 12) {
                  handleInputChange("p_aadhaar_card_number", e);
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_aadhaar_card_number}
              className="form-control"
            ></input>
            {formik.touched &&
            formik.errors?.p_aadhaar_card_number &&
            formik.errors?.p_aadhaar_card_number ? (
              <div className="text-danger">
                {formik.errors?.p_aadhaar_card_number &&
                  formik.errors?.p_aadhaar_card_number}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_dob">
              {lang == "english"
                ? "Date of birth"
                : lang == "hindi"
                ? "जन्म दिनांक"
                : "जन्मदिनाङ्कः"}
            </label>
            <input
              type="date"
              name="p_dob"
              id="p_dob"
              maxLength={10}
              max={new Date()?.toISOString()?.slice(0, 10)}
              onChange={(e) => handleInputChange("p_dob", e)}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_dob}
              className="form-control"
            ></input>
            {formik.touched && formik?.errors?.p_dob && formik.errors?.p_dob ? (
              <div className="text-danger">
                {formik.errors?.p_dob && formik.errors?.p_dob}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_pob">
              {lang == "english"
                ? "Place of birth"
                : lang == "hindi"
                ? "जन्म स्थान"
                : "जन्मस्थानम्"}
            </label>
            <input
              type="text"
              name="p_pob"
              id="p_pob"
              onChange={(e) => handleInputChange("p_pob", e)}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_pob}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_pob && formik.errors?.p_pob ? (
              <div className="text-danger">
                {formik.errors?.p_pob && formik.errors?.p_pob}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_father_name">
              {lang == "english"
                ? "Father's name"
                : lang == "hindi"
                ? "पिता का नाम"
                : "पितुः नाम"}
            </label>
            <input
              type="text"
              name="p_father_name"
              id="p_father_name"
              onChange={(e) => handleInputChange("p_father_name", e)}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_father_name}
              className="form-control"
            ></input>
            {formik.touched &&
            formik.errors?.p_father_name &&
            formik.errors?.p_father_name ? (
              <div className="text-danger">
                {formik.errors?.p_father_name && formik.errors?.p_father_name}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_mother_name">
              {lang == "english"
                ? "Mother's name"
                : lang == "hindi"
                ? "माता का नाम"
                : "मातुः नाम"}
            </label>
            <input
              type="text"
              name="p_mother_name"
              id="p_mother_name"
              onChange={(e) => handleInputChange("p_mother_name", e)}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_mother_name}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_mother_name ? (
              <div className="text-danger">
                {formik.errors?.p_mother_name && formik.errors?.p_mother_name}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_rishi_gotra">
              {lang == "english"
                ? "Rishi gotra"
                : lang == "hindi"
                ? "ऋषि गोत्र"
                : "ऋषिगोत्रम्"}
            </label>
            <select
              id="p_rishi_gotra"
              name="p_rishi_gotra"
              className="form-control"
              onChange={(e) => handleInputChange("p_rishi_gotra", e)}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_rishi_gotra}
            >
              <option selected>
                ---
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
                ---
              </option>
              <option value="Bharadvajagotra">
                {lang == "english"
                  ? "Bharadvajagotra"
                  : lang == "hindi"
                  ? "भारद्वाजगोत्र"
                  : "भारद्वाजगोत्रः"}
              </option>

              <option value="Vatsagotra">
                {lang == "english"
                  ? "Vatsagotra"
                  : lang == "hindi"
                  ? "वत्सगोत्र"
                  : "वत्सगोत्रः"}
              </option>

              <option value="Kashyapagotra">
                {lang == "english"
                  ? "Kashyapagotra"
                  : lang == "hindi"
                  ? "काश्यपगोत्र"
                  : "काश्यपगोत्रः"}
              </option>

              <option value="Gautamagotra">
                {lang == "english"
                  ? "Gautamagotra"
                  : lang == "hindi"
                  ? "गौतमगोत्र"
                  : "गौतमगोत्रः"}
              </option>

              <option value="Vishvamitragotra">
                {lang == "english"
                  ? "Vishvamitragotra"
                  : lang == "hindi"
                  ? "विश्वामित्रगोत्र"
                  : "विश्वामित्रगोत्रः"}
              </option>

              <option value="Jamadagnigotra">
                {lang == "english"
                  ? "Jamadagnigotra"
                  : lang == "hindi"
                  ? "जामदग्निगोत्र"
                  : "जामदग्निगोत्रः"}
              </option>

              <option value="Atreyagotra">
                {lang == "english"
                  ? "Atreyagotra"
                  : lang == "hindi"
                  ? "आत्रेयगोत्र"
                  : "आत्रेयगोत्रः"}
              </option>

              <option value="Vashishtgotra">
                {lang == "english"
                  ? "Vashishtgotra"
                  : lang == "hindi"
                  ? "वासिष्ठगोत्र"
                  : "वासिष्ठगोत्रः"}
              </option>

              <option value="Atrigotra">
                {lang == "english"
                  ? "Atrigotra"
                  : lang == "hindi"
                  ? "अत्रिगोत्र"
                  : "अत्रिगोत्रः"}
              </option>

              <option value="Anyagotra">
                {lang == "english"
                  ? "Anyagotra"
                  : lang == "hindi"
                  ? "अन्यगोत्र"
                  : "अन्यगोत्रः"}
              </option>
            </select>
            {formik.touched && formik.errors?.p_rishi_gotra ? (
              <div className="text-danger">
                {formik.errors?._rishi_gotra && formik.errors?.p_rishi_gotra}
              </div>
            ) : null}
          </div>
          {formik?.values?.p_rishi_gotra == "Anyagotra" && (
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlfor="p_rishi_gotra_other">
                {lang == "english"
                  ? "Anyagotra"
                  : lang == "hindi"
                  ? "अन्यगोत्र"
                  : "अन्यगोत्रः"}
              </label>
              <input
                type="text"
                id="p_rishi_gotra_other"
                name="p_rishi_gotra_other"
                className="form-control"
                onChange={(e) => handleInputChange("p_rishi_gotra_other", e)}
                onBlur={(e) => handleInputSave()}
                value={formik?.values?.p_rishi_gotra_other}
              />
            </div>
          )}

          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_swavedashakha">
              {lang == "english"
                ? "Swavedashakha"
                : lang == "hindi"
                ? "स्ववेद शाखा"
                : "स्ववेदशाखा "}
            </label>
            <select
              id="p_swavedshakha"
              name="p_swavedshakha"
              className="form-control"
              onChange={(e) => {
                handleInputChange("p_swavedshakha", e);
                if (e.target.value !== "Anyashakha") {
                  formik.setFieldValue("p_anyashakha", "N/A");
                } else {
                  formik.setFieldValue("p_anyashakha", "");
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_swavedshakha}
            >
              <option selected>
                ---
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
                ---
              </option>
              <option value="Shakalshakha">
                {lang == "english"
                  ? "Shakalshakha"
                  : lang == "hindi"
                  ? "शाकलशाखा "
                  : "शाकलशाखा "}
              </option>
              <option value="Ashvalayanashakha">
                {lang == "english"
                  ? "Ashvalayanshakha"
                  : lang == "hindi"
                  ? "आश्वलायनशाखा "
                  : "आश्वलायनशाखा "}
              </option>
              <option value="Bashkalashakha">
                {lang == "english"
                  ? "Bashkalshakha"
                  : lang == "hindi"
                  ? "बाष्कलशाखा "
                  : "बाष्कलशाखा "}
              </option>
              <option value="Shankhayanashakha">
                {lang == "english"
                  ? "Shankhayanshakha"
                  : lang == "hindi"
                  ? "शांखायनशाखा "
                  : "शाङ्खायनशाखा "}
              </option>
              <option value="Kanvashakha ">
                {lang == "english"
                  ? "Kanvashakha"
                  : lang == "hindi"
                  ? "काण्वशाखा "
                  : "काण्वशाखा "}
              </option>
              <option value="Madhyanadinshakha">
                {lang == "english"
                  ? "Madhyandinshakha"
                  : lang == "hindi"
                  ? "माध्यन्दिनशाखा "
                  : "माध्यन्दिनशाखा "}
              </option>
              <option value="Taittiriyashakha ">
                {lang == "english"
                  ? "Taittiriyashakha"
                  : lang == "hindi"
                  ? "तैत्तिरीयशाखा "
                  : "तैत्तिरीयशाखा "}
              </option>
              <option value="Maitrayanishakha ">
                {lang == "english"
                  ? "Maitrayanishakha"
                  : lang == "hindi"
                  ? "मैत्रायणीशाखा "
                  : "मैत्रायणीशाखा "}
              </option>
              <option value="Kathashakha ">
                {lang == "english"
                  ? "Kathashakha"
                  : lang == "hindi"
                  ? "कठशाखा "
                  : "कठशाखा "}
              </option>
              <option value="Kapishthalashakha ">
                {lang == "english"
                  ? "Kapishthalshakha"
                  : lang == "hindi"
                  ? "कपिष्ठलशाखा "
                  : "कपिष्ठलशाखा "}
              </option>
              <option value="Kouthumshakha ">
                {lang == "english"
                  ? "Kouthumshakha"
                  : lang == "hindi"
                  ? "कौथुमशाखा "
                  : "कौथुमशाखा "}
              </option>
              <option value="Ranayaniyashakha ">
                {lang == "english"
                  ? "Ranayaniyashakha"
                  : lang == "hindi"
                  ? "राणायनीयशाखा "
                  : "राणायनीयशाखा "}
              </option>
              <option value="Jaiminiyashakha ">
                {lang == "english"
                  ? "Jaiminiyashakha"
                  : lang == "hindi"
                  ? "जैमिनीयशाखा "
                  : "जैमिनीयशाखा "}
              </option>
              <option value="Shounkashakha ">
                {lang == "english"
                  ? "Shounkashakha"
                  : lang == "hindi"
                  ? "शौनकशाखा "
                  : "शौनकशाखा "}
              </option>
              <option value="Paippaladshakha ">
                {lang == "english"
                  ? "Paippaladshakha"
                  : lang == "hindi"
                  ? "पैप्पलादशाखा "
                  : "पैप्पलादशाखा"}
              </option>
              <option value={"Anyashakha"}>
                {lang == "english"
                  ? "Anyashakha "
                  : lang == "hindi"
                  ? "अन्यशाखा"
                  : "अन्यशाखा "}
              </option>
            </select>
            {formik.touched && formik.errors?.p_swavedshakha ? (
              <div className="text-danger">
                {formik.errors?.p_swavedshakha && formik.errors?.p_swavedshakha}
              </div>
            ) : null}
          </div>
          {formik?.values?.p_swavedshakha == "Anyashakha" && (
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="p_anyashakha">
                {lang == "english"
                  ? "Anyashakha "
                  : lang == "hindi"
                  ? "अन्यशाखा"
                  : "अन्यशाखा "}
              </label>
              <input
                type="text"
                name="p_anyashakha"
                id="p_anyashakha"
                onChange={(e) => handleInputChange("p_anyashakha", e)}
                onBlur={(e) => handleInputSave()}
                value={formik?.values?.p_anyashakha}
                className="form-control"
              ></input>
              {formik.touched && formik.errors?.p_anyashakha ? (
                <div className="text-danger">
                  {formik.errors?.p_anyashakha && formik.errors?.p_anyashakha}
                </div>
              ) : null}
            </div>
          )}
        </div>

        <h5>
          {lang == "english"
            ? "Full correspondence address"
            : lang == "hindi"
            ? "पत्राचार का पूर्ण पता"
            : "पूर्णपत्रसङ्केतः"}
        </h5>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_full_correspondence_pincode">
              {lang == "english"
                ? "Pin code"
                : lang == "hindi"
                ? "पिन कोड"
                : "पिनकोड-क्रमाङ्क:"}
            </label>
            <input
              type="number"
              name="p_full_correspondence_pincode"
              id="p_full_correspondence_pincode"
              onChange={(e) => {
                if (e?.target?.value?.length <= 6) {
                  handleInputChange("p_full_correspondence_pincode", e);
                  if (formik.values?.p_same_as?.includes("on")) {
                    formik.setFieldValue(
                      "p_paternal_home_pincode",
                      e.target.value
                    );
                  }
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_full_correspondence_pincode}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_full_correspondence_pincode ? (
              <div className="text-danger">
                {formik.errors?.p_full_correspondence_pincode &&
                  formik.errors?.p_full_correspondence_pincode}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_full_correspondence_district">
              {lang == "english"
                ? "District"
                : lang == "hindi"
                ? "जिला "
                : "जनपदः"}
            </label>
            <input
              type="text"
              name="p_full_correspondence_district"
              id="p_full_correspondence_district"
              onChange={(e) => {
                handleInputChange("p_full_correspondence_district", e);
                if (formik?.values?.p_same_as?.includes("on")) {
                  formik.setFieldValue(
                    "p_paternal_home_district",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_full_correspondence_district}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_full_correspondence_district ? (
              <div className="text-danger">
                {formik.errors?.p_full_correspondence_district &&
                  formik.errors?.p_full_correspondence_district}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_full_correspondence_state">
              {lang == "english"
                ? "State"
                : lang == "hindi"
                ? "राज्य "
                : "राज्यम् "}
            </label>
            <input
              type="text"
              name="p_full_correspondence_state"
              id="p_full_correspondence_state"
              onChange={(e) => {
                handleInputChange("p_full_correspondence_state", e);
                if (formik?.values?.p_same_as?.includes("on")) {
                  formik.setFieldValue("p_paternal_home_state", e.target.value);
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_full_correspondence_state}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_full_correspondence_state ? (
              <div className="text-danger">
                {formik.errors?.p_full_correspondence_state &&
                  formik.errors?.p_full_correspondence_state}
              </div>
            ) : null}
          </div>
          <div className="col-12 form-group">
            <label htmlFor="p_full_correspondence_address_line">
              {lang == "english" ? "Address" : lang == "hindi" ? "पता" : "पता"}
            </label>
            <input
              name="p_full_correspondence_address_line"
              type="text"
              id="p_full_correspondence_address_line"
              className="form-control"
              onChange={(e) => {
                handleInputChange("p_full_correspondence_address_line", e);
                if (formik?.values?.p_same_as?.includes("on")) {
                  formik.setFieldValue(
                    "p_paternal_home_address_line",
                    e.target.value
                  );
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_full_correspondence_address_line}
            ></input>
            {formik.touched &&
            formik.errors?.p_full_correspondence_address_line ? (
              <div className="text-danger">
                {formik.errors?.p_full_correspondence_address_line &&
                  formik.errors?.p_full_correspondence_address_line}
              </div>
            ) : null}
          </div>
        </div>

        <h5>
          {lang == "english"
            ? "Permanent / paternal home address"
            : lang == "hindi"
            ? "स्थायी / पितृ गृह का पता"
            : "स्थायि / पितृगृहपत्रसङ्केतः"}
        </h5>
        <div className="mb-5px">
          <input
            type="checkbox"
            id="p_same_as"
            name="p_same_as"
            onChange={(e) => {
              handleInputChange("p_same_as", e);
              if (e.target.value) {
                formik.setFieldValue(
                  "p_paternal_home_pincode",
                  formik?.values?.p_full_correspondence_pincode
                );
                formik.setFieldValue(
                  "p_paternal_home_district",
                  formik?.values?.p_full_correspondence_district
                );
                formik.setFieldValue(
                  "p_paternal_home_state",
                  formik?.values?.p_full_correspondence_state
                );
                formik.setFieldValue(
                  "p_paternal_home_address_line",
                  formik?.values?.p_full_correspondence_address_line
                );
              }
            }}
            onBlur={(e) => handleInputSave()}
          />
          <label for="same_as" className="pl-3">
            {lang == "english"
              ? "Same as full correspondence address"
              : lang == "hindi"
              ? "पत्राचार के पते के समान"
              : "पूर्णपत्रसङ्केतः इव"}
          </label>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_paternal_home_pincode">
              {lang == "english"
                ? "Pin code"
                : lang == "hindi"
                ? "पिन कोड"
                : "पिनकोड-क्रमाङ्क:"}
            </label>
            <input
              type="number"
              name="p_paternal_home_pincode"
              id="p_paternal_home_pincode"
              onChange={(e) => {
                if (e?.target?.value?.length <= 6) {
                  handleInputChange("p_paternal_home_pincode", e);
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_paternal_home_pincode}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_paternal_home_pincode ? (
              <div className="text-danger">
                {formik.errors?.p_paternal_home_pincode &&
                  formik.errors?.p_paternal_home_pincode}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_paternal_home_district">
              {lang == "english"
                ? "District"
                : lang == "hindi"
                ? "जिला "
                : "जनपदः"}
            </label>
            <input
              type="text"
              name="p_paternal_home_district"
              id="p_paternal_home_district"
              onChange={(e) => handleInputChange("p_paternal_home_district", e)}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_paternal_home_district}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_paternal_home_district ? (
              <div className="text-danger">
                {formik.errors?.p_paternal_home_district &&
                  formik.errors?.p_paternal_home_district}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_paternal_home_state">
              {" "}
              {lang == "english"
                ? "State"
                : lang == "hindi"
                ? "राज्य "
                : "राज्यम् "}{" "}
            </label>
            <input
              type="text"
              name="p_paternal_home_state"
              id="p_paternal_home_state"
              onChange={(e) => handleInputChange("p_paternal_home_state", e)}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_paternal_home_state}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_paternal_home_state ? (
              <div className="text-danger">
                {formik.errors?.p_paternal_home_state &&
                  formik.errors?.p_paternal_home_state}
              </div>
            ) : null}
          </div>
          <div className="col-12 form-group">
            <label htmlFor=" p_paternal_home_address_line">
              {lang == "english" ? "Address" : lang == "hindi" ? "पता" : "पता"}
            </label>
            <input
              name="p_paternal_home_address_line"
              type="text"
              id="p_paternal_home_address_line"
              className="  form-control"
              onChange={(e) =>
                handleInputChange("p_paternal_home_address_line", e)
              }
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_paternal_home_address_line}
            ></input>
            {formik.touched && formik.errors?.p_paternal_home_address_line ? (
              <div className="text-danger">
                {formik.errors?.p_paternal_home_address_line &&
                  formik.errors?.p_paternal_home_address_line}
              </div>
            ) : null}
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_mobile_number">
              {lang == "english"
                ? "Mobile number"
                : lang == "hindi"
                ? "मोबाइल नंबर"
                : "चलदूरवाणीसङ्ख्या"}
            </label>
            <input
              type="number"
              name="p_mobile_number"
              id="p_mobile_number"
              onChange={(e) => {
                if (e?.target?.value?.length <= 10) {
                  handleInputChange("p_mobile_number", e);
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_mobile_number}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_mobile_number ? (
              <div className="text-danger">
                {formik.errors?.p_mobile_number && formik.errors?.p_mobile_number}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_email_address">
              {lang == "english"
                ? "Email address"
                : lang == "hindi"
                ? "ईमेल आईडी॰"
                : "अन्तर्जालीय पत्रसङ्केतः"}
            </label>
            <input
              type="email"
              name="p_email_address"
              id="p_email_address"
              onChange={(e) => handleInputChange("p_email_address", e)}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_email_address}
              className="form-control"
            ></input>
            {formik.touched && formik.errors?.p_email_address ? (
              <div className="text-danger">
                {formik.errors?.p_email_address && formik.errors?.p_email_address}
              </div>
            ) : null}
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlFor="p_married_status">
              {lang == "english" ? "" : lang == "hindi" ? "" : ""}
              {lang == "english"
                ? "Martial status"
                : lang == "hindi"
                ? "वैवाहिक स्थिति"
                : "वैवाहिक-स्थितिः"}
            </label>
            <select
              id="p_married_status"
              name="p_married_status"
              className="form-control"
              onChange={(e) => {
                handleInputChange("p_married_status", e);
                if (e.target.value == "Married") {
                  formik?.setFieldValue("p_wife_name", "");
                }
                if (e.target.value == "Unmarried") {
                  formik?.setFieldValue("p_wife_name", "N/A");
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_married_status}
            >
              <option selected>
                ---{" "}
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
                ---
              </option>
              <option value="Married">
                {lang == "english"
                  ? "Married"
                  : lang == "hindi"
                  ? "विवाहित "
                  : "विवाहितः "}
              </option>
              <option value="Unmarried">
                {lang == "english"
                  ? "Unmarried"
                  : lang == "hindi"
                  ? "अविवाहित "
                  : "अविवाहितः "}
              </option>
            </select>
            {formik.touched && formik.errors?.p_married_status ? (
              <div className="text-danger">
                {formik.errors?.p_married_status &&
                  formik.errors?.p_married_status}
              </div>
            ) : null}
          </div>

          {formik?.values?.p_married_status === "Married" ? (
            <div className="col-md-6 col-lg-4 form-group">
              <label htmlFor="p_wife_name">
                {lang == "english"
                  ? "Wife's name"
                  : lang == "hindi"
                  ? "पत्नी का नाम"
                  : "भार्या-नाम"}
              </label>
              <input
                type="text"
                name="p_wife_name"
                id="p_wife_name"
                onChange={(e) => handleInputChange("p_wife_name", e)}
                onBlur={(e) => handleInputSave()}
                value={formik?.values?.p_wife_name}
                className="form-control"
              ></input>
              {formik.touched && formik.errors?.p_wife_name ? (
                <div className="text-danger">
                  {formik.errors?.p_wife_name && formik.errors?.p_wife_name}
                </div>
              ) : null}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="form-group">
          <h5>
            {lang == "english"
              ? "Details of children in age order"
              : lang == "hindi"
              ? "आयुक्रम में सन्तानों का विवरण"
              : "आयुक्रमेण सन्ततेः विवरणम्"}
          </h5>
          <div>
            <select
              className="form-control mb-10"
              name="p_details_of_children_show"
              id="p_details_of_children_show"
              onChange={(e) => {
                handleInputChange("p_details_of_children_show", e);
                if (e.target.value == "no") {
                  formik.setFieldValue("p_add_son_daughter", [
                    {
                      name: "N/A",
                      gender: "N/A",
                      age: "N/A",
                      veda_education_level: "N/A",
                      other_study: "N/A",
                    },
                  ]);
                }
                if (e.target.value == "yes") {
                  formik.setFieldValue("p_add_son_daughter", [
                    {
                      name: "",
                      gender: "",
                      age: "",
                      veda_education_level: "",
                      other_study: "",
                    },
                  ]);
                }
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_details_of_children_show}
            >
              <option>
                ---
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
                ---
              </option>
              <option value="yes">
                {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
              </option>
              <option value="no">
                {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
              </option>
            </select>
            {formik.touched && formik.errors?.p_details_of_children_show ? (
              <div className="text-danger">
                {formik.errors?.p_details_of_children_show &&
                  formik.errors?.p_details_of_children_show}
              </div>
            ) : null}
          </div>
        </div>
        {formik?.values?.p_details_of_children_show == "yes" ? (
          <div className="table-responsive form-group">
            <table className="table">
              <thead>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रम सङ्ख्या"
                    : "क्रमसङ्ख्या"}
                </th>
                <th>
                  {lang === "english"
                    ? "Child's name"
                    : lang === "hindi"
                    ? "सन्तान का नाम"
                    : "सन्तान-नाम"}
                </th>
                <th>
                  {lang === "english"
                    ? "Gender"
                    : lang === "hindi"
                    ? "लिंग "
                    : "लिङ्गम्"}
                </th>
                <th>
                  {lang === "english"
                    ? "Age"
                    : lang === "hindi"
                    ? "आयु "
                    : "आयुः "}
                </th>
                <th>
                  {lang === "english"
                    ? "Veda education level"
                    : lang === "hindi"
                    ? "वेद शिक्षा स्तर"
                    : "वेदशिक्षा-स्तरः"}
                </th>
                <th>
                  {lang === "english"
                    ? "Other study"
                    : lang === "hindi"
                    ? "अन्य शिक्षा"
                    : "अन्यशिक्षा"}
                </th>
                <th width="120">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </thead>
              <tbody>
                {formik.values?.p_add_son_daughter &&
                  formik.values?.p_add_son_daughter.map((son, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <input
                          type="text"
                          name={`p_add_son_daughter[${index}].name`}
                          className="form-control"
                          value={
                            formik?.values?.p_add_son_daughter[index].name || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `p_add_son_daughter[${index}].name`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                        />
                        {formik.touched &&
                          formik.errors?.p_add_son_daughter &&
                          formik.errors?.p_add_son_daughter[index]?.name && (
                            <div className="text-danger">
                              {formik.errors?.p_add_son_daughter[index]?.name &&
                                formik.errors?.p_add_son_daughter[index]?.name}
                            </div>
                          )}
                      </td>

                      <td>
                        <select
                          id="gender"
                          name={`p_add_son_daughter[${index}].gender`}
                          className="form-control"
                          value={
                            formik?.values?.p_add_son_daughter[index].gender ||
                            ""
                          }
                          onChange={(e) => {
                            handleInputChange(
                              `p_add_son_daughter[${index}].gender`,
                              e
                            );
                          }}
                          onBlur={(e) => handleInputSave()}
                        >
                          <option selected>
                            ---
                            {lang == "english"
                              ? "Select"
                              : lang == "hindi"
                              ? "चुने"
                              : "चिनोतु"}
                            ---
                          </option>
                          <option value="male">
                            {lang === "english"
                              ? "Male"
                              : lang === "hindi"
                              ? "पुरुष "
                              : "पुरुषः "}
                          </option>
                          <option value="female">
                            {lang === "english"
                              ? "Female"
                              : lang === "hindi"
                              ? "स्त्री "
                              : "स्त्री "}
                          </option>
                          <option value="other">
                            {lang === "english"
                              ? "Other"
                              : lang === "hindi"
                              ? "अन्य "
                              : "इतरम् "}
                          </option>
                        </select>
                        {formik.touched &&
                          formik.errors?.p_add_son_daughter &&
                          formik.errors?.p_add_son_daughter[index]?.gender && (
                            <div className="text-danger">
                              {formik.errors?.p_add_son_daughter[index]
                                .gender &&
                                formik.errors?.p_add_son_daughter[index].gender}
                            </div>
                          )}
                      </td>

                      <td>
                        <input
                          type="number"
                          name={`p_add_son_daughter[${index}].age`}
                          className="form-control"
                          value={
                            formik?.values?.p_add_son_daughter[index].age || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `p_add_son_daughter[${index}].age`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                        />
                        {formik.touched &&
                          formik.errors?.p_add_son_daughter &&
                          formik.errors?.p_add_son_daughter[index]?.age && (
                            <div className="text-danger">
                              {formik.errors?.p_add_son_daughter[index]?.age &&
                                formik.errors?.p_add_son_daughter[index]?.age}
                            </div>
                          )}
                      </td>

                      <td>
                        <select
                          id={`p_add_son_daughter[${index}].veda_education_level`}
                          name={`p_add_son_daughter[${index}].veda_education_level`}
                          className="form-control"
                          value={
                            formik?.values?.p_add_son_daughter[index]
                              .veda_education_level || ""
                          }
                          onChange={(e) => {
                            handleInputChange(
                              `p_add_son_daughter[${index}].veda_education_level`,
                              e
                            );
                            if (e.target.value != "other") {
                              formik.setFieldValue(
                                `add_son_daughter[${index}].other_study`,
                                "N/A"
                              );
                            }
                            if (e.target.value == "other") {
                              formik.setFieldValue(
                                `add_son_daughter[${index}].other_study`,
                                ""
                              );
                            }
                          }}
                          onBlur={(e) => handleInputSave()}
                        >
                          <option selected>
                            ---
                            {lang == "english"
                              ? "Select"
                              : lang == "hindi"
                              ? "चुने"
                              : "चिनोतु"}
                            ---
                          </option>
                          <option value="Samhita">
                            {lang === "english"
                              ? "Samhita"
                              : lang === "hindi"
                              ? "संहिता"
                              : "संहिता"}
                          </option>
                          <option value="Pada">
                            {lang === "english"
                              ? "Pada"
                              : lang === "hindi"
                              ? "पद"
                              : "पदः"}
                          </option>
                          <option value="Krama">
                            {lang === "english"
                              ? "Krama"
                              : lang === "hindi"
                              ? "क्रम"
                              : "क्रमः"}
                          </option>
                          <option value="Jata">
                            {lang === "english"
                              ? "Jata"
                              : lang === "hindi"
                              ? "जटा"
                              : "जटा"}
                          </option>
                          <option value="Ghana">
                            {lang === "english"
                              ? "Ghana"
                              : lang === "hindi"
                              ? "घन"
                              : "घनः"}
                          </option>
                          <option value="Salakshanaghana">
                            {lang === "english"
                              ? "Salakshanaghana"
                              : lang === "hindi"
                              ? "सलक्षणघन"
                              : "सलक्षणघनः"}
                          </option>
                          <option value="other">
                            {lang === "english"
                              ? "Other"
                              : lang === "hindi"
                              ? "अन्य"
                              : "अन्यः"}
                          </option>
                        </select>
                        {formik.touched &&
                          formik.errors?.p_add_son_daughter &&
                          formik.errors?.p_add_son_daughter[index]
                            ?.veda_education_level && (
                            <div className="text-danger">
                              {formik.errors?.p_add_son_daughter[index]
                                ?.veda_education_level &&
                                formik.errors?.p_add_son_daughter[index]
                                  ?.veda_education_level}
                            </div>
                          )}
                      </td>
                      {formik?.values?.p_add_son_daughter[index]
                        .veda_education_level === "other" ? (
                        <td>
                          <input
                            type="text"
                            id={`p_add_son_daughter[${index}].other_study`}
                            name={`p_add_son_daughter[${index}].other_study`}
                            className="form-control"
                            value={
                              formik?.values?.p_add_son_daughter[index]
                                .other_study || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                `p_add_son_daughter[${index}].other_study`,
                                e
                              )
                            }
                            onBlur={(e) => handleInputSave()}
                          />
                          {formik.touched &&
                            formik.errors?.p_add_son_daughter &&
                            formik.errors?.p_add_son_daughter[index]
                              .other_study && (
                              <div className="text-danger">
                                {formik.errors?.p_add_son_daughter[index]
                                  .other_study &&
                                  formik.errors?.p_add_son_daughter[index]
                                    .other_study}
                              </div>
                            )}
                        </td>
                      ) : (
                        <td></td>
                      )}
                      <td className="col-md-auto form-group align-self-end">
                        {formik?.values?.p_add_son_daughter?.length >= 2 ? (
                          <button
                            onClick={(e) => removeBrotherSisterRow(index, e)}
                            type="button"
                            className="btn btn-outline-danger"
                          >
                            {lang == "english"
                              ? "Remove"
                              : lang == "hindi"
                              ? "हटाएँ"
                              : "अपाकरोतु"}
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                <tr className="border-bottom-0">
                  <td className="text-right" colspan="7">
                    <button
                      onClick={(e) => addBrotherSisterRow(e)}
                      type="button"
                      className="btn btn-outline-danger"
                    >
                      {lang == "english"
                        ? "Add"
                        : lang == "hindi"
                        ? "जोड़ें"
                        : "संयोजयतु"}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
        <h5>
          {lang == "english"
            ? " If you are kumaradhyapaka or imparting ved education at your home then answer the following questions"
            : lang == "hindi"
            ? "यदि आप कुमाराध्यापक है या अपने घर में दाध्यापन कर रहे है, तो निम्न प्रश्नों के उत्तर दीजिये "
            : "यदि भवान् कुमाराध्यापकः अथवा स्वगृहे वेदं पाठयसि तर्हि...निम्नलिखितप्रश्नानां उत्तरं ददातु"}
        </h5>
        <div className="row">
          <div className="col-md-12">
            <select
              className="form-control mb-10"
              name="p_details_of_school"
              id="p_details_of_school"
              onChange={(e) => {
                handleInputChange("p_details_of_school", e);
                formik.setFieldValue(
                  "p_average",
                  e.target.value === "no" ? "N/A" : ""
                );
                formik.setFieldValue(
                  "p_minimum",
                  e.target.value === "no" ? "N/A" : ""
                );
                formik.setFieldValue(
                  "p_maximum",
                  e.target.value === "no" ? "N/A" : ""
                );
                formik.setFieldValue(
                  "p_food_and_accommodation",
                  e.target.value === "no" ? "N/A" : ""
                );
                formik.setFieldValue(
                  "p_self_observance",
                  e.target.value === "no" ? "N/A" : ""
                );
              }}
              onBlur={(e) => handleInputSave()}
              value={formik?.values?.p_details_of_school}
            >
              <option>
                ---
                {lang == "english"
                  ? "Select"
                  : lang == "hindi"
                  ? "चुने"
                  : "चिनोतु"}
                ---
              </option>
              <option value="yes">
                {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
              </option>
              <option value="no">
                {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
              </option>
            </select>
            {formik.touched && formik.errors?.p_details_of_school ? (
              <div className="text-danger">
                {formik.errors?.p_details_of_school &&
                  formik.errors?.p_details_of_school}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className={`row ${
            formik?.values?.p_details_of_school === "yes" ? "" : "d-none"
          }`}
        >
          <div className="col-12">
            <label htmlFor="five_year_disiples">
              {lang == "english"
                ? "In the last five years the your N number of disciplesstudents in your vedavidyalaya in the past five years."
                : lang == "hindi"
                ? "पिछले पाँच वर्षों में आपके वेदविद्यालय में वेदविद्यार्थियों की संख्या"
                : "विगतपञ्चवर्षेषु भवतः वेदविद्यालये वेदविद्यार्थिनां संख्या"}
            </label>
            <div className="row">
              <div className="col-md-6 col-lg-4 form-group">
                <label htmlFor="p_average">
                  {lang == "english"
                    ? "Average"
                    : lang == "hindi"
                    ? "औसत "
                    : "मध्यमा "}
                </label>
                <input
                  type="number"
                  id="p_average"
                  name="p_average"
                  className="form-control"
                  value={formik?.values?.p_average || ""}
                  onChange={(e) => handleInputChange("p_average", e)}
                  onBlur={(e) => handleInputSave()}
                ></input>
                {formik.touched && formik.errors?.p_average ? (
                  <div className="text-danger">
                    {formik.errors?.p_average && formik.errors?.p_average}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-lg-4 form-group">
                <label htmlFor="p_minimum">
                  {lang == "english"
                    ? "Minimum"
                    : lang == "hindi"
                    ? "न्यूनतम "
                    : "न्यूनतमा "}
                </label>
                <input
                  type="number"
                  id="p_minimum"
                  name="p_minimum"
                  className="form-control"
                  value={formik?.values?.p_minimum || ""}
                  onChange={(e) => handleInputChange("p_minimum", e)}
                  onBlur={(e) => handleInputSave()}
                ></input>
                {formik.touched && formik.errors?.p_minimum ? (
                  <div className="text-danger">
                    {formik.errors?.p_minimum && formik.errors?.p_minimum}
                  </div>
                ) : null}
              </div>
              <div className="col-md-6 col-lg-4 form-group">
                <label htmlFor="p_maximum">
                  {lang == "english"
                    ? "Maximum"
                    : lang == "hindi"
                    ? "अधिकतम "
                    : "अधिकतमा "}
                </label>
                <input
                  id="p_maximum"
                  type="number"
                  name="p_maximum"
                  className="form-control"
                  value={formik?.values?.p_maximum || ""}
                  onChange={(e) => handleInputChange("p_maximum", e)}
                  onBlur={(e) => handleInputSave()}
                ></input>
                {formik.touched && formik.errors?.p_maximum ? (
                  <div className="text-danger">
                    {formik.errors?.p_maximum && formik.errors?.p_maximum}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-12 form-group">
            <label htmlFor="p_food_and_accommodation">
              {lang == "english"
                ? "Details of food and accommodation arrangements of the students"
                : lang == "hindi"
                ? "वेदविद्यार्थियों की भोजन व आवास व्यवस्था का विवरण"
                : "वेदविद्यार्थिनां कृते विहितायाः भोजनावासव्यवस्थायाः विवरणम्"}
            </label>
            <textarea
              type="text"
              id="p_food_and_accommodation"
              name="p_food_and_accommodation"
              className="form-control"
              rows={2}
              maxLength={700}
              value={formik?.values?.p_food_and_accommodation || ""}
              onChange={(e) => handleInputChange("p_food_and_accommodation", e)}
              onBlur={(e) => handleInputSave()}
            ></textarea>
            <p className="text-right  ">
              {" "}
              {formik?.values?.p_food_and_accommodation?.length}
              /700 Characters
            </p>
            {formik.touched && formik.errors?.p_food_and_accommodation ? (
              <div className="text-danger">
                {formik.errors?.p_food_and_accommodation &&
                  formik.errors?.p_food_and_accommodation}
              </div>
            ) : null}
          </div>
          <div className="col-12 form-group">
            <label htmlFor="p_self_observance">
              {lang == "english"
                ? "Description of self-observance ofhow the disciples students discharge their personal duties"
                : lang == "hindi"
                ? "वेदविद्यार्थियों के स्वधर्म पालन का विवरण"
                : "वेदविद्यार्थिनां स्वधर्मपालनस्य विवरणम्"}
            </label>
            <textarea
              type="text"
              rows={2}
              maxLength={700}
              id="p_self_observance"
              name="p_self_observance"
              className="form-control"
              value={formik?.values?.p_self_observance || ""}
              onChange={(e) => handleInputChange("p_self_observance", e)}
              onBlur={(e) => handleInputSave()}
            ></textarea>
            <p className="text-right  ">
              {" "}
              {formik?.values?.p_self_observance?.length}
              /700 Characters
            </p>
            {formik.touched && formik.errors?.p_self_observance ? (
              <div className="text-danger">
                {formik.errors?.p_self_observance &&
                  formik.errors?.p_self_observance}
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-4 form-group">
            <label htmlfor="p_upload_passport_photo">
              {lang == "english"
                ? "Upload your passport size photo here"
                : lang == "hindi"
                ? "अपना पासपोर्ट साइज फोटो यहाँ अपलोड करे"
                : "स्वस्य पासपोर्ट-साइज-फोटो इति अत्र अपलोड इति करोतु"}
            </label>
            <input
              type="file"
              id="fileUpload"
              accept=".jpg, .jpeg, .png, .pdf"
              name="p_upload_passport_photo"
              className="form-control"
              value={formik?.values?.p_upload_passport_photo || ""}
              onChange={async (e) => {
                if (e?.target?.files?.length > 0) {
                  await fileUploadFunction(
                    "p_upload_passport_photo_string",
                    e.target.files[0]
                  );
                }
                // handleInputChange("p_upload_passport_photo", e);
              }}
              onBlur={(e) => handleInputSave()}
            ></input>
            {formik.values?.p_upload_passport_photo_string && (
              <button
                className="uploadLink"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${process.env.REACT_APP_MEDIA_URL}/${formik?.values?.p_upload_passport_photo_string}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                {`${formik?.values?.p_upload_passport_photo_string.replace(
                  "upload-file/",
                  ""
                )}`}
              </button>
            )}
            {formik.touched && formik.errors?.p_upload_passport_photo_string ? (
              <div className="text-danger">
                {formik.errors?.p_upload_passport_photo_string &&
                  formik.errors?.p_upload_passport_photo_string}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section_1;
