const Page6FormField = {
  ga_teacher_full_name: "",
  ga_applicant_name: "",
  ga_applicant_file: "",
  ga_applicant_file_string: "",
  ga_applicant_full_name: "",
  ga_applicant_signature: "",
  ga_applicant_location: "",
  ga_applicant_date: "",
};

export default Page6FormField;
