import { useContext } from "react";
import LoaderContext from "./LoaderContext";

const Loader = () => {
  const { loaderCheck } = useContext(LoaderContext);

  return <>{loaderCheck ? <div className="loader"></div> : null}</>;
};

export default Loader;
