import style from "./Login.module.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { showErrorToast, showSuccessToast } from "../../Utils/Toaster";
import "../../index.css";
import axiosPrivate from "../../Hooks/axiosPrivate";
import { useRefreshToken } from "../../context/RefreshTokenContext";
import LoaderContext from "../../context/Loader/LoaderContext";
import Loader from "../../context/Loader/Loader.js";

const Login = () => {
  const [loginMethod, setLoginMethod] = useState("password");
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // Track whether OTP is sent
  const { refreshToken, setRefreshToken } = useRefreshToken();
  const navigate = useNavigate();
  const { loaderCheck, setLoaderCheck } = useContext(LoaderContext);

  useEffect(() => {
    setLoaderCheck(true);
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
    mobile: "",
    otp: "",
  };

  const loginSchema = Yup.object({
    email: Yup.string().when("loginMethod", {
      is: "password",
      then: Yup.string().required("Email is required"),
    }),
    password: Yup.string().when("loginMethod", {
      is: "password",
      then: Yup.string().required("Password is required"),
    }),
    mobile: Yup.string().when("loginMethod", {
      is: "otp",
      then: Yup.string().required("Phone number is required"),
    }),
    otp: Yup.string().when("loginMethod", {
      is: "otp",
      then: Yup.string().required("OTP is required"),
    }),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    // resetForm,
    validationSchema: loginSchema,
    // setValues,
    onSubmit: async (values) => {
      setLoaderCheck(true);
      try {
        const endpoint = !otpSent ? "login" : "login-otp";
        const payload = !otpSent
          ? { email: values.email, password: values.password }
          : { mobile: values.mobile.toString(), otp: values.otp };

        const response = await axiosPrivate.post(`${endpoint}`, payload);
       
        if (response?.status == 200) {
          showSuccessToast(response.data.message);
          localStorage.setItem("userData", JSON.stringify(response.data));
          localStorage.setItem("refreshToken", response.data.refresh_token);
          localStorage.setItem("accessToken", response.data.token);
          setRefreshToken(response.data.refresh_token);
          if (response.data.role === "admin") {
            setLoaderCheck(false);
            navigate("/admin/dashboard");
          } else {
            const responseAnswers = await axiosPrivate.get(`user-answer-data`);
            if (
              responseAnswers.status == 200 &&
              responseAnswers?.data?.data &&
              responseAnswers?.data?.data.length > 0 &&
              responseAnswers.data.data[0].status == "In Progress"
            ) {
              let url = "";
              if (responseAnswers?.data?.data[0]?.type == "Vedavidyarthi") {
                url = "/student_form";
              } else if (
                responseAnswers?.data?.data[0]?.type == "Vedaadhyapaka"
              ) {
                url = "/teacher_form";
              } else if (
                responseAnswers?.data?.data[0]?.type == "Vedavidyalaya"
              ) {
                url = "/school_form";
              }
              setLoaderCheck(false);
              navigate(url, {
                state: {
                  lang: responseAnswers.data.data[0].language,
                  user_type: responseAnswers.data.data[0].type,
                  apply_type: responseAnswers.data.data[0].user_option,
                  oldData: responseAnswers.data.data[0],
                },
              });
            } else {
              setLoaderCheck(false);
              navigate("/home");
            }
          }
        } else {
          
          setLoaderCheck(false);
          // showSuccessToast(response.message);
        }
      } catch (error) {
        setLoaderCheck(false);
        
       
        showErrorToast(error.message);
      }
    },
  });

  const handleChangeEmailMobile = () => {
    setShowOtpInput(false);
  };

  const handleSendOtp = async (formik) => {
    const mobile = {
      mobile: formik.values.mobile,
    };
    try {
      // Logic to send OTP to the provided phone number
      const response = await axiosPrivate.post("send-otp", mobile);
      if (response.status === 200) {
        setShowOtpInput(true);
        setOtpSent(true);
        showSuccessToast("Otp sent successfully");
      } else {
        showErrorToast("Failed to send OTP");
      }
    } catch (error) {
      showErrorToast(error.message);
    }
  };

  let handleSubmit = "";
  if (loginMethod === "otp" && !showOtpInput) {
    handleSubmit = (e) => {
      e.preventDefault();
      handleSendOtp(formik);
    };
  } else {
    handleSubmit = formik.handleSubmit;
  }

  return (
    <>
      {loaderCheck ? (
        <div className="loader"></div>
      ) : (
        <div className={`${style["LoginDetailContainer"]}`}>
          <div className={style.LoginWrapper}>
            <h3 className="text-xl font-bold mb-30">Login</h3>
            <div className={style.methodButtons}>
              <button
                className={
                  loginMethod === "password"
                    ? `${style.activeButton} ${style.passwordButton}`
                    : style.passwordButton
                }
                onClick={(e) => {
                  e.preventDefault();
                  formik.resetForm();
                  setLoginMethod("password");
                  setOtpSent(false);
                }}
              >
                Via password
              </button>
              <button
                className={
                  loginMethod === "otp"
                    ? `${style.activeButton} ${style.otpButton}`
                    : style.otpButton
                }
                onClick={(e) => {
                  e.preventDefault();
                  formik.resetForm();
                  setLoginMethod("otp");
                  setOtpSent(true);
                }}
              >
                Via OTP
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              {loginMethod === "otp" ? (
                <div className={style.loginForm}>
                  {!showOtpInput ? (
                    <div className={style.input}>
                      <div className={style.labelContainer}>
                        <label htmlFor="mobile">
                          Email address / mobile number{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                      </div>
                      <input
                        className={style.userId}
                        type="text"
                        name="mobile"
                        id="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <div className={style.error}>
                          {formik.errors.mobile}
                        </div>
                      ) : null}
                      <button
                        type="button"
                        onClick={() => handleSendOtp(formik)}
                        className=" text-white py-2 bg-primary w-full mt-30"
                        tabIndex={-1}
                      >
                        Send OTP
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className={style.input}>
                        <div className={style.labelContainer}>
                          <label htmlFor="otp">
                            Enter OTP <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <input
                          className={style.userId}
                          type="text"
                          name="otp"
                          id="otp"
                          value={formik.values.otp}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.otp && formik.errors.otp ? (
                          <div className={style.error}>{formik.errors.otp}</div>
                        ) : null}
                        <button
                          type="submit"
                          className=" text-white py-2 bg-primary w-full mt-30"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="flex justify-between mt-[-3%]">
                        <div
                          onClick={(e) => handleSendOtp(formik)}
                          style={{ cursor: "pointer" }}
                          className={style.forgetPassword}
                        >
                          Resend OTP
                        </div>
                        <div
                          onClick={(e) => handleChangeEmailMobile(formik)}
                          style={{ cursor: "pointer" }}
                          className={style.forgetPassword}
                        >
                          Change email / mobile
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className={style.loginForm}>
                  <div className={style.input}>
                    <div className={style.labelContainer}>
                      <label htmlFor="email">
                        Email address / mobile number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <input
                      className={style.userId}
                      type="text"
                      name="email"
                      id="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className={style.error}>{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className={style.input}>
                    <div>
                      <label htmlFor="password">Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        className={style.loginPassword}
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className={style.loginPasswordEyeButton}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                      {formik.touched.password && formik.errors.password ? (
                        <div className={style.error}>
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>

                    {formik.touched.password && formik.errors.password ? (
                      <div className={style.error}>
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>
                  <div className={style.input} style={{ marginTop: "-20px" }}>
                    <label>
                      <input
                        type="checkbox"
                        name="rememberMe"
                        checked={formik.values.rememberMe}
                        onChange={formik.handleChange}
                        style={{ fontSize: "12px" }}
                      />
                      <p className="pl-2">Remember me</p>
                    </label>
                  </div>
                  <button
                    type="submit"
                    className=" text-white py-2 bg-primary w-full"
                  >
                    Submit
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
      {setLoaderCheck(false)}
    </>
  );
};

export default Login;
