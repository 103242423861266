const page5FormFields = {
  af_name: "",
  af_years: "",
  af_affidavit: "",
  af_affidavit_string: "",
  af_full_name: "",
  af_signature: "",
  af_location: "",
  af_date: "",
};

export default page5FormFields;
