import React, { useState, useEffect } from "react";

function Section_5({
  handleInputChange,
  formik,
  handleInputSave,
  lang,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(4);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik.values.o_main_efforts_by_you, count);
    count = handleInputValue(formik.values.o_five_achievements, count);
    count = handleInputValue(
      formik.values.o_quality_of_an_vedadhdyapaka,
      count
    );
    count = handleInputValue(
      formik.values.o_understanding_of_young_generation,
      count
    );
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(5, totalCount, actualCount);
  }, [actualCount, totalCount]);
  return (
    <div className="mt-0">
      <h3>
        {lang == "english"
          ? "Other significant information (limit your answer to the space provided)"
          : lang == "hindi"
          ? "अन्य उल्लेखनीय जानकारी (अपना उत्तर दी गई जगह तक सीमित रखिये) "
          : "अन्याः महत्त्वपूर्णाः सूचनाः (स्वस्य उत्तरं प्रदत्तस्थाने एव लेखनीयम्)"}
      </h3>
      <p className="text-primary ">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
          ? "सभी प्रश्‍न अनिवार्य हैं।"
          : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>

      <div className="row">
        <div className="col-12 form-group">
          <label>
            {lang == "english"
              ? "Write in detail about the efforts made by you for the promotion and protection of veds."
              : lang == "hindi"
              ? "आपके द्वारा वेद के प्रचार-प्रसार और संरक्षण के लिये किए गए मुख्य प्रयासों का उल्लेख कीजिये।"
              : "वेदस्य प्रचारार्थं रक्षणार्थं च भवता विहितान्  मुख्यान् प्रयासान् लिखतु"}
          </label>
          <textarea
            className="form-control col-12"
            id="o_main_efforts_by_you"
            name="o_main_efforts_by_you"
            rows={2}
            maxLength={700}
            onChange={(e) => handleInputChange("o_main_efforts_by_you", e)}
            onBlur={(e) => handleInputSave()}
            value={formik.values.o_main_efforts_by_you}
          ></textarea>
          {formik.touched && formik.errors?.o_main_efforts_by_you ? (
            <div className="text-danger">
              {formik.errors?.o_main_efforts_by_you &&
                formik.errors?.o_main_efforts_by_you}
            </div>
          ) : null}
          <p className="text-right">
            {formik.values.o_main_efforts_by_you?.length}/700{" "}
            {lang == "english"
              ? " Characters"
              : lang == "hindi"
              ? " शब्द"
              : " शब्दः"}
          </p>
        </div>
        <div className="col-12 form-group">
          <label>
            {lang == "english"
              ? "Five achievements you feel proud of while teaching veds."
              : lang == "hindi"
              ? "अपने वेदाध्यापन की पाँच ऐसी उप्लब्धियाँ बताएँ जिनसे आप स्वयं गौरवान्वित हैं।"
              : "भवतः वेदशिक्षणस्य ताः पञ्चोपलब्धयः याभिः भवान् आत्मानं धन्यं मनुते"}
          </label>
          <div>
            <textarea
              className="form-control col-12"
              id="o_five_achievements"
              name="o_five_achievements"
              rows={2}
              maxLength={700}
              onChange={(e) => handleInputChange("o_five_achievements", e)}
              onBlur={(e) => handleInputSave()}
              value={formik.values.o_five_achievements}
            ></textarea>
            <p className="text-right ">
              {formik.values.o_five_achievements?.length}/700{" "}
              {lang == "english"
                ? " Characters"
                : lang == "hindi"
                ? " शब्द"
                : " शब्दः"}
            </p>
            {formik.touched && formik.errors?.o_five_achievements ? (
              <div className="text-danger">
                {formik.errors?.o_five_achievements &&
                  formik.errors?.o_five_achievements}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12 form-group">
          <label>
            {lang == "english"
              ? "Describe the qualities of an adarsh vedadhdyapaka."
              : lang == "hindi"
              ? "एक आदर्श वेदाध्यापक के गुणों का विवरण दीजिए।"
              : "आदर्शवेदगुरोः गुणान् वर्णयतु।"}
          </label>
          <div>
            <textarea
              className="form-control col-12"
              name="o_quality_of_an_vedadhdyapaka"
              rows={2}
              maxLength={700}
              onChange={(e) =>
                handleInputChange("o_quality_of_an_vedadhdyapaka", e)
              }
              onBlur={(e) => handleInputSave()}
              value={formik.values.o_quality_of_an_vedadhdyapaka}
            ></textarea>
            <p className="text-right ">
              {formik.values.o_quality_of_an_vedadhdyapaka?.length}
              /700
              {lang == "english"
                ? " Characters"
                : lang == "hindi"
                ? " शब्द"
                : " शब्दः"}
            </p>
            {formik.touched && formik.errors?.o_quality_of_an_vedadhdyapaka ? (
              <div className="text-danger">
                {formik.errors?.o_quality_of_an_vedadhdyapaka &&
                  formik.errors?.o_quality_of_an_vedadhdyapaka}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12 form-group">
          <label className="">
            {lang == "english"
              ? "The young generation does not under the importance of veds in our life, how will you explain this to the people who do not believe in the ved?"
              : lang == "hindi"
              ? "आज की युवा पीढ़ी अपने दैनिक जीवन में वेद का महत्त्व नहीं समझती। आप ऐसे लोगों को वेद का महत्त्व कैसे समझाएँगे?"
              : "अद्यतनयुवजनाः नित्यजीवने वेदानां महत्त्वं न अवगच्छन्ति। एतादृशानां जनानां कृते वेदस्य महत्त्वं कथं व्याख्यास्यति?"}
          </label>
          <div>
            <textarea
              className="form-control col-12 "
              id="o_understanding_of_young_generation"
              name="o_understanding_of_young_generation"
              rows={2}
              maxLength={700}
              onChange={(e) =>
                handleInputChange("o_understanding_of_young_generation", e)
              }
              onBlur={(e) => handleInputSave()}
              value={formik.values.o_understanding_of_young_generation}
            ></textarea>
          </div>
          <div>
            <p className="text-right">
              {formik.values.o_understanding_of_young_generation?.length}
              /700
              {lang == "english"
                ? " Characters"
                : lang == "hindi"
                ? " शब्द"
                : " शब्दः"}
            </p>
            {formik.touched &&
            formik.errors?.o_understanding_of_young_generation ? (
              <div className="text-danger">
                {formik.errors?.o_understanding_of_young_generation &&
                  formik.errors?.o_understanding_of_young_generation}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section_5;
