const Page5FormField = {
  g_guruji_name_info: "",
  g_guruji_education_name: "",
  g_guruji_education: "",
  g_guruji_education_string: "",
  g_applicant_conduct_comments: "",
  g_affidavit_file: "",
  g_affidavit_file_string: "",
  heard_from_applicant: "",
};

export default Page5FormField;
