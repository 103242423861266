import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Login from "./Pages/Login/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./Pages/admin/Dashboard";
import Home from "./Pages/Home/Home";
import QuestionPage from "./Pages/QuestionPage/QuestionPage";
import TeacherFormPage from "./Pages/TeacherFormPage/TeacherFormPage";
import Profile from "./Pages/profile/Profile";
import { useState, useEffect } from "react";
import SchoolFormPage from "./Pages/SchoolFormPage/SchoolFormPage";
import Section_new from "./Components/TeacherForm/Section_4";
import {
  RefreshTokenProvider,
  useRefreshToken,
} from "../src/context/RefreshTokenContext";
import Test from "./Pages/Test";
import AuthMain from "./Components/layouts/AuthMain";
import Main from "./Components/layouts/Main";
import AdminMain from "./Components/layouts/AdminMain";
import FormTimeLine from "./Pages/admin/FormTimeLine";
import AddAdmin from "./Pages/admin/AddAdmin";
import AdminProfile from "./Pages/admin/AdminProfile";

function App() {
  // const { refreshToken, setRefreshToken } = useRefreshToken();
  // const [isLoggedIn, setIsLoggedIn] = useState("");
  // useEffect(() => {
  //   setIsLoggedIn(refreshToken);
  // }, [refreshToken]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthMain />}>
          <Route path="/" element={<Login />} />
        </Route>
        <Route path="/" element={<Main />}>
          <Route path="/home" element={<Home />} />
          <Route path="/student_form" element={<QuestionPage />} />
          <Route path="/teacher_form" element={<TeacherFormPage />} />
          <Route path="/school_form" element={<SchoolFormPage />} />
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route path="/admin" element={<AdminMain />}>
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/timeline" element={<FormTimeLine />} />
          <Route path="/admin/add" element={<AddAdmin />} />
          <Route path="/admin/profile" element={<AdminProfile />} />
        </Route>
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
