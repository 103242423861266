import React, { useState, useEffect } from "react";

function Section_6({
  handleInputChange,
  formik,
  handleInputSave,
  lang,
  fileUploadFunction,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(2);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik.values.ad_total_teaching_year, count);
    count = handleInputValue(formik.values.ad_affidavit_file_string, count);
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(6, totalCount, actualCount);
  }, [actualCount, totalCount]);

  return (
    <div>
      <div>
        <h3>
          {lang == "english"
            ? "Affidavit"
            : lang == "hindi"
            ? "शपथ पत्र "
            : "शपथपत्रम् "}
        </h3>
        <p className="text-primary">
          {lang == "english"
            ? "All fields are mandatory"
            : lang == "hindi"
            ? "सभी प्रश्‍न अनिवार्य हैं।"
            : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
        </p>

        <div className="row">
          <div className="col-12 col-md-6  form-group">
            <label>
              {lang == "english"
                ? "I (full name of the applicant)"
                : lang == "hindi"
                ? "मैं (वेदाध्यापक का पूरा नाम) "
                : "अहं (वेदाध्यापकस्य पूर्णं नाम)"}
            </label>
            <input
              type="text"
              className="form-control"
              id="p_name"
              name="p_name"
              value={formik.values.p_name}
            ></input>
          </div>
        </div>
        <div>
          <div className="mb-30">
            {lang == "english"
              ? "do hereby solemnly certify that do that:"
              : lang == "hindi"
              ? "एतद् द्वारा सत्यनिष्ठा से यह प्रमाणित करता हूँ कि:"
              : "एतत् सत्यनिष्ठया प्रमाणयामि यत्"}
            <br />
            {lang == "english" ? (
              <>
                {`- I am currently a full-time teacher of Ved Vidya. I have been teaching the Ved continuously for `}
                {` years`}
              </>
            ) : lang == "hindi" ? (
              <>
                {`- मैं वेद विद्या का वर्तमान में पूर्णकालिक अध्यापक हूँ; मैंने वेद की शिक्षा `}
                {` वर्षों तक निरंतर दी है।`}
              </>
            ) : (
              <>
                {`- अहं सम्प्रति वेदविद्यायाः पूर्णकालिकः वेदाध्यापकः अस्मि; अहं वेदं `}
                {` वर्षेभ्यः अद्य अनुसरामि।`}
              </>
            )}
            <br />
            {lang === "english" ? (
              <>
                {` I have been teaching veds continuously for last `}
                <input
                  style={{ width: "60px", height: "30px" }}
                  type="number"
                  name="ad_total_teaching_year"
                  id="ad_total_teaching_year"
                  className="form-control inline-block"
                  onChange={(e) => {
                    if (e.target.value <= 2025) {
                      handleInputChange("ad_total_teaching_year", e);
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                  value={formik?.values?.ad_total_teaching_year}
                />
                {` years`}
                {formik.touched && formik.errors?.ad_total_teaching_year ? (
                  <div className="text-danger">
                    {formik.errors?.ad_total_teaching_year &&
                      formik.errors?.ad_total_teaching_year}
                  </div>
                ) : null}
              </>
            ) : lang === "hindi" ? (
              <>
                {`- मेरा पूर्णकालिक वेदाध्यापन पिछले `}
                <input
                  style={{ width: "60px", height: "30px" }}
                  type="number"
                  name="ad_total_teaching_year"
                  id="ad_total_teaching_year"
                  onChange={(e) =>
                    handleInputChange("ad_total_teaching_year", e)
                  }
                  onBlur={(e) => handleInputSave()}
                  value={formik?.values?.ad_total_teaching_year}
                />
                {` वर्षों से निरंतर चल रहा है।`}
                {formik.touched && formik.errors?.ad_total_teaching_year ? (
                  <div className="text-danger">
                    {formik.errors?.ad_total_teaching_year &&
                      formik.errors?.ad_total_teaching_year}
                  </div>
                ) : null}
              </>
            ) : (
              <>
                {`- मम पूर्णकालिकं वेदाध्यापनं विगत `}
                <input
                  type="number"
                  style={{ width: "60px", height: "30px" }}
                  name="ad_total_teaching_year"
                  id="ad_total_teaching_year"
                  onChange={(e) =>
                    handleInputChange("ad_total_teaching_year", e)
                  }
                  onBlur={(e) => handleInputSave()}
                  value={formik?.values?.ad_total_teaching_year}
                />
                {` वर्षेभ्यः निरंतरं प्रचलति।`}
                {formik.touched && formik.errors?.ad_total_teaching_year ? (
                  <div className="text-danger">
                    {formik.errors?.ad_total_teaching_year &&
                      formik.errors?.ad_total_teaching_year}
                  </div>
                ) : null}
              </>
            )}
            <br />
            {lang === "english"
              ? `All the information provided in this application form (except questions 3 and 4) is completely true;`
              : lang === "hindi"
              ? `- इस आवेदन पत्र में दी गई सभी जानकारी (उ ३ व ४ को छोड़कर) पूर्णतया सत्य हैं;`
              : `- अस्मिन् आवेदनपत्रे (उ॰ ३, ४ विहाय) दत्ताः सर्वाः सूचनाः सर्वथा सत्याः सन्ति ;`}
            <br />
            {lang == "english"
              ? "- The answers of question no. 3, 4 are based on my personal beliefs and / or are my opinion. and"
              : lang == "hindi"
              ? "- प्रश्न सं॰ उ-३ व उ-४ के उत्तर मेरी व्यक्तिगत मान्यताओं पर आधारित हैं अथवा मेरी राय हैं। तथा	"
              : "- प्रश्नसंख्या उ-3,४ इत्यनयोः उत्तराणि मम व्यक्तिगतप्रत्ययानाम् आधारेण सन्ति अथवा मम मतम् अस्ति;"}
            <br />
            {lang == "english"
              ? "- The information given in all the contracts and certificates attached to this application is true."
              : lang == "hindi"
              ? "-इस आवेदन से संलग्न सभी अनुबन्धों व प्रमाण पत्रों में दी गई जानकारी सत्य है।"
              : "- अस्मिन् आवेदनपत्रे संलग्नेषु सर्वेषु अनुबन्धेषु प्रमाणपत्रेषु च दत्ता: सूचनाः सत्याः सन्ति।"}
            <br />
            {lang == "english"
              ? "I agree that if any of the information provided in this affidavit is found to be false, I and all my disciples may be considered ineligible for Bharatatma Puraskar forever."
              : lang == "hindi"
              ? "मैं स्वीकार करता हूँ कि यदि यह शपथ पत्र असत्य पाया गया तो मैं व मेरे सभी शिष्य सदा के लिये भारतात्मा पुरस्कार के लिये अयोग्य माने जा सकते हैं।"
              : "अहं स्वीकरोमि यत् यदि एतत् शपथपत्रम् असत्यं ज्ञायते तर्हि अहं मम सर्वे विद्यार्थिनः च भारतात्मापुरस्कारस्य कृते सदा अयोग्याः भवेयुः।"}
            I
            <br />
            {lang == "english"
              ? "This affidavit has been signed by me after verifying all the facts. I fully understand the importance and implications of signing the affidavit ."
              : lang == "hindi"
              ? "मैंने सभी तथ्यों की पुष्टि करने के बाद इस शपथपत्र पर हस्ताक्षर किये हैं। मैं इस शपथ पत्र पर हस्ताक्षर करने के महत्त्व व निहितार्थ को पूरी तरह से समझता हूँ।"
              : "मया सर्वाणि तथ्यानि स्वप्रमाणीकृत्य अस्मिन् शपथपत्रे हस्ताक्षरं कृतम्। अस्मिन् शपथपत्रे विहितस्य हस्ताक्षरस्य महत्त्वं निहितार्थं च अहं पूर्णतया अवगच्छामि।"}
          </div>
        </div>
        <div className="mb-10">
          <p className="d-flex mt-30 mb-10">
            {lang == "english"
              ? "Full name of the Vedaadhyapaka"
              : lang == "hindi"
              ? "वेदाध्यापक का पूरा नाम"
              : "वेदाध्यापकस्य पूर्णनाम "}
            <p> &nbsp; - {formik.values.p_name}</p>
          </p>
          <p className="d-flex mt-30 mb-10">
            {lang == "english"
              ? "Signature of Vedadhyapak"
              : lang == "hindi"
              ? "वेदाध्यापक के हस्ताक्षर"
              : "वेदाध्यापकस्य-हस्ताक्षरम्"}
            <p> &nbsp; ____________________________</p>
          </p>
          <p className="d-flex mt-30 mb-10">
            {lang == "english"
              ? "Location"
              : lang == "hindi"
              ? "स्थान "
              : "स्थानम् "}
            <p> &nbsp; ____________________________</p>
          </p>
          <p className="d-flex mt-30 mb-10">
            {lang == "english"
              ? "Date"
              : lang == "hindi"
              ? "दिनांक  "
              : "दिनाङ्कः "}
            <p> &nbsp; ____________________________</p>
          </p>
        </div>
        <hr />
        <br />
        <p>
          {lang == "english"
            ? "If you are teaching in the ved Pathshala run by the institution, then the affidavit of the head of the institution is necessary."
            : lang == "hindi"
            ? "यदि आप किसी संस्था द्वारा सञ्चालित वेदविद्यालय में अध्यापन कर रहे हैं तब संस्था प्रमुख का शपथ पत्र आवश्यक है।"
            : "यदि भवान् कयापि संस्थया सञ्चालिते वेदविद्यालये अध्यापयति तर्हि  संस्थाप्रमुखस्य शपथपत्रम् आवश्यकं वर्तते।"}
        </p>
        <br />
        <h5>
          {lang == "english"
            ? "Affidavit by the head of the institution"
            : lang == "hindi"
            ? "संस्था प्रमुख का शपथ पत्र"
            : "संस्थाप्रमुखस्य शपथपत्रम् "}
        </h5>
        <p>
          {lang == "english"
            ? "It is hereby certified That the applicant is a teacher in this institution and all the above mentioned information is correct."
            : lang == "hindi"
            ? "एतद् द्वारा यह प्रमाणित किया जाता है कि आवेदक इस संस्था में अध्यापक है और उपर्युक्त सम्पूर्ण जानकारी सर्वथा सत्य है।"
            : "एतत् प्रमाणीक्रियते यत् आवेदकः अस्यां संस्थायाम् अध्यापकोऽस्ति तथा च उपर्युक्ताः सम्पूर्णसूचनाः सर्वथा सत्याः वर्तन्ते।"}
        </p>

        <div className="mb-10">
          <p className="d-flex mt-30 mb-10">
            {lang == "english"
              ? "Name of Vedavidyalaya"
              : lang == "hindi"
              ? "वेदविद्यालय का नाम"
              : "वेदविद्यालय-नाम"}
            <p> &nbsp; - {formik.values.vt_address_present_organization}</p>
          </p>
          <p className="d-flex mt-30 mb-10">
            {lang == "english"
              ? "Name of the head of vedavidyalaya"
              : lang == "hindi"
              ? "संस्था प्रमुख का नाम"
              : "संस्थाप्रमुख-नाम"}
            <p> &nbsp; - {formik.values.vt_name_head_of_the_institution}</p>
          </p>
          <p className="d-flex mt-30 mb-10">
            {lang == "english"
              ? "Date"
              : lang == "hindi"
              ? "दिनांक "
              : "दिनाङ्कः "}
            <p> &nbsp; ____________________________</p>
          </p>
          <p className="d-flex mt-30 mb-10">
            {lang == "english"
              ? "Signature of the head of the institution"
              : lang == "hindi"
              ? "संस्था प्रमुख के हस्ताक्षर"
              : "संस्थाप्रमुख-हस्ताक्षरम्"}
            <p> &nbsp; ____________________________</p>
          </p>
        </div>

        <div className="row noPrint">
          <div className="col-md-6 col-lg-4 form-group">
            <label className="d-block">
              {lang == "english"
                ? "Download Affidavit"
                : lang == "hindi"
                ? "शपथपत्र डाउनलोड करें"
                : "शपथपत्रम् डाउनलोड इति करोतु"}
            </label>
            <button
              className="btn btn-outline-danger"
              onClick={(e) => {
                e.preventDefault();
                window.print();
              }}
            >
              {lang == "english"
                ? "Download"
                : lang == "hindi"
                ? "डाउनलोड"
                : "डाउनलोड"}
            </button>
          </div>
          <div className="col-md-6 col-lg-4 form-group">
            <label>
              {lang == "english"
                ? "Upload Affidavit"
                : lang == "hindi"
                ? "शपथपत्र अपलोड करें"
                : "शपथपत्रम् अपलोड इति करोतु"}
            </label>
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              className="form-control"
              id="ad_affidavit_file"
              name="ad_affidavit_file"
              onChange={async (e) => {
                if (e.target.files.length > 0) {
                  await fileUploadFunction(
                    "ad_affidavit_file_string",
                    e.target.files[0]
                  );
                }
              }}
              onBlur={(e) => handleInputSave()}
            />
            {formik.values?.ad_affidavit_file_string && (
              <button
                className="uploadLink"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${process.env.REACT_APP_MEDIA_URL}/${formik.values.ad_affidavit_file_string}`,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                {`${formik.values.ad_affidavit_file_string.replace(
                  "upload-file/",
                  ""
                )}`}
              </button>
            )}
            {formik.touched && formik.errors?.ad_affidavit_file_string ? (
              <div className="text-danger">
                {formik.errors?.ad_affidavit_file_string &&
                  formik.errors?.ad_affidavit_file_string}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section_6;
