import React, { useEffect } from "react";
import "./style.css";
import { showErrorToast } from "../../Utils/Toaster";
import axiosPrivate from "../../Hooks/axiosPrivate";

function Page8_preview_your_application({ lang, id, isPageComplete }) {
  const handlePreview = async (e) => {
    e.preventDefault();
    try {
      // Logic to get preview data
      const response = await axiosPrivate.get(`pdf/${id}`);
      window.open(
        process.env.REACT_APP_BACKEND_URL + response?.data?.path,
        "Download"
      );
    } catch (error) {
      showErrorToast(error.message);
    }
  };
  useEffect(() => {
    isPageComplete(8, 1, 1);
  }, []);
  return (
    <div>
      <div>
        <h3 className="mb-30">
          {lang == "english"
            ? "Review your application"
            : lang == "hindi"
              ? "अपने आवेदन का पूर्वावलोकन करे"
              : "स्वस्य आवेदनस्य पूर्वावलोकनं करोतु"}
        </h3>

        <button
          className="btn btn-outline-danger mb-30"
          onClick={(e) => handlePreview(e)}
        >
          {lang == "english"
            ? "Get application for review"
            : lang == "hindi"
              ? "पूर्वावलोकन प्राप्त करे"
              : "पूर्वावलोकनं प्राप्नोतु"}
        </button>
        <div>
          <p className="mb-30">
            {lang == "english"
              ? "Your document is ready for review. Please check it "
              : lang == "hindi"
                ? "आपका पूर्वावलोकन तैयार है। कृपया इसे जाँचे"
                : " भवतः पूर्वावलोकनं सज्जम् अस्ति। कृपया च परीक्षयतु"}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Page8_preview_your_application;
