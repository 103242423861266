import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { showErrorToast, showSuccessToast } from "../../Utils/Toaster";
import axiosPrivate from "../../Hooks/axiosPrivate";

const SetAlertModal = ({ onClose, ids }) => {

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
    const [code, setCode] = useState('1');

    const [selectedIds, setSelectedIds] = useState([]);
    
    useEffect(() => {
       if(ids){
            setSelectedIds([...new Set(ids)]);
      }
    }, [ids]);
   

  const handleApi = async () => {
    try {
      const formData = {
          alertcode: code,
          subject: subject,
          message: message,
          ids: selectedIds,

      };
      const response = await axiosPrivate.post("set-alert", formData);
      if (response?.status === 200 ) {
        showSuccessToast("Alert set successfully");
      }
    } catch (error) {
      showErrorToast(error?.response?.data?.message);
      }
    finally {
        setSubject("");
        setMessage("");
        setCode('1');
      }
  };



  const handleSubmit = () => {
    handleApi();
    onClose();
    };
    
   console.log(selectedIds);

  return (
    <Modal
      show={onClose}
      onHide={onClose}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>Alert Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div >
          <form>
            <input
              type='text'
              className='form-control'
              placeholder='Subject'
              onChange={(e) => setSubject(e.target.value)}
            />
            <br />
            <select
              className='form-select w-100 form-control'
              aria-label='Default select example'
              onChange={(e) => setCode(e.target.value)}
            >
              <option
                defaultChecked
                value= '1'
              >
                Send Email
              </option>
              <option value='2'>Send SMS</option>
              <option value='3'>Send Email & SMS</option>
            </select>
            <textarea
              className='form-control mt-3'
              placeholder='Message'
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className='justify-content-start'>
        <button
          className={`btn btn-danger`}
          onClick={() => handleSubmit()}
        >
          Send
        </button>
        <button
          className={`btn btn-outline-danger`}
          onClick={onClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SetAlertModal;
