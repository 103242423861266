const alFields = {
  al_description_of_articles: [
    {
      details_of_document: "",
      attach_file: "",
      attach_file_string: "",
    },
  ],
};

export default alFields;
