import React, { useEffect, useState } from "react";

function Section_3({
  handleInputChange,
  formik,
  handleInputSave,
  addVedaTeachingRow,
  removeVedaTeachingRow,
  addAttendanceInVedaSabhasRow,
  removeAttendanceInVedaSabhasRow,
  addVedaStudentsCurrentlyBeingTaughtByYouTableRow,
  removeVedaStudentsCurrentlyBeingTaughtByYouTableRow,
  addVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow,
  removeVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow,
  lang,
  fileUploadFunction,
  vt_family_members_taught_by_youAddRow,
  vt_family_members_taught_by_youRemoveRow,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(47);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;

    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values.vt_veda_teaching[0]?.from_month_year,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values.vt_veda_teaching[0]?.till_month_year,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values.vt_veda_teaching[0]?.name_vedavidyalaya,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values.vt_veda_teaching[0]?.location_and_state,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_address_present_organization,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_pincode_present_organization,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_district_present_organization,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_state_present_organization,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_name_head_of_the_institution,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_mobile_head_of_the_institution,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[0]?.mulanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[0]?.kramanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[0]?.ghananta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[0]?.total,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[1]?.mulanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[1]?.kramanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[1]?.ghananta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[1]?.total,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[2]?.mulanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[2]?.kramanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[2]?.ghananta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[2]?.total,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[3]?.mulanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[3]?.kramanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[3]?.ghananta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[3]?.total,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[4]?.mulanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[4]?.kramanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[4]?.ghananta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_last_five_years_students_passed[4]?.total,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_studying_with_you_at_present?.mulanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_studying_with_you_at_present?.kramanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_studying_with_you_at_present?.ghananta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_studying_with_you_at_present?.shadanga,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_studying_with_you_at_present?.lakshana,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_studying_with_you_at_present?.bhashya,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_studying_with_you_at_present?.total,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_taught_by_you_entire_time_as_vedadhyapak
          ?.mulanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_taught_by_you_entire_time_as_vedadhyapak
          ?.kramanta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_taught_by_you_entire_time_as_vedadhyapak
          ?.ghananta,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_students_taught_by_you_entire_time_as_vedadhyapak
          ?.total,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_did_not_complete_the_study_of_veda,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_attendance_in_veda_sabhas,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_veda_students_currently_being_taught_by_you,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_shakhas_that_are_dying,
      count
    );
    count = handleInputValue(
      formik.values.vt_veda_teaching &&
        formik.values?.vt_have_you_taught_any_member_detail,
      count
    );

    setActualCount(count);
  }, [formik.values]);


  useEffect(() => {
    isPageComplete(3, totalCount, actualCount);
  }, [actualCount, totalCount]);

  return (
    <div>
      <h3>
        {lang == "english"
          ? "Vedadhyapana information"
          : lang == "hindi"
          ? "वेदाध्यापन सम्बन्धी जानकारी"
          : "वेदाध्यापनसम्बद्धा सूचना "}
      </h3>
      <p className="text-primary">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
          ? "सभी प्रश्‍न अनिवार्य हैं।"
          : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>
      {/* Add entries of Vedadhyapan */}
      <div className="row">
        <div className="table-responsive form-group col-12">
          <label>
            {lang == "english"
              ? "List your experience as a vedadhyapak in date order."
              : lang == "hindi"
              ? "आपके वेदाध्यापन का क्रम से वर्णन"
              : "भवतः वेदाध्यापनस्य क्रमबद्धवर्णनम्"}
          </label>
          <table className="table">
            <thead>
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रम संख्या"
                    : "क्रमसङ्ख्या"}
                </th>
                <th>
                  {lang == "english"
                    ? "From month and year"
                    : lang == "hindi"
                    ? "माह व वर्ष से"
                    : "मासतः वर्षतः च"}
                </th>
                <th>
                  {lang == "english"
                    ? "Till month and year"
                    : lang == "hindi"
                    ? "माह व वर्ष तक"
                    : "मासपर्यन्तं वर्षर्यन्तं च"}
                </th>
                <th>
                  {lang == "english"
                    ? "Vidhyalaya name"
                    : lang == "hindi"
                    ? "वेदविद्यालय का नाम"
                    : "वेदविद्यालय-नाम"}
                </th>
                <th>
                  {lang == "english"
                    ? "Location and state"
                    : lang == "hindi"
                    ? "स्थान व राज्य"
                    : "स्थानं राज्यञ्च"}
                </th>
                <th width="120">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
            </thead>
            <tbody>
              {formik.values?.vt_veda_teaching?.map((son, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <input
                      type="month"
                      maxLength={6}
                      max={new Date()?.toISOString()?.slice(0, 7)}
                      className="form-control"
                      id={`vt_veda_teaching[${index}].from_month_year`}
                      name={`vt_veda_teaching[${index}].from_month_year`}
                      value={
                        formik.values.vt_veda_teaching[index].from_month_year ||
                        ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_veda_teaching[${index}].from_month_year`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_veda_teaching &&
                    formik.errors?.vt_veda_teaching[index].from_month_year ? (
                      <div className="text-danger">
                        {formik.errors?.vt_veda_teaching[index]
                          .from_month_year &&
                          formik.errors?.vt_veda_teaching[index]
                            .from_month_year}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="month"
                      maxLength={6}
                      max={new Date()?.toISOString()?.slice(0, 7)}
                      className="form-control"
                      id={`vt_veda_teaching[${index}].till_month_year`}
                      name={`vt_veda_teaching[${index}].till_month_year`}
                      value={
                        formik.values.vt_veda_teaching[index].till_month_year ||
                        ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_veda_teaching[${index}].till_month_year`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_veda_teaching &&
                    formik.errors?.vt_veda_teaching[index].till_month_year ? (
                      <div className="text-danger">
                        {formik.errors?.vt_veda_teaching[index]
                          .till_month_year &&
                          formik.errors?.vt_veda_teaching[index]
                            .till_month_year}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_veda_teaching[${index}].name_vedavidyalaya`}
                      name={`vt_veda_teaching[${index}].name_vedavidyalaya`}
                      value={
                        formik.values.vt_veda_teaching[index]
                          .name_vedavidyalaya || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_veda_teaching[${index}].name_vedavidyalaya`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_veda_teaching &&
                    formik.errors?.vt_veda_teaching[index]
                      .name_vedavidyalaya ? (
                      <div className="text-danger">
                        {formik.errors?.vt_veda_teaching[index]
                          .name_vedavidyalaya &&
                          formik.errors?.vt_veda_teaching[index]
                            .name_vedavidyalaya}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_veda_teaching[${index}].location_and_state`}
                      name={`vt_veda_teaching[${index}].location_and_state`}
                      value={
                        formik.values.vt_veda_teaching[index]
                          .location_and_state || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_veda_teaching[${index}].location_and_state`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_veda_teaching &&
                    formik.errors?.vt_veda_teaching[index]
                      .location_and_state ? (
                      <div className="text-danger">
                        {formik.errors?.vt_veda_teaching[index]
                          .location_and_state &&
                          formik.errors?.vt_veda_teaching[index]
                            .location_and_state}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {formik.values.vt_veda_teaching.length >= 2 ? (
                      <button
                        className="btn btn-outline-danger"
                        onClick={(e) => removeVedaTeachingRow(index, e)}
                      >
                        {lang == "english"
                          ? "Remove"
                          : lang == "hindi"
                          ? "हटाएँ"
                          : "अपाकरोतु"}
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
              <tr className="border-bottom-0">
                <td className="text-right" colspan="7">
                  <button
                    className="btn btn-outline-danger"
                    onClick={(e) => addVedaTeachingRow(e)}
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-12 col-lg-12 form-group col-12">
          <label>
            {lang == "english"
              ? "Name and full address of the present organization"
              : lang == "hindi"
              ? "वर्तमान संस्था का नाम व पूरा पता"
              : "वर्तमानसंस्थायाः नाम पूर्णपत्रसङ्केतश्च"}
          </label>
          <input
            type="text"
            id="vt_address_present_organization"
            name="vt_address_present_organization"
            onChange={(e) =>
              handleInputChange("vt_address_present_organization", e)
            }
            onBlur={(e) => handleInputSave()}
            value={formik.values.vt_address_present_organization}
            className="form-control"
          ></input>
          {formik.touched && formik.errors?.vt_address_present_organization ? (
            <div className="text-danger">
              {formik.errors?.vt_address_present_organization &&
                formik.errors?.vt_address_present_organization}
            </div>
          ) : null}
        </div>
        <div className="col-md-6 col-lg-4 form-group">
          <label htmlFor="paternal_home_pincode">
            {lang == "english"
              ? "Pin code"
              : lang == "hindi"
              ? "पिन कोड"
              : "पिनकोड-क्रमाङ्क:"}
          </label>
          <input
            type="number"
            id="vt_pincode_present_organization"
            name="vt_pincode_present_organization"
            onChange={(e) => {
              if (e.target.value.length <= 6) {
                handleInputChange("vt_pincode_present_organization", e);
              }
            }}
            onBlur={(e) => handleInputSave()}
            value={formik.values.vt_pincode_present_organization}
            className="form-control"
          ></input>
          {formik.touched && formik.errors?.vt_pincode_present_organization ? (
            <div className="text-danger">
              {formik.errors?.vt_pincode_present_organization &&
                formik.errors?.vt_pincode_present_organization}
            </div>
          ) : null}
        </div>
        <div className="col-md-6 col-lg-4 form-group">
          <label htmlFor="paternal_home_district">
            {lang == "english"
              ? "District"
              : lang == "hindi"
              ? "जिला "
              : "जनपदः"}
          </label>
          <input
            type="text"
            id="vt_district_present_organization"
            name="vt_district_present_organization"
            onChange={(e) =>
              handleInputChange("vt_district_present_organization", e)
            }
            onBlur={(e) => handleInputSave()}
            value={formik.values.vt_district_present_organization}
            className="form-control"
          ></input>
          {formik.touched && formik.errors?.vt_district_present_organization ? (
            <div className="text-danger">
              {formik.errors?.vt_district_present_organization &&
                formik.errors?.vt_district_present_organization}
            </div>
          ) : null}
        </div>
        <div className="col-md-6 col-lg-4 form-group">
          <label htmlFor="paternal_home_state">
            {" "}
            {lang == "english"
              ? "State"
              : lang == "hindi"
              ? "राज्य "
              : "राज्यम् "}{" "}
          </label>
          <input
            type="text"
            id="vt_state_present_organization"
            name="vt_state_present_organization"
            onChange={(e) =>
              handleInputChange("vt_state_present_organization", e)
            }
            onBlur={(e) => handleInputSave()}
            value={formik.values.vt_state_present_organization}
            className="form-control"
          ></input>
          {formik.touched && formik.errors?.vt_state_present_organization ? (
            <div className="text-danger">
              {formik.errors?.vt_state_present_organization &&
                formik.errors?.vt_state_present_organization}
            </div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4 form-group">
          <label>
            {lang == "english"
              ? "Name of the head of the institution"
              : lang == "hindi"
              ? "संस्था प्रमुख का नाम"
              : "संस्थाप्रमुखस्य नाम"}
          </label>
          <input
            type="text"
            id="vt_name_head_of_the_institution"
            name="vt_name_head_of_the_institution"
            onChange={(e) =>
              handleInputChange("vt_name_head_of_the_institution", e)
            }
            onBlur={(e) => handleInputSave()}
            value={formik.values.vt_name_head_of_the_institution}
            className="form-control"
          ></input>
          {formik.touched && formik.errors?.vt_name_head_of_the_institution ? (
            <div className="text-danger">
              {formik.errors?.vt_name_head_of_the_institution &&
                formik.errors?.vt_name_head_of_the_institution}
            </div>
          ) : null}
        </div>
        <div className="col-md-6 col-lg-4 form-group">
          <label>
            {lang == "english"
              ? "Mobile number of the head of the institution"
              : lang == "hindi"
              ? "संस्था प्रमुख का मोबाइल नंबर"
              : "संस्थाप्रमुखस्य चलदूरवाणीसङ्ख्या"}
          </label>
          <input
            type="number"
            id="vt_mobile_head_of_the_institution"
            name="vt_mobile_head_of_the_institution"
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                handleInputChange("vt_mobile_head_of_the_institution", e);
              }
            }}
            onBlur={(e) => handleInputSave()}
            value={formik.values.vt_mobile_head_of_the_institution}
            className="form-control"
          ></input>
          {formik.touched && formik.errors?.vt_mobile_head_of_the_institution ? (
            <div className="text-danger">
              {formik.errors?.vt_mobile_head_of_the_institution &&
                formik.errors?.vt_mobile_head_of_the_institution}
            </div>
          ) : null}
        </div>
      </div>
      {/* Add students entries */}
      <label className="mb-5px">
        {lang == "english"
          ? "List of your students who, in the last five years, have passed following examinations"
          : lang == "hindi"
          ? "पिछले पाँच वर्षों में आपके विद्यार्थियों की संख्या जो निम्नलिखित परीक्षाओं मे उत्तीर्ण हुए "
          : "विगतपञ्चवर्षेषु निम्नलिखितपरीक्षासु उत्तीर्णानां भवतां छात्राणां सङ्ख्या "}
      </label>
      <div className="table-responsive form-group">
        <table className="table">
          <thead>
            <tr>
              <th>
                {lang == "english"
                  ? "Year"
                  : lang == "hindi"
                  ? "वर्ष"
                  : "वर्षम्"}
              </th>
              <th>
                {lang == "english"
                  ? "Mulanta"
                  : lang == "hindi"
                  ? "मूलांत"
                  : "मूलान्तः"}
              </th>
              <th>
                {lang == "english"
                  ? "Kramanta"
                  : lang == "hindi"
                  ? "क्रमांत "
                  : "क्रमान्त:"}
              </th>
              <th>
                {lang == "english"
                  ? "Ghananta"
                  : lang == "hindi"
                  ? "घनांत"
                  : "घनान्तः"}
              </th>
              <th>
                {lang == "english"
                  ? "Total"
                  : lang == "hindi"
                  ? "कुल जोड़"
                  : "समेषां योगः"}
              </th>
            </tr>
          </thead>
          <tbody>
            {formik.values.vt_last_five_years_students_passed?.map(
              (row, index) => (
                <tr key={index}>
                  <td>
                    {new Date().getFullYear() -
                      formik.values.vt_last_five_years_students_passed[index]
                        .yindex -
                      1}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_last_five_years_students_passed[${index}].mulanta`}
                      name={`vt_last_five_years_students_passed[${index}].mulanta`}
                      value={
                        formik.values.vt_last_five_years_students_passed[index]
                          ?.mulanta || ""
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `vt_last_five_years_students_passed[${index}].mulanta`,
                          e
                        );
                        if (
                          parseFloat(
                            parseFloat(e.target.value || 0) +
                              parseFloat(
                                formik.values
                                  .vt_last_five_years_students_passed[index]
                                  .kramanta || 0
                              ) +
                              parseFloat(
                                formik.values
                                  .vt_last_five_years_students_passed[index]
                                  .ghananta || 0
                              )
                          ) > 0
                        ) {
                          formik.setFieldValue(
                            `vt_last_five_years_students_passed[${index}].total`,
                            parseFloat(
                              parseFloat(e.target.value || 0) +
                                parseFloat(
                                  formik.values
                                    .vt_last_five_years_students_passed[index]
                                    .kramanta || 0
                                ) +
                                parseFloat(
                                  formik.values
                                    .vt_last_five_years_students_passed[index]
                                    .ghananta || 0
                                )
                            )
                          );
                        } else {
                          formik.setFieldValue(
                            `vt_last_five_years_students_passed[${index}].total`,
                            0
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_last_five_years_students_passed &&
                    formik.errors?.vt_last_five_years_students_passed[index]
                      ?.mulanta ? (
                      <div className="text-danger">
                        {formik.errors?.vt_last_five_years_students_passed[
                          index
                        ]?.mulanta &&
                          formik.errors?.vt_last_five_years_students_passed[
                            index
                          ]?.mulanta}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_last_five_years_students_passed[${index}].kramanta`}
                      name={`vt_last_five_years_students_passed[${index}].kramanta`}
                      value={
                        formik.values.vt_last_five_years_students_passed[index]
                          .kramanta || ""
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `vt_last_five_years_students_passed[${index}].kramanta`,
                          e
                        );
                        if (
                          parseFloat(
                            parseFloat(e.target.value || 0) +
                              parseFloat(
                                formik.values
                                  .vt_last_five_years_students_passed[index]
                                  ?.mulanta || 0
                              ) +
                              parseFloat(
                                formik.values
                                  .vt_last_five_years_students_passed[index]
                                  .ghananta || 0
                              )
                          ) > 0
                        ) {
                          formik.setFieldValue(
                            `vt_last_five_years_students_passed[${index}].total`,
                            parseFloat(
                              parseFloat(e.target.value || 0) +
                                parseFloat(
                                  formik.values
                                    .vt_last_five_years_students_passed[index]
                                    .mulanta || 0
                                ) +
                                parseFloat(
                                  formik.values
                                    .vt_last_five_years_students_passed[index]
                                    .ghananta || 0
                                )
                            )
                          );
                        } else {
                          formik.setFieldValue(
                            `vt_last_five_years_students_passed[${index}].total`,
                            0
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_last_five_years_students_passed &&
                    formik.errors?.vt_last_five_years_students_passed[index]
                      ?.kramanta ? (
                      <div className="text-danger">
                        {formik.errors?.vt_last_five_years_students_passed[
                          index
                        ]?.kramanta &&
                          formik.errors?.vt_last_five_years_students_passed[
                            index
                          ]?.kramanta}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_last_five_years_students_passed[${index}].ghananta`}
                      name={`vt_last_five_years_students_passed[${index}].ghananta`}
                      value={
                        formik.values.vt_last_five_years_students_passed[index]
                          ?.ghananta || ""
                      }
                      onChange={(e) => {
                        handleInputChange(
                          `vt_last_five_years_students_passed[${index}].ghananta`,
                          e
                        );
                        if (
                          parseFloat(
                            parseFloat(e.target.value || 0) +
                              parseFloat(
                                formik.values
                                  .vt_last_five_years_students_passed[index]
                                  .mulanta || 0
                              ) +
                              parseFloat(
                                formik.values
                                  .vt_last_five_years_students_passed[index]
                                  .kramanta || 0
                              )
                          ) > 0
                        ) {
                          formik.setFieldValue(
                            `vt_last_five_years_students_passed[${index}].total`,
                            parseFloat(
                              parseFloat(e.target.value || 0) +
                                parseFloat(
                                  formik.values
                                    .vt_last_five_years_students_passed[index]
                                    .mulanta || 0
                                ) +
                                parseFloat(
                                  formik.values
                                    .vt_last_five_years_students_passed[index]
                                    .kramanta || 0
                                )
                            )
                          );
                        } else {
                          formik.setFieldValue(
                            `vt_last_five_years_students_passed[${index}].total`,
                            0
                          );
                        }
                      }}
                      onBlur={(e) => handleInputSave()}
                    />
                    {formik.touched &&
                    formik.errors?.vt_last_five_years_students_passed &&
                    formik.errors?.vt_last_five_years_students_passed[index]
                      ?.ghananta ? (
                      <div className="text-danger">
                        {formik.errors?.vt_last_five_years_students_passed[
                          index
                        ]?.ghananta &&
                          formik.errors?.vt_last_five_years_students_passed[
                            index
                          ]?.ghananta}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id={`vt_last_five_years_students_passed[${index}].total`}
                      name={`vt_last_five_years_students_passed[${index}].total`}
                      value={
                        formik.values.vt_last_five_years_students_passed[index]
                          .total || ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          `vt_last_five_years_students_passed[${index}].total`,
                          e
                        )
                      }
                      onBlur={(e) => handleInputSave()}
                      disabled
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {/* Add Entries of Current Students&* */}
      <label>
        {lang == "english"
          ? "  Presently you are getting education from, The number of students who are studying with you at present."
          : lang == "hindi"
          ? "वर्तमान में आप द्वारा शिक्षण प्राप्त कर रहे विद्यार्थियों की संख्या"
          : "सम्प्रति भवता पाठ्यमानानां विद्यार्थिनां संख्या"}
      </label>
      <div className="table-responsive form-group">
        <table className="table">
          <thead>
            <tr>
              <th>
                {lang == "english"
                  ? "Education level"
                  : lang == "hindi"
                  ? "शिक्षा स्तर"
                  : "शिक्षा-स्तरः"}
              </th>
              <th>
                {lang == "english"
                  ? "Mulanta"
                  : lang == "hindi"
                  ? "मूलांत"
                  : "मूलान्तः"}
              </th>
              <th>
                {lang == "english"
                  ? "Kramanta"
                  : lang == "hindi"
                  ? "क्रमांत "
                  : "क्रमान्त:"}
              </th>
              <th>
                {lang == "english"
                  ? "Ghananta"
                  : lang == "hindi"
                  ? "घनांत"
                  : "घनान्तः"}
              </th>
              <th>
                {lang == "english"
                  ? "Shadanga"
                  : lang == "hindi"
                  ? "षडङ्ग"
                  : "षडङ्गानि"}
              </th>
              <th>
                {lang == "english"
                  ? "Lakshana"
                  : lang == "hindi"
                  ? "लक्षण"
                  : "लक्षणम्"}
              </th>
              <th>
                {lang == "english"
                  ? "Bhashya"
                  : lang == "hindi"
                  ? "भाष्य"
                  : "भाष्यम्"}
              </th>
              <th>
                {lang == "english"
                  ? "Total"
                  : lang == "hindi"
                  ? "कुल जोड़"
                  : "समेषां योगः"}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {lang == "english"
                  ? "Student number"
                  : lang == "hindi"
                  ? "विद्यार्थी संख्या "
                  : "विद्यार्थि-सङ्ख्या"}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id={`vt_students_studying_with_you_at_present.mulanta`}
                  name={`vt_students_studying_with_you_at_present.mulanta`}
                  value={
                    formik.values.vt_students_studying_with_you_at_present
                      ?.mulanta || ""
                  }
                  onChange={(e) => {
                    handleInputChange(
                      `vt_students_studying_with_you_at_present.mulanta`,
                      e
                    );
                    if (
                      parseFloat(
                        parseFloat(e.target.value || 0) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.kramanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.ghananta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.shadanga || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.lakshana || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.bhashya || 0
                          )
                      ) > 0
                    ) {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        parseFloat(
                          parseFloat(e.target.value || 0) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.kramanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.ghananta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.shadanga || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.lakshana || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.bhashya || 0
                            )
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        0
                      );
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched &&
                formik.errors?.vt_students_studying_with_you_at_present &&
                formik.errors?.vt_students_studying_with_you_at_present
                  .mulanta ? (
                  <div className="text-danger">
                    {formik.errors?.vt_students_studying_with_you_at_present
                      .mulanta &&
                      formik.errors?.vt_students_studying_with_you_at_present
                        .mulanta}
                  </div>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id={`vt_students_studying_with_you_at_present.kramanta`}
                  name={`vt_students_studying_with_you_at_present.kramanta`}
                  value={
                    formik.values.vt_students_studying_with_you_at_present
                      ?.kramanta || ""
                  }
                  onChange={(e) => {
                    handleInputChange(
                      `vt_students_studying_with_you_at_present.kramanta`,
                      e
                    );
                    if (
                      parseFloat(
                        parseFloat(e.target.value || 0) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.mulanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.ghananta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.shadanga || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.lakshana || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.bhashya || 0
                          )
                      ) > 0
                    ) {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        parseFloat(
                          parseFloat(e.target.value || 0) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.mulanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.ghananta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.shadanga || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.lakshana || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.bhashya || 0
                            )
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        0
                      );
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched &&
                formik.errors?.vt_students_studying_with_you_at_present &&
                formik.errors?.vt_students_studying_with_you_at_present
                  ?.kramanta ? (
                  <div className="text-danger">
                    {formik.errors?.vt_students_studying_with_you_at_present
                      ?.kramanta &&
                      formik.errors?.vt_students_studying_with_you_at_present
                        ?.kramanta}
                  </div>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id={`vt_students_studying_with_you_at_present.ghananta`}
                  name={`vt_students_studying_with_you_at_present.ghananta`}
                  value={
                    formik.values.vt_students_studying_with_you_at_present
                      ?.ghananta || ""
                  }
                  onChange={(e) => {
                    handleInputChange(
                      `vt_students_studying_with_you_at_present.ghananta`,
                      e
                    );
                    if (
                      parseFloat(
                        parseFloat(e.target.value || 0) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.mulanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.kramanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.shadanga || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.lakshana || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.bhashya || 0
                          )
                      ) > 0
                    ) {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        parseFloat(
                          parseFloat(e.target.value || 0) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.mulanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.kramanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.shadanga || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.lakshana || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.bhashya || 0
                            )
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        0
                      );
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched &&
                formik.errors?.vt_students_studying_with_you_at_present &&
                formik.errors?.vt_students_studying_with_you_at_present
                  .ghananta ? (
                  <div className="text-danger">
                    {formik.errors?.vt_students_studying_with_you_at_present
                      ?.ghananta &&
                      formik.errors?.vt_students_studying_with_you_at_present
                        ?.ghananta}
                  </div>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id={`vt_students_studying_with_you_at_present.shadanga`}
                  name={`vt_students_studying_with_you_at_present.shadanga`}
                  value={
                    formik.values.vt_students_studying_with_you_at_present
                      ?.shadanga || ""
                  }
                  onChange={(e) => {
                    handleInputChange(
                      `vt_students_studying_with_you_at_present.shadanga`,
                      e
                    );
                    if (
                      parseFloat(
                        parseFloat(e.target.value || 0) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.mulanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.kramanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.ghananta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.lakshana || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.bhashya || 0
                          )
                      ) > 0
                    ) {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        parseFloat(
                          parseFloat(e.target.value || 0) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.mulanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.kramanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.ghananta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.lakshana || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.bhashya || 0
                            )
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        0
                      );
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched &&
                formik.errors?.vt_students_studying_with_you_at_present &&
                formik.errors?.vt_students_studying_with_you_at_present
                  .shadanga ? (
                  <div className="text-danger">
                    {formik.errors?.vt_students_studying_with_you_at_present
                      .shadanga &&
                      formik.errors?.vt_students_studying_with_you_at_present
                        .shadanga}
                  </div>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id={`vt_students_studying_with_you_at_present.lakshana`}
                  name={`vt_students_studying_with_you_at_present.lakshana`}
                  value={
                    formik.values.vt_students_studying_with_you_at_present
                      ?.lakshana || ""
                  }
                  onChange={(e) => {
                    handleInputChange(
                      `vt_students_studying_with_you_at_present.lakshana`,
                      e
                    );
                    if (
                      parseFloat(
                        parseFloat(e.target.value || 0) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.mulanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.kramanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.ghananta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.shadanga || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.bhashya || 0
                          )
                      ) > 0
                    ) {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        parseFloat(
                          parseFloat(e.target.value || 0) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.mulanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.kramanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.ghananta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.shadanga || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.bhashya || 0
                            )
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        0
                      );
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched &&
                formik.errors?.vt_students_studying_with_you_at_present &&
                formik.errors?.vt_students_studying_with_you_at_present
                  .lakshana ? (
                  <div className="text-danger">
                    {formik.errors?.vt_students_studying_with_you_at_present
                      .lakshana &&
                      formik.errors?.vt_students_studying_with_you_at_present
                        .lakshana}
                  </div>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id={`vt_students_studying_with_you_at_present.bhashya`}
                  name={`vt_students_studying_with_you_at_present.bhashya`}
                  value={
                    formik.values.vt_students_studying_with_you_at_present
                      ?.bhashya || ""
                  }
                  onChange={(e) => {
                    handleInputChange(
                      `vt_students_studying_with_you_at_present.bhashya`,
                      e
                    );
                    if (
                      parseFloat(
                        parseFloat(e.target.value || 0) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.mulanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.kramanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.ghananta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.shadanga || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_studying_with_you_at_present
                              ?.lakshana || 0
                          )
                      ) > 0
                    ) {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        parseFloat(
                          parseFloat(e.target.value || 0) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.mulanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.kramanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.ghananta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.shadanga || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_studying_with_you_at_present
                                ?.lakshana || 0
                            )
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        `vt_students_studying_with_you_at_present.total`,
                        0
                      );
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched &&
                formik.errors?.vt_students_studying_with_you_at_present &&
                formik.errors?.vt_students_studying_with_you_at_present
                  .bhashya ? (
                  <div className="text-danger">
                    {formik.errors?.vt_students_studying_with_you_at_present
                      .bhashya &&
                      formik.errors?.vt_students_studying_with_you_at_present
                        .bhashya}
                  </div>
                ) : null}
              </td>
              <td>
                <input
                  disabled
                  type="text"
                  className="form-control"
                  id={`vt_students_studying_with_you_at_present.total`}
                  name={`vt_students_studying_with_you_at_present.total`}
                  value={
                    formik.values.vt_students_studying_with_you_at_present
                      ?.total || ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      `vt_students_studying_with_you_at_present.total`,
                      e
                    )
                  }
                  onBlur={(e) => handleInputSave()}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* Details of students educated in his entire Vedadhyapak Nakal* */}
      <label>
        {lang == "english"
          ? "Details of students taught by you in during your entire time as a vedadhyapak."
          : lang == "hindi"
          ? "अपने संपूर्ण वेदाध्यापनकाल में शिक्षित वेदविद्यार्थियों का विवरण​"
          : " भवतः सम्पूर्णे वेदशिक्षणकाले अध्यापितानां वेदविद्यार्थिनां विवरणम्​"}
      </label>
      <div className="table-responsive form-group">
        <table className="table">
          <thead>
            <tr>
              <th>
                {lang == "english"
                  ? "Mulanta"
                  : lang == "hindi"
                  ? "मूलांत"
                  : "मूलान्तः"}
              </th>
              <th>
                {lang == "english"
                  ? "Kramanta"
                  : lang == "hindi"
                  ? "क्रमांत "
                  : "क्रमान्त:"}
              </th>
              <th>
                {lang == "english"
                  ? "Ghananta"
                  : lang == "hindi"
                  ? "घनांत"
                  : "घनान्तः"}
              </th>
              <th>
                {lang == "english"
                  ? "Total"
                  : lang == "hindi"
                  ? "कुल जोड़"
                  : "समेषां योगः"}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id={`vt_students_taught_by_you_entire_time_as_vedadhyapak.mulanta`}
                  name={`vt_students_taught_by_you_entire_time_as_vedadhyapak.mulanta`}
                  value={
                    formik.values
                      .vt_students_taught_by_you_entire_time_as_vedadhyapak
                      ?.mulanta || ""
                  }
                  onChange={(e) => {
                    if (e.target.value >= "a" && e.target.value <= "z") return;
                    handleInputChange(
                      `vt_students_taught_by_you_entire_time_as_vedadhyapak.mulanta`,
                      e
                    );
                    if (
                      parseFloat(
                        parseFloat(e.target.value || 0) +
                          parseFloat(
                            formik.values
                              .vt_students_taught_by_you_entire_time_as_vedadhyapak
                              ?.ghananta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_taught_by_you_entire_time_as_vedadhyapak
                              ?.kramanta || 0
                          )
                      )
                    ) {
                      formik.setFieldValue(
                        `vt_students_taught_by_you_entire_time_as_vedadhyapak.total`,
                        parseFloat(
                          parseFloat(e.target.value || 0) +
                            parseFloat(
                              formik.values
                                .vt_students_taught_by_you_entire_time_as_vedadhyapak
                                ?.ghananta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_taught_by_you_entire_time_as_vedadhyapak
                                ?.kramanta || 0
                            )
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        `vt_students_taught_by_you_entire_time_as_vedadhyapak.total`,
                        0
                      );
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched &&
                formik.errors
                  ?.vt_students_taught_by_you_entire_time_as_vedadhyapak &&
                formik.errors
                  ?.vt_students_taught_by_you_entire_time_as_vedadhyapak
                  .mulanta ? (
                  <div className="text-danger">
                    {formik.errors
                      ?.vt_students_taught_by_you_entire_time_as_vedadhyapak
                      .mulanta &&
                      formik.errors
                        ?.vt_students_taught_by_you_entire_time_as_vedadhyapak
                        .mulanta}
                  </div>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id={`vt_students_taught_by_you_entire_time_as_vedadhyapak.kramanta`}
                  name={`vt_students_taught_by_you_entire_time_as_vedadhyapak.kramanta`}
                  value={
                    formik.values
                      .vt_students_taught_by_you_entire_time_as_vedadhyapak
                      ?.kramanta || ""
                  }
                  onChange={(e) => {
                    if (e.target.value >= "a" && e.target.value <= "z") return;
                    handleInputChange(
                      `vt_students_taught_by_you_entire_time_as_vedadhyapak.kramanta`,
                      e
                    );
                    if (
                      parseFloat(
                        parseFloat(e.target.value || 0) +
                          parseFloat(
                            formik.values
                              .vt_students_taught_by_you_entire_time_as_vedadhyapak
                              ?.ghananta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_taught_by_you_entire_time_as_vedadhyapak
                              ?.mulanta || 0
                          )
                      )
                    ) {
                      formik.setFieldValue(
                        `vt_students_taught_by_you_entire_time_as_vedadhyapak.total`,
                        parseFloat(
                          parseFloat(e.target.value || 0) +
                            parseFloat(
                              formik.values
                                .vt_students_taught_by_you_entire_time_as_vedadhyapak
                                ?.ghananta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_taught_by_you_entire_time_as_vedadhyapak
                                ?.mulanta || 0
                            )
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        `vt_students_taught_by_you_entire_time_as_vedadhyapak.total`,
                        0
                      );
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched &&
                formik.errors
                  ?.vt_students_taught_by_you_entire_time_as_vedadhyapak &&
                formik.errors
                  ?.vt_students_taught_by_you_entire_time_as_vedadhyapak
                  .kramanta ? (
                  <div className="text-danger">
                    {formik.errors
                      ?.vt_students_taught_by_you_entire_time_as_vedadhyapak
                      .kramanta &&
                      formik.errors
                        ?.vt_students_taught_by_you_entire_time_as_vedadhyapak
                        .kramanta}
                  </div>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id={`vt_students_taught_by_you_entire_time_as_vedadhyapak.ghananta`}
                  name={`vt_students_taught_by_you_entire_time_as_vedadhyapak.ghananta`}
                  value={
                    formik.values
                      .vt_students_taught_by_you_entire_time_as_vedadhyapak
                      ?.ghananta || ""
                  }
                  onChange={(e) => {
                    if (e.target.value >= 'a' && e.target.value <= 'z') return;
                    handleInputChange(
                      `vt_students_taught_by_you_entire_time_as_vedadhyapak.ghananta`,
                      e
                    );
                    if (
                      parseFloat(
                        parseFloat(e.target.value || 0) +
                          parseFloat(
                            formik.values
                              .vt_students_taught_by_you_entire_time_as_vedadhyapak
                              ?.kramanta || 0
                          ) +
                          parseFloat(
                            formik.values
                              .vt_students_taught_by_you_entire_time_as_vedadhyapak
                              ?.mulanta || 0
                          )
                      )
                    ) {
                      formik.setFieldValue(
                        `vt_students_taught_by_you_entire_time_as_vedadhyapak.total`,
                        parseFloat(
                          parseFloat(e.target.value || 0) +
                            parseFloat(
                              formik.values
                                .vt_students_taught_by_you_entire_time_as_vedadhyapak
                                ?.kramanta || 0
                            ) +
                            parseFloat(
                              formik.values
                                .vt_students_taught_by_you_entire_time_as_vedadhyapak
                                ?.mulanta || 0
                            )
                        )
                      );
                    } else {
                      formik.setFieldValue(
                        `vt_students_taught_by_you_entire_time_as_vedadhyapak.total`,
                        0
                      );
                    }
                  }}
                  onBlur={(e) => handleInputSave()}
                />
                {formik.touched &&
                formik.errors
                  ?.vt_students_taught_by_you_entire_time_as_vedadhyapak &&
                formik.errors
                  ?.vt_students_taught_by_you_entire_time_as_vedadhyapak
                  ?.ghananta ? (
                  <div className="text-danger">
                    {formik.errors
                      ?.vt_students_taught_by_you_entire_time_as_vedadhyapak
                      .ghananta &&
                      formik.errors
                        ?.vt_students_taught_by_you_entire_time_as_vedadhyapak
                        .ghananta}
                  </div>
                ) : null}
              </td>
              <td>
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  id={`vt_students_taught_by_you_entire_time_as_vedadhyapak.total`}
                  name={`vt_students_taught_by_you_entire_time_as_vedadhyapak.total`}
                  value={
                    formik.values
                      .vt_students_taught_by_you_entire_time_as_vedadhyapak
                      ?.total || ""
                  }
                  onChange={(e) =>
                    handleInputChange(
                      `vt_students_taught_by_you_entire_time_as_vedadhyapak.total`,
                      e
                    )
                  }
                  onBlur={(e) => handleInputSave()}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12 form-group">
          <label>
            {lang == "english"
              ? "Number of students who did not complete the study of the ved during your career as a teacher. "
              : lang == "hindi"
              ? "आपके संपूर्ण वेदाध्यापनकाल में बीच में अध्ययन छोड़ चुके कुल छात्रों की संख्या "
              : "भवतः सम्पूर्णवेदाध्यापनकाले मध्ये एव वेदाध्ययनं त्यक्तवतां छात्राणां सङ्ख्या"}
          </label>
          <input
            type="text"
            className=" form-control"
            id={`vt_did_not_complete_the_study_of_veda`}
            name={`vt_did_not_complete_the_study_of_veda`}
            value={formik.values.vt_did_not_complete_the_study_of_veda || ""}
            onChange={(e) =>
              
              handleInputChange(`vt_did_not_complete_the_study_of_veda`, e)
            }
            onBlur={(e) => handleInputSave()}
          ></input>
          {formik.touched &&
          formik.errors?.vt_did_not_complete_the_study_of_veda ? (
            <div className="text-danger">
              {formik.errors?.vt_did_not_complete_the_study_of_veda &&
                formik.errors?.vt_did_not_complete_the_study_of_veda}
            </div>
          ) : null}
        </div>
      </div>
      {/* Add Veda student list */}
      <div className="row">
        <div className="col-lg-12 form-group">
          <label>
            {lang == "english"
              ? "List of ved pandits educated by you during your lifetime who passed ghananta or higher level examination. Attach Annexure-1?"
              : lang == "hindi"
              ? "वेदसभाओं, संगोष्ठियों, कार्यशालाओं इत्यादि में उपस्थिति। अनुबन्ध-१ संलग्न करें?"
              : "वेदसभा-संगोष्ठी-कार्यशालासु उपस्थितिः। अनुबन्धम्-१ संलग्नं करोतु?"}
          </label>
          <select
            id={`vt_attendance_in_veda_sabhas`}
            name={`vt_attendance_in_veda_sabhas`}
            value={formik.values.vt_attendance_in_veda_sabhas || ""}
            onChange={(e) => {
              handleInputChange(`vt_attendance_in_veda_sabhas`, e);
              if (e.target.value == "no") {
                formik.setFieldValue("vt_attendance_in_veda_sabhas_table", [
                  {
                    student_name: "N/A",
                    study: "N/A",
                    name_examination_body: "N/A",
                    year: "N/A",
                    certificate: "N/A",
                    certificate_string: "N/A",
                  },
                ]);
              }
              if (e.target.value == "yes") {
                formik.setFieldValue("vt_attendance_in_veda_sabhas_table", [
                  {
                    student_name: "",
                    study: "",
                    name_examination_body: "",
                    year: "",
                    certificate: "",
                    certificate_string: "",
                  },
                ]);
              }
            }}
            className="form-control   "
            onBlur={(e) => handleInputSave()}
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value="no">
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched && formik.errors?.vt_attendance_in_veda_sabhas ? (
            <div className="text-danger">
              {formik.errors?.vt_attendance_in_veda_sabhas &&
                formik.errors?.vt_attendance_in_veda_sabhas}
            </div>
          ) : null}
        </div>
        {formik.values.vt_attendance_in_veda_sabhas == "yes" ? (
          <div className="table-responsive form-group col-12">
            <table className="table">
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रमांक "
                    : "क्रमाङ्कः"}
                </th>
                <th>
                  {lang == "english"
                    ? "Student name "
                    : lang == "hindi"
                    ? "विद्यार्थी का नाम "
                    : "विद्यार्थिनः नाम"}
                </th>
                <th>
                  {lang == "english"
                    ? "Study "
                    : lang == "hindi"
                    ? "अध्ययन"
                    : "अध्ययनम्‌"}
                </th>
                <th>
                  {lang == "english"
                    ? "Name of the examination body "
                    : lang == "hindi"
                    ? "परीक्षा संस्था का नाम "
                    : "परीक्षासंस्थायाः नाम "}
                </th>
                <th>
                  {lang == "english"
                    ? "Year "
                    : lang == "hindi"
                    ? "वर्ष "
                    : "वर्षम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Upload certificates  "
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु "}
                </th>
                <th width="120">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
              {formik.values?.vt_attendance_in_veda_sabhas_table.map(
                (son, index) => (
                  <tr>
                    <td>{1 + index}</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`vt_attendance_in_veda_sabhas_table[${index}].student_name`}
                        name={`vt_attendance_in_veda_sabhas_table[${index}].student_name`}
                        value={
                          formik.values.vt_attendance_in_veda_sabhas_table[
                            index
                          ].student_name || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vt_attendance_in_veda_sabhas_table[${index}].student_name`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table[index]
                        .student_name ? (
                        <div className="text-danger">
                          {formik.errors?.vt_attendance_in_veda_sabhas_table[
                            index
                          ].student_name &&
                            formik.errors?.vt_attendance_in_veda_sabhas_table[
                              index
                            ].student_name}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`vt_attendance_in_veda_sabhas_table[${index}].study`}
                        name={`vt_attendance_in_veda_sabhas_table[${index}].study`}
                        value={
                          formik.values.vt_attendance_in_veda_sabhas_table[
                            index
                          ].study || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vt_attendance_in_veda_sabhas_table[${index}].study`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table[index]
                        .study ? (
                        <div className="text-danger">
                          {formik.errors?.vt_attendance_in_veda_sabhas_table[
                            index
                          ].study &&
                            formik.errors?.vt_attendance_in_veda_sabhas_table[
                              index
                            ].study}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`vt_attendance_in_veda_sabhas_table[${index}].name_examination_body`}
                        name={`vt_attendance_in_veda_sabhas_table[${index}].name_examination_body`}
                        value={
                          formik.values.vt_attendance_in_veda_sabhas_table[
                            index
                          ].name_examination_body || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vt_attendance_in_veda_sabhas_table[${index}].name_examination_body`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table[index]
                        .name_examination_body ? (
                        <div className="text-danger">
                          {formik.errors?.vt_attendance_in_veda_sabhas_table[
                            index
                          ].name_examination_body &&
                            formik.errors?.vt_attendance_in_veda_sabhas_table[
                              index
                            ].name_examination_body}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`vt_attendance_in_veda_sabhas_table[${index}].year`}
                        name={`vt_attendance_in_veda_sabhas_table[${index}].year`}
                        value={
                          formik.values.vt_attendance_in_veda_sabhas_table[
                            index
                          ].year || ""
                        }
                        onChange={(e) => {
                          if (e.target.value < 2099) {
                            handleInputChange(
                              `vt_attendance_in_veda_sabhas_table[${index}].year`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table[index]
                        .year ? (
                        <div className="text-danger">
                          {formik.errors?.vt_attendance_in_veda_sabhas_table[
                            index
                          ].year &&
                            formik.errors?.vt_attendance_in_veda_sabhas_table[
                              index
                            ].year}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        className="form-control"
                        id={`vt_attendance_in_veda_sabhas_table[${index}].certificate`}
                        name={`vt_attendance_in_veda_sabhas_table[${index}].certificate`}
                        onChange={async (e) => {
                          if (e.target.files.length > 0) {
                            await fileUploadFunction(
                              `vt_attendance_in_veda_sabhas_table[${index}].certificate_string`,
                              e.target.files[0]
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.values?.vt_attendance_in_veda_sabhas_table[index]
                        .certificate_string && (
                        <button
                          className="uploadLink"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vt_attendance_in_veda_sabhas_table[index].certificate_string}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {`${formik.values?.vt_attendance_in_veda_sabhas_table[
                            index
                          ].certificate_string.replace("upload-file/", "")}`}
                        </button>
                      )}
                      {formik.touched &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table &&
                      formik.errors?.vt_attendance_in_veda_sabhas_table[index]
                        .certificate_string ? (
                        <div className="text-danger">
                          {formik.errors?.vt_attendance_in_veda_sabhas_table[
                            index
                          ].certificate_string &&
                            formik.errors?.vt_attendance_in_veda_sabhas_table[
                              index
                            ].certificate_string}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values.vt_attendance_in_veda_sabhas_table
                        .length >= 2 ? (
                        <button
                          className="btn btn-outline-danger"
                          onClick={(e) =>
                            removeAttendanceInVedaSabhasRow(index, e)
                          }
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className="border-bottom-0">
                <td className="text-right" colspan="7">
                  <button
                    className="btn btn-outline-danger"
                    onClick={(e) => addAttendanceInVedaSabhasRow(e)}
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row">
        <div className="col-lg-12 form-group">
          <label>
            {lang == "english"
              ? "List of ved students currently being taught by you. Attach Annexure-2?"
              : lang == "hindi"
              ? "वर्तमान में आपके द्वारा शिक्षण प्राप्त कर रहे वेद विद्यार्थियों की सूची। अनुबन्ध-२ संलग्न करे?"
              : "सम्प्रति भवता पाठ्यमानानां वेदविद्यार्थिनां सूचिं। अनुबन्धम्-२ संलग्नं करोतु?"}
          </label>
          <select
            id={`vt_veda_students_currently_being_taught_by_you`}
            name={`vt_veda_students_currently_being_taught_by_you`}
            value={
              formik.values.vt_veda_students_currently_being_taught_by_you || ""
            }
            onChange={(e) => {
              handleInputChange(
                `vt_veda_students_currently_being_taught_by_you`,
                e
              );
              if (e.target.value == "no") {
                formik.setFieldValue(
                  "vt_veda_students_currently_being_taught_by_you_table",
                  [
                    {
                      student_name: "N/A",
                      age: "N/A",
                      exam_level: "N/A",
                      other: "N/A",
                      year: "N/A",
                      certificate: "N/A",
                      certificate_string: "N/A",
                    },
                  ]
                );
              }
              if (e.target.value == "yes") {
                formik.setFieldValue(
                  "vt_veda_students_currently_being_taught_by_you_table",
                  [
                    {
                      student_name: "",
                      age: "",
                      exam_level: "",
                      other: "",
                      year: "",
                      certificate: "",
                      certificate_string: "",
                    },
                  ]
                );
              }
            }}
            className="form-control   "
            onBlur={(e) => handleInputSave()}
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value="no">
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched &&
          formik.errors?.vt_veda_students_currently_being_taught_by_you ? (
            <div className="text-danger">
              {formik.errors?.vt_veda_students_currently_being_taught_by_you &&
                formik.errors?.vt_veda_students_currently_being_taught_by_you}
            </div>
          ) : null}
        </div>
        {formik.values.vt_veda_students_currently_being_taught_by_you ==
        "yes" ? (
          <div className="table-responsive form-group col-12">
            <table className="table">
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रमांक "
                    : "क्रमाङ्कः"}
                </th>
                <th>
                  {lang == "english"
                    ? "Student name "
                    : lang == "hindi"
                    ? "विद्यार्थी का नाम "
                    : "विद्यार्थिनः नाम"}
                </th>
                <th>
                  {lang == "english" ? "Age " : lang == "hindi" ? "आयु" : "वयः"}
                </th>
                <th>
                  {lang == "english"
                    ? "Exam level"
                    : lang == "hindi"
                    ? "परीक्षा स्तर"
                    : "परीक्षा स्तर"}
                </th>
                <th>
                  {lang == "english"
                    ? "Other"
                    : lang == "hindi"
                    ? "अन्य  "
                    : "अन्यः  "}
                </th>
                <th>
                  {lang == "english"
                    ? "Year "
                    : lang == "hindi"
                    ? "वर्ष "
                    : "वर्षम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Upload certificates  "
                    : lang == "hindi"
                    ? "प्रमाण पत्र अपलोड करे"
                    : "प्रमाणपत्रम् अपलोड इति करोतु "}
                </th>
                <th width="120">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
              {formik.values?.vt_veda_students_currently_being_taught_by_you_table.map(
                (son, index) => (
                  <tr>
                    <td>{1 + index}</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`vt_veda_students_currently_being_taught_by_you_table[${index}].student_name`}
                        name={`vt_veda_students_currently_being_taught_by_you_table[${index}].student_name`}
                        value={
                          formik.values
                            .vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].student_name || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vt_veda_students_currently_being_taught_by_you_table[${index}].student_name`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table[
                        index
                      ].student_name ? (
                        <div className="text-danger">
                          {formik.errors
                            ?.vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].student_name &&
                            formik.errors
                              ?.vt_veda_students_currently_being_taught_by_you_table[
                              index
                            ].student_name}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`vt_veda_students_currently_being_taught_by_you_table[${index}].age`}
                        name={`vt_veda_students_currently_being_taught_by_you_table[${index}].age`}
                        value={
                          formik.values
                            .vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].age || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vt_veda_students_currently_being_taught_by_you_table[${index}].age`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table[
                        index
                      ].age ? (
                        <div className="text-danger">
                          {formik.errors
                            ?.vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].age &&
                            formik.errors
                              ?.vt_veda_students_currently_being_taught_by_you_table[
                              index
                            ].age}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <select
                        id={`vt_veda_students_currently_being_taught_by_you_table[${index}].exam_level`}
                        name={`vt_veda_students_currently_being_taught_by_you_table[${index}].exam_level`}
                        value={
                          formik.values
                            .vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].exam_level || ""
                        }
                        onChange={(e) => {
                          handleInputChange(
                            `vt_veda_students_currently_being_taught_by_you_table[${index}].exam_level`,
                            e
                          );
                          if (e.target.value != "Other") {
                            formik.setFieldValue(
                              `vt_veda_students_currently_being_taught_by_you_table[${index}].other`,
                              "N/A"
                            );
                          }
                          if (e.target.value == "Other") {
                            formik.setFieldValue(
                              `vt_veda_students_currently_being_taught_by_you_table[${index}].other`,
                              ""
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                        className="form-control"
                      >
                        <option>
                          ---
                          {lang == "english"
                            ? "Select"
                            : lang == "hindi"
                            ? "चुने"
                            : "चिनोतु"}
                          ---
                        </option>
                        <option value="Mulanta">
                          {lang == "english"
                            ? "Mulanta"
                            : lang == "hindi"
                            ? "मूलांत"
                            : "मूलान्तः"}
                        </option>
                        <option value="Kramanta">
                          {lang == "english"
                            ? "Kramanta"
                            : lang == "hindi"
                            ? "क्रमांत "
                            : "क्रमान्त:"}
                        </option>
                        <option value="Ghananta">
                          {lang == "english"
                            ? "Ghananta"
                            : lang == "hindi"
                            ? "घनांत"
                            : "घनान्तः"}
                        </option>
                        <option value="Shadanga">
                          {lang == "english"
                            ? "Shadanga"
                            : lang == "hindi"
                            ? "षडङ्ग"
                            : "षडङ्गानि"}
                        </option>
                        <option value="Lakshana">
                          {lang == "english"
                            ? "Lakshana"
                            : lang == "hindi"
                            ? "लक्षण"
                            : "लक्षणम्"}
                        </option>
                        <option value="Bhashya">
                          {lang == "english"
                            ? "Bhashya"
                            : lang == "hindi"
                            ? "भाष्य"
                            : "भाष्यम्"}
                        </option>
                        <option value="Other">
                          {lang == "english"
                            ? "Other"
                            : lang == "hindi"
                            ? "अन्य  "
                            : "अन्यः  "}
                        </option>
                      </select>
                      {formik.touched &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table[
                        index
                      ].exam_level ? (
                        <div className="text-danger">
                          {formik.errors
                            ?.vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].exam_level &&
                            formik.errors
                              ?.vt_veda_students_currently_being_taught_by_you_table[
                              index
                            ].exam_level}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values
                        .vt_veda_students_currently_being_taught_by_you_table &&
                      formik.values
                        .vt_veda_students_currently_being_taught_by_you_table[
                        index
                      ].exam_level == "Other" ? (
                        <input
                          type="text"
                          className="form-control"
                          id={`vt_veda_students_currently_being_taught_by_you_table[${index}].other`}
                          name={`vt_veda_students_currently_being_taught_by_you_table[${index}].other`}
                          value={
                            formik.values
                              .vt_veda_students_currently_being_taught_by_you_table[
                              index
                            ].other || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `vt_veda_students_currently_being_taught_by_you_table[${index}].other`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                        ></input>
                      ) : (
                        ""
                      )}
                      {formik.touched &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table[
                        index
                      ].other ? (
                        <div className="text-danger">
                          {formik.errors
                            ?.vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].other &&
                            formik.errors
                              ?.vt_veda_students_currently_being_taught_by_you_table[
                              index
                            ].other}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`vt_veda_students_currently_being_taught_by_you_table[${index}].year`}
                        name={`vt_veda_students_currently_being_taught_by_you_table[${index}].year`}
                        value={
                          formik.values
                            .vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].year || ""
                        }
                        onChange={(e) => {
                          if (e.target.value < 2099) {
                            handleInputChange(
                              `vt_veda_students_currently_being_taught_by_you_table[${index}].year`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table[
                        index
                      ].year ? (
                        <div className="text-danger">
                          {formik.errors
                            ?.vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].year &&
                            formik.errors
                              ?.vt_veda_students_currently_being_taught_by_you_table[
                              index
                            ].year}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .pdf"
                        className="form-control"
                        id={`vt_veda_students_currently_being_taught_by_you_table[${index}].certificate`}
                        name={`vt_veda_students_currently_being_taught_by_you_table[${index}].certificate`}
                        onChange={async (e) => {
                          if (e.target.files.length > 0) {
                            await fileUploadFunction(
                              `vt_veda_students_currently_being_taught_by_you_table[${index}].certificate_string`,
                              e.target.files[0]
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.values
                        ?.vt_veda_students_currently_being_taught_by_you_table[
                        index
                      ].certificate_string && (
                        <button
                          className="uploadLink"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `${process.env.REACT_APP_MEDIA_URL}/${formik.values?.vt_veda_students_currently_being_taught_by_you_table[index].certificate_string}`,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          {`${formik.values?.vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].certificate_string.replace("upload-file/", "")}`}
                        </button>
                      )}
                      {formik.touched &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table &&
                      formik.errors
                        ?.vt_veda_students_currently_being_taught_by_you_table[
                        index
                      ].certificate_string ? (
                        <div className="text-danger">
                          {formik.errors
                            ?.vt_veda_students_currently_being_taught_by_you_table[
                            index
                          ].certificate_string &&
                            formik.errors
                              ?.vt_veda_students_currently_being_taught_by_you_table[
                              index
                            ].certificate_string}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values
                        .vt_veda_students_currently_being_taught_by_you_table
                        .length >= 2 ? (
                        <button
                          className="btn btn-outline-danger"
                          onClick={(e) =>
                            removeVedaStudentsCurrentlyBeingTaughtByYouTableRow(
                              index,
                              e
                            )
                          }
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className="border-bottom-0">
                <td className="text-right" colspan="8">
                  <button
                    className="btn btn-outline-danger"
                    onClick={(e) =>
                      addVedaStudentsCurrentlyBeingTaughtByYouTableRow(e)
                    }
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row">
        <div className="col-lg-12 form-group">
          <label>
            {lang == "english"
              ? "Have any of your vedavidyarthis became vedadhyapak? Attach Annexure-3?"
              : lang == "hindi"
              ? "आपके द्वारा शिक्षित उन वेदविद्यार्थियों की सूची जो शिक्षण के बाद वेदाध्यापक बने। अनुबन्ध-३ संलग्न करे?"
              : "भवतः ते वेदविद्यार्थिनः ये अध्ययनानन्तरं वेदाध्यापकाः अभवन् तेषां वेदविद्यार्थिनां नामसूची। अनुबन्धम्-३ संलग्नं करोतु?"}
          </label>
          <select
            id={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka`}
            name={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka`}
            value={
              formik.values
                .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka || ""
            }
            onChange={(e) => {
              handleInputChange(
                `vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka`,
                e
              );
              if (e.target.value == "no") {
                formik.setFieldValue(
                  "vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table",
                  [
                    {
                      vedvidyarthi_name: "N/A",
                      last_year_of_vedadhyayan: "N/A",
                      student_vedshakha: "N/A",
                      student_vedshakha_other: "N/A",
                      institution_name: "N/A",
                    },
                  ]
                );
              }
              if (e.target.value == "yes") {
                formik.setFieldValue(
                  "vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table",
                  [
                    {
                      vedvidyarthi_name: "",
                      last_year_of_vedadhyayan: "",
                      student_vedshakha: "",
                      student_vedshakha_other: "",
                      institution_name: "",
                    },
                  ]
                );
              }
            }}
            className="form-control   "
            onBlur={(e) => handleInputSave()}
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value="no">
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched &&
          formik.errors?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka ? (
            <div className="text-danger">
              {formik.errors?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka &&
                formik.errors?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka}
            </div>
          ) : null}
        </div>
        {formik.values
          .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka == "yes" ? (
          <div className="table-responsive form-group col-12">
            <table className="table">
              <tr>
                <th>
                  {lang == "english"
                    ? "Serial number"
                    : lang == "hindi"
                    ? "क्रमांक "
                    : "क्रमाङ्कः"}
                </th>
                <th>
                  {lang == "english"
                    ? "Vedavidyarthi name"
                    : lang == "hindi"
                    ? "वेदविद्यार्थी का नाम "
                    : "वेदविद्यार्थिनः नाम"}
                </th>
                <th>
                  {lang == "english"
                    ? "The last year of vedadhyayan "
                    : lang == "hindi"
                    ? "वेदाध्ययन समाप्ति वर्ष "
                    : "वेदाध्ययनसमाप्तिवर् षम् "}
                </th>
                <th>
                  {lang == "english"
                    ? "Student's vedashakha "
                    : lang == "hindi"
                    ? "विद्यार्थी की वेदशाखा"
                    : "विद्यार्थिनः वेदशाखा "}
                </th>
                <th>
                  {lang == "english"
                    ? "Student's anyashakha "
                    : lang == "hindi"
                    ? "विद्यार्थी की अन्यशाखा"
                    : "विद्यार्थिनः अन्यशाखा "}
                </th>
                <th>
                  {lang == "english"
                    ? "Institution name where they are teaching today "
                    : lang == "hindi"
                    ? "संस्था नाम जहाँ आज अध्यापन कर रहे है "
                    : "संस्थानाम यत्र सम्प्रति पाठयति "}
                </th>
                <th width="120">
                  {lang == "english"
                    ? "Action"
                    : lang == "hindi"
                    ? "क्रिया"
                    : "क्रिया"}
                </th>
              </tr>
              {formik.values?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table.map(
                (son, index) => (
                  <tr>
                    <td>{1 + index}</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].vedvidyarthi_name`}
                        name={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].vedvidyarthi_name`}
                        value={
                          formik.values
                            .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                            index
                          ].vedvidyarthi_name || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].vedvidyarthi_name`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table &&
                      formik.errors?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                        index
                      ].vedvidyarthi_name ? (
                        <div className="text-danger">
                          {formik.errors?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                            index
                          ].vedvidyarthi_name &&
                            formik.errors
                              ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                              index
                            ].vedvidyarthi_name}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        id={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].last_year_of_vedadhyayan`}
                        name={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].last_year_of_vedadhyayan`}
                        value={
                          formik.values
                            .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                            index
                          ].last_year_of_vedadhyayan || ""
                        }
                        onChange={(e) => {
                          if (e.target.value <= 2025) {
                            handleInputChange(
                              `vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].last_year_of_vedadhyayan`,
                              e
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors
                        ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table &&
                      formik.errors
                        ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                        index
                      ].last_year_of_vedadhyayan ? (
                        <div className="text-danger">
                          {formik.errors
                            ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                            index
                          ].last_year_of_vedadhyayan &&
                            formik.errors
                              ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                              index
                            ].last_year_of_vedadhyayan}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      <select
                        className="form-control"
                        id={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].student_vedshakha`}
                        name={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].student_vedshakha`}
                        value={
                          formik.values
                            .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                            index
                          ].student_vedshakha || ""
                        }
                        onChange={(e) => {
                          handleInputChange(
                            `vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].student_vedshakha`,
                            e
                          );
                          if (e.target.value == "Anyashakha") {
                            formik.setFieldValue(
                              `vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].student_vedshakha_other`,
                              ""
                            );
                          }
                          if (e.target.value != "Anyashakha") {
                            formik.setFieldValue(
                              `vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].student_vedshakha_other`,
                              "N/A"
                            );
                          }
                        }}
                        onBlur={(e) => handleInputSave()}
                      >
                        <option selected>
                          ---
                          {lang == "english"
                            ? "Select"
                            : lang == "hindi"
                            ? "चुने"
                            : "चिनोतु"}
                          ---
                        </option>
                        <option value="Shakalshakha">
                          {lang == "english"
                            ? "Shakalshakha"
                            : lang == "hindi"
                            ? "शाकलशाखा "
                            : "शाकलशाखा "}
                        </option>
                        <option value="Ashvalayanshakha">
                          {lang == "english"
                            ? "Ashvalayanshakha"
                            : lang == "hindi"
                            ? "आश्वलायनशाखा "
                            : "आश्वलायनशाखा "}
                        </option>
                        <option value="Bashkalshakha">
                          {lang == "english"
                            ? "Bashkalshakha"
                            : lang == "hindi"
                            ? "बाष्कलशाखा "
                            : "बाष्कलशाखा "}
                        </option>
                        <option value="Shankhayanshakha">
                          {lang == "english"
                            ? "Shankhayanshakha"
                            : lang == "hindi"
                            ? "शांखायनशाखा "
                            : "शाङ्खायनशाखा "}
                        </option>
                        <option value="Kanvashakha ">
                          {lang == "english"
                            ? "Kanvashakha"
                            : lang == "hindi"
                            ? "काण्वशाखा "
                            : "काण्वशाखा "}
                        </option>
                        <option value="Madhyandinshakha">
                          {lang == "english"
                            ? "Madhyandinshakha"
                            : lang == "hindi"
                            ? "माध्यन्दिनशाखा "
                            : "माध्यन्दिनशाखा "}
                        </option>
                        <option value="Taittiriyashakha ">
                          {lang == "english"
                            ? "Taittiriyashakha"
                            : lang == "hindi"
                            ? "तैत्तिरीयशाखा "
                            : "तैत्तिरीयशाखा "}
                        </option>
                        <option value="Maitrayanishakha ">
                          {lang == "english"
                            ? "Maitrayanishakha"
                            : lang == "hindi"
                            ? "मैत्रायणीशाखा "
                            : "मैत्रायणीशाखा "}
                        </option>
                        <option value="Kathashakha ">
                          {lang == "english"
                            ? "Kathashakha"
                            : lang == "hindi"
                            ? "कठशाखा "
                            : "कठशाखा "}
                        </option>
                        <option value="Kapishthalshakha ">
                          {lang == "english"
                            ? "Kapishthalshakha"
                            : lang == "hindi"
                            ? "कपिष्ठलशाखा "
                            : "कपिष्ठलशाखा "}
                        </option>
                        <option value="Kouthumshakha ">
                          {lang == "english"
                            ? "Kouthumshakha"
                            : lang == "hindi"
                            ? "कौथुमशाखा "
                            : "कौथुमशाखा "}
                        </option>
                        <option value="Ranayaniyashakha ">
                          {lang == "english"
                            ? "Ranayaniyashakha"
                            : lang == "hindi"
                            ? "राणायनीयशाखा "
                            : "राणायनीयशाखा "}
                        </option>
                        <option value="Jaiminiyashakha ">
                          {lang == "english"
                            ? "Jaiminiyashakha"
                            : lang == "hindi"
                            ? "जैमिनीयशाखा "
                            : "जैमिनीयशाखा "}
                        </option>
                        <option value="Shounkashakha ">
                          {lang == "english"
                            ? "Shounkashakha"
                            : lang == "hindi"
                            ? "शौनकशाखा "
                            : "शौनकशाखा "}
                        </option>
                        <option value="Paippaladshakha ">
                          {lang == "english"
                            ? "Paippaladshakha"
                            : lang == "hindi"
                            ? "पैप्पलादशाखा "
                            : "पैप्पलादशाखा"}
                        </option>
                        <option value={"Anyashakha"}>
                          {lang == "english"
                            ? "Anyashakha "
                            : lang == "hindi"
                            ? "अन्यशाखा"
                            : "अन्यशाखा "}
                        </option>
                      </select>
                      {formik.touched &&
                      formik.errors
                        ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table &&
                      formik.errors
                        ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                        index
                      ].student_vedshakha ? (
                        <div className="text-danger">
                          {formik.errors
                            ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                            index
                          ].student_vedshakha &&
                            formik.errors
                              ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                              index
                            ].student_vedshakha}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values
                        .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table &&
                      formik.values
                        .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                        index
                      ].student_vedshakha === "Anyashakha" ? (
                        <input
                          type="text"
                          className="form-control"
                          id={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].student_vedshakha_other`}
                          name={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].student_vedshakha_other`}
                          value={
                            formik.values
                              .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                              index
                            ].student_vedshakha_other || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].student_vedshakha_other`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                        ></input>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        id={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].institution_name`}
                        name={`vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].institution_name`}
                        value={
                          formik.values
                            .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                            index
                          ].institution_name || ""
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[${index}].institution_name`,
                            e
                          )
                        }
                        onBlur={(e) => handleInputSave()}
                      ></input>
                      {formik.touched &&
                      formik.errors
                        ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table &&
                      formik.errors
                        ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                        index
                      ].institution_name ? (
                        <div className="text-danger">
                          {formik.errors
                            ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                            index
                          ].institution_name &&
                            formik.errors
                              ?.vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table[
                              index
                            ].institution_name}
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {formik.values
                        .vt_vedvidyarthi_educated_by_you_who_became_vedadhyapaka_table
                        .length >= 2 ? (
                        <button
                          className="btn btn-outline-danger"
                          onClick={(e) =>
                            removeVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow(
                              index,
                              e
                            )
                          }
                        >
                          {lang == "english"
                            ? "Remove"
                            : lang == "hindi"
                            ? "हटाएँ"
                            : "अपाकरोतु"}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                )
              )}
              <tr className="border-bottom-0">
                <td className="text-right" colspan="7">
                  <button
                    className="btn btn-outline-danger"
                    onClick={(e) =>
                      addVedvidyarthiEducatedByYouWhoBecameVedadhyapakaTableRow(
                        e
                      )
                    }
                  >
                    {lang == "english"
                      ? "Add"
                      : lang == "hindi"
                      ? "जोड़ें"
                      : "संयोजयतु"}
                  </button>
                </td>
              </tr>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row">
        <div className="col-lg-12 form-group">
          <label>
            {lang == "english"
              ? "Are you teaching, preserving and promoting rare vedashakhas / vedajnana or those shakhas that are becoming extinct?"
              : lang == "hindi"
              ? "दुर्लभ अथवा लुप्तप्राय वेदशाखा / वेदज्ञान के अध्ययन, ध्यापन, संरक्षण और संवर्धन के लिए आपके द्वारा प्रयास किए गए है?"
              : "दुर्लभयोः वेदशाखा/वेदज्ञानयोः अध्ययनाय अध्यापनाय संरक्षणाय रवर्धनाय च भवता प्रयासाः विहिताः?"}
          </label>
          <select
            id={`vt_shakhas_that_are_dying`}
            name={`vt_shakhas_that_are_dying`}
            value={formik.values.vt_shakhas_that_are_dying || ""}
            onChange={(e) => {
              handleInputChange(`vt_shakhas_that_are_dying`, e);
              if (e.target.value == "no") {
                formik.setFieldValue("vt_teaching_preservation_details", "N/A");
              }
              if (e.target.value == "yes") {
                formik.setFieldValue("vt_teaching_preservation_details", "");
              }
            }}
            className="form-control   "
            onBlur={(e) => handleInputSave()}
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value="no">
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched && formik.errors?.vt_shakhas_that_are_dying ? (
            <div className="text-danger">
              {formik.errors?.vt_shakhas_that_are_dying &&
                formik.errors?.vt_shakhas_that_are_dying}
            </div>
          ) : null}
        </div>
        {formik.values.vt_shakhas_that_are_dying == "yes" ? (
          <div className="form-group col-12 form-group">
            <h5>
              {lang == "english"
                ? "Give details:"
                : lang == "hindi"
                ? "विवरण दीजिये।"
                : "विवरणं ददातु "}
            </h5>
            <div>
              <textarea
                className="form-control"
                name="vt_teaching_preservation_details"
                rows={2}
                value={formik.values.vt_teaching_preservation_details}
                maxLength={700}
                onChange={(e) =>
                  handleInputChange(`vt_teaching_preservation_details`, e)
                }
                onBlur={(e) => handleInputSave()}
              ></textarea>
              <p className="text-right">
                {formik.values.vt_teaching_preservation_details?.length}
                /700
                {lang == "english"
                  ? " Characters"
                  : lang == "hindi"
                  ? " शब्द"
                  : " शब्दः"}
              </p>
              {formik.touched &&
              formik.errors?.vt_teaching_preservation_details ? (
                <div className="text-danger">
                  {formik.errors?.vt_teaching_preservation_details &&
                    formik.errors?.vt_teaching_preservation_details}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* ------------------------------------------------------------------------------------ */}
      <div className="row">
        <div className="col-lg-12 form-group">
          <label>
            {lang == "english"
              ? "Have you taught any members of your family to study the Veds?"
              : lang == "hindi"
              ? "क्या आपने अपने निकट परिजनों को वेद अध्ययन करवाया है?"
              : "किं भवान् स्वकुटुम्बजनान् वेदाध्ययनं कारितवान्?"}
          </label>
          <select
            className="form-control   "
            id={`vt_have_you_taught_any_member_detail`}
            name={`vt_have_you_taught_any_member_detail`}
            value={formik.values.vt_have_you_taught_any_member_detail || ""}
            onChange={(e) => {
              handleInputChange(`vt_have_you_taught_any_member_detail`, e);
              if (e.target.value == "yes") {
                formik.setFieldValue("vt_family_members_taught_by_you", [
                  {
                    family_member_name: "",
                    relationship: "",
                    level_of_study: "",
                    other: "",
                    family_member_occupation: "",
                  },
                ]);
              }
              if (e.target.value == "no") {
                formik.setFieldValue("vt_family_members_taught_by_you", [
                  {
                    family_member_name: "N/A",
                    relationship: "N/A",
                    level_of_study: "N/A",
                    other: "N/A",
                    family_member_occupation: "N/A",
                  },
                ]);
              }
            }}
            onBlur={(e) => handleInputSave()}
          >
            <option>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes " : lang == "hindi" ? "हाँ " : "आम् "}
            </option>
            <option value="no">
              {lang == "english" ? "No " : lang == "hindi" ? "नहीं  " : "न  "}
            </option>
          </select>
          {formik.touched &&
          formik.errors?.vt_have_you_taught_any_member_detail ? (
            <div className="text-danger">
              {formik.errors?.vt_have_you_taught_any_member_detail &&
                formik.errors?.vt_have_you_taught_any_member_detail}
            </div>
          ) : null}
        </div>
        {formik.values.vt_have_you_taught_any_member_detail == "yes" ? (
          <div className="table-responsive form-group col-12">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    {lang == "english"
                      ? "Serial number"
                      : lang == "hindi"
                      ? "क्रम संख्या "
                      : "क्रमसङ्ख्या "}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Full Name"
                      : lang == "hindi"
                      ? "पूरा नाम"
                      : "सम्पूर्ण नाम"}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Relationship with you"
                      : lang == "hindi"
                      ? "आपसे सम्बन्ध"
                      : "भवता सह सम्बन्धः"}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Level of study"
                      : lang == "hindi"
                      ? "अध्ययन स्तर"
                      : "अध्ययन-स्तरः"}
                  </th>
                  <th>
                    {" "}
                    {lang == "english"
                      ? "Other     "
                      : lang == "hindi"
                      ? "अन्य "
                      : "अन्यः "}
                  </th>
                  <th>
                    {lang == "english"
                      ? "Present occupation"
                      : lang == "hindi"
                      ? "वर्तमान व्यवसाय"
                      : "वर्तमान-वृत्तिः"}
                  </th>
                  <th width="120">
                    {lang == "english"
                      ? "Action"
                      : lang == "hindi"
                      ? "क्रिया"
                      : "क्रिया"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {formik.values?.vt_family_members_taught_by_you?.map(
                  (son, index) => (
                    <tr key={index}>
                      <td>{1 + index}</td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          id={`vt_family_members_taught_by_you[${index}].family_member_name`}
                          name={`vt_family_members_taught_by_you[${index}].family_member_name`}
                          value={
                            formik.values.vt_family_members_taught_by_you[index]
                              .family_member_name || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `vt_family_members_taught_by_you[${index}].family_member_name`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                        />
                        {formik.touched &&
                        formik.errors?.vt_family_members_taught_by_you &&
                        formik.errors?.vt_family_members_taught_by_you[index]
                          .family_member_name ? (
                          <div className="text-danger">
                            {formik.errors?.vt_family_members_taught_by_you[
                              index
                            ].family_member_name &&
                              formik.errors?.vt_family_members_taught_by_you[
                                index
                              ].family_member_name}
                          </div>
                        ) : null}
                      </td>
                      <td>
                        <input
                          type="text"
                          id={`vt_family_members_taught_by_you[${index}].relationship`}
                          name={`vt_family_members_taught_by_you[${index}].relationship`}
                          value={
                            formik.values.vt_family_members_taught_by_you[index]
                              .relationship || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `vt_family_members_taught_by_you[${index}].relationship`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                          className="form-control"
                        />
                        {formik.touched &&
                        formik.errors?.vt_family_members_taught_by_you &&
                        formik.errors?.vt_family_members_taught_by_you[index]
                          .relationship ? (
                          <div className="text-danger">
                            {formik.errors?.vt_family_members_taught_by_you[
                              index
                            ].relationship &&
                              formik.errors?.vt_family_members_taught_by_you[
                                index
                              ].relationship}
                          </div>
                        ) : null}
                      </td>
                      <td>
                        <select
                          id={`vt_family_members_taught_by_you[${index}].level_of_study`}
                          name={`vt_family_members_taught_by_you[${index}].level_of_study`}
                          value={
                            formik.values.vt_family_members_taught_by_you[index]
                              .level_of_study || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `vt_family_members_taught_by_you[${index}].level_of_study`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                          className="form-control"
                        >
                          <option value="">
                            ---
                            {lang == "english"
                              ? "Select"
                              : lang == "hindi"
                              ? "चुने"
                              : "चिनोतु"}
                            ---
                          </option>
                          <option value="Mulanta">
                            {" "}
                            {lang == "english"
                              ? "Mulanta"
                              : lang == "hindi"
                              ? "मूलांत"
                              : "मूलान्तः"}
                          </option>
                          <option value="Kramanta">
                            {lang == "english"
                              ? "Kramanta"
                              : lang == "hindi"
                              ? "क्रमांत "
                              : "क्रमान्त:"}
                          </option>
                          <option value="Ghananta">
                            {lang == "english"
                              ? "Ghananta"
                              : lang == "hindi"
                              ? "घनांत"
                              : "घनान्तः"}
                          </option>
                          <option value="Shadanga">
                            {lang == "english"
                              ? "Shadanga "
                              : lang == "hindi"
                              ? "षडङ्ग "
                              : "षडङ्गानि "}
                          </option>
                          <option value="Lakshana">
                            {lang == "english"
                              ? "Lakshana  "
                              : lang == "hindi"
                              ? "लक्षण  "
                              : "लक्षणम्  "}
                          </option>
                          <option value="Bhashya">
                            {lang == "english"
                              ? "Bhashya   "
                              : lang == "hindi"
                              ? "भाष्य   "
                              : "भाष्यम्   "}
                          </option>
                          <option value="Other">
                            {lang == "english"
                              ? "Other     "
                              : lang == "hindi"
                              ? "अन्य "
                              : "अन्यः "}
                          </option>
                        </select>
                        {formik.touched &&
                        formik.errors?.vt_family_members_taught_by_you &&
                        formik.errors?.vt_family_members_taught_by_you[index]
                          .level_of_study ? (
                          <div className="text-danger">
                            {formik.errors?.vt_family_members_taught_by_you[
                              index
                            ].level_of_study &&
                              formik.errors?.vt_family_members_taught_by_you[
                                index
                              ].level_of_study}
                          </div>
                        ) : null}
                      </td>

                      <td>
                        {formik.values.vt_family_members_taught_by_you[index]
                          .level_of_study == "Other" ? (
                          <>
                            <input
                              type="text"
                              id={`vt_family_members_taught_by_you[${index}].other`}
                              name={`vt_family_members_taught_by_you[${index}].other`}
                              value={
                                formik.values.vt_family_members_taught_by_you[
                                  index
                                ].other || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  `vt_family_members_taught_by_you[${index}].other`,
                                  e
                                )
                              }
                              onBlur={(e) => handleInputSave()}
                              className="form-control"
                            />
                            {formik.touched &&
                            formik.errors?.vt_family_members_taught_by_you &&
                            formik.errors?.vt_family_members_taught_by_you[
                              index
                            ].other ? (
                              <div className="text-danger">
                                {formik.errors?.vt_family_members_taught_by_you[
                                  index
                                ].other &&
                                  formik.errors
                                    ?.vt_family_members_taught_by_you[index]
                                    .other}
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          id={`vt_family_members_taught_by_you[${index}].family_member_occupation`}
                          name={`vt_family_members_taught_by_you[${index}].family_member_occupation`}
                          value={
                            formik.values.vt_family_members_taught_by_you[index]
                              .family_member_occupation || ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              `vt_family_members_taught_by_you[${index}].family_member_occupation`,
                              e
                            )
                          }
                          onBlur={(e) => handleInputSave()}
                          className="form-control"
                        />
                        {formik.touched &&
                        formik.errors?.vt_family_members_taught_by_you &&
                        formik.errors?.vt_family_members_taught_by_you[index]
                          .family_member_occupation ? (
                          <div className="text-danger">
                            {formik.errors?.vt_family_members_taught_by_you[
                              index
                            ].family_member_occupation &&
                              formik.errors?.vt_family_members_taught_by_you[
                                index
                              ].family_member_occupation}
                          </div>
                        ) : null}
                      </td>
                      <td>
                        {formik.values.vt_family_members_taught_by_you.length >=
                        2 ? (
                          <button
                            className="btn btn-outline-danger"
                            onClick={(e) =>
                              vt_family_members_taught_by_youRemoveRow(index, e)
                            }
                          >
                            {lang == "english"
                              ? "Remove"
                              : lang == "hindi"
                              ? "हटाएँ"
                              : "अपाकरोतु"}
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  )
                )}
                <tr className="border-bottom-0">
                  <td className="text-right" colspan="7">
                    <button
                      className="btn btn-outline-danger"
                      onClick={(e) => vt_family_members_taught_by_youAddRow(e)}
                    >
                      {lang == "english"
                        ? "Add"
                        : lang == "hindi"
                        ? "जोड़ें"
                        : "संयोजयतु"}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Section_3;
