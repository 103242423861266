import { useEffect, useState } from "react";

function Page4_affidavit_given_by_the_applicant({
  formData,
  formik,
  handleInputChange,
  handleInputSave,
  lang,
  fileUploadFunction,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(2);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.sa_applicant_Name, count);
    count = handleInputValue(formik?.values?.sa_applicant_file_string, count);
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(4, totalCount, actualCount);
  }, [actualCount, totalCount]);
  return (
    <div>
      <h3 className="mb-30">
        {lang == "english"
          ? "Affidavit given by the applicant"
          : lang == "hindi"
            ? "आवेदक द्वारा दिया गया शपथपत्र"
            : "आवेदकेन दत्तं शपथपत्रम्"}
      </h3>
      <div className="d-flex justify-content-start mb-30 align-items-center">
        <p>
          {lang == "english"
            ? "I (full name of the applicant)"
            : lang == "hindi"
              ? "मैं (आवेदक का पूरा नाम)"
              : "अहं (आवेदकस्य पूर्णं नाम)"}
        </p>
        <input
          type="text"
          name="sa_applicant_Name"
          id="sa_applicant_Name"
          className="form-control"
          style={{ width: "20%", marginLeft: "10px" }}
          onChange={(e) => handleInputChange("sa_applicant_Name", e)}
          value={formik.values.sa_applicant_Name}
          onBlur={(e) => {
            handleInputSave();
          }}
        />
        {formik.touched && formik.errors.sa_applicant_Name ? (
          <div className="text-danger">{formik.errors.sa_applicant_Name}</div>
        ) : null}
      </div>
      <div className="mb-30">
        <p>
          {lang == "english"
            ? "Do hereby solemnly certify that: "
            : lang == "hindi"
              ? "एतद् द्वारा सत्यनिष्ठा से यह प्रमाणित करता हूँ कि: "
              : "एतत् सत्यनिष्ठया प्रमाणयामि यत्;"}
        </p>
        <ul style={{ listStyle: "square", marginLeft: "30px" }}>
          <li>
            {lang == "english"
              ? "I am currently a full time student of vedavidya;"
              : lang == "hindi"
                ? "मैं वेदविद्या का वर्तमान में पूर्णकालिक विद्यार्थी हूँ;"
                : "अहं सम्प्रति वेदविद्यायाः पूर्णकालिकः छात्रः अस्मि;"}
          </li>
          <li>
            {lang == "english"
              ? "All the information given in this application (except E 12) is completely true ;"
              : lang == "hindi"
                ? "इस आवेदनपत्र में दी गई सभी जानकारियाँ (ई १२ को छोड़कर) पूर्णतया सत्य हैं;"
                : "अस्मिन् आवेदनपत्रे दत्ताः सर्वा: सूचनाः (ई १२ विहाय) सर्वथा सत्याः सन्ति;"}
          </li>
          <li>
            {lang == "english"
              ? "Answer to question E-13 is my personal opinion;"
              : lang == "hindi"
                ? "प्रश्न सं॰ ई-१३ का उत्तर मेरी व्यक्तिगत राय है;"
                : "प्रश्नक्रमाङ्कः ई-१३ इत्यस्य उत्तरं मम व्यक्तिगतं मतम् अस्ति : १."}
          </li>
          <li>
            {lang == "english"
              ? "All the annexures and certificates attached to this application are true."
              : lang == "hindi"
                ? "इस आवेदन से संलग्न सभी अनुबन्धों व प्रमाण पत्रों में दी गई जानकारियाँ सत्य हैं।"
                : "अस्मिन् आवेदनपत्रे संलग्नेषु सर्वेषु अनुलग्नकेषु प्रमाणपत्रेषु च दत्ताः सूचनाः च सत्याः सन्ति;"}
          </li>
        </ul>
        <br />
        <p>
          {lang == "english"
            ? "I accept that if this affidavit is found to be untrue, I may be disqualified for ever for applying for Bharatatma puraskar.I have signed this certificate after verifying all the facts."
            : lang == "hindi"
              ? "मैं स्वीकार करता हूँ कि यदि यह शपथ-पत्र असत्य पाया गया तो मैं सदा के लिए भारतात्मा पुरस्कार के लिये अयोग्य माना जा सकता हूँ। मैंने सभी तथ्यों की पुष्टि करने के बाद इस प्रमाण पत्र पर हस्ताक्षर कियें है।"
              : "अहं स्वीकरोमि यत् यदि एतत् शपथपत्रम् अलीकं ज्ञायते तर्हि अहं भारतात्मापुरस्कारस्य कृते सदा अयोग्यः भवेयम् इति। सर्वाणि तथ्यानि मया प्रमाणीकृत्य अस्मिन् प्रमाणपत्रे हस्ताक्षरं कृतम्।"}
        </p>
        <p>
          {lang == "english"
            ? "I fully understand the importance and implications of signing this affidavit."
            : lang == "hindi"
              ? "मैं इस शपथपत्र पर हस्ताक्षर करने के महत्त्व व निहितार्थ को पूरी तरह से समझता हूँ।"
              : "अस्मिन् शपथपत्रे हस्ताक्षरस्य महत्त्वं निहितार्थं च अहं पूर्णतया अवगच्छामि।"}
        </p>
      </div>
      <div className="mb-30">
        <p className="d-flex mt-30 mb-10">
          {lang == "english"
            ? "Full name of the applicant"
            : lang == "hindi"
              ? "आवेदक का पूरा नाम"
              : "आवेदकस्य पूर्णं नाम"}
          {/* <input
            type="text"
            name="sa_applicant_full_name"
            id="sa_applicant_full_name"
            className="form-control"
            style={{ width: "20%", marginLeft: "10px" }}
            onChange={(e) => handleInputChange("sa_applicant_full_name", e)}
          />
          {formik.touched && formik.errors.sa_applicant_full_name ? (
            <div className="text-danger">
              {formik.errors.sa_applicant_full_name}
            </div>
          ) : null} */}
          {formik.values.sa_applicant_Name ? (
            <p>&nbsp; - {formik.values.sa_applicant_Name}</p>
          ) : (
            <p> &nbsp; ____________________________</p>
          )}
        </p>
        <p className="d-flex mt-30 mb-10">
          {lang == "english"
            ? "Signature of applicant"
            : lang == "hindi"
              ? "आवेदक के हस्ताक्षर"
              : "आवेदकस्य हस्ताक्षरम्"}
          <p> &nbsp; ____________________________</p>
          {/* <input
            type="text"
            name="sa_applicant_signature"
            id="sa_applicant_signature"
            className="form-control"
            style={{ width: "20%", marginLeft: "10px" }}
            onChange={(e) => handleInputChange("sa_applicant_signature", e)}
          />
          {formik.touched && formik.errors.sa_applicant_signature ? (
            <div className="text-danger">
              {formik.errors.sa_applicant_signature}
            </div>
          ) : null} */}
        </p>
        <p className="d-flex mt-30 mb-10">
          {lang == "english"
            ? "Location"
            : lang == "hindi"
              ? "स्थान"
              : "स्थानम्"}
          {/* <input
            type="text"
            name="sa_applicant_location"
            id="sa_applicant_location"
            className="form-control"
            style={{ width: "20%", marginLeft: "10px" }}
            onChange={(e) => handleInputChange("sa_applicant_location", e)}
          />
          {formik.touched && formik.errors.sa_applicant_location ? (
            <div className="text-danger">{formik.errors.sa_applicant_location}</div>
          ) : null} */}
          <p> &nbsp; ____________________________</p>
        </p>
        <p className="d-flex mt-30 mb-10">
          {lang == "english" ? "Date" : lang == "hindi" ? "दिनांक" : "दिनाङ्कः"}
          <p> &nbsp; ____________________________</p>
          {/* <input
            type="date"
            name="sa_applicant_date"
            id="sa_applicant_date"
            className="form-control"
            style={{ width: "20%", marginLeft: "10px" }}
            onChange={(e) => handleInputChange("sa_applicant_date", e)}
          />
          {formik.touched && formik.errors.sa_applicant_date ? (
            <div className="text-danger">{formik.errors.sa_applicant_date}</div>
          ) : null} */}
        </p>
      </div>
      <div className="d-flex mb-30 noPrint">
        <div className="noPrint">
          <label className="d-block">Download Affidavit</label>
          <button
            className={`btn btn-outline-danger noPrint`}
            onClick={(e) => {
              e.preventDefault();
              window.print();
            }}
          >
            Download
          </button>
        </div>
        <div className="col-lg-4 noPrint">
          <label>Upload affidavit</label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png, .pdf"
            className={`form-control noPrint`}
            id="sa_applicant_file"
            name="sa_applicant_file"
            onChange={async (e) => {
              e.preventDefault();
              if (e.target.files.length > 0) {
                await fileUploadFunction(
                  "sa_applicant_file_string",
                  e.target.files[0]
                );
                await handleInputSave();
              }
            }}
            onBlur={(e) => {
              handleInputSave(e);
            }}
          />
          {formik.values?.sa_applicant_file_string && (
            <button
              className="uploadLink"
              onClick={(e) => {
                e.preventDefault();
                window.open(
                  `${process.env.REACT_APP_MEDIA_URL}/${formik.values.sa_applicant_file_string}`,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              {`${formik.values.sa_applicant_file_string.replace(
                "upload-file/",
                ""
              )}`}
            </button>
          )}
          {formik.touched && formik.errors.sa_applicant_file_string ? (
            <div className="text-danger">
              {formik.errors.sa_applicant_file_string}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Page4_affidavit_given_by_the_applicant;
