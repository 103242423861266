import React from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/AdminNavbar";

const AdminMain = () => {
    return (
        <>
            <Navbar />
            <div className="MainContainer">
                <Outlet />
            </div>
        </>
    )
}

export default AdminMain
