import React, { useEffect, useState } from "react";
import "./style.css";
function Page3_General_ingormation({
  formData,
  formik,
  handleInputChange,
  addOtherVentureDetails,
  removeOtherVentureDetails,
  lang,
  handleInputSave,
  isPageComplete,
}) {
  const [totalCount, setTotalCount] = useState(13);
  const [actualCount, setActualCount] = useState(0);

  const handleInputValue = (value, count) => {
    if (value === "") {
      return count > 0 ? count - 1 : count;
    }
    if (value === undefined) {
      return count + 0;
    }
    if (value !== undefined) {
      return count + 1;
    }
  };

  useEffect(() => {
    let count = 0;
    let total_count = totalCount;
    count = handleInputValue(formik?.values?.gi_marriage_status, count);
    count = handleInputValue(formik?.values?.gi_carry_dand, count);
    count = handleInputValue(formik?.values?.gi_sandhyavandan_frequency, count);
    count = handleInputValue(formik?.values?.gi_place_of_living, count);
    count = handleInputValue(formik?.values?.gi_agnikarya, count);
    count = handleInputValue(formik?.values?.gi_practice_yoga, count);
    count = handleInputValue(formik?.values?.gi_routine_in_vedavidyalay, count);
    count = handleInputValue(formik?.values?.gi_ved_level, count);
    count = handleInputValue(formik?.values?.gi_interest_in, count);
    count = handleInputValue(formik?.values?.gi_career_goal, count);
    count = handleInputValue(formik?.values?.gi_life_purpose, count);
    count = handleInputValue(formik?.values?.gi_other_venture, count);
    count = handleInputValue(formik?.values?.gi_reason, count);
    setActualCount(count);
  }, [formik.values]);

  useEffect(() => {
    isPageComplete(3, totalCount, actualCount);
  }, [actualCount, totalCount]);
  return (
    <div>
      <h3>
        {lang == "english"
          ? "General information"
          : lang == "hindi"
          ? "सामान्य जानकारी"
          : "सामान्य-सूचना"}
      </h3>
      <p className="text-primary mb-30">
        {lang == "english"
          ? "All fields are mandatory"
          : lang == "hindi"
          ? "सभी प्रश्न अनिवार्य हैं।"
          : "सर्वे प्रश्नाः अनिवार्याः सन्ति।"}
      </p>
      <div className="row">
        <div className="col-lg-4 form-group ">
          <label htmlFor="marriage_status">
            {lang == "english"
              ? "Are you married?"
              : lang == "hindi"
              ? "क्या आप विवाहित है?"
              : "किं भवान् विवाहितः अस्ति?"}
          </label>
          <select
            id="gi_marriage_status"
            name="gi_marriage_status"
            className="form-control"
            value={formik.values.gi_marriage_status}
            onChange={(e) => handleInputChange("gi_marriage_status", e)}
            onBlur={(e) => {
              handleInputSave();
            }}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes" : lang == "hindi" ? "हाँ" : "आम्"}
            </option>
            <option value="no">
              {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
            </option>
          </select>
          {formik.touched && formik.errors.gi_marriage_status ? (
            <div className="text-danger">
              {formik.errors.gi_marriage_status}
            </div>
          ) : null}
        </div>
        <div className="col-lg-4 form-group ">
          <label htmlFor="carry_dand">
            {lang == "english"
              ? "Do you carry a dand ?"
              : lang == "hindi"
              ? "क्या आप दण्ड धारण करते है?"
              : "किं भवान् दण्डधारणं करोति?"}
          </label>
          <select
            id="gi_carry_dand"
            name="gi_carry_dand"
            className="form-control"
            onChange={(e) => handleInputChange("gi_carry_dand", e)}
            value={formik.values.gi_carry_dand}
            onBlur={(e) => {
              handleInputSave();
            }}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes" : lang == "hindi" ? "हाँ" : "आम्"}
            </option>
            <option value="no">
              {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
            </option>
          </select>
          {formik.touched && formik.errors.gi_carry_dand ? (
            <div className="text-danger">{formik.errors.gi_carry_dand}</div>
          ) : null}
        </div>
        <div className="col-lg-4 form-group ">
          <label htmlFor="sandhyavandan_frequency">
            {lang == "english"
              ? "How many times a day do you do sandhyavandan?"
              : lang == "hindi"
              ? "आप दिन में कितनी बार सन्ध्यावन्दन करते हैं?"
              : "भवान् दिने कतिवारं सन्ध्यावन्दनं करोति?"}
          </label>
          <select
            id="gi_sandhyavandan_frequency"
            name="gi_sandhyavandan_frequency"
            className="form-control"
            onChange={(e) => handleInputChange("gi_sandhyavandan_frequency", e)}
            value={formik.values.gi_sandhyavandan_frequency}
            onBlur={(e) => {
              handleInputSave();
            }}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="no">
              {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
            </option>
            <option value="once">
              {lang == "english"
                ? "Once"
                : lang == "hindi"
                ? "एक बार"
                : "एकवारम्"}
            </option>
            <option value="twice">
              {lang == "english"
                ? "Twice"
                : lang == "hindi"
                ? "दो बार"
                : "द्विवारम्"}
            </option>
            <option value="thrice">
              {lang == "english"
                ? "Three times"
                : lang == "hindi"
                ? "तीन बार"
                : "त्रिवारम्"}
            </option>
          </select>
          {formik.touched && formik.errors.gi_sandhyavandan_frequency ? (
            <div className="text-danger">
              {formik.errors.gi_sandhyavandan_frequency}
            </div>
          ) : null}
        </div>
        <div className="col-lg-4 form-group ">
          <label htmlFor="place_of_living">
            {lang == "english"
              ? "Where do you live?"
              : lang == "hindi"
              ? "आप कहाँ रहते हैं?"
              : "भवान् कुत्र निवसति?"}
          </label>
          <select
            id="gi_place_of_living"
            name="gi_place_of_living"
            className="form-control"
            onChange={(e) => handleInputChange("gi_place_of_living", e)}
            value={formik.values.gi_place_of_living}
            onBlur={(e) => {
              handleInputSave();
            }}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="in_vedvidyalaya">
              {lang == "english"
                ? "In Vedavidyalaya"
                : lang == "hindi"
                ? "वेदविद्यालय में"
                : "वेदविद्यालये"}
            </option>
            <option value="in_vedadhyapaka_house">
              {lang == "english"
                ? "In vedaadhyapaka’s house"
                : lang == "hindi"
                ? "वेदाध्यापक के गृह में"
                : "वेदाध्यापकगृहे"}
            </option>
            <option value="in_father_house">
              {lang == "english"
                ? "In father’s house"
                : lang == "hindi"
                ? "पिता के गृह में"
                : "पितृगृहे"}
            </option>
          </select>
          {formik.touched && formik.errors.gi_place_of_living ? (
            <div className="text-danger">
              {formik.errors.gi_place_of_living}
            </div>
          ) : null}
        </div>
        <div className="col-lg-8 form-group ">
          <label htmlFor="agnikarya">
            {lang == "english"
              ? "Do you do Agnikarya or Agnihotra or Samidhom regularly? If yes how many times a day?"
              : lang == "hindi"
              ? "क्या आप नित्य अग्निकार्य अथवा अग्निहोत्र अथवा समिद् होम करते हैं? यदि हाँ तो दिन में कितनी बार?"
              : "किं भवान् नित्यम् अग्निकार्यम् अग्निहोत्रं समिद्धोमं वा करोति? यदि आम् तर्हि दिवसे कतिवारम्?"}
          </label>
          <select
            id="gi_agnikarya"
            name="gi_agnikarya"
            className="form-control"
            onChange={(e) => handleInputChange("gi_agnikarya", e)}
            value={formik.values.gi_agnikarya}
            onBlur={(e) => {
              handleInputSave();
            }}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="no">
              {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
            </option>
            <option value="once">
              {lang == "english"
                ? "Once"
                : lang == "hindi"
                ? "एक बार"
                : "एकवारम्"}
            </option>
            <option value="twice">
              {lang == "english"
                ? "Twice"
                : lang == "hindi"
                ? "दो बार"
                : "द्विवारम्"}
            </option>
          </select>
          {formik.touched && formik.errors.gi_agnikarya ? (
            <div className="text-danger">{formik.errors.gi_agnikarya}</div>
          ) : null}
        </div>
        <div className="col-lg-4 form-group ">
          <label htmlFor="practice_yoga">
            {lang == "english"
              ? "Do you practice yoga regularly?"
              : lang == "hindi"
              ? "क्या आप नित्य योगाभ्यास करते हैं?"
              : "किं भवान् नित्य-योगाभ्यासं करोति?"}
          </label>
          <select
            id="gi_practice_yoga"
            name="gi_practice_yoga"
            className="form-control"
            value={formik.values.gi_practice_yoga || ""}
            onChange={(e) => handleInputChange("gi_practice_yoga", e)}
            onBlur={(e) => {
              handleInputSave();
            }}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes" : lang == "hindi" ? "हाँ" : "आम्"}
            </option>
            <option value="no">
              {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
            </option>
          </select>
          {formik.touched && formik.errors.gi_practice_yoga ? (
            <div className="text-danger">{formik.errors.gi_practice_yoga}</div>
          ) : null}
        </div>
        <div className="col-12">
          <label htmlFor="routine_in_vedavidyalay">
            {lang == "english"
              ? "Describe your daily routine while at the vedavidyalay-"
              : lang == "hindi"
              ? "वेदविद्यालय में अपनी दिनचर्या का वर्णन कीजिये-"
              : "वेदविद्यालये भवतः दैनन्दिनकार्यक्रमस्य वर्णनं करोतु-"}
          </label>
          <textarea
            name="gi_routine_in_vedavidyalay"
            id="gi_routine_in_vedavidyalay"
            value={formik.values.gi_routine_in_vedavidyalay || ""}
            onChange={(e) => handleInputChange("gi_routine_in_vedavidyalay", e)}
            className="form-control"
            maxLength={700}
            onBlur={(e) => {
              handleInputSave();
            }}
          ></textarea>
          <p className="text-right mb-3">
            {formik.values.gi_routine_in_vedavidyalay.length}
            /700{" "}
            {lang == "english"
              ? " Characters"
              : lang == "hindi"
              ? " शब्द"
              : " शब्दः"}
          </p>
          {formik.touched && formik.errors.gi_routine_in_vedavidyalay ? (
            <div className="text-danger">
              {formik.errors.gi_routine_in_vedavidyalay}
            </div>
          ) : null}
        </div>
        <div className="col-lg-4 form-group ">
          <label htmlFor="ved_level">
            {lang == "english"
              ? "To what level do you want to study the ved?"
              : lang == "hindi"
              ? "आप किस स्तर तक वेदाध्ययन करना चाहते हैं?"
              : "भवान् कस्मिन् स्तरपर्यन्तं वेदाध्ययनं कर्तुम् इच्छति ?"}
          </label>
          <select
            id="gi_ved_level"
            name="gi_ved_level"
            className="form-control"
            value={formik.values.gi_ved_level || ""}
            onChange={(e) => handleInputChange("gi_ved_level", e)}
            onBlur={(e) => {
              handleInputSave();
            }}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="level-1">
              {lang == "english"
                ? "Level-1"
                : lang == "hindi"
                ? "स्तर-१"
                : "स्तरः-१"}
            </option>
            <option value="level-2">
              {lang == "english"
                ? "Level-2"
                : lang == "hindi"
                ? "स्तर-२"
                : "स्तरः-२"}
            </option>
          </select>
          {formik.touched && formik.errors.gi_ved_level ? (
            <div className="text-danger">{formik.errors.gi_ved_level}</div>
          ) : null}
        </div>
        <div className="col-lg-4 form-group ">
          <label htmlFor="interest_in">
            {lang == "english"
              ? "Are you interested in the Paurohitya or Archkatva?"
              : lang == "hindi"
              ? "क्या आप पौरोहित्य अथवा अर्चकत्व में रुचि रखते हैं?"
              : "किं भवतः पौरोहित्ये अर्चकत्वे वा रुचिः अस्ति ?"}
          </label>
          <select
            id="gi_interest_in"
            name="gi_interest_in"
            className="form-control"
            value={formik.values.gi_interest_in || ""}
            onChange={(e) => handleInputChange("gi_interest_in", e)}
            onBlur={(e) => {
              handleInputSave();
            }}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes" : lang == "hindi" ? "हाँ" : "आम्"}
            </option>
            <option value="no">
              {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
            </option>
          </select>
          {formik.touched && formik.errors.gi_interest_in ? (
            <div className="text-danger">{formik.errors.gi_interest_in}</div>
          ) : null}
        </div>
        <div className="col-lg-4 form-group">
          <label htmlFor="career_goal">
            {lang == "english"
              ? "What do you want as your vocation when you grow up?"
              : lang == "hindi"
              ? "आप बड़े होकर क्या बनना चाहते है?"
              : "भवान् वर्धमानः किं भवितुम् इच्छति ?"}
          </label>
          <input
            type="text"
            name="gi_career_goal"
            id="gi_career_goal"
            value={formik.values.gi_career_goal || ""}
            onChange={(e) => handleInputChange("gi_career_goal", e)}
            className="form-control"
            onBlur={(e) => {
              handleInputSave();
            }}
          />
          {formik.touched && formik.errors.gi_career_goal ? (
            <div className="text-danger">{formik.errors.gi_career_goal}</div>
          ) : null}
        </div>
        <div className="col-12">
          <label htmlFor="life_purpose">
            {lang == "english"
              ? "Which vocation do you want to pursue for your livelihood?"
              : lang == "hindi"
              ? "आप कौनसा व्यवसाय आजीविका के रूप में करना चाहते हैं?"
              : "भवान् कं व्यवसायम् आजीविकारूपेण कर्तुम् इच्छति?"}
          </label>
          <textarea
            name="gi_life_purpose"
            id="gi_life_purpose"
            value={formik.values.gi_life_purpose || ""}
            onChange={(e) => handleInputChange("gi_life_purpose", e)}
            className="form-control"
            max={700}
            onBlur={(e) => {
              handleInputSave();
            }}
          ></textarea>
          <p className="text-right mb-3">
            {formik.values.gi_life_purpose.length}
            /700
            {lang == "english"
              ? " Characters"
              : lang == "hindi"
              ? " शब्द"
              : " शब्दः"}
          </p>
          {/* <input
            type="text"
            name="gi_life_purpose"
            id="gi_life_purpose"
            value={formik.values.gi_life_purpose || ""}
            onChange={(e) => handleInputChange("gi_life_purpose", e)}
            className="form-control"
          /> */}
          {formik.touched && formik.errors.gi_life_purpose ? (
            <div className="text-danger">{formik.errors.gi_life_purpose}</div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 form-group">
          <label htmlFor="other_venture">
            {lang == "english"
              ? "Other than Vedadhyayan, have you assisted in any other venture or activity."
              : lang == "hindi"
              ? "वेदाध्ययन के साथ आपने किसी अन्य उपक्रम में सहयोग दिया है?"
              : "वेदाध्ययनेन सह भवता अन्यस्मिन् कस्मिंश्चित्  उद्यमे साहाय्यं  कृतं वा?"}
          </label>
          <select
            id="gi_other_venture"
            name="gi_other_venture"
            className="form-control"
            value={formik.values.gi_other_venture || ""}
            onChange={(e) => {
              handleInputChange("gi_other_venture", e);
              if (e.target.value == "yes") {
                formik.setFieldValue(`gi_other_venture_details`, [
                  {
                    undertaking_name: "",
                    organiser: "",
                    location: "",
                    year: "",
                  },
                ]);
              }
              if (e.target.value == "no") {
                formik.setFieldValue(`gi_other_venture_details`, [
                  {
                    undertaking_name: "N/A",
                    organiser: "N/A",
                    location: "N/A",
                    year: "N/A",
                  },
                ]);
              }
            }}
            onBlur={(e) => {
              handleInputSave();
            }}
          >
            <option selected>
              ---
              {lang == "english"
                ? "Select"
                : lang == "hindi"
                ? "चुने"
                : "चिनोतु"}
              ---
            </option>
            <option value="yes">
              {lang == "english" ? "Yes" : lang == "hindi" ? "हाँ" : "आम्"}
            </option>
            <option value="no">
              {lang == "english" ? "No" : lang == "hindi" ? "नहीं" : "न"}
            </option>
          </select>
          {formik.touched && formik.errors.gi_other_venture ? (
            <div className="text-danger">{formik.errors.gi_other_venture}</div>
          ) : null}
        </div>
        <div className="col-lg-12">
          {formik.values.gi_other_venture == "yes" ? (
            <div className="table-responsive mb-30">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      {lang == "english"
                        ? "Serial Number"
                        : lang == "hindi"
                        ? "क्रमांक "
                        : "क्रमाङ्कः"}
                    </th>
                    <th>
                      {lang == "english"
                        ? "Name of the undertaking"
                        : lang == "hindi"
                        ? "उपक्रम का नाम"
                        : "उपक्रमस्य नाम"}
                    </th>
                    <th>
                      {lang == "english"
                        ? "Organiser "
                        : lang == "hindi"
                        ? "आयोजक  "
                        : "आयोजकः  "}
                    </th>
                    <th>
                      {lang == "english"
                        ? "Location"
                        : lang == "hindi"
                        ? "स्थान"
                        : "स्थानम्‌"}
                    </th>
                    <th>
                      {lang == "english"
                        ? "Year "
                        : lang == "hindi"
                        ? "वर्ष "
                        : "वर्षम् "}
                    </th>
                    <th width="120">
                      {lang == "english"
                        ? "Action"
                        : lang == "hindi"
                        ? "क्रिया"
                        : "क्रिया"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.gi_other_venture_details?.map(
                    (item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name={`gi_other_venture_details[${index}].undertaking_name`}
                            id={`gi_other_venture_details[${index}].undertaking_name`}
                            value={
                              formik.values.gi_other_venture_details[index]
                                .undertaking_name || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                `gi_other_venture_details[${index}].undertaking_name`,
                                e
                              )
                            }
                            onBlur={(e) => {
                              handleInputSave();
                            }}
                          />
                          {formik.touched &&
                          formik.errors.gi_other_venture_details &&
                          formik.errors.gi_other_venture_details[index]
                            ?.undertaking_name ? (
                            <div className="text-danger">
                              {
                                formik.errors.gi_other_venture_details[index]
                                  ?.undertaking_name
                              }
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name={`gi_other_venture_details[${index}].organiser`}
                            id={`gi_other_venture_details[${index}].organiser`}
                            value={
                              formik.values.gi_other_venture_details[index]
                                .organiser || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                `gi_other_venture_details[${index}].organiser`,
                                e
                              )
                            }
                            onBlur={(e) => {
                              handleInputSave();
                            }}
                          />
                          {formik.touched &&
                          formik.errors.gi_other_venture_details &&
                          formik.errors.gi_other_venture_details[index]
                            ?.organiser ? (
                            <div className="text-danger">
                              {
                                formik.errors.gi_other_venture_details[index]
                                  ?.organiser
                              }
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name={`gi_other_venture_details[${index}].location`}
                            id={`gi_other_venture_details[${index}].location`}
                            value={
                              formik.values.gi_other_venture_details[index]
                                .location || ""
                            }
                            onChange={(e) =>
                              handleInputChange(
                                `gi_other_venture_details[${index}].location`,
                                e
                              )
                            }
                            onBlur={(e) => {
                              handleInputSave();
                            }}
                          />
                          {formik.touched &&
                          formik.errors.gi_other_venture_details &&
                          formik.errors.gi_other_venture_details[index]
                            ?.location ? (
                            <div className="text-danger">
                              {
                                formik.errors.gi_other_venture_details[index]
                                  ?.location
                              }
                            </div>
                          ) : null}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name={`gi_other_venture_details[${index}].year`}
                            id={`gi_other_venture_details[${index}].year`}
                            value={
                              formik.values.gi_other_venture_details[index]
                                .year || ""
                            }
                            onChange={(e) => {
                              if (
                                e.target.value.length <= 4 &&
                                e.target.value <= 2024
                              ) {
                                handleInputChange(
                                  `gi_other_venture_details[${index}].year`,
                                  e
                                );
                              }
                            }}
                            onBlur={(e) => {
                              handleInputSave();
                            }}
                          />
                          {formik.touched &&
                          formik.errors.gi_other_venture_details &&
                          formik.errors.gi_other_venture_details[index]
                            ?.year ? (
                            <div className="text-danger">
                              {
                                formik.errors.gi_other_venture_details[index]
                                  ?.year
                              }
                            </div>
                          ) : null}
                        </td>
                        <td>
                          {formik.values.gi_other_venture_details.length >=
                          2 ? (
                            <button
                              onClick={(e) =>
                                removeOtherVentureDetails(index, e)
                              }
                              type="button"
                              className="btn btn-outline-danger"
                            >
                              {lang == "english"
                                ? "Remove"
                                : lang == "hindi"
                                ? "हटाएँ"
                                : "अपाकरोतु"}
                            </button>
                          ) : null}
                        </td>
                      </tr>
                    )
                  )}
                  <tr className="border-bottom-0">
                    <td className="text-right" colspan="7">
                      <button
                        onClick={(e) => addOtherVentureDetails(e)}
                        type="button"
                        className="btn btn-danger"
                      >
                        {lang == "english"
                          ? "Add"
                          : lang == "hindi"
                          ? "जोड़ें"
                          : "संयोजयतु"}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="row mb-30">
        <div className="col-lg-12">
          <label htmlFor="reason">
            {lang == "english"
              ? "Why should you be given this award ? Give five main reasons with proof."
              : lang == "hindi"
              ? "आपको यह पुरस्कार क्यों दिया जाए? सप्रमाण पाँच प्रमुख कारण बताये।"
              : "भवते  पुरस्कारोऽयं किमर्थं प्रदातव्यः? सप्रमाणं पञ्च मुख्यकारणानि लिखतु।"}
          </label>
          <textarea
            name="gi_reason"
            id="gi_reason"
            value={formik.values.gi_reason || ""}
            onChange={(e) => handleInputChange("gi_reason", e)}
            className="form-control"
            max={700}
            onBlur={(e) => {
              handleInputSave();
            }}
          ></textarea>
          <p className="text-right mb-3">
            {formik.values.gi_reason.length}
            /700{" "}
            {lang == "english"
              ? " Characters"
              : lang == "hindi"
              ? " शब्द"
              : " शब्दः"}
          </p>
          {/* <input
            type="text"
            name="gi_reason"
            id="gi_reason"
            value={formik.values.gi_reason || ""}
            onChange={(e) => handleInputChange("gi_reason", e)}
            className="form-control"
          /> */}
          {formik.touched && formik.errors.gi_reason ? (
            <div className="text-danger">{formik.errors.gi_reason}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Page3_General_ingormation;
