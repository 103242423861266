import * as Yup from "yup";

const validationRules = {
  // PI Validatons
  pi_name: Yup.string().required("This field is required"),
  pi_adhar_card_no: Yup.string()
    .required("This field is required")
    .min(12, "Aadhaar card must be of 12 digits"),
  pi_date_of_birth: Yup.string().required("This field is required"),
  pi_place_of_birth: Yup.string().required("This field is required"),
  pi_fathers_name: Yup.string().required("This field is required"),
  pi_mothers_name: Yup.string().required("This field is required"),
  pi_rishi_gotra: Yup.string().required("This field is required"),
  pi_rishi_gotra_other: Yup.string().required("This field is required"),
  pi_Swavedshakha: Yup.string().required("This field is required"),
  pi_anyashakha: Yup.string().required("This field is required"),
  pi_full_corresponding_address_line_1: Yup.string().required(
    "This field is required"
  ),
  pi_full_correspondence_address_district: Yup.string().required(
    "This field is required"
  ),
  pi_full_correspondence_address_state: Yup.string().required(
    "This field is required"
  ),
  pi_full_correspondence_address_pincode: Yup.string()
    .required("This field is required")
    .min(6, "Pincode must be of 6 digits"),
  pi_permanent_home_address_line_1: Yup.string().required(
    "This field is required"
  ),
  pi_permanent_address_district: Yup.string().required(
    "This field is required"
  ),
  pi_permanent_address_state: Yup.string().required("This field is required"),
  pi_permanent_address_pincode: Yup.string()
    .required("This field is required")
    .min(6, "Pincode must be of 6 digits"),
  pi_mobile_no: Yup.number()
    .required("This field is required")
    .min(10, "Mobile no. must be of 10 digits"),
  pi_email_address: Yup.string()
    .required("This field is required")
    .email("Invalid email address"),
  pi_language: Yup.string().required("This field is required"),
  pi_have_brothers_sisters: Yup.string().required("This field is required"),
  pi_brothers_sisters: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("This field is required"),
      gender: Yup.string().required("This field is required"),
      age: Yup.number().required("This field is required"),
      studying_the_vedas: Yup.string().required("This field is required"),
    })
  ),
  pi_present_guruji_name: Yup.string().required("This field is required"),
  pi_guruji_mobile: Yup.string()
    .required("This field is required")
    .min(10, "Mobile no. must be of 10 digits"),
  pi_passport_photo_string: Yup.string().required("This field is required"),

  // VI Validations
  vi_studied_veda: Yup.string().required("This field is required"),
  vi_studied_vedasakha: Yup.string().required("This field is required"),
  vi_studied_vedasakha_other: Yup.string().required("This field is required"),
  vi_more_than_one_veda_option: Yup.string().required("This field is required"),
  vi_more_than_one_veda: Yup.string().required("This field is required"),
  vi_ved_and_passed_of_ved_examinations: Yup.array().of(
    Yup.object().shape({
      vi_month_and_year: Yup.string().required("This field is required"),
    })
  ),
  vedadhyapaka_vedvidyalaya_details: Yup.array().of(
    Yup.object().shape({
      from: Yup.string().required("This field is required"),
      till: Yup.string().required("This field is required"),
      vedadhyapak_name: Yup.string().required("This field is required"),
      vedadhyapak_mobile: Yup.number()
        .required("This field is required")
        .min(10, "Mobile no. must be of 10 digits"),
      vedvidyalaya_name: Yup.string().required("This field is required"),
    })
  ),
  is_relative: Yup.string().required("This field is required"),
  relative_details: Yup.array().of(
    Yup.object().shape({
      serial_no: Yup.string().required("This field is required"),
      vedadhyapakName: Yup.string().required("This field is required"),
      relation: Yup.string().required("This field is required"),
      other_relation: Yup.string().required("This field is required"),
    })
  ),
  grantha_details: Yup.array().of(
    Yup.object().shape({
      grantha_name: Yup.string().required("This field is required"),
      start_study: Yup.string().required("This field is required"),
      end_study: Yup.string().required("This field is required"),
      exam_month_year: Yup.string().required("This field is required"),
      certificate_string: Yup.string().required("This field is required"),
    })
  ),
  have_received_ward: Yup.string().required("This field is required"),
  award_details: Yup.array().of(
    Yup.object().shape({
      award_month_year: Yup.string().required("This field is required"),
      award_name: Yup.string().required("This field is required"),
      awarding_organisation: Yup.string().required("This field is required"),
      certificate_string: Yup.string().required("This field is required"),
    })
  ),

  // GI Validations
  gi_marriage_status: Yup.string().required("This field is required"),
  gi_carry_dand: Yup.string().required("This field is required"),
  gi_sandhyavandan_frequency: Yup.string().required("This field is required"),
  gi_place_of_living: Yup.string().required("This field is required"),
  gi_agnikarya: Yup.string().required("This field is required"),
  gi_practice_yoga: Yup.string().required("This field is required"),
  gi_routine_in_vedavidyalay: Yup.string().required("This field is required"),
  gi_ved_level: Yup.string().required("This field is required"),
  gi_interest_in: Yup.string().required("This field is required"),
  gi_career_goal: Yup.string().required("This field is required"),
  gi_life_purpose: Yup.string().required("This field is required"),
  gi_other_venture: Yup.string().required("This field is required"),
  gi_other_venture_details: Yup.array().of(
    Yup.object().shape({
      undertaking_name: Yup.string().required("This field is required"),
      organiser: Yup.string().required("This field is required"),
      location: Yup.string().required("This field is required"),
      year: Yup.string().required("This field is required"),
    })
  ),
  gi_reason: Yup.string().required("This field is required"),

  // SA Validations
  sa_applicant_Name: Yup.string().required("This field is required"),
  sa_applicant_file_string: Yup.string().required("This field is required"),

  // G Validations
  g_guruji_name_info: Yup.string().required("This field is required"),
  g_guruji_education_name: Yup.string().required("This field is required"),
  g_applicant_conduct_comments: Yup.string().required("This field is required"),
  g_affidavit_file_string: Yup.string().required("This field is required"),
  // heard_from_applicant: Yup.string().required("This field is required"),

  // GA Validations
  ga_teacher_full_name: Yup.string().required("This field is required"),
  ga_applicant_name: Yup.string().required("This field is required"),
  ga_applicant_file_string: Yup.string().required("This field is required"),
};

export default validationRules;
